import {
    DatabaseOutline,
    StylusNoteOutline,
    TextureOutline,
} from "@biggeo/bg-ui/lab/icons";
import type { MainPaletteDefinition } from "@biggeo/bg-ui/lab/theme";
import MapboxDraw, { type DrawCustomModeThis } from "@mapbox/mapbox-gl-draw";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import map from "lodash/map";
import range from "lodash/range";
import uuid from "react-uuid";
import { match } from "ts-pattern";
import ActivityZoneOutline from "../../icons/ActivityZoneOutline";
import AdjustOutline from "../../icons/AdjustOutline";
import PolylineOutline from "../../icons/PolylineOutline";
import { closePolygon } from "../../utils/utils";
import { FunctionType, LastFunctionType } from "./utils";

export type VertexCoordPath =
    | "0.0"
    | "0.1"
    | "0.2"
    | "0.3"
    | "1.0"
    | "2.0"
    | "3.0"
    | "4.0";

// ███████╗████████╗██╗   ██╗██╗     ███████╗███████╗
// ██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝██╔════╝
// ███████╗   ██║    ╚████╔╝ ██║     █████╗  ███████╗
// ╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝  ╚════██║
// ███████║   ██║      ██║   ███████╗███████╗███████║
// ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝╚══════╝

export const getMatchingIconFromLastType = (
    lastFunctionType?: LastFunctionType,
    color?: keyof MainPaletteDefinition | "inherit"
) => {
    return match(lastFunctionType)
        .with(FunctionType.polygon, () => <PolylineOutline color={color} />)
        .with(FunctionType.radius, () => <AdjustOutline color={color} />)
        .with(FunctionType.square, () => <ActivityZoneOutline color={color} />)
        .with(FunctionType.fillArea, () => <TextureOutline color={color} />)
        .with(FunctionType.draw, () => <StylusNoteOutline color={color} />)
        .with(FunctionType.data, () => <DatabaseOutline color={color} />)
        .otherwise(() => <PolylineOutline color={color} />);
};

export const getShapeIcon = ({
    functionType,
    color,
    lastFunctionType,
}: {
    functionType: FunctionType;
    color?: keyof MainPaletteDefinition;
    lastFunctionType?: LastFunctionType;
}) => {
    switch (functionType) {
        case FunctionType.polygon:
            return <PolylineOutline />;
        case FunctionType.radius:
            return <AdjustOutline />;
        case FunctionType.square:
            return <ActivityZoneOutline />;
        case FunctionType.fillArea:
            return <TextureOutline />;
        case FunctionType.draw:
            return <StylusNoteOutline />;
        case FunctionType.data:
            return <DatabaseOutline />;
        default:
            return getMatchingIconFromLastType(lastFunctionType, color);
    }
};

// ███╗   ███╗ ██████╗ ██████╗ ███████╗███████╗
// ████╗ ████║██╔═══██╗██╔══██╗██╔════╝██╔════╝
// ██╔████╔██║██║   ██║██║  ██║█████╗  ███████╗
// ██║╚██╔╝██║██║   ██║██║  ██║██╔══╝  ╚════██║
// ██║ ╚═╝ ██║╚██████╔╝██████╔╝███████╗███████║
// ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚══════╝╚══════╝

export const disableDragPan = (context: DrawCustomModeThis) => {
    setTimeout(() => {
        if (!context.map || !context.map.doubleClickZoom) return;
        // Always disable here, as it's necessary in some cases.
        context.map.dragPan.disable();
    }, 0);
};

// any type instead of DrawCustomModeThis to access _ctx. For some reason, it exists
// but it's not accessible.
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const enableDragPan = (context: any) => {
    setTimeout(() => {
        // First check we've got a map and some context.
        if (
            !context.map ||
            !context.map.dragPan ||
            !context._ctx ||
            !context._ctx.store ||
            !context._ctx.store.getInitialConfigValue
        )
            return;
        // Now check initial state wasn't false (we leave it disabled if so)
        if (!context._ctx.store.getInitialConfigValue("dragPan")) return;
        context.map.dragPan.enable();
    }, 0);
};

export const getCircleVertices = (
    polygon?: GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>
): GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[] => {
    if (!polygon) return [];

    const { properties, geometry } = polygon;

    const vertices: GeoJSON.Position[] = geometry.coordinates[0].slice(0, -1);

    // There are usually 64 vertices, we want only 4 so we're skipping 16 at a time
    const limitedVertices = vertices.filter((_, index) => index % 16 === 0);

    const points = limitedVertices.map((vertex) => {
        const originalIndex = vertices.indexOf(vertex);

        return MapboxDraw.lib.createVertex(
            properties?.id,
            vertices[originalIndex],
            `0.${originalIndex}`,
            false
        );
    });

    return points;
};

export const limitCircleVertices = (
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    geojson: any
): GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[] | null => {
    if (!geojson.properties.user_isCircle) return null;

    return getCircleVertices(geojson);
};

export const updateSquareCoordinates = ({
    movedVertex,
    movedVertexCoordPath,
    feature,
}: {
    readonly movedVertex: number[];
    readonly movedVertexCoordPath: VertexCoordPath;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    readonly feature: any;
}) => {
    const featureCoordinates: number[][] = feature.coordinates[0];
    const latitude = movedVertex[1];
    const longitude = movedVertex[0];

    /*
     * Edges = 0.0: bottom left, 0.1: bottom right, 0.2: top right, 0.3: top left.
     * Midpoints = 1.0: bottom, 2.0: right, 3.0: top, 4.0: left.
     *
     * When 0.0 moves, 0.1 and 0.3 need to move too. For 0.1, it's 0.2 and 0.0.
     * For 0.2, it's 0.1 and 0.3. For 0.3, it's 0.2 and 0.0.
     *
     * When 1.0 moves, 0.0 and 0.1 need to move too. For 2.0, it's 0.2 and 0.1.
     * For 3.0, it's 0.3 and 0.2. For 4.0, it's 0.0 and 0.3.
     */

    match(movedVertexCoordPath)
        .with("0.0", () => {
            feature.updateCoordinate("0.0", movedVertex[0], movedVertex[1]);

            feature.updateCoordinate("0.1", featureCoordinates[1][0], latitude);

            feature.updateCoordinate(
                "0.3",
                longitude,
                featureCoordinates[3][1]
            );
        })
        .with("0.1", () => {
            feature.updateCoordinate("0.1", movedVertex[0], movedVertex[1]);

            feature.updateCoordinate(
                "0.2",
                longitude,
                featureCoordinates[2][1]
            );

            feature.updateCoordinate("0.0", featureCoordinates[0][0], latitude);
        })
        .with("0.2", () => {
            feature.updateCoordinate("0.2", movedVertex[0], movedVertex[1]);

            feature.updateCoordinate(
                "0.1",
                longitude,
                featureCoordinates[1][1]
            );

            feature.updateCoordinate("0.3", featureCoordinates[3][0], latitude);
        })
        .with("0.3", () => {
            feature.updateCoordinate("0.3", movedVertex[0], movedVertex[1]);

            feature.updateCoordinate(
                "0.0",
                longitude,
                featureCoordinates[0][1]
            );

            feature.updateCoordinate("0.2", featureCoordinates[2][0], latitude);
        })
        .with("1.0", () => {
            feature.updateCoordinate("0.1", featureCoordinates[1][0], latitude);
            feature.updateCoordinate("0.0", featureCoordinates[0][0], latitude);
        })
        .with("2.0", () => {
            feature.updateCoordinate(
                "0.2",
                longitude,
                featureCoordinates[2][1]
            );
            feature.updateCoordinate(
                "0.1",
                longitude,
                featureCoordinates[1][1]
            );
        })
        .with("3.0", () => {
            feature.updateCoordinate("0.2", featureCoordinates[2][0], latitude);
            feature.updateCoordinate("0.3", featureCoordinates[3][0], latitude);
        })
        .with("4.0", () => {
            feature.updateCoordinate(
                "0.0",
                longitude,
                featureCoordinates[0][1]
            );
            feature.updateCoordinate(
                "0.3",
                longitude,
                featureCoordinates[3][1]
            );
        })
        .exhaustive();
};

export const getMidpoints = (coordinates: number[][]): number[][] => {
    return map(range(coordinates.length - 1), (i) => {
        const point1 = coordinates[i];
        const point2 = coordinates[i + 1];

        const longitude = (point1[0] + point2[0]) / 2;
        const latitude = (point1[1] + point2[1]) / 2;

        return [longitude, latitude];
    });
};

export const limitSquareVertices = (
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    geojson: any
): GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[] | null => {
    const { properties, geometry } = geojson;
    const coordinates = geometry.coordinates[0];

    if (!properties.user_isSquare) return null;

    // Limit the number of visible vertices to 4.
    const vertices: [number, number][] = coordinates.slice(0, 4);

    const midpoints = getMidpoints(
        coordinates.length < 5 ? closePolygon(coordinates) : coordinates
    );

    /*
     * Create edge and midpoints vertices.
     *
     * Edges = 0.0: bottom left, 0.1: bottom right, 0.2: top right, 0.3: top left.
     * Midpoints = 1.0: bottom, 2.0: right, 3.0: top, 4.0: left.
     */

    const points = pipe(
        vertices.map((_, index) =>
            MapboxDraw.lib.createVertex(
                properties.id,
                vertices[index],
                `0.${index}`,
                false
            )
        ),
        A.concat(
            pipe(
                midpoints.map((_, index) =>
                    MapboxDraw.lib.createVertex(
                        properties.id,
                        midpoints[index],
                        `${index + 1}.0`,
                        false
                    )
                ),
                A.map(
                    (midpoint) =>
                        ({
                            ...midpoint,
                            properties: {
                                ...midpoint.properties,
                                real_meta: "midpoint",
                            },
                        }) as GeoJSON.Feature<
                            GeoJSON.Point,
                            GeoJSON.GeoJsonProperties
                        >
                )
            )
        )
    );

    return points;
};

export const getCircleAdditionalFeatures = ({
    center,
    cursorCoordinates,
    radius,
    vertexPath,
}: {
    center: number[];
    cursorCoordinates: number[][];
    radius: number;
    vertexPath?: string;
}): {
    centeredPoint: GeoJSON.Feature<GeoJSON.Point>;
    lineString: GeoJSON.Feature<GeoJSON.LineString>;
    cursorPrompt: GeoJSON.Feature<GeoJSON.Point>;
} => {
    const centeredPoint = {
        type: MapboxDraw.constants.geojsonTypes.FEATURE,
        properties: {
            meta: "centeredPoint",
            active: MapboxDraw.constants.activeStates.ACTIVE,
        },
        geometry: {
            type: MapboxDraw.constants.geojsonTypes.POINT,
            coordinates: center,
        },
    };

    const lineString = {
        type: MapboxDraw.constants.geojsonTypes.FEATURE,
        properties: {
            id: uuid(),
            active: MapboxDraw.constants.activeStates.ACTIVE,
            currentVertexPath: vertexPath || undefined,
        },
        geometry: {
            type: MapboxDraw.constants.geojsonTypes.LINE_STRING,
            coordinates: cursorCoordinates,
        },
    };

    const cursorPrompt = {
        type: MapboxDraw.constants.geojsonTypes.FEATURE,
        properties: {
            meta: "currentCursorPosition",
            radius: `${radius}km`,
            parent: lineString.properties.id,
        },
        geometry: {
            type: MapboxDraw.constants.geojsonTypes.POINT,
            coordinates: cursorCoordinates[1],
        },
    };

    return { centeredPoint, lineString, cursorPrompt };
};

export const convertCoordsToPixels = (
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    coordinates: any,
    map: mapboxgl.Map | null
) => {
    // From project geographic coordinates to pixel coordinates
    if (map) {
        const { x, y } = map.project(coordinates);
        return { top: y, left: x };
    }
    return { top: 0, left: 0 };
};

export const isFeature = (
    value:
        | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
        | GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
): value is GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> =>
    value.type === "Feature";

export const isFeatureCollection = (
    value:
        | GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
        | GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
): value is GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    GeoJSON.GeoJsonProperties
> => value.type === "FeatureCollection";
