import {
    FilterObject,
    InputViewBox,
    MapTemplateDatasetExtended,
    SavedArea,
} from "@biggeo/bg-server-lib/datascape-ai";
import { SelectableTreeMenuItem } from "@biggeo/bg-ui";
import { WithRequiredProperty, toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import last from "lodash/last";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";
import uniqWith from "lodash/uniqWith";
import { createContext, useContext, useEffect } from "react";
import uuid from "react-uuid";
import { ColorSwatchOption } from "../../../common/components/ColorSwatchSelector";
import { MapFilterCriteriaForm } from "../../filter-criteria/utils/utils";
import { InputPolygonWithId } from "../../hooks/pure-data-string-hook";
import { isFeature, isFeatureCollection } from "../../utils/draw-modes-utils";
import {
    FunctionType,
    getInputPolygon,
    getPolygonFromSavedAreas,
    isPolygonFeature,
    updateArray,
} from "../../utils/utils";
import { DEFAULT_MAP_STYLE } from "../../views/MapStyles";

export const MAP_STATES_LIMIT = 10;
export type MapType = React.MutableRefObject<mapboxgl.Map | null>;
export type DrawType = React.MutableRefObject<MapboxDraw | null>;
export type MapPreviousState = {
    id?: string;
    map: MapType;
    draw: DrawType;
    featureCollection?: GeoJSON.FeatureCollection<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >;
    datasets: readonly string[];
    modes: Record<MapModes, boolean>;
    functionType?: FunctionType;
    viewport?: Partial<{
        bounds: mapboxgl.LngLatBounds;
        center: mapboxgl.LngLat;
        view: InputViewBox;
    }>;
    filters: FilterObject[];
    selectedSavedAreas: {
        savedAreas: SavedArea[];
        selectableItems: SelectableTreeMenuItem[];
    };
    styleUrl?: string;
};
export type MapContextFilter = MapFilterCriteriaForm & {
    readonly id: string;
    readonly visible: boolean;
    readonly selected: boolean;
    readonly disabled: boolean;
};
export type MapContextDataset = Pick<
    MapTemplateDatasetExtended,
    "dataSource" | "mapTemplateDataset"
> & {
    currentHeatMapValue?: ColorSwatchOption;
};

export type MapContextType = {
    readonly map: MapType;
    readonly draw: DrawType;
    readonly isLoaded: boolean;
    readonly selectedShapes: GeoJSON.FeatureCollection<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >;
    readonly modes?: {
        [MapModes.select]: {
            isSelectMode: boolean;
            selectMode: (s: boolean) => void;
        };
    };
    readonly dispatch?: React.Dispatch<MapAction>;
    readonly previousMapState?: MapPreviousState;
    readonly mapStates: MapPreviousState[];
    readonly filters: MapContextFilter[];
    readonly lastSelectedDataset?: MapContextDataset;
};

export enum MapModes {
    draw = "draw",
    select = "select",
}

export const previousMapInitialState: MapPreviousState = {
    id: undefined,
    map: { current: null },
    draw: { current: null },
    featureCollection: {
        type: "FeatureCollection",
        features: [],
    },
    datasets: [],
    modes: {
        draw: false,
        select: false,
    },
    functionType: FunctionType.viewport,
    filters: [],
    selectedSavedAreas: {
        savedAreas: [],
        selectableItems: [],
    },
    viewport: undefined,
    styleUrl: DEFAULT_MAP_STYLE.url,
};

export const MapContext = createContext<MapContextType>({
    map: { current: null },
    draw: { current: null },
    isLoaded: false,
    selectedShapes: {
        type: "FeatureCollection",
        features: [],
    },
    dispatch: undefined,
    previousMapState: previousMapInitialState,
    mapStates: [],
    modes: undefined,
    filters: [],
    lastSelectedDataset: undefined,
});

export type MapContextState = {
    readonly map: MapType;
    readonly draw: DrawType;
    readonly isLoaded: boolean;
    readonly selectedShapes: GeoJSON.FeatureCollection<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >;
    readonly previousMapState?: MapPreviousState;
    readonly mapStates: MapPreviousState[];
    readonly modes?: {
        [MapModes.select]: {
            isSelectMode: boolean;
            selectMode: (s: boolean) => void;
        };
    };
    readonly filters: MapContextFilter[];
    readonly lastSelectedDataset?: MapContextDataset;
};

export const useMap = (): MapContextType => useContext(MapContext);

export type MapAction =
    | {
          readonly type: "SET_MAP";
          readonly values: Partial<MapContextState>;
      }
    | {
          readonly type: "SET_PREVIOUS_STATE";
          readonly values: {
              feature?: GeoJSON.Feature<
                  GeoJSON.Geometry,
                  GeoJSON.GeoJsonProperties
              >;
              state: MapPreviousState;
              type?: "draw.create" | "draw.update" | "draw.delete";
          };
      }
    | {
          readonly type: "SET_MAP_STATES";
          readonly values: {
              feature?: GeoJSON.Feature<
                  GeoJSON.Geometry,
                  GeoJSON.GeoJsonProperties
              >;
          };
      }
    | {
          readonly type: "SET_SELECTED_SHAPES";
          readonly values:
              | GeoJSON.FeatureCollection<
                    GeoJSON.Geometry,
                    GeoJSON.GeoJsonProperties
                >
              | GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
      }
    | {
          type: "ADD_FILTER";
          values: MapContextFilter;
      }
    | {
          type: "UPDATE_FILTER";
          values: WithRequiredProperty<Partial<MapContextFilter>, "id">;
      }
    | {
          type: "REMOVE_FILTER";
          values: string;
      }
    | { type: "CLEAR_FILTERS" }
    | {
          type: "EDIT_FILTER";
          values: string;
      }
    | {
          type: "SAVE_FILTER";
          values: string;
      }
    | { type: "RESET_FILTERS" }
    | {
          type: "SET_LAST_SELECTED_DATASET";
          values: MapContextDataset | undefined;
      }
    | {
          type: "SET_LAST_SELECTED_DATASET_DATA_AGGREGATION";
          values: {
              id: string;
              heatmap?: ColorSwatchOption;
          };
      };

export const MapReducer = (
    state: MapContextState,
    action: MapAction
): MapContextState => {
    switch (action.type) {
        case "SET_MAP": {
            return {
                ...state,
                ...omitBy(action.values, isNil),
            };
        }
        case "SET_PREVIOUS_STATE": {
            const feature = action.values.feature;

            if (action.values.type === "draw.delete" && feature) {
                return {
                    ...state,
                    previousMapState: {
                        ...(state.previousMapState as MapPreviousState),
                        ...action.values.state,
                        featureCollection: {
                            type: "FeatureCollection",
                            features: pipe(
                                state.previousMapState?.featureCollection
                                    ?.features || [],
                                A.filter((f) => f.id !== feature.id)
                            ),
                        },
                    },
                };
            }
            return {
                ...state,
                previousMapState: {
                    ...(state.previousMapState as MapPreviousState),
                    ...action.values.state,
                    featureCollection: feature
                        ? {
                              type: "FeatureCollection",
                              features: updateArray(
                                  feature,
                                  state.previousMapState?.featureCollection
                                      ?.features || []
                              ),
                          }
                        : state.previousMapState?.featureCollection,
                },
            };
        }
        case "SET_MAP_STATES": {
            if (isEqual(state.mapStates.length, MAP_STATES_LIMIT)) {
                return state;
            }

            const currentState = state.previousMapState;
            const lastState = last(state.mapStates);

            /*
                This check is important because after drawing shapes,
                the draw mode and functionType go back momentarily to `false`
                and `viewport`. It's needed for the draw to work (to draw shapes 
                consecutively), but we don't want to store that state.
            */
            const isDifferent =
                action.values.feature &&
                !isEqual(
                    omit(currentState, ["id", "functionType", "modes"]),
                    omit(lastState, ["id", "functionType", "modes"])
                );

            const updatedState = pipe(
                currentState,
                O.fromNullable,
                O.fold(
                    () => state.mapStates,
                    (current) =>
                        pipe(
                            updateArray(current, state.mapStates),
                            A.map((i) =>
                                i.id === current.id && isDifferent
                                    ? { ...current, id: uuid() }
                                    : i
                            )
                        )
                )
            );

            return {
                ...state,
                mapStates: updatedState,
            };
        }
        case "SET_SELECTED_SHAPES": {
            const feature = action.values;
            const found = isFeature(feature)
                ? state.selectedShapes.features.find((f) => f.id === feature.id)
                : undefined;

            return {
                ...state,
                selectedShapes: isFeatureCollection(action.values)
                    ? action.values
                    : {
                          type: "FeatureCollection",
                          features: found
                              ? pipe(
                                    state.selectedShapes.features,
                                    A.filter((f) => f.id !== found.id)
                                )
                              : isFeature(feature)
                                ? pipe(
                                      state.selectedShapes.features,
                                      A.concat([feature])
                                  )
                                : state.selectedShapes.features,
                      },
            };
        }
        case "ADD_FILTER": {
            return {
                ...state,
                filters: pipe(state.filters, A.concat([action.values])),
            };
        }
        case "UPDATE_FILTER": {
            return {
                ...state,
                filters: pipe(
                    state.filters,
                    A.map((f) =>
                        f.id === action.values.id
                            ? { ...f, ...action.values }
                            : f
                    )
                ),
            };
        }
        case "REMOVE_FILTER": {
            const id = action.values;

            return {
                ...state,
                filters: pipe(
                    state.filters,
                    A.filter((f) => f.id !== id)
                ),
            };
        }
        case "CLEAR_FILTERS": {
            return {
                ...state,
                filters: [],
            };
        }
        case "EDIT_FILTER": {
            const id = action.values;

            return {
                ...state,
                filters: pipe(
                    state.filters,
                    A.map((f) =>
                        f.id === id
                            ? { ...f, selected: true }
                            : {
                                  ...f,
                                  visible: false,
                                  disabled: true,
                                  selected: false,
                              }
                    )
                ),
            };
        }
        case "SAVE_FILTER": {
            const id = action.values;

            return {
                ...state,
                filters: pipe(
                    state.filters,
                    A.map((f) =>
                        f.id === id
                            ? { ...f, selected: false }
                            : {
                                  ...f,
                                  visible: false,
                                  disabled: false,
                                  selected: false,
                              }
                    )
                ),
            };
        }
        case "RESET_FILTERS": {
            return {
                ...state,
                filters: pipe(
                    state.filters,
                    A.map((f) => ({
                        ...f,
                        disabled: false,
                        selected: false,
                        visible: false,
                    }))
                ),
            };
        }
        case "SET_LAST_SELECTED_DATASET": {
            return {
                ...state,
                lastSelectedDataset: action.values,
            };
        }
        case "SET_LAST_SELECTED_DATASET_DATA_AGGREGATION": {
            const datasetId = action.values.id;

            return {
                ...state,
                lastSelectedDataset:
                    state.lastSelectedDataset &&
                    isEqual(datasetId, state.lastSelectedDataset.dataSource.id)
                        ? {
                              ...state.lastSelectedDataset,
                              currentHeatMapValue: action.values.heatmap,
                          }
                        : state.lastSelectedDataset,
            };
        }
        default:
            return state;
    }
};

export const useMapPreviousState = ({
    isLoaded,
    state,
    dispatch,
    callbacks,
}: {
    isLoaded: boolean;
    state: MapPreviousState;
    dispatch?: React.Dispatch<MapAction>;
    callbacks?: {
        deleteShape: (id: string) => void;
    };
}) => {
    const {
        map,
        draw,
        datasets,
        modes,
        filters,
        selectedSavedAreas,
        featureCollection,
        ...params
    } = state;

    const selected = pipe(
        selectedSavedAreas.selectableItems,
        toNonReadonlyArray,
        A.flatMap((item) =>
            pipe(
                item.subItems,
                toNonReadonlyArray,
                A.filter((sub) => sub.selected),
                A.map((sub) => sub.id)
            )
        )
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        const handleMapReset = (e?: any) => {
            if (isLoaded) {
                const feature = e ? e.features[0] : undefined;

                if (e && e.type === "draw.delete" && callbacks && feature) {
                    callbacks.deleteShape(feature.id);
                }

                dispatch?.({
                    type: "SET_PREVIOUS_STATE",
                    values: {
                        type: e ? e.type : undefined,
                        feature,
                        state: {
                            map,
                            draw,
                            datasets,
                            modes,
                            filters,
                            selectedSavedAreas,
                            ...omitBy(params, isNil),
                        },
                    },
                });

                dispatch?.({
                    type: "SET_MAP_STATES",
                    values: { feature },
                });
            }
        };

        if (map.current) {
            map.current.on("draw.create", handleMapReset);
            map.current.on("draw.update", handleMapReset);
            map.current.on("draw.delete", handleMapReset);

            handleMapReset();
        }

        return () => {
            if (map.current) {
                map.current.off("draw.create", handleMapReset);
                map.current.off("draw.update", handleMapReset);
                map.current.off("draw.delete", handleMapReset);
            }
        };
    }, [
        map.current,
        draw.current,
        isLoaded,
        datasets.length,
        params.functionType,
        params.styleUrl,
        params.viewport?.view,
        modes.draw,
        modes.select,
        selected.length,
    ]);
};

export const onMapReset = ({
    map,
    draw,
    state,
    setState,
    polygons: OGPolygons,
}: {
    map: mapboxgl.Map;
    draw?: MapboxDraw;
    state: MapPreviousState;
    polygons: InputPolygonWithId[];
    setState: {
        setDatasets: (d: readonly string[]) => void;
        setPolygons: (polygons: InputPolygonWithId[]) => void;
        setFunctionType: (f: FunctionType) => void;
        setMode: (m: boolean) => void;
        setFilters: (f: FilterObject[]) => void;
        setViewport: (
            v: Partial<{
                bounds: mapboxgl.LngLatBounds;
                center: mapboxgl.LngLat;
                view: InputViewBox;
            }>
        ) => void;
        setStyle: (url: string) => void;
        setSavedAreas: (a: {
            savedAreas: SavedArea[];
            selectableItems: SelectableTreeMenuItem[];
        }) => void;
    };
}) => {
    const {
        setDatasets,
        setPolygons,
        setFunctionType,
        setFilters,
        setViewport,
        setStyle,
        setSavedAreas,
        setMode,
    } = setState;

    const {
        featureCollection,
        functionType,
        viewport,
        filters,
        selectedSavedAreas,
        styleUrl,
        datasets,
        modes,
    } = state;

    map.on("load", () => {
        if (featureCollection && draw) {
            draw.set(featureCollection);
        }

        if (styleUrl) {
            const currentStyle = map.getStyle();

            if (!isEqual(currentStyle.sprite, styleUrl)) {
                setStyle(styleUrl);
            }

            if (viewport && isEmpty(selectedSavedAreas.savedAreas)) {
                setViewport(viewport);
            }
        }

        const drawnShapes = draw?.getAll().features;

        const polygons = pipe(
            featureCollection?.features || [],
            A.map((feature) =>
                isPolygonFeature(feature) ? getInputPolygon(feature) : undefined
            ),
            compact
        );

        if (!!drawnShapes && !isEmpty(drawnShapes)) {
            // Wait till the shapes are drawn, then set the polygons state
            setPolygons(
                uniqWith(
                    pipe(
                        polygons,
                        A.concat(
                            getPolygonFromSavedAreas(
                                selectedSavedAreas.savedAreas
                            )
                        )
                    ),
                    isEqual
                )
            );
        }

        if (!isEmpty(polygons)) {
            // Wait till the polygons state is set, then fetch the datasets
            if (!isEmpty(OGPolygons)) {
                setDatasets(datasets);
            }
        } else {
            setDatasets(datasets);
        }
    });

    map.on("style.load", () => {
        if (functionType) {
            setFunctionType(functionType);
        }

        setMode(modes.select);
        setFilters(filters);
        setSavedAreas(selectedSavedAreas);
    });
};
