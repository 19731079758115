import { NavigationRailCell, Stack } from "@biggeo/bg-ui/lab";
import {
    AdOutline,
    CropFreeOutline,
    TuneOutline,
} from "@biggeo/bg-ui/lab/icons";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import { match } from "ts-pattern";
import { MapConfigurationTabs } from "../../containers/MapConfiguration";
interface IMapConfigurationSideNav {
    readonly currentTab: MapConfigurationTabs;
    readonly onChange: (tab: MapConfigurationTabs) => void;
}

const MapConfigurationSideNav = ({
    currentTab,
    onChange,
}: IMapConfigurationSideNav) => {
    return (
        <Stack width="100%" gap={1} sx={{ padding: 4 }}>
            {Object.values(MapConfigurationTabs).map((tab) => (
                <NavigationRailCell
                    key={tab}
                    selected={isEqual(tab, currentTab)}
                    onClick={() => onChange(tab)}
                    startNode={match(tab)
                        .with(MapConfigurationTabs.datasets, () => (
                            <AdOutline size="xs" />
                        ))
                        .with(MapConfigurationTabs.availableAreas, () => (
                            <CropFreeOutline size="xs" />
                        ))
                        .with(MapConfigurationTabs.configuration, () => (
                            <TuneOutline size="xs" />
                        ))
                        .exhaustive()}
                >
                    {startCase(tab)}
                </NavigationRailCell>
            ))}
        </Stack>
    );
};

export default MapConfigurationSideNav;
