import MarketplaceUpgradeForm, {
    UpgradeInfo,
} from "../views/MarketplaceUpgradeForm";

interface IUpgradeFormContainer {
    readonly navigate: (to?: string) => void;
    readonly confirm: (input: UpgradeInfo) => void;
    readonly loading: boolean;
}

const MarketplaceUpgradeContainer = ({
    navigate,
    confirm,
    loading,
}: IUpgradeFormContainer) => {
    return (
        <MarketplaceUpgradeForm
            navigate={navigate}
            confirm={confirm}
            loading={loading}
        />
    );
};

export default MarketplaceUpgradeContainer;
