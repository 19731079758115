import { Grid, Stack, TextField, Typography } from "@biggeo/bg-ui/lab";
import { SearchOutline } from "@biggeo/bg-ui/lab/icons";

export const DatasourcesBanner = ({
    searchText,
    onSearchTextChange,
}: {
    readonly searchText: string;
    readonly onSearchTextChange: (text: string) => void;
}) => {
    return (
        <Stack
            gap={4}
            sx={{
                paddingY: 8,
                paddingX: 4,
                backgroundColor: (theme) => theme.palette.surface.container,
                borderRadius: (theme) => theme.radius.default,
                backgroundImage:
                    "url(https://biggeo.blob.core.windows.net/media/supercharge-teams-banner.png)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top",
            }}
        >
            <Stack>
                <Typography variant={"h5"} fontWeight={"bold"}>
                    Supercharge Your Teams
                </Typography>
                <Typography variant={"body2"} fontWeight={"regular"}>
                    Deploy BigGeo to give your teams a boost
                </Typography>
            </Stack>
            <Grid item xs={12} md={8} lg={4}>
                <TextField
                    fullWidth
                    startNode={<SearchOutline />}
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => onSearchTextChange(e?.target.value || "")}
                />
            </Grid>
            {/*TODO: Get background image from product team to use as background image instead*/}
            {/*<Grid container item md={2} justifyContent="center">*/}
            {/*    /!*Placeholder for banner image*!/*/}
            {/*    <img*/}
            {/*        width={200}*/}
            {/*        height={200}*/}
            {/*        src={`https://vividtheory.blob.core.windows.net/media/7de94bf8-ed32-47f4-b117-b49384ea1096.jpg`}*/}
            {/*        alt="signin-1"*/}
            {/*    />*/}
            {/*</Grid>*/}
        </Stack>
    );
};
