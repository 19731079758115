import {
    Box,
    Button,
    OverflowingTypography,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import { ReactNode } from "react";

type ResourceCardProps = {
    readonly logo: ReactNode;
    readonly title: string;
    readonly description: string;
    readonly onClick: (id: string) => void;
    readonly cta?: ReactNode;
};

export const ResourceCard = ({
    onClick,
    logo,
    title,
    description,
    cta,
}: ResourceCardProps) => {
    return (
        <Stack
            gap={4}
            sx={{
                backgroundColor: (theme) => theme.palette.background.main,
                borderRadius: (theme) => theme.radius.xs3,
                border: (theme) => `1px solid ${theme.palette.stroke[100]}`,
                padding: 4,
                justifyContent: "space-between",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: (theme) => theme.palette.surface.container,
                    width: 10,
                    height: 10,
                    alignItems: "center",
                }}
            >
                {logo}
            </Box>
            <Stack>
                <Typography variant={"body3"} fontWeight={"semibold"}>
                    {title}
                </Typography>
                <Stack
                    sx={{
                        minHeight: (theme) => theme.spacing(9),
                    }}
                >
                    <OverflowingTypography
                        sx={{
                            color: (theme) =>
                                theme.palette.disabled.onContainer,
                        }}
                        variant={"body3"}
                        lineClamp={2}
                    >
                        {description}
                    </OverflowingTypography>
                </Stack>
            </Stack>

            <Box
                sx={{
                    display: "flex",
                    cursor: "pointer",
                    justifyContent: "flex-end",
                }}
            >
                {cta ? (
                    cta
                ) : (
                    <Button
                        density={"dense"}
                        variant={"outlined"}
                        onClick={() => onClick}
                    >
                        View
                    </Button>
                )}
            </Box>
        </Stack>
    );
};
