import {
    CreateEditDeleteMapTemplate,
    DataSource,
    MapTemplateExtended,
    useFetchDataSourcesQuery,
    useFetchMapTemplateExtendedQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { LoadingBar } from "@biggeo/bg-ui/lab";
import * as R from "@vividtheory/remotedata";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { CallBacksType } from "../../utils/types";
import MapTemplateForm from "../views/MapTemplateForm";

interface IManageMapTemplateContainer {
    readonly id: number;
    readonly navigate: (to?: string) => void;
    readonly save: (
        input: CreateEditDeleteMapTemplate,
        callbacks?: CallBacksType<MapTemplateExtended>
    ) => void;
    readonly loading: boolean;
}

const ManageMapTemplateContainer = ({
    id,
    navigate,
    save,
    loading,
}: IManageMapTemplateContainer) => {
    const { remote, setRemote } = useFetchMapTemplateExtendedQuery({
        variables: { id },
    });

    const {
        queryReturn: { data: dataSourcesRd, loading: dataSourceLoading },
    } = useFetchDataSourcesQuery({
        variables: {
            input: {
                isConnected: true,
            },
        },
    });

    return (
        <>
            {R.match(remote, {
                _: () => <LoadingBar />,
                Success: (data) => {
                    if (dataSourceLoading) {
                        return <LoadingBar />;
                    }

                    const linkedDatasets =
                        data.fetchMapTemplateExtended.datasets;

                    return (
                        <MapTemplateForm
                            map={data.fetchMapTemplateExtended.mapTemplate}
                            navigate={navigate}
                            save={(input, callbacks) =>
                                save(input, {
                                    ...callbacks,
                                    onSuccess: (data) => {
                                        callbacks?.onSuccess?.(data);
                                        setRemote(
                                            new R.Success({
                                                fetchMapTemplateExtended: data,
                                            })
                                        );
                                    },
                                })
                            }
                            linkedDatasets={linkedDatasets}
                            loading={loading}
                            datasets={pipe(
                                dataSourcesRd?.fetchDataSources.dataSources ||
                                    [],
                                O.fromPredicate((x) => !isEmpty(x)),
                                O.fold(
                                    () => [],
                                    (ds: DataSource[]) =>
                                        pipe(
                                            ds,
                                            A.map((item) => ({
                                                id: item.id,
                                                title:
                                                    item.label ||
                                                    item.collectionName,
                                                image: item.icon || "",
                                                select: linkedDatasets.some(
                                                    (dataset) =>
                                                        isEqual(
                                                            dataset.fkDataSourceId,
                                                            item.id
                                                        )
                                                ),
                                            }))
                                        )
                                )
                            )}
                        />
                    );
                },
            })}
        </>
    );
};

export default ManageMapTemplateContainer;
