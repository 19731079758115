import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toasterActions } from "../../../toaster/containers/redux/model";
import { FunctionType, SavedPolygonSource } from "../../utils/utils";

import mapboxgl from "mapbox-gl";
import { match } from "ts-pattern";
import { MAP_TOOLTIP_HEIGHT } from "../views/MapPromptPopup.tsx";
import {
    OUTSIDE_AREA_LAYER_ID,
    SavedPolygonType,
} from "./saved-polygon-hooks.ts";

export type MapStylePromptType = "Shape" | "Editing shape";

export type MapPromptType =
    | "Select starting point"
    | "Select to add point"
    | "Double-click to create shape or click to add point"
    | "Press enter or double-click to create shape"
    | "Press enter or click to create shape"
    | "You can't create shape outside of your enabled area boundaries"
    | undefined;

export type OnMapClickType = { x: number; y: number; counter: number };

export const usePopup = ({
    map,
    draw,
    isDrawMode,
    functionType,
    isLoaded,
    savedPolygons,
    setFunctionType,
    isSelectMode,
    actions,
}: {
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly draw: React.MutableRefObject<MapboxDraw | null>;
    readonly isDrawMode: boolean;
    readonly functionType: FunctionType;
    readonly isLoaded: boolean;
    readonly savedPolygons: SavedPolygonType;
    readonly setFunctionType: React.Dispatch<
        React.SetStateAction<FunctionType>
    >;
    readonly isSelectMode: boolean;
    readonly actions: Record<"isResizing" | "isDragging", boolean>;
}): { isDoneDrawing: boolean } => {
    const current = map.current;
    const dispatch = useDispatch();
    const onClickInitialState: OnMapClickType = {
        x: 0,
        y: 0,
        counter: 0,
    };
    const [onClick, setOnClick] = useState<OnMapClickType>(onClickInitialState);

    const isSavedAreaMode =
        !isEmpty(savedPolygons.polygons) &&
        isEqual(savedPolygons.source, SavedPolygonSource.savedArea);

    const isInitialState = isEqual(onClick, onClickInitialState);

    const currentShape =
        draw.current && isLoaded ? draw.current.getSelected() : undefined;
    const isDoneDrawing = !!currentShape && !isEmpty(currentShape.features);

    const hide = () =>
        dispatch(
            toasterActions.openMapPopup({
                tooltipArrow: false,
                sx: {
                    display: "none",
                },
            })
        );

    const show = (
        left: number,
        top: number,
        prompt: MapPromptType,
        enableArrow = false
    ) =>
        dispatch(
            toasterActions.openMapPopup({
                message: prompt,
                sx: {
                    display: "block",
                    left: `${left}px`,
                    top: `${top}px`,
                    height: (theme) =>
                        enableArrow
                            ? theme.spacing(19)
                            : theme.spacing(MAP_TOOLTIP_HEIGHT / 4),
                },
                tooltipArrow: enableArrow,
            })
        );

    const isCursorOnMap = (
        x: number,
        y: number,
        bounds: { left: number; right: number; top: number; bottom: number }
    ): boolean => {
        const { left, right, top, bottom } = bounds;

        // If x is between left and right, and y between bottom and top, the cursor is on the map.
        return x > left && x < right && y > top && y < bottom;
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (isLoaded) {
            if (isDoneDrawing) {
                setOnClick(onClickInitialState);
            } else {
                hide();
            }

            // Handle cases where shape was created with `Enter`
            // or deleted with `Escape`.
            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === "Escape" || event.key === "Enter") {
                    setOnClick(onClickInitialState);
                }
            };

            if (isDrawMode && !isDoneDrawing) {
                document.addEventListener("keydown", handleKeyDown);
            }

            return () => {
                if (isDrawMode && !isDoneDrawing) {
                    document.removeEventListener("keydown", handleKeyDown);
                }
            };
        }
    }, [isDoneDrawing, draw.current, currentShape, isLoaded, isDrawMode]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            current &&
            isEqual(isDrawMode, true) &&
            isEqual(isSelectMode, false)
        ) {
            // When you click "Enter" on the map to create a shape, it shows a border around it.
            // This code is to remove that border.
            const canvas = current.getCanvas();
            canvas.style.outline = "none";

            // The cursor event is for the whole screen.
            // We need to deduct the left and top of the map container to get the cursor's position on the map.
            const mapBounds = canvas.getBoundingClientRect();

            const right = mapBounds ? mapBounds.right : 0;
            const left = mapBounds ? mapBounds.left : 0;
            const top = mapBounds ? mapBounds.top : 0;
            const bottom = mapBounds ? mapBounds.bottom : 0;

            const onMouseEnter = (
                event: mapboxgl.MapMouseEvent & {
                    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
                } & mapboxgl.EventData
            ) => {
                const canvas = current.getCanvas();
                canvas.style.cursor = "crosshair";

                // To ensure the cursor always shows in the middle of the popup
                const popup = document.getElementById("map-popup");
                const popupBounds = popup?.getBoundingClientRect();
                const width = popupBounds ? popupBounds.width : 0;
                const middle = width / 2;

                const x = event.originalEvent.clientX - left - middle;
                const y = event.originalEvent.clientY - top + 20;

                show(x, y, "Select starting point");

                const hasMovedAfterClick = x !== onClick.x || y !== onClick.y;

                if (!isInitialState && !hasMovedAfterClick) {
                    hide();
                }

                if (!isInitialState && hasMovedAfterClick) {
                    const prompt = match<FunctionType, MapPromptType>(
                        functionType
                    )
                        .with(FunctionType.polygon, () => {
                            const isThirdVertex = onClick.counter === 2;

                            if (isThirdVertex) {
                                return "Double-click to create shape or click to add point";
                            }

                            return onClick.counter >= 3
                                ? "Press enter or double-click to create shape"
                                : "Select to add point";
                        })
                        .otherwise(
                            () => "Press enter or click to create shape"
                        );

                    show(x, y, prompt);
                    return;
                }

                if (isDrawMode || isDoneDrawing) {
                    show(x, y, "Select starting point");
                }
            };

            const onMouseLeave = (
                e: mapboxgl.MapMouseEvent & {
                    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
                } & mapboxgl.EventData
            ) => {
                e.preventDefault();

                const canvas = current.getCanvas();
                canvas.style.cursor = "";

                const isOnMap = isCursorOnMap(
                    e.originalEvent.clientX,
                    e.originalEvent.clientY,
                    { left, right, top, bottom }
                );

                const elementCursorIsOn = document.elementFromPoint(
                    e.originalEvent.clientX,
                    e.originalEvent.clientY
                );

                const elementsOnTopOfMap = pipe(
                    elementCursorIsOn,
                    O.fromNullable,
                    O.fold(
                        () => [],
                        (element) =>
                            Array.prototype.filter.call(
                                element.children,
                                (child) => child.className
                            )
                    )
                );

                if (isEqual(isOnMap, false) || !isEmpty(elementsOnTopOfMap)) {
                    hide();
                }
            };

            const onMapClick = (
                e: mapboxgl.MapMouseEvent & {
                    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
                } & mapboxgl.EventData
            ) => {
                const features = map.current?.queryRenderedFeatures(e.point, {
                    layers: [OUTSIDE_AREA_LAYER_ID],
                });

                const isOnOutsideArea = !isEmpty(features);

                e.preventDefault();

                if (isEqual(isOnOutsideArea, false)) {
                    const x = e.originalEvent.clientX - left;
                    const y = e.originalEvent.clientY - top;

                    setOnClick({
                        x,
                        y,
                        counter: onClick.counter + 1,
                    });

                    hide();
                }
            };

            current.on("mousemove", onMouseEnter);
            current.on("mouseout", onMouseLeave);
            current.on("click", onMapClick);

            return () => {
                if (current) {
                    current.off("mousemove", onMouseEnter);
                    current.off("mouseout", onMouseLeave);
                    current.off("click", onMapClick);
                }
            };
        }
    }, [current, isDrawMode, onClick, functionType, isSelectMode]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (current && isEqual(isSavedAreaMode, true)) {
            const onMouseMove = (
                event: mapboxgl.MapMouseEvent & {
                    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
                } & mapboxgl.EventData
            ) => {
                const canvas = current.getCanvas();
                canvas.style.outline = "none";
                const mapBounds = canvas.getBoundingClientRect();
                const left = mapBounds ? mapBounds.left : 0;
                const top = mapBounds ? mapBounds.top : 0;

                const popup = document.getElementById("map-popup");
                const popupBounds = popup?.getBoundingClientRect();
                const width = popupBounds ? popupBounds.width : 0;
                const middle = width / 2;

                const x = event.originalEvent.clientX - left - middle;
                const y = event.originalEvent.clientY - top - 100;

                const isDrawing = onClick.counter > 0;

                if (!isDrawing && !actions.isResizing && !actions.isDragging) {
                    current.getCanvas().style.cursor = "not-allowed";

                    // To be able to draw in between 2 saved areas
                    if (savedPolygons.polygons.length === 1) {
                        setFunctionType(FunctionType.savedPolygon);
                    }

                    show(
                        x,
                        y,
                        "You can't create shape outside of your enabled area boundaries",
                        true
                    );
                }
            };

            const onMouseLeave = (
                event: mapboxgl.MapMouseEvent & {
                    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
                } & mapboxgl.EventData
            ) => {
                event.preventDefault();

                const canvas = current.getCanvas();
                canvas.style.cursor = "";

                hide();
            };

            current.on("mousemove", OUTSIDE_AREA_LAYER_ID, onMouseMove);
            current.on("mouseleave", OUTSIDE_AREA_LAYER_ID, onMouseLeave);

            return () => {
                if (current) {
                    current.off(
                        "mousemove",
                        OUTSIDE_AREA_LAYER_ID,
                        onMouseMove
                    );
                    current.off(
                        "mouseleave",
                        OUTSIDE_AREA_LAYER_ID,
                        onMouseLeave
                    );
                }
            };
        }
    }, [
        current,
        draw.current,
        onClick,
        functionType,
        isSavedAreaMode,
        actions,
    ]);

    return { isDoneDrawing };
};
