import {
    Box,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    OverflowingTypography,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    EditOutline,
    MoreHorizOutline,
    OpenInNewOutline,
} from "@biggeo/bg-ui/lab/icons";
import { convertDateToTimePeriod } from "../../utils/dates";

const SavedViewCard = ({
    bodyOnClick,
    selected,
    image,
    title,
    subTitle,
    updatedAt,
    onClickManage,
}: {
    readonly title: string;
    readonly subTitle?: string;
    readonly updatedAt?: Date;
    readonly image: string;
    readonly bodyOnClick?: () => void;
    readonly selected?: boolean;
    readonly onClickManage?: () => void;
}) => {
    return (
        <Box
            sx={{
                "&:hover .hover-show": {
                    opacity: 1,
                },
                "&:hover .hover-show-box": {
                    boxShadow: (theme) => theme.shadows.allAround,
                },
                width: "100%",
                height: "100%",
            }}
        >
            <Stack
                className="hover-show-box"
                gap={2}
                width={"100%"}
                sx={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: (theme) => theme.palette.background.main,
                    border: 1,
                    borderRadius: 1,
                    borderColor: (theme) =>
                        selected
                            ? theme.palette.background.onContainer
                            : theme.palette.stroke[100],
                    padding: 2,
                    justifyContent: "space-between",
                }}
            >
                <Box
                    sx={{
                        height: (theme) => theme.spacing(46.7),
                        width: "100%",
                        borderRadius: 1,
                        overflow: "hidden",
                        position: "relative",
                        background: (theme) => theme.palette.surface.container,
                        backgroundImage: `url('${image}')`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        cursor: "pointer",
                    }}
                    onClick={() => bodyOnClick?.()}
                />

                <Stack gap={1}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item xs={10}>
                            <OverflowingTypography
                                variant="body2"
                                fontWeight="semibold"
                            >
                                {title}
                            </OverflowingTypography>
                        </Grid>
                        <Grid
                            item
                            className="hover-show"
                            sx={{ breakpoints: { md: { opacity: 0 } } }}
                        >
                            <Grid container spacing={1} alignItems="center">
                                <IconButton
                                    variant="minimal"
                                    onClick={bodyOnClick}
                                >
                                    <OpenInNewOutline size="sm" />
                                </IconButton>
                                {onClickManage && (
                                    <Menu
                                        content={
                                            <MenuItem
                                                label="Manage"
                                                density="dense"
                                                startNode={
                                                    <EditOutline size="xs" />
                                                }
                                                onClick={onClickManage}
                                            />
                                        }
                                        placement="bottom-end"
                                    >
                                        <IconButton variant="minimal">
                                            <MoreHorizOutline size="sm" />
                                        </IconButton>
                                    </Menu>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <OverflowingTypography
                        variant="body3"
                        lineClamp={2}
                        sx={{
                            color: (theme) =>
                                theme.palette.disabled.onContainer,
                        }}
                    >
                        {subTitle}
                    </OverflowingTypography>
                </Stack>
                <Stack gap={1}>
                    <Divider />
                    {updatedAt && (
                        <Typography
                            variant="body4"
                            sx={{
                                color: (theme) =>
                                    theme.palette.disabled.onContainer,
                            }}
                        >
                            {`Last update: ${convertDateToTimePeriod(updatedAt)}`}
                        </Typography>
                    )}
                </Stack>
            </Stack>
        </Box>
    );
};

export default SavedViewCard;
