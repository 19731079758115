import {
    InputSavedArea,
    SavedArea,
    SlimSavedArea,
} from "@biggeo/bg-server-lib/datascape-ai";
import { DescriptionTextField, WithLoading } from "@biggeo/bg-ui";
import {
    BreadcrumbsButton,
    BreadcrumbsGroup,
    Button,
    Stack,
    StickyFabPlacementHelper,
    TextField,
    Typography,
} from "@biggeo/bg-ui/lab";
import { CenteredNestedLayoutWithHeader } from "@biggeo/bg-ui/lab/layouts";
import { Formik } from "formik";
import capitalize from "lodash/capitalize";
import isEqual from "lodash/isEqual";
import { useDispatch } from "react-redux";
import Zod from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { OnPageLeave } from "../../common/components/OnPageLeave";
import { MapTabs } from "../../map/map-wrappers/MapViewWrapper";
import { modalActions } from "../../modal/redux/model";
import { Routes } from "../../navigation/redux/model";
import { CallBacksType } from "../../utils/types";
import DeleteSavedAreaModal from "./DeleteSavedAreaModal";
interface ISavedAreaForm {
    readonly savedArea: SlimSavedArea;
    readonly navigate: (to: string) => void;
    readonly save: (
        input: InputSavedArea,
        callbacks?: CallBacksType<SlimSavedArea>
    ) => void;
    readonly updateLoading?: boolean;
    readonly deleteLoading: boolean;
    readonly onRemove: (
        id: number,
        callbacks?: CallBacksType<SavedArea>
    ) => void;
    readonly mapTemplateId: number;
    readonly savedAreaId: number;
}

export const SavedAreaFormView = ({
    savedArea,
    navigate,
    save,
    updateLoading,
    onRemove,
    mapTemplateId,
}: ISavedAreaForm) => {
    const dispatch = useDispatch();

    const initialValues = {
        id: savedArea.id,
        name: savedArea.name,
        description: savedArea.description || undefined,
    };

    const openModal = (area: SlimSavedArea) => {
        dispatch(
            modalActions.openModal({
                modalType: "new-dialog",
                dialogProps: {
                    variant: "centered-medium",
                },
                component: (
                    <DeleteSavedAreaModal
                        onRemove={(callbacks) => onRemove(area.id, callbacks)}
                        savedArea={area}
                    />
                ),
            })
        );
    };
    return (
        <Formik<InputSavedArea>
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                save(values, {
                    onSuccess: (data) => {
                        actions.setSubmitting(false);
                        actions.resetForm({
                            values: {
                                id: data.id,
                                name: data.name,
                                description: data.description || undefined,
                            },
                        });
                    },
                });
            }}
            validationSchema={toFormikValidationSchema(
                Zod.object({
                    id: Zod.number(),
                    name: Zod.string(),
                    description: Zod.string().optional(),
                })
            )}
            validateOnMount
        >
            {({
                values,
                setValues,
                dirty,
                isValid,
                isSubmitting,
                handleSubmit,
            }) => {
                const onChange = (i: Partial<InputSavedArea>) => {
                    setValues((p) => ({ ...p, ...i }));
                };
                return (
                    <OnPageLeave
                        trigger={!isEqual(values, initialValues)}
                        save={({ navigate }) =>
                            save(values, { onSuccess: () => navigate() })
                        }
                    >
                        <CenteredNestedLayoutWithHeader
                            title={
                                <BreadcrumbsGroup value={`${savedArea.name}`}>
                                    <BreadcrumbsButton
                                        value="available-areas"
                                        onClick={() =>
                                            navigate(
                                                `${Routes.mapView}/${mapTemplateId}/${MapTabs.configuration}/${savedArea.id}`
                                            )
                                        }
                                    >
                                        Available areas
                                    </BreadcrumbsButton>
                                    <BreadcrumbsButton
                                        value={`${savedArea.name}`}
                                        onClick={() =>
                                            navigate(
                                                `${Routes.mapView}/${mapTemplateId}/${MapTabs.configuration}/${savedArea.id}`
                                            )
                                        }
                                        hideSeparator
                                    >
                                        {capitalize(savedArea.name)}
                                    </BreadcrumbsButton>
                                </BreadcrumbsGroup>
                            }
                        >
                            <Stack gap={4}>
                                <Stack gap={0.5}>
                                    <Typography
                                        variant="title1"
                                        fontWeight="bold"
                                    >
                                        Manage Saved Area
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        fontWeight="regular"
                                        sx={{
                                            color: (theme) =>
                                                theme.palette.disabled
                                                    .onContainer,
                                        }}
                                    >
                                        Edit saved area details
                                    </Typography>
                                </Stack>
                                <TextField
                                    fullWidth
                                    required
                                    name="name"
                                    label="Saved area name"
                                    value={values.name || ""}
                                    placeholder="Enter name"
                                    onChange={(_, v) =>
                                        onChange({
                                            name: v === "" ? undefined : v,
                                        })
                                    }
                                />
                                <DescriptionTextField
                                    value={values.description || undefined}
                                    onChange={(_, v) =>
                                        onChange({
                                            description:
                                                v === "" ? undefined : v,
                                        })
                                    }
                                />
                                <StickyFabPlacementHelper
                                    placement="right"
                                    sx={{
                                        paddingY: 4,
                                        backgroundColor: (theme) =>
                                            theme.palette.background.container,
                                    }}
                                >
                                    <Stack
                                        flexDirection="row"
                                        justifyContent="flex-end"
                                        gap={4}
                                    >
                                        <Stack flexDirection="row" gap={4}>
                                            <Button
                                                type="submit"
                                                variant={"outlined"}
                                                color={"error"}
                                                disabled={isSubmitting}
                                                onClick={() =>
                                                    openModal(savedArea)
                                                }
                                            >
                                                Remove
                                            </Button>
                                            <WithLoading
                                                loading={updateLoading}
                                                text={"Saving Changes..."}
                                            >
                                                <Button
                                                    type="submit"
                                                    disabled={
                                                        !isValid || !dirty
                                                    }
                                                    onClick={() =>
                                                        handleSubmit()
                                                    }
                                                >
                                                    Save Changes
                                                </Button>
                                            </WithLoading>
                                        </Stack>
                                    </Stack>
                                </StickyFabPlacementHelper>
                            </Stack>
                        </CenteredNestedLayoutWithHeader>
                    </OnPageLeave>
                );
            }}
        </Formik>
    );
};
