import {
    DataSource,
    MapTemplateDataset,
    MapTemplateDatasetExtended,
} from "@biggeo/bg-server-lib/datascape-ai";
import { EmptyScreen, IFilterSearchPaginate } from "@biggeo/bg-ui/lab";
import { AddOutline } from "@biggeo/bg-ui/lab/icons";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { isAppRunningOnSF } from "../../../common/redux/hooks";
import MapDatasetsConfigGrid from "./MapDatasetsConfigGrid";

interface IMapDatasetsConfigView {
    readonly mapTemplateDatasets: MapTemplateDatasetExtended[];
    readonly filterSearchPaginateProps: IFilterSearchPaginate;
    readonly route?: "data" | "configuration";
    readonly setMapTemplateDataset: (i: {
        mapTemplateDatasetId: number;
        mapTemplateDataset?: MapTemplateDataset;
        dataSource?: DataSource;
    }) => void;
}

const MapDatasetsConfigView = ({
    mapTemplateDatasets,
    filterSearchPaginateProps,
    route,
    setMapTemplateDataset,
}: IMapDatasetsConfigView) => {
    const isRunningOnSF = isAppRunningOnSF();

    return pipe(
        mapTemplateDatasets,
        O.fromPredicate((d) => !isEmpty(d)),
        O.fold(
            () => (
                <EmptyScreen
                    title={
                        isEqual(route, "data")
                            ? "No Datasets Available"
                            : "No Datasets Enabled"
                    }
                    subtitle={
                        isEqual(route, "data")
                            ? "Your datasets that need to be indexed by BigGeo first will appear here."
                            : "You don't have any datasets from your data management area enabled for this map template."
                    }
                    image={
                        isEqual(route, "data")
                            ? "https://biggeo.blob.core.windows.net/media/DataManagmentEmpty.png"
                            : "https://biggeo.blob.core.windows.net/test/f0896fd5-e036-2957-9c1c-61dfa1ff8cf9.png.png"
                    }
                    buttons={[
                        {
                            startNode: <AddOutline />,
                            onClick: () => {},
                            children: "Add Dataset",
                            variant: "filled",
                            color: "primary",
                        },
                        isEqual(route, "data")
                            ? {
                                  onClick: () => {},
                                  children: "Index Data",
                              }
                            : {
                                  onClick: () => {},
                                  children: "Learn More",
                              },
                    ]}
                />
            ),
            (data) => (
                <MapDatasetsConfigGrid
                    route={route}
                    mapTemplateDatasets={data}
                    filterSearchPaginateProps={filterSearchPaginateProps}
                    setMapTemplateDataset={setMapTemplateDataset}
                    isRunningOnSF={isRunningOnSF}
                />
            )
        )
    );
};

export default MapDatasetsConfigView;
