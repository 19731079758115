import {
    DataSource,
    useFetchDataSourceLazyQuery,
    useSwitchComputeOffMutation,
    useSwitchComputeOnMutation,
    useUpdateDataSourceMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    CircularLoading,
    Grid,
    IconButton,
    Severity,
    Typography,
} from "@biggeo/bg-ui/lab";
import { PlayCircleOutline, StopCircle } from "@biggeo/bg-ui/lab/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toasterActions } from "../../toaster/containers/redux/model";
import { collectionStorageDirectory } from "../../utils/variables";

export interface ComputeViewProps {
    readonly compute: boolean;
    readonly id: string;
    readonly collectionName: string;
    readonly loading: boolean;
    readonly setDataSource: (dataSource: Partial<DataSource>) => void;

    readonly setDisabledByCompute: React.Dispatch<
        React.SetStateAction<readonly string[]>
    >;
    readonly updateDataset: (updated: DataSource) => void;
}

export const ComputeView = ({
    compute,
    id,
    collectionName,
    loading,
    setDataSource,
    setDisabledByCompute,
    updateDataset,
}: ComputeViewProps) => {
    const dispatch = useDispatch();

    const { executeMutation: switchComputeOff } = useSwitchComputeOffMutation();
    const { executeMutation: switchComputeOn } = useSwitchComputeOnMutation();
    const { executeQuery: fetchDataSources } = useFetchDataSourceLazyQuery();
    const { executeMutation: updateDataSource } = useUpdateDataSourceMutation();

    const [switched, setSwitched] = useState<boolean>(compute);
    const [isLoading, setIsLoading] = useState<boolean>(loading);

    const addToDisabledByCompute = () => {
        setDisabledByCompute((prev) => [id, ...prev]);
    };
    const removeFromDisabledByCompute = () => {
        setDisabledByCompute((prev) => prev.filter((currId) => currId !== id));
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const intervalId = setInterval(() => {
            isLoading === true &&
                fetchDataSources({
                    variables: { id },
                    onCompleted: (data) => {
                        const returnedIsLoading = Boolean(
                            data.fetchDataSource.isLoading
                        );
                        const returnedCompute = data.fetchDataSource.compute;

                        if (returnedIsLoading !== isLoading) {
                            setIsLoading(returnedIsLoading);

                            if (returnedCompute !== compute) {
                                setSwitched(!switched);
                                const returnedData = data.fetchDataSource;
                                setDataSource({
                                    id: returnedData.id,
                                    compute: returnedData.compute,
                                    isLoading: returnedData.isLoading,
                                });
                                updateDataset(returnedData);

                                if (returnedData.compute) {
                                    removeFromDisabledByCompute();
                                } else {
                                    addToDisabledByCompute();
                                }
                            }
                        }
                    },
                });
        }, 3000);

        return () => {
            clearInterval(intervalId);
        };
    }, [isLoading]);

    return isLoading ? (
        <CircularLoading />
    ) : switched ? (
        <Grid container alignItems="center" gap={1}>
            <IconButton
                variant="tonal"
                density="dense"
                disabled={isLoading}
                onClick={(e) => {
                    e.preventDefault();
                    addToDisabledByCompute();
                    setIsLoading(true);
                    switchComputeOff({
                        variables: {
                            input: {
                                id: id,
                                collectionName: collectionName,
                            },
                        },
                        onCompleted: (data) => {
                            setDataSource({
                                id: data.switchComputeOff.id,
                                compute: data.switchComputeOff.compute,
                                isLoading: data.switchComputeOff.isLoading,
                            });
                            updateDataset(data.switchComputeOff);
                            setIsLoading(false);
                            setSwitched(!switched);
                        },
                        onError: (e) => {
                            const errorMsg = JSON.parse(e.message) as {
                                code: string;
                                details: string;
                            };

                            const containsDoesNotExist =
                                errorMsg.details.includes("does not exist");

                            if (containsDoesNotExist) {
                                updateDataSource({
                                    variables: {
                                        input: {
                                            id,
                                            compute: false,
                                        },
                                    },
                                    onCompleted: (data) => {
                                        setDataSource({
                                            id: data.updateDataSource.id,

                                            compute:
                                                data.updateDataSource.compute,
                                        });

                                        updateDataset(data.updateDataSource);
                                        setSwitched(!switched);
                                        setIsLoading(false);
                                        dispatch(
                                            toasterActions.openToast({
                                                open: true,
                                                severity: Severity.error,
                                                title: "Dataset is currently not loaded. Compute value has been changed to match.",
                                                autoHideDuration: 10000,
                                            })
                                        );
                                    },
                                    onError: () => {
                                        setSwitched(!switched);
                                        setIsLoading(false);
                                    },
                                });
                            } else {
                                removeFromDisabledByCompute();
                                setIsLoading(false);

                                dispatch(
                                    toasterActions.openToast({
                                        open: true,
                                        severity: Severity.error,
                                        title: errorMsg.details,
                                        autoHideDuration: 5000,
                                    })
                                );
                            }
                        },
                    });
                }}
                sx={{
                    borderRadius: (theme) => theme.radius.full,
                }}
            >
                <StopCircle color={isLoading ? "disabled" : "primary"} />
            </IconButton>
            <Typography variant="body3" fontWeight="semibold">
                On
            </Typography>
        </Grid>
    ) : (
        <Grid container alignItems="center" gap={1}>
            <IconButton
                variant="tonal"
                density="dense"
                disabled={isLoading}
                onClick={(e) => {
                    e.preventDefault();
                    setIsLoading(true);
                    switchComputeOn({
                        variables: {
                            input: {
                                id: id,
                                collectionName: collectionName,
                                location:
                                    // biome-ignore lint/style/useTemplate: <explanation>
                                    collectionStorageDirectory +
                                    `/${collectionName}.cereal`,
                            },
                        },
                        onCompleted: (data) => {
                            setDataSource({
                                id: data.switchComputeOn.id,
                                compute: data.switchComputeOn.compute,
                                isLoading: data.switchComputeOn.isLoading,
                            });
                            updateDataset(data.switchComputeOn);
                            removeFromDisabledByCompute();
                            setIsLoading(false);
                            setSwitched(!switched);
                        },
                        onError: (e) => {
                            const errorMsg = JSON.parse(e.message) as {
                                code: string;
                                details: string;
                            };

                            setIsLoading(false);
                            dispatch(
                                toasterActions.openToast({
                                    open: true,
                                    severity: Severity.error,
                                    title: errorMsg.details,
                                    autoHideDuration: 5000,
                                })
                            );
                        },
                    });
                }}
                sx={{
                    borderRadius: (theme) => theme.radius.full,
                }}
            >
                <PlayCircleOutline color={isLoading ? "disabled" : "primary"} />
            </IconButton>
            <Typography variant="body3" fontWeight="semibold">
                Off
            </Typography>
        </Grid>
    );
};
