import {
    DataSource,
    InputConnectDataSource,
    UserSnowflakeTableData,
} from "@biggeo/bg-server-lib/datascape-ai";
import { DraggablePopper } from "@biggeo/bg-ui";
import {
    AutoComplete,
    Box,
    Button,
    CellItem,
    FlexScrollArea,
    FlexScrollAreaContainer,
    Grid,
    IconButton,
    Radio,
    Stack,
    TextField,
    TopAppBar,
} from "@biggeo/bg-ui/lab";
import {
    CloseOutline,
    CropSquare,
    SearchOutline,
} from "@biggeo/bg-ui/lab/icons";
import { Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import tinycolor2 from "tinycolor2";
import { modalActions } from "../modal/redux/model";
import ColorPicker from "./ColorPicker/ColorPicker";

export type MappingModalProps = {
    readonly snowflakeData: UserSnowflakeTableData;
    readonly dataSource: DataSource;
    readonly connectDataSource: (input: InputConnectDataSource) => void;
};

export const MappingModal = ({
    snowflakeData,
    dataSource,
    connectDataSource,
}: MappingModalProps) => {
    const [oneGeospatialColumn, setOneGeospatialColumn] =
        useState<boolean>(false);
    const [twoGeospatialColumn, setTwoGeospatialColumn] =
        useState<boolean>(false);
    const [mapping, setMapping] = useState<
        Pick<
            InputConnectDataSource,
            | "id"
            | "collectionName"
            | "label"
            | "color"
            | "tableId"
            | "description"
        > & {
            readonly lat: string;
            readonly long: string;
            readonly geoSpatialColumn: string;
            readonly tableIdType: string;
        }
    >({
        id: dataSource.id,
        lat: "",
        long: "",
        tableIdType: "",
        geoSpatialColumn: "",
        collectionName: dataSource.collectionName,
        label: "",
        tableId: "",
        description: "",
        color: "#000000ff",
    });
    const [datasetAnchor, setDatasetAnchor] =
        useState<HTMLButtonElement | null>(null);
    const addDatasetAnchor = (e?: React.MouseEvent<HTMLButtonElement>) =>
        setDatasetAnchor(e ? e.currentTarget : null);
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(modalActions.closeModal());
    };

    const oneGeospatialFilledIn =
        oneGeospatialColumn && mapping.geoSpatialColumn !== "";

    const twoGeospatialFilledIn =
        twoGeospatialColumn && mapping.lat !== "" && mapping.long !== "";

    const { lat, long, geoSpatialColumn, collectionName, ...restMapping } =
        mapping;

    const isDisabled =
        Object.values(restMapping).includes("") ||
        (!oneGeospatialFilledIn && !twoGeospatialFilledIn);

    return (
        <FlexScrollAreaContainer width="100%">
            <TopAppBar
                density="dense"
                sx={{
                    borderBottom: 1,
                    borderColor: (theme) => theme.palette.stroke[100],
                }}
                endNode={
                    <IconButton variant="minimal" onClick={closeModal}>
                        <CloseOutline />
                    </IconButton>
                }
            />
            <FlexScrollArea
                sx={{
                    padding: 4,
                }}
            >
                <Grid
                    container
                    height="100%"
                    rowGap="4%"
                    sx={{
                        flexDirection: "column",
                        breakpoints: {
                            md: {
                                flexDirection: "row",
                            },
                        },
                    }}
                >
                    <Grid
                        item
                        xs
                        minWidth={0}
                        sx={{
                            height: "48%",
                            breakpoints: {
                                md: {
                                    height: "100%",
                                    padding: 4,
                                },
                            },
                        }}
                    >
                        <FlexScrollAreaContainer width="100%">
                            <FlexScrollArea
                                width="100%"
                                sx={{
                                    flexDirection: "column",
                                }}
                            >
                                <Typography variant="h6" fontWeight="bold">
                                    Table Columns
                                </Typography>
                                <Stack width="100%">
                                    {snowflakeData.columns.map((val) => (
                                        <CellItem
                                            key={val.name}
                                            title={val.name}
                                        />
                                    ))}
                                </Stack>
                            </FlexScrollArea>
                        </FlexScrollAreaContainer>
                    </Grid>
                    <Grid
                        item
                        xs
                        minWidth={0}
                        sx={{
                            height: "48%",
                            breakpoints: {
                                md: {
                                    height: "100%",
                                    padding: 4,
                                },
                            },
                        }}
                    >
                        <FlexScrollAreaContainer width="100%">
                            <FlexScrollArea
                                width="100%"
                                sx={{
                                    flexDirection: "column",
                                    gap: 4,
                                }}
                            >
                                <Typography variant="h6" fontWeight="bold">
                                    Mapping
                                </Typography>
                                <TextField
                                    label="Dataset Name"
                                    required
                                    placeholder="Enter dataset name"
                                    fullWidth
                                    onChange={(e) =>
                                        setMapping((prev) => ({
                                            ...prev,
                                            label: e?.target.value || "",
                                        }))
                                    }
                                />
                                <Box />
                                <AutoComplete
                                    options={snowflakeData.columns
                                        .filter(
                                            (data) =>
                                                data.type === "TEXT" ||
                                                data.type === "NUMBER" ||
                                                data.type === "FIXED"
                                        )
                                        .map((data) => ({
                                            label: data.name,
                                        }))}
                                    required
                                    label="Table ID"
                                    placeholder="Select"
                                    startNode={<SearchOutline />}
                                    helperText="Enter table ID"
                                    fullWidth
                                    onOptionSelect={(option) => {
                                        setMapping((prev) => {
                                            const column =
                                                snowflakeData.columns.find(
                                                    (data) =>
                                                        data.name ===
                                                        option.label
                                                );
                                            return {
                                                ...prev,
                                                tableId: option.label || "",
                                                tableIdType: column?.type || "",
                                            };
                                        });
                                    }}
                                />
                                <Box />
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                    }}
                                >
                                    <Stack gap={1} alignItems={"center"}>
                                        <Radio
                                            checked={oneGeospatialColumn}
                                            onChange={() => {
                                                setOneGeospatialColumn(true);
                                                setTwoGeospatialColumn(false);
                                            }}
                                        />
                                        <Typography variant="body3">
                                            One geospatial columns
                                        </Typography>
                                    </Stack>
                                    <Stack gap={1} alignItems={"center"}>
                                        <Radio
                                            checked={twoGeospatialColumn}
                                            onChange={() => {
                                                setOneGeospatialColumn(false);
                                                setTwoGeospatialColumn(true);
                                            }}
                                        />
                                        <Typography variant="body3">
                                            Two geospatial columns
                                        </Typography>
                                    </Stack>
                                </Box>
                                {oneGeospatialColumn && (
                                    <AutoComplete
                                        options={snowflakeData.columns
                                            .filter(
                                                (data) =>
                                                    data.type === "GEOGRAPHY" ||
                                                    data.type === "GEOMETRY"
                                            )
                                            .map((data) => ({
                                                label: data.name,
                                            }))}
                                        required
                                        label="Geospatial Column"
                                        placeholder="Select"
                                        startNode={<SearchOutline />}
                                        helperText="Select the geospatial column from your table"
                                        fullWidth
                                        onOptionSelect={(option) => {
                                            setMapping((prev) => ({
                                                ...prev,
                                                geoSpatialColumn:
                                                    option.label || "",
                                            }));
                                        }}
                                    />
                                )}
                                {twoGeospatialColumn && (
                                    <>
                                        <AutoComplete
                                            options={snowflakeData.columns
                                                .filter(
                                                    (data) =>
                                                        data.type === "REAL" ||
                                                        data.type === "FIXED"
                                                )
                                                .map((data) => ({
                                                    label: data.name,
                                                }))}
                                            required
                                            label="Latitude"
                                            placeholder="Select"
                                            startNode={<SearchOutline />}
                                            helperText="Select the latitude column from your table"
                                            fullWidth
                                            onOptionSelect={(option) => {
                                                setMapping((prev) => ({
                                                    ...prev,
                                                    lat: option.label || "",
                                                }));
                                            }}
                                        />
                                        <AutoComplete
                                            options={snowflakeData.columns
                                                .filter(
                                                    (data) =>
                                                        data.type === "REAL" ||
                                                        data.type === "FIXED"
                                                )
                                                .map((data) => ({
                                                    label: data.name,
                                                }))}
                                            required
                                            label="Longitude"
                                            placeholder="Select"
                                            startNode={<SearchOutline />}
                                            helperText="Select the longitude column from your table"
                                            fullWidth
                                            onOptionSelect={(option) => {
                                                setMapping((prev) => ({
                                                    ...prev,
                                                    long: option.label || "",
                                                }));
                                            }}
                                        />
                                    </>
                                )}
                                <CellItem
                                    density="none"
                                    disableActiveEffect
                                    disableHoverEffect
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        addDatasetAnchor(
                                            datasetAnchor ? undefined : e
                                        );
                                    }}
                                    startNode={
                                        <CropSquare
                                            sx={{
                                                color:
                                                    mapping.color || "#fffff",
                                            }}
                                        />
                                    }
                                    title={`${
                                        tinycolor2(
                                            mapping.color
                                        ).toHexString() || "#fffff"
                                    }`}
                                />
                                <DraggablePopper
                                    open={Boolean(datasetAnchor)}
                                    anchorEl={datasetAnchor}
                                    onClose={() => addDatasetAnchor()}
                                    placement="top"
                                    sx={{
                                        zIndex: 999,
                                        width: 300,
                                        minHeight: 340,
                                    }}
                                >
                                    {Boolean(datasetAnchor) && (
                                        <Grid
                                            item
                                            xs={12}
                                            container
                                            gap={4}
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            sx={{
                                                padding: 4,
                                            }}
                                        >
                                            <Grid
                                                item
                                                container
                                                spacing={2}
                                                justifyContent={"space-around"}
                                            >
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="title2"
                                                        fontWeight="bold"
                                                    >
                                                        {mapping.label ||
                                                            mapping.id}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="title3"
                                                        fontWeight="bold"
                                                    >
                                                        Color
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ColorPicker
                                                        initialColor={
                                                            mapping.color
                                                        }
                                                        getColor={(color) => {
                                                            setMapping(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    color: color,
                                                                })
                                                            );
                                                            addDatasetAnchor();
                                                        }}
                                                        applyButton
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </DraggablePopper>
                            </FlexScrollArea>
                        </FlexScrollAreaContainer>
                    </Grid>
                </Grid>
            </FlexScrollArea>
            <Grid container justifyContent="flex-end" sx={{ padding: 4 }}>
                <Button
                    color="primary"
                    disabled={isDisabled}
                    onClick={() => {
                        const tinyColor = tinycolor2(mapping.color);

                        connectDataSource({
                            id: mapping.id,
                            geographyColumn: twoGeospatialColumn
                                ? `${mapping.long},${mapping.lat}`
                                : `${mapping.geoSpatialColumn}`,
                            collectionName: mapping.collectionName,
                            tableId: mapping.tableId,
                            tableIdType: mapping.tableIdType,
                            label: mapping.label,
                            color: tinyColor.toHexString(),
                        });
                        closeModal();
                    }}
                >
                    Confirm Mapping
                </Button>
            </Grid>
        </FlexScrollAreaContainer>
    );
};
