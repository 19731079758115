import {
    InputUpdateSavedArea,
    SavedAreasExtended,
    SlimSavedArea,
    useFetchSavedAreasByAreaQuery,
    useUpdateSavedAreaMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { GridColDef } from "@biggeo/bg-ui";
import {
    CellItem,
    ColFilterType,
    Grid,
    IconButton,
    Switch,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    CropFreeOutline,
    Info,
    OpenInNewOutline,
} from "@biggeo/bg-ui/lab/icons";
import * as turf from "@turf/turf";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import lowerCase from "lodash/lowerCase";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isAppRunningOnSF, useDataGridOptions } from "../../common/redux/hooks";
import { DataGridContainer } from "../../components/DataGrid/DataGridContainer";
import { mapDataActions } from "../../map/redux/model";
import { Routes } from "../../navigation/redux/model";
import { convertSqmToSkm } from "../../utils/utils";
interface ISavedAreasTableContainer {
    readonly areaId: number;
    readonly mapTemplateId?: number;
    readonly selectedAreaName?: string;
}

const SavedAreasTableContainer = ({
    areaId,
    mapTemplateId,
    selectedAreaName,
}: ISavedAreasTableContainer) => {
    const dispatch = useDispatch();
    const isRunningOnSF = isAppRunningOnSF();
    const toPage = useNavigate();

    const { dataGridFetchInputProps, filterSearchPaginateProps } =
        useDataGridOptions();

    const [localSavedAreas, setLocalSavedAreas] = useState<SavedAreasExtended>({
        total: 0,
        savedAreas: [],
    });

    const {
        queryReturn: { loading: savedAreasLoading, refetch },
    } = useFetchSavedAreasByAreaQuery({
        variables: {
            input: {
                areaId,
                ...(isEqual(lowerCase(selectedAreaName), "saved areas") && {
                    fkMapTemplateId: mapTemplateId,
                }),
                dataGridFetchInput: dataGridFetchInputProps,
                isSavedViewArea: false,
            },
        },
        onCompleted: (data) => {
            setLocalSavedAreas(data.fetchSavedAreasByArea);
        },
    });

    const {
        executeRemoteMutation,
        mutationReturn: [_, { loading: updateAreaLoading }],
    } = useUpdateSavedAreaMutation();

    const _toggleShowOnMap = async (
        value: boolean,
        savedArea: SlimSavedArea
    ) => {
        setSelectedRow(savedArea.id);
        await executeRemoteMutation({
            variables: {
                input: {
                    id: savedArea.id,
                    isEnabled: value,
                    name: savedArea.name,
                },
            },
            onCompleted: () => {
                refetch();
            },
        });
    };
    const [, setSelectedRow] = useState<number | undefined>(undefined);

    const toggleMapUse = async (
        value: boolean,
        savedArea: InputUpdateSavedArea
    ) => {
        setSelectedRow(savedArea.id);
        await executeRemoteMutation({
            variables: {
                input: {
                    id: savedArea.id,
                    mapUse: value,
                },
            },
            onCompleted: () => {
                setSelectedRow(undefined);
                refetch();
            },
        });
    };

    const onLoadArea = (id: number) => {
        dispatch(mapDataActions.updateMapData({ fkSavedAreaId: id }));
        toPage("/");
    };
    const cols: GridColDef<{
        id: number;
        name: string;
        totalArea: string;
        isEnabled: boolean;
        mapUse: boolean;
        areaId: number;
    }>[] = [
        {
            field: "src",
            headerName: "Src",
            flex: 1,
            width: 200,
            minWidth: 75,
            sortable: false,
            renderCell: () => (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    gap={8}
                    sx={{
                        padding: 2,
                    }}
                >
                    <IconButton variant="tonal" density="dense" color="primary">
                        <CropFreeOutline size="sm" />
                    </IconButton>
                </Grid>
            ),
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            minWidth: 200,
            sortable: false,
            type: ColFilterType.string,
            filterable: true,
        },
        {
            field: "area",
            renderHeader: () => {
                return (
                    <CellItem
                        sx={{
                            color: (theme) =>
                                theme.palette.disabled.onContainer,
                        }}
                        title="Area"
                    />
                );
            },
            minWidth: 175,
            sortable: false,
            type: ColFilterType.number,
            renderCell: (params) => (
                <Typography
                    variant="body3"
                    fontWeight="semibold"
                    sx={{ padding: 4 }}
                >
                    {`${params.row.totalArea} km`}
                    <sup>2</sup>
                </Typography>
            ),
        },
        {
            field: "mapUse",
            renderHeader: () => {
                return (
                    <CellItem
                        sx={{
                            color: (theme) =>
                                theme.palette.disabled.onContainer,
                        }}
                        endNode={<Info color={"primary"} />}
                        title="Map use"
                    />
                );
            },
            minWidth: 175,
            sortable: false,
            type: ColFilterType.boolean,
            renderCell: (params) => (
                <Switch
                    size="small"
                    switched={params.row.mapUse}
                    onSwitchChange={(v) =>
                        toggleMapUse(!v, { id: params.row.id })
                    }
                />
            ),
        },
        {
            field: "",
            minWidth: 175,
            sortable: false,
            filterable: false,
            renderHeader: () => {
                return (
                    <CellItem
                        sx={{
                            color: (theme) =>
                                theme.palette.disabled.onContainer,
                        }}
                        endNode={<Info color={"primary"} />}
                        title="View"
                    />
                );
            },
            renderCell: (params) => {
                return (
                    <Grid container justifyContent="center">
                        <IconButton
                            variant="filled"
                            density="dense"
                            disabled={
                                updateAreaLoading || !params.row.isEnabled
                            }
                            sx={{ flexShrink: 0 }}
                        >
                            <Typography
                                variant={"button"}
                                fontWeight={"semibold"}
                            >
                                Open
                            </Typography>
                            <OpenInNewOutline
                                size="sm"
                                onClick={() => {
                                    return (
                                        !updateAreaLoading &&
                                        onLoadArea(params.row.id)
                                    );
                                }}
                            />
                        </IconButton>
                    </Grid>
                );
            },
        },
    ];

    if (isEqual(lowerCase(selectedAreaName), "saved areas")) {
        cols.push({
            field: "actions",
            minWidth: 125,
            sortable: false,
            filterable: false,
            headerName: "",
            type: "actions",
            renderCell: (params) => {
                return (
                    <Grid
                        container
                        justifyContent="center"
                        sx={{ paddingLeft: 4, paddingRight: 4 }}
                    >
                        <IconButton
                            variant="outlined"
                            density="comfortable"
                            disabled={!params.row.isEnabled}
                            sx={{ flexShrink: 0 }}
                            onClick={() => {
                                toPage(
                                    `${Routes.mapView}/${mapTemplateId}/configuration/${params.row.id}`
                                );
                            }}
                        >
                            <Typography
                                variant={"button"}
                                fontWeight={"semibold"}
                            >
                                Manage
                            </Typography>
                        </IconButton>
                    </Grid>
                );
            },
        });
    }

    return (
        <DataGridContainer
            columnVisibilityModel={{
                id: false,
                showOnMap: isRunningOnSF,
            }}
            disableColumnResize
            loading={savedAreasLoading}
            columns={cols}
            initialState={{ pinnedColumns: { right: ["actions"] } }}
            rows={pipe(
                localSavedAreas.savedAreas,
                A.map((poly) => {
                    return {
                        id: poly.id,
                        name: poly.name,
                        totalArea: pipe(
                            poly.geometries,
                            A.reduce(0, (acc, val) =>
                                pipe(
                                    val,
                                    turf.area,
                                    convertSqmToSkm,
                                    (sq) => acc + sq
                                )
                            ),
                            (totalArea) => totalArea.toFixed(4)
                        ),
                        isEnabled: poly.isEnabled,
                        areaId: poly.areaId,
                        mapUse: poly.mapUse,
                    };
                })
            )}
            rowCount={localSavedAreas.total}
            filterSearchPaginateProps={filterSearchPaginateProps}
            title={selectedAreaName || ""}
        />
    );
};

export default SavedAreasTableContainer;
