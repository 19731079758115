import { CompanyAvatar } from "@biggeo/bg-ui/lab";
import { NavigationRail } from "../components/NavigationRail";
import { LayoutTab } from "./DatasourcesLayout";

export const DatasourcesLeftContent = ({
    tab,
    key,
    onClick,
    selected,
}: {
    readonly tab: LayoutTab;
    readonly key: string;
    readonly selected: string;
    readonly onClick: (label: string) => void;
}) => {
    return (
        <NavigationRail
            key={key}
            selected={selected === tab.title}
            onClick={() => onClick(tab.title)}
            icon={
                tab.logoUrl ? (
                    <CompanyAvatar
                        size={"xxs"}
                        alt={"company logo"}
                        src={tab.logoUrl}
                    />
                ) : (
                    <></>
                )
            }
        >
            {tab.title}
        </NavigationRail>
    );
};
