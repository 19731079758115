import {
    InputGeometryId,
    PointDataInput,
} from "@biggeo/bg-server-lib/datascape-ai";
import mapboxgl from "mapbox-gl";
import { useDatabaseMetaDataArray } from "../../../database-meta-data/redux/hooks";

export type HoveredData = {
    id?: number;
    databaseId: string;
    label: string;
    count: number;
};

export const useViewDataHook = (
    setSelectedPoint: (num?: PointDataInput) => void,
    setHover: (hovered?: HoveredData) => void
) => {
    const datasets = useDatabaseMetaDataArray();

    const handler = (
        e: mapboxgl.MapMouseEvent & {
            features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
        } & mapboxgl.EventData
    ) => {
        const properties = e.features?.[0]?.properties;
        if (!properties) {
            return;
        }

        const id = JSON.parse(properties?.id);

        const databaseId = properties?.databaseId;
        if (id) {
            setSelectedPoint({
                id:
                    "uint64" in id
                        ? {
                              uint64: {
                                  low: id.uint64?.low
                                      ? id.uint64.low
                                      : id.uint64,
                              },
                          }
                        : { string: id.string },
                databaseId,
            });
        }
    };

    const onStyleLoad = (map: mapboxgl.Map) => {
        for (const dataset of datasets) {
            map.on("click", `${dataset.id}-points-layer`, handler);
            map.on("click", `${dataset.id}-polygons`, handler);
            map.on("click", `${dataset.id}-polygons-outline`, handler);

            for (const layer of [
                `${dataset.id}-points-layer`,
                `${dataset.id}-points-heatmap-layer`,
                `${dataset.id}-polygons`,
                `${dataset.id}-polygons-outline`,
                `${dataset.id}-filtered-points-layer`,
                `${dataset.id}-filtered-heatmap-layer`,
                `${dataset.id}-icons`,
                `${dataset.id}-triangles`,
            ]) {
                map.on("mouseenter", layer, (e) => {
                    // Change the cursor style as a UI indicator.
                    if (
                        layer === `${dataset.id}-points-layer` ||
                        layer === `${dataset.id}-polygons` ||
                        `${dataset.id}-polygons-outline`
                    ) {
                        map.getCanvas().style.cursor = "pointer";
                    }
                    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                    setHover(e.features?.[0].properties as any);
                });
                map.on("mouseleave", layer, () => {
                    map.getCanvas().style.cursor = "";
                    // setHover(undefined);
                });
            }
        }
    };

    return {
        onStyleLoad,
    };
};
