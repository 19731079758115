import { Chip, chipClasses } from "@biggeo/bg-ui/lab";
import { AddCircleOutline } from "@biggeo/bg-ui/lab/icons";
import React from "react";

export type DashedChipProps = {
    text: string;
    onClick: () => void;
    startNode?: React.ReactNode;
};

export const DashedChip = ({ text, onClick, startNode }: DashedChipProps) => (
    <Chip
        density="dense"
        variant="outlined"
        rounded
        startNode={startNode ?? <AddCircleOutline size="sm" />}
        onClick={onClick}
        sx={{
            [`&.${chipClasses.outlined}`]: {
                boxShadow: "none",
            },
            border: 1,
            borderStyle: "dashed",
            borderColor: (theme) => theme.palette.outline,
        }}
    >
        {text}
    </Chip>
);
