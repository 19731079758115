import { ApolloQueryResult } from "@apollo/client";
import {
    DataSource,
    Exact,
    FetchAllAreasExtendedQuery,
    FetchSavedViewsQuery,
    FilterObject,
    InputFetchSavedView,
    InputViewBox,
    SavedArea,
    SavedView,
    useCreateSavedViewMutation,
    useFetchSavedViewByIdQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { MobileDesktopEmulator, Typography } from "@biggeo/bg-ui/lab";
import { Box } from "@biggeo/bg-ui/lab";
import { match } from "@vividtheory/remotedata";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import React from "react";
import { useParams } from "react-router";
import { ErrorPage } from "../../../common/components/ErrorPage.tsx";
import {
    getInputPolygon,
    getMapFeatures,
    isPolygonFeature,
} from "../../utils/utils";
import SaveViewForm from "../containers/SaveViewForm.tsx";
import { SavedPolygonType } from "../hooks/saved-polygon-hooks.ts";

interface ISaveView {
    readonly openSaveViewPopper?: boolean;
    readonly savedViewId?: number;
    readonly setOpenSaveViewPopper?: (value: boolean) => void;
    readonly dataSources: DataSource[];
    readonly bounds?: mapboxgl.LngLatBounds;
    readonly selectedDataset: readonly string[];
    readonly viewport: InputViewBox;
    readonly multiFilters: FilterObject[];
    readonly savedPolygons?: SavedPolygonType;
    readonly refetchSavedAreas: (
        variables?:
            | Partial<
                  Exact<{
                      [key: string]: never;
                  }>
              >
            | undefined
    ) => Promise<ApolloQueryResult<FetchAllAreasExtendedQuery>>;
    readonly refetchSavedViews: (
        variables?:
            | Partial<
                  Exact<{
                      input: InputFetchSavedView;
                  }>
              >
            | undefined
    ) => Promise<ApolloQueryResult<FetchSavedViewsQuery>>;
    onCloseDraw: () => void;
    readonly draw: React.MutableRefObject<MapboxDraw | null>;
    readonly isLoaded: boolean;
    readonly handleSavedView: ({
        savedArea,
        viewport,
    }: Pick<SavedView, "savedArea" | "viewport">) => void;
}

const SaveView = ({
    openSaveViewPopper,
    setOpenSaveViewPopper,
    draw,
    dataSources,
    bounds,
    selectedDataset,
    viewport,
    multiFilters,
    refetchSavedAreas,
    refetchSavedViews,
    onCloseDraw,
    isLoaded,
    savedViewId,
    savedPolygons,
    handleSavedView,
}: ISaveView) => {
    const { remote: savedViewRD } = useFetchSavedViewByIdQuery({
        variables: {
            id: Number(savedViewId),
        },
    });

    const { mapTemplateId } = useParams();

    if (openSaveViewPopper) {
        onCloseDraw();
    }

    const polygons = pipe(
        getMapFeatures(draw, isLoaded),
        A.map((feature) =>
            isPolygonFeature(feature) ? getInputPolygon(feature) : undefined
        ),
        compact
    );

    const renderForm = (
        svData?: Partial<SavedView> | null,
        isFailure?: boolean
    ) => {
        return (
            <Box>
                <Box
                    sx={{
                        position: "absolute",
                        display: openSaveViewPopper ? "flex" : "none",
                        left: 4,
                        bottom: 4,
                        width: 85.75,
                        backgroundColor: (theme) => theme.palette.surface.main,
                        color: (theme) => theme.palette.background.main,
                        paddingX: 4,
                        paddingY: 3,
                        borderRadius: (theme) => theme.spacing(0.5),
                    }}
                >
                    <Typography variant="body3">
                        Drag viewport to desired starting point. Updated
                        configuration will be saved in this view.
                    </Typography>
                </Box>

                <Box
                    sx={{
                        position: "absolute",
                        display: openSaveViewPopper ? "flex" : "none",
                        right: 4,
                        bottom: 4,
                        ...(isFailure && {
                            width: 100,
                            height: 100,
                        }),
                        border: 1,
                        borderColor: (theme) => theme.palette.stroke[100],
                        borderRadius: 1,
                        boxShadow: (theme) => theme.shadows.allAround,
                    }}
                >
                    {isFailure ? (
                        <MobileDesktopEmulator
                            links={[
                                {
                                    rel: "stylesheet",
                                    href: "https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap",
                                },
                                {
                                    rel: "preconnect",
                                    href: "https://fonts.googleapis.com",
                                },
                                {
                                    rel: "preconnect",
                                    href: "https://fonts.gstatic.com",
                                },
                                {
                                    rel: "icon",
                                    href: "https://assets-global.website-files.com/6345ad9c87035c200bff8d84/65501dcde85a825aba284630_BigGeo%20Fav%20Icon.png",
                                },
                            ]}
                            width="100%"
                            height="100%"
                            disableResize
                        >
                            <ErrorPage />
                        </MobileDesktopEmulator>
                    ) : (
                        <SaveViewForm
                            draw={draw}
                            dataSources={dataSources}
                            mapTemplateId={mapTemplateId}
                            multiFilters={multiFilters}
                            selectedDataset={selectedDataset}
                            viewport={viewport}
                            savedView={svData}
                            bounds={bounds}
                            polygons={polygons}
                            refetchSavedAreas={refetchSavedAreas}
                            refetchSavedViews={refetchSavedViews}
                            onCloseDraw={onCloseDraw}
                            openSaveViewPopper={openSaveViewPopper}
                            setOpenSaveViewPopper={setOpenSaveViewPopper}
                            handleSavedView={handleSavedView}
                            savedPolygons={savedPolygons}
                        />
                    )}
                </Box>
            </Box>
        );
    };

    return pipe(
        savedViewId,
        O.fromPredicate((x) => x !== 0),
        O.fold(
            () => renderForm(null),
            () =>
                match(savedViewRD, {
                    _: () => <></>,
                    Success: (fetchSavedViews) =>
                        renderForm(fetchSavedViews.fetchSavedViewById),
                    Failure: () => renderForm(null, true),
                })
        )
    );
};

export default SaveView;
