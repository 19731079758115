import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "../../navigation/redux/model";
import MarketplaceUpgradeContainer from "../containers/MarketplaceUpgradeContainer.tsx";
import { UpgradeInfo } from "../views/MarketplaceUpgradeForm.tsx";

export type AvailableDataset = {
    readonly id: string;
    readonly title: string;
    readonly image?: string;
    readonly select?: boolean;
};

const UpgradeToBGVelocityPage = () => {
    const toPage = useNavigate();

    const navigate = (to?: string) =>
        to ? toPage(`${to}`) : toPage(Routes.marketplace);

    const confirm = (_input: UpgradeInfo) => {
        //implement API to send the confirm order
        toPage(Routes.unlocked);
    };

    return (
        <MarketplaceUpgradeContainer
            navigate={navigate}
            confirm={confirm}
            loading={false}
        />
    );
};

export default UpgradeToBGVelocityPage;
