import { Button, Stack, Typography } from "@biggeo/bg-ui/lab";
import { AddOutline } from "@biggeo/bg-ui/lab/icons";
import { useNavigate } from "react-router";
import { Routes } from "../../navigation/redux/model";

export const NoMapTemplateView = () => {
    const toPage = useNavigate();

    return (
        <Stack
            gap={2}
            alignItems="center"
            sx={{
                paddingY: 5,
            }}
        >
            <Typography variant="body3" fontWeight="bold" align="center">
                No Map Templates
            </Typography>
            <Typography
                variant="body4"
                align="center"
                color="disabled"
                colorSet="container"
                invertColors
            >
                You don’t have any map templates. Create your first map tp view
                their data usage here
            </Typography>
            <Button
                variant="outlined"
                color="surface"
                density="dense"
                startNode={<AddOutline size="xs" />}
                onClick={() => toPage(Routes.mapTemplatesCreate)}
            >
                Create
            </Button>
        </Stack>
    );
};
