import {
    FilterObject,
    InputPolygon,
    InputRadius,
    InputViewBox,
    PointDataInput,
    ReqOptions,
    SavedPolygon,
    SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Fade } from "@biggeo/bg-ui";
import { Box, Chip, Grid } from "@biggeo/bg-ui/lab";
import "ol/ol.css";
import { HoveredData } from "../../mapbox/hooks/view-data-hooks";
import { FunctionType } from "../../utils/utils";
import { useInitializeMap } from "../hooks/hooks";

const OpenLayersContainer = ({
    functionType,
    multiFilters,
    setSelectedPoint,
    viewport,
    handleViewportChange,
    recentResponse,
    showTriangles,
    showPoints,
    showFiltered,
    selectedDataset,
    savedPolygon,
    hoveredData,
    setHoveredData,
    options,
    handleRadiusChange,
    radius,
    polygon,
    handlePolygonChange,
}: {
    functionType: FunctionType;
    multiFilters: FilterObject[];
    setSelectedPoint: (num?: PointDataInput) => void;
    handleRadiusChange: ({ radius }: { radius: Partial<InputRadius> }) => void;
    radius: InputRadius;
    viewport: InputViewBox;
    handleViewportChange: ({ viewport }: { viewport: InputViewBox }) => void;
    polygon: InputPolygon;
    handlePolygonChange: ({ polygon }: { polygon: InputPolygon }) => void;
    recentResponse: SubscriptionResponse | undefined;
    selectedDataset: readonly string[];
    showTriangles: boolean;
    showPoints: boolean;
    showFiltered: boolean;
    savedPolygon?: SavedPolygon;
    hoveredData: HoveredData | undefined;
    setHoveredData: (hoveredData: HoveredData | undefined) => void;
    options: ReqOptions;
}) => {
    const { isLoaded, resetPolygon } = useInitializeMap({
        viewport,
        handleViewportChange,
        handlePolygonChange,
        polygon,
        functionType,
        radius,
        handleRadiusChange,
        multiFilters,
        recentResponse,
        selectedDataset,
        setSelectedPoint,
        showTriangles,
        showPoints,
        showFiltered,
        setHoveredData,
        savedPolygon,
        options,
    });
    return (
        <Fade in={isLoaded} timeout={1500}>
            <Grid
                sx={{
                    height: "100%",
                    width: "100%",
                    position: "relative",
                    backgroundColor: "rgb(30,30,30)",
                    overflow: "hidden",
                }}
            >
                <Grid
                    sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        zIndex: 2,
                        padding: 2,
                    }}
                    container
                    gap={1}
                >
                    <Grid
                        item
                        container
                        gap={1}
                        flex={1}
                        justifyContent="flex-end"
                    >
                        {functionType === FunctionType.polygon && (
                            <Grid item>
                                <Chip variant="outlined" onClick={resetPolygon}>
                                    Reset
                                </Chip>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Box
                    id="map"
                    style={{
                        height: "100%",
                        width: "100%",
                        zIndex: 0,
                    }}
                />
            </Grid>
        </Fade>
    );
};
export default OpenLayersContainer;
