import { MapTemplateDatasetExtended } from "@biggeo/bg-server-lib/datascape-ai";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isString from "lodash/isString";
import mapValues from "lodash/mapValues";
import replace from "lodash/replace";
import some from "lodash/some";
import { Expression, GeoJSONSource } from "mapbox-gl";
import { ColorSwatchOption } from "../../common/components/ColorSwatchSelector";
import {
    MapFilterCriteriaShapeStyle,
    MapFilterCriteriaStyle,
    MapFilterCriteriaStyleType,
} from "../filter-criteria/utils/utils";
import { MapContextDataset } from "../mapbox/context";
import {
    CustomShapeSource,
    DEFAULT_SHAPE_COLOR,
    DEFAULT_SHAPE_OPACITY,
} from "../mapbox/hooks/style-hooks";
import { MapShapeColorType } from "../views/MapShapeLayerStyles";
import { generateColorScale, generateSteps } from "./utils";

export const onCustomShapeLayerLoad = (
    map: mapboxgl.Map,
    features?: GeoJSON.FeatureCollection<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >
): GeoJSONSource => {
    const customizationSource = map.getSource(
        CustomShapeSource.customization
    ) as GeoJSONSource;

    if (customizationSource && features) {
        customizationSource.setData(features);
    }

    map.addLayer({
        id: "shape-customization-fill",
        type: "fill",
        source: CustomShapeSource.customization,
        paint: {
            "fill-color": [
                "coalesce",
                ["get", "fill-color"],
                DEFAULT_SHAPE_COLOR,
            ],
            "fill-opacity": [
                "coalesce",
                ["get", "fill-opacity"],
                DEFAULT_SHAPE_OPACITY,
            ],
        },
        filter: ["==", ["geometry-type"], "Polygon"],
    });

    map.addLayer({
        id: "shape-customization-line",
        type: "line",
        source: CustomShapeSource.customization,
        paint: {
            "line-color": [
                "coalesce",
                ["get", "stroke-color"],
                DEFAULT_SHAPE_COLOR,
            ],
            "line-width": ["coalesce", ["get", "stroke-width"], 2],
            "line-opacity": ["coalesce", ["get", "stroke-opacity"], 1],
        },
        filter: ["==", ["geometry-type"], "Polygon"],
    });

    return customizationSource;
};

export const setLayersFilter = ({
    map,
    isSelectMode,
}: { map: mapboxgl.Map; isSelectMode: boolean }) => {
    if (isEqual(isSelectMode, true)) {
        // Hide the draw layers when select mode is true
        map.setFilter("gl-map-shapes-fill.hot", ["!=", "user_selected", true]);
        map.setFilter("gl-map-shapes-fill.cold", ["!=", "user_selected", true]);

        map.setFilter("gl-map-shapes-line.hot", ["!=", "user_selected", true]);
        map.setFilter("gl-map-shapes-line.cold", ["!=", "user_selected", true]);

        map.setFilter("gl-draw-polygon-stroke-active.hot", [
            "!=",
            "user_selected",
            true,
        ]);
        map.setFilter("gl-draw-polygon-stroke-active.cold", [
            "!=",
            "user_selected",
            true,
        ]);

        // Hide the shape customization layers when select mode is true
        map.setFilter("shape-customization-fill", ["!=", "selected", true]);
        map.setFilter("shape-customization-line", ["!=", "selected", true]);
    }

    if (isEqual(isSelectMode, false)) {
        // Show the draw layers when select mode is false
        map.setFilter("gl-map-shapes-fill.hot", [
            "all",
            ["==", "$type", "Polygon"],
        ]);
        map.setFilter("gl-map-shapes-fill.cold", [
            "all",
            ["==", "$type", "Polygon"],
        ]);

        map.setFilter("gl-map-shapes-line.hot", [
            "all",
            ["==", "$type", "Polygon"],
        ]);
        map.setFilter("gl-map-shapes-line.cold", [
            "all",
            ["==", "$type", "Polygon"],
        ]);

        map.setFilter("gl-draw-polygon-stroke-active.hot", [
            "all",
            ["==", "active", "true"],
            ["==", "$type", "Polygon"],
        ]);
        map.setFilter("gl-draw-polygon-stroke-active.cold", [
            "all",
            ["==", "active", "true"],
            ["==", "$type", "Polygon"],
        ]);

        // Show the shape customization layers when select mode is false
        map.setFilter("shape-customization-fill", undefined);
        map.setFilter("shape-customization-line", undefined);
    }
};

export const removeCustomLayers = (map: mapboxgl.Map) => {
    map.removeLayer("shape-customization-fill");
    map.removeLayer("shape-customization-line");

    map.removeLayer(`map-shapes-fill-${CustomShapeSource.select}`);
    map.removeLayer(`map-shapes-line-${CustomShapeSource.select}`);
};

export const resize = ({
    map,
    draw,
    isLoaded,
    onResize,
    onRelease,
}: {
    map: mapboxgl.Map | null;
    draw: MapboxDraw | null;
    isLoaded: boolean;
    onResize: (i: {
        isResizing: boolean;
        isMidpoint: boolean;
        feature?: GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>;
        cursor?: { x: number; y: number };
        canvas: HTMLCanvasElement | undefined;
    }) => void;
    onRelease?: (i: {
        isResizing: boolean;
        isMidpoint: boolean;
        feature?: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
    }) => void;
}): { cleanup: () => void } => {
    let isResizing = false;
    let featureId: string | undefined = undefined;
    let isMidpoint = false;

    const canvas = map?.getCanvas();
    const mapBounds = canvas?.getBoundingClientRect();
    const top = mapBounds ? mapBounds.top : 0;
    const left = mapBounds ? mapBounds.left : 0;

    const onMouseDown = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
        const selectedFeature = draw?.getSelected().features[0];

        const id =
            selectedFeature && isString(selectedFeature.id)
                ? selectedFeature.id
                : undefined;

        if (map && selectedFeature) {
            const vertexLayers = [
                "gl-draw-polygon-and-line-vertex-inactive.hot",
                "gl-draw-polygon-and-line-vertex-inactive.cold",
                "gl-draw-polygon-and-line-vertex-active.hot",
                "gl-draw-polygon-and-line-vertex-active.cold",
                "gl-draw-polygon-midpoint.hot",
                "gl-draw-polygon-midpoint.cold",
                "gl-draw-point-active.hot",
                "gl-draw-point-active.cold",
            ];

            const features = map.queryRenderedFeatures(e.point, {
                layers: vertexLayers,
            });
            const isVertex =
                !isEmpty(features) &&
                some(
                    features,
                    (f) =>
                        f.properties?.meta === "vertex" ||
                        f.properties?.meta === "midpoint"
                );

            if (isVertex) {
                isResizing = true;
                featureId = id;
                isMidpoint = some(
                    features,
                    (f) => f.properties?.real_meta === "midpoint"
                );
            } else {
                isResizing = false;
                featureId = undefined;
            }
        }
    };

    const onMouseUp = () => {
        if (isEqual(isResizing, true)) {
            onRelease?.({
                isResizing,
                feature: draw && featureId ? draw.get(featureId) : undefined,
                isMidpoint,
            });

            isResizing = false;
            isMidpoint = false;
            featureId = undefined;
            draw?.changeMode("simple_select");
            if (canvas) canvas.style.cursor = "";
        }
    };

    const onDrawUpdate = () => {
        if (isEqual(isResizing, true)) {
            onRelease?.({
                isResizing,
                feature: draw && featureId ? draw.get(featureId) : undefined,
                isMidpoint,
            });
        }

        isResizing = false;
        isMidpoint = false;
        featureId = undefined;
        draw?.changeMode("simple_select");
        if (canvas) canvas.style.cursor = "";
    };

    const onMouseMove = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
        if (isEqual(isResizing, true)) {
            const selectedFeatureWithUpdatedCoordinates =
                featureId && draw ? draw.get(featureId) : undefined;

            const x = e.originalEvent.clientX - left;
            const y = e.originalEvent.clientY - top;

            featureId = selectedFeatureWithUpdatedCoordinates?.id?.toString();

            const cursor = { x, y };

            onResize({
                isResizing,
                feature:
                    selectedFeatureWithUpdatedCoordinates as GeoJSON.Feature<
                        GeoJSON.Polygon,
                        GeoJSON.GeoJsonProperties
                    >,
                cursor,
                canvas,
                isMidpoint,
            });
        }
    };

    if (map && isLoaded) {
        map.on("mousedown", onMouseDown);
        map.on("mouseup", onMouseUp);
        map.on("mousemove", onMouseMove);
        map.on("draw.update", onDrawUpdate);
    }

    return {
        cleanup: () => {
            if (map) {
                map.off("mousedown", onMouseDown);
                map.off("mouseup", onMouseUp);
                map.off("mousemove", onMouseMove);
                map.off("draw.update", onDrawUpdate);
            }
        },
    };
};

export const drag = ({
    map,
    draw,
    isLoaded,
    onDrag,
    mode,
    onRelease,
}: {
    map: mapboxgl.Map | null;
    draw: MapboxDraw | null;
    isLoaded: boolean;
    mode: boolean;
    onDrag: (i: {
        isDragging: boolean;
        feature?: GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>;
        cursor?: { x: number; y: number };
        canvas: HTMLCanvasElement | undefined;
    }) => void;
    onRelease: (i: {
        isDragging: boolean;
        feature?: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
    }) => void;
}): { cleanup: () => void } => {
    let isDragging = false;
    let selected:
        | GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
        | undefined = undefined;

    const canvas = map?.getCanvas();
    const mapBounds = canvas?.getBoundingClientRect();
    const top = mapBounds ? mapBounds.top : 0;
    const left = mapBounds ? mapBounds.left : 0;

    const onClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
        const shape = e.features[0];

        if (map && shape) {
            selected = {
                type: "Feature",
                id: shape.id,
                geometry: shape.geometry,
                properties: shape.properties,
            };
        }
    };

    const onMouseEnter = (
        e: mapboxgl.MapMouseEvent & {
            features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
        } & mapboxgl.EventData
    ) => {
        e.preventDefault();
        const shape = e.features?.[0];

        selected = shape
            ? {
                  type: "Feature",
                  id: shape.id,
                  geometry: shape.geometry,
                  properties: shape.properties,
              }
            : undefined;
    };

    const onMouseUp = () => {
        if (isEqual(isDragging, true)) {
            onRelease({
                isDragging,
                feature: selected,
            });

            isDragging = false;
            selected = undefined;
            draw?.changeMode("simple_select");
            if (canvas) canvas.style.cursor = "";
        }
    };

    const onDrawUpdate = () => {
        if (isEqual(isDragging, true)) {
            onRelease({
                isDragging,
                feature: selected,
            });
        }

        isDragging = false;
        selected = undefined;
        draw?.changeMode("simple_select");
        if (canvas) canvas.style.cursor = "";
    };

    const onMouseMove = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
        const selectedFeatureWithUpdatedCoordinates =
            selected && isString(selected.id) && draw
                ? draw.get(selected.id)
                : undefined;

        const hasMoved = !isEqual(
            selected?.geometry,
            selectedFeatureWithUpdatedCoordinates?.geometry
        );

        const x = e.originalEvent.clientX - left;
        const y = e.originalEvent.clientY - top;

        const cursor = { x, y };
        if (hasMoved) {
            isDragging = true;

            selected = selectedFeatureWithUpdatedCoordinates;

            onDrag({
                isDragging,
                feature:
                    selectedFeatureWithUpdatedCoordinates as GeoJSON.Feature<
                        GeoJSON.Polygon,
                        GeoJSON.GeoJsonProperties
                    >,
                cursor,
                canvas,
            });
        } else {
            isDragging = false;
        }
    };

    if (map && isLoaded && isEqual(mode, true)) {
        map.on("click", `map-shapes-fill-${CustomShapeSource.select}`, onClick);
        map.on(
            "mouseenter",
            `map-shapes-fill-${CustomShapeSource.select}`,
            onMouseEnter
        );
        map.on("mousemove", onMouseMove);
        map.on("mouseup", onMouseUp);
        map.on("draw.update", onDrawUpdate);
    }

    return {
        cleanup: () => {
            if (map) {
                map.off(
                    "click",
                    `map-shapes-fill-${CustomShapeSource.select}`,
                    onClick
                );
                map.off(
                    "mouseenter",
                    `map-shapes-fill-${CustomShapeSource.select}`,
                    onMouseEnter
                );
                map.off("mousemove", onMouseMove);
                map.off("mouseup", onMouseUp);
                map.off("draw.update", onDrawUpdate);
            }
        },
    };
};

export const hexToRgba = ({
    hex,
    opacity = 0.9,
}: { hex?: string; opacity?: number }) =>
    pipe(
        hex,
        O.fromNullable,
        O.fold(
            () => [0, 0, 0, opacity],
            (hex) =>
                pipe(
                    replace(hex, "#", ""),
                    (color) => color.match(/.{1,2}/g),
                    O.fromNullable,
                    O.foldW(
                        () => [0, 0, 0, opacity],
                        (color) => [
                            ...A.map((c: string) => Number.parseInt(c, 16))(
                                color
                            ),
                            opacity,
                        ]
                    )
                )
        )
    );

export const createSquareIcon = ({
    size = 64,
    color,
    stroke,
    borderWidth = 10,
}: {
    size?: number;
    color?: Partial<MapShapeColorType>;
    stroke?: Partial<MapShapeColorType>;
    borderWidth?: number;
}) => {
    const bytesPerPixel = 4; // Each pixel is represented by 4 bytes: red, green, blue, and alpha.
    const imageData = new Uint8ClampedArray(size * size * bytesPerPixel);
    const [red, green, blue, opacity] = hexToRgba({
        hex: color?.color,
        opacity: color?.opacity,
    });
    const [bRed, bGreen, bBlue, bOpacity] = hexToRgba({
        hex: stroke?.color,
        opacity: stroke?.opacity,
    });

    for (let x = 0; x < size; x++) {
        for (let y = 0; y < size; y++) {
            const index = (y * size + x) * bytesPerPixel;
            if (
                x < borderWidth ||
                x >= size - borderWidth ||
                y < borderWidth ||
                y >= size - borderWidth
            ) {
                imageData[index] = bRed;
                imageData[index + 1] = bGreen;
                imageData[index + 2] = bBlue;
                imageData[index + 3] = Math.round(bOpacity * 255);
            } else {
                // Inside the square
                imageData[index] = red;
                imageData[index + 1] = green;
                imageData[index + 2] = blue;
                imageData[index + 3] = Math.round(opacity * 255);
            }
        }
    }
    return { width: size, height: size, data: imageData };
};

export const isShapeFilterLayer = (
    value:
        | mapboxgl.AnyLayer
        | Record<MapFilterCriteriaShapeStyle, mapboxgl.AnyLayer>
): value is Record<MapFilterCriteriaShapeStyle, mapboxgl.AnyLayer> =>
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    value.hasOwnProperty(MapFilterCriteriaShapeStyle.square) ||
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    value.hasOwnProperty(MapFilterCriteriaShapeStyle.oval);

export const getFiltersLayers = ({
    dataset,
    sources,
    currentStyles,
    addedStyles,
    suffix = "preview",
}: {
    suffix?: string;
    dataset?: MapContextDataset;
    sources: {
        aggregate: string;
        points: string;
    };
    currentStyles?: Partial<MapFilterCriteriaStyle>;
    addedStyles?: Partial<MapFilterCriteriaStyle>;
}): Record<
    Exclude<
        MapFilterCriteriaStyleType,
        | MapFilterCriteriaStyleType.stroke
        | MapFilterCriteriaStyleType.dataAggregation
    >,
    mapboxgl.AnyLayer | Record<MapFilterCriteriaShapeStyle, mapboxgl.AnyLayer>
> => {
    const datasetId = dataset?.dataSource.id;

    const color = dataset?.dataSource.color || undefined;
    const opacity = dataset?.dataSource.opacity || 0.9;
    const customMarker = dataset?.dataSource.icon || "airport";
    const { heatMapColorArray } = getLayerHeatmapStyle(
        addedStyles?.dataAggregation?.heatmap ?? dataset?.currentHeatMapValue,
        dataset?.dataSource.color || undefined
    );

    const heatmap: Expression = !isEmpty(heatMapColorArray)
        ? ["interpolate", ["linear"], ["heatmap-density"], ...heatMapColorArray]
        : [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(255,255,255,0)",
              0.5,
              `${addedStyles?.fill?.color || color}`,
              1,
              `${addedStyles?.fill?.color || color}`,
          ];

    return {
        [MapFilterCriteriaStyleType.shape]: {
            [MapFilterCriteriaShapeStyle.square]: {
                id: `${datasetId}-filtered-points-icon-square-${suffix}`,
                type: "symbol",
                source: sources.points,
                layout: {
                    "icon-image": `${datasetId}-filtered-square-icon`,
                    "icon-size": 0.7,
                },
                paint: {
                    "icon-opacity": {
                        default: currentStyles?.fill?.opacity || opacity,
                        stops: [
                            [14, 0],
                            [15, currentStyles?.fill?.opacity || opacity],
                        ],
                    },
                },
            },
            [MapFilterCriteriaShapeStyle.oval]: {
                id: `${datasetId}-filtered-points-icon-oval-${suffix}`,
                type: "circle",
                source: sources.points,
                layout: {
                    visibility: "visible",
                },
                paint: {
                    "circle-radius": 4,
                    "circle-color": `${currentStyles?.fill?.color || color}`,
                    "circle-stroke-color": `${currentStyles?.stroke?.color || color}`,
                    "circle-stroke-width": 2,
                    "circle-stroke-opacity": {
                        default: currentStyles?.stroke?.opacity || opacity,
                        stops: [
                            [14, 0],
                            [15, currentStyles?.stroke?.opacity || opacity],
                        ],
                    },
                    "circle-opacity": {
                        default: currentStyles?.fill?.opacity || opacity,
                        stops: [
                            [14, 0],
                            [15, currentStyles?.fill?.opacity || opacity],
                        ],
                    },
                },
            },
        },
        [MapFilterCriteriaStyleType.fill]: {
            id: `${datasetId}-filtered-heatmap-${suffix}`,
            type: "heatmap",
            source: sources.aggregate,
            paint: {
                "heatmap-weight": [
                    "interpolate",
                    ["linear"],
                    ["get", "count"],
                    0,
                    0,
                    3000,
                    20,
                ],
                "heatmap-intensity": {
                    stops: [
                        [8, 1],
                        [11, 3],
                    ],
                },
                "heatmap-radius": 15,
                "heatmap-color": heatmap,
                "heatmap-opacity": {
                    default: addedStyles?.fill?.opacity || opacity,
                    stops: [
                        [14, addedStyles?.fill?.opacity || opacity],
                        [15, 0],
                    ],
                },
            },
        },
        [MapFilterCriteriaStyleType.customMarker]: {
            id: `${datasetId}-filtered-custom-icons-${suffix}`,
            type: "symbol",
            source: sources.points,
            layout: {
                "icon-image":
                    addedStyles?.customMarker ||
                    currentStyles?.customMarker ||
                    customMarker,
                "icon-size": 1.2,
                visibility: "none",
            },
            paint: {
                "icon-opacity": {
                    default: currentStyles?.fill?.opacity || opacity,
                    stops: [
                        [14, 0],
                        [15, currentStyles?.fill?.opacity || opacity],
                    ],
                },
            },
        },
    };
};

export const setFiltersLayers = (input: {
    addedStyles: Partial<MapFilterCriteriaStyle>;
    currentStyles: Partial<MapFilterCriteriaStyle>;
    dataset?: Pick<
        MapTemplateDatasetExtended,
        "dataSource" | "mapTemplateDataset"
    >;
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
    suffix: string;
}) => {
    const { map, isLoaded, addedStyles, currentStyles, dataset, suffix } =
        input;

    const datasetId = dataset?.dataSource.id;
    const color = dataset?.dataSource.color || undefined;
    const opacity = dataset?.dataSource.opacity || 0.9;

    if (isLoaded && map.current) {
        if (isEqual(addedStyles.shape, MapFilterCriteriaShapeStyle.square)) {
            map.current.setLayoutProperty(
                `${datasetId}-filtered-points-icon-square-${suffix}`,
                "visibility",
                "visible"
            );

            const image = map.current.hasImage(
                `${datasetId}-filtered-square-icon`
            );

            const squareColor = {
                color: currentStyles.fill?.color || color,
                opacity: currentStyles.fill?.opacity || opacity,
            };

            const squareStroke = {
                color: currentStyles.stroke?.color || color,
                opacity: currentStyles.stroke?.opacity || opacity,
            };

            if (image) {
                map.current.updateImage(
                    `${datasetId}-filtered-square-icon`,
                    createSquareIcon({
                        color: squareColor,
                        stroke: squareStroke,
                    })
                );
            } else {
                map.current.addImage(
                    `${datasetId}-filtered-square-icon`,
                    createSquareIcon({
                        color: squareColor,
                        stroke: squareStroke,
                    }),
                    { pixelRatio: 4 }
                );
            }

            map.current.setLayoutProperty(
                `${datasetId}-filtered-custom-icons-${suffix}`,
                "visibility",
                "none"
            );
            map.current.setLayoutProperty(
                `${datasetId}-filtered-points-icon-oval-${suffix}`,
                "visibility",
                "none"
            );
        }

        if (isEqual(addedStyles.shape, MapFilterCriteriaShapeStyle.oval)) {
            map.current.setLayoutProperty(
                `${datasetId}-filtered-points-icon-oval-${suffix}`,
                "visibility",
                "visible"
            );

            map.current.setLayoutProperty(
                `${datasetId}-filtered-custom-icons-${suffix}`,
                "visibility",
                "none"
            );
            map.current.setLayoutProperty(
                `${datasetId}-filtered-points-icon-square-${suffix}`,
                "visibility",
                "none"
            );

            map.current.setPaintProperty(
                `${datasetId}-filtered-points-icon-oval-${suffix}`,
                "circle-color",
                `${currentStyles.fill?.color || color}`
            );
            map.current.setPaintProperty(
                `${datasetId}-filtered-points-icon-oval-${suffix}`,
                "circle-stroke-color",
                `${currentStyles.stroke?.color || color}`
            );
            map.current.setPaintProperty(
                `${datasetId}-filtered-points-icon-oval-${suffix}`,
                "circle-stroke-opacity",
                {
                    default: currentStyles.stroke?.opacity || opacity,
                    stops: [
                        [14, 0],
                        [15, currentStyles.stroke?.opacity || opacity],
                    ],
                }
            );
            map.current.setPaintProperty(
                `${datasetId}-filtered-points-icon-oval-${suffix}`,
                "circle-opacity",
                {
                    default: currentStyles.fill?.opacity || opacity,
                    stops: [
                        [14, 0],
                        [15, currentStyles.fill?.opacity || opacity],
                    ],
                }
            );
        }

        if (addedStyles.fill && !isEmpty(addedStyles.fill)) {
            // Update square shape color
            const squareColor = {
                color: addedStyles.fill.color,
                opacity: addedStyles.fill.opacity,
            };

            const squareStroke = {
                color: currentStyles.stroke?.color || color,
                opacity: currentStyles.stroke?.opacity || opacity,
            };
            map.current.updateImage(
                `${datasetId}-filtered-square-icon`,
                createSquareIcon({
                    color: squareColor,
                    stroke: squareStroke,
                })
            );

            // Update oval shape color
            map.current.setPaintProperty(
                `${datasetId}-filtered-points-icon-oval-${suffix}`,
                "circle-color",
                addedStyles.fill.color
            );

            map.current.setPaintProperty(
                `${datasetId}-filtered-points-icon-oval-${suffix}`,
                "circle-opacity",
                addedStyles.fill.opacity
            );

            // Update custom marker color
            map.current.setPaintProperty(
                `${datasetId}-filtered-custom-icons-${suffix}`,
                "icon-color",
                addedStyles.fill.color
            );
        }

        if (addedStyles.stroke && !isEmpty(addedStyles.stroke)) {
            map.current.setPaintProperty(
                `${datasetId}-filtered-points-icon-oval-${suffix}`,
                "circle-stroke-color",
                addedStyles.stroke.color
            );

            // Update square icon color
            const squareColor = {
                color: currentStyles.fill?.color,
                opacity: currentStyles.fill?.opacity,
            };

            const squareStroke = {
                color: addedStyles.stroke.color,
                opacity: addedStyles.stroke.opacity || opacity,
            };

            map.current.updateImage(
                `${datasetId}-filtered-square-icon`,
                createSquareIcon({
                    color: squareColor,
                    stroke: squareStroke,
                })
            );
        }

        if (addedStyles.customMarker) {
            map.current.setLayoutProperty(
                `${datasetId}-filtered-custom-icons-${suffix}`,
                "visibility",
                "visible"
            );
            map.current.setLayoutProperty(
                `${datasetId}-filtered-points-icon-square-${suffix}`,
                "visibility",
                "none"
            );
            map.current.setLayoutProperty(
                `${datasetId}-filtered-points-icon-oval-${suffix}`,
                "visibility",
                "none"
            );

            map.current.setLayoutProperty(
                `${datasetId}-filtered-custom-icons-${suffix}`,
                "icon-image",
                addedStyles.customMarker
            );
        }

        if (addedStyles.dataAggregation?.heatmap) {
            const { heatMapColorArray } = getLayerHeatmapStyle(
                addedStyles.dataAggregation.heatmap
            );

            map.current.setLayoutProperty(
                `${datasetId}-filtered-heatmap-${suffix}`,
                "visibility",
                "visible"
            );

            map.current.setPaintProperty(
                `${datasetId}-filtered-heatmap-${suffix}`,
                "heatmap-color",
                [
                    "interpolate",
                    ["linear"],
                    ["heatmap-density"],
                    ...heatMapColorArray,
                ]
            );
        }
    }
};

export const getLayerHeatmapStyle = (
    value?: ColorSwatchOption,
    color?: string
): {
    steps: number[];
    heatMapColorArray: (string | number)[];
    colorSet: string[];
    colorMap: (string | number)[];
} => {
    if (!value) {
        if (color) {
            const heatmapColorSet = generateColorScale(color, 1.5, 3);

            return {
                steps: [],
                heatMapColorArray: [
                    0,
                    "rgba(255,255,255,0)",
                    0.1,
                    heatmapColorSet[0],
                    0.5,
                    heatmapColorSet[1],
                    1,
                    heatmapColorSet[2],
                ],
                colorSet: [],
                colorMap: [],
            };
        }

        return { steps: [], heatMapColorArray: [], colorSet: [], colorMap: [] };
    }

    const steps = generateSteps(value.swatch.length);

    // biome-ignore lint/correctness/noFlatMapIdentity: <explanation>
    const heatMapColorArray = steps
        .map((step, i) => [step, value.swatch[i].color])
        .flatMap((c) => c);

    const colorSet = generateColorScale(
        value.swatch[steps.length / 2].color || "#ffffff",
        3,
        20
    );

    const colorMap = colorSet.flatMap((color, idx) => [idx, `${color}`]);

    return { steps, heatMapColorArray, colorSet, colorMap };
};

export const hideFilteredDataLayers = ({
    map,
    isLoaded,
    suffix = "preview",
    dataset,
}: {
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
    suffix?: string;
    dataset?: MapContextDataset;
}) => {
    if (map.current && isLoaded) {
        const aggregateSource = map.current.getSource(
            `${CustomShapeSource.filtering}-aggregate-${suffix}`
        ) as GeoJSONSource;

        const pointsSource = map.current.getSource(
            `${CustomShapeSource.filtering}-points-${suffix}`
        ) as GeoJSONSource;

        aggregateSource.setData({
            type: "FeatureCollection",
            features: [],
        });
        pointsSource.setData({
            type: "FeatureCollection",
            features: [],
        });

        const layers = getFiltersLayers({
            sources: {
                aggregate: `${CustomShapeSource.filtering}-aggregate-${suffix}`,
                points: `${CustomShapeSource.filtering}-points-${suffix}`,
            },
            dataset,
            suffix,
        });

        mapValues(layers, (value) => {
            if (isShapeFilterLayer(value)) {
                map.current?.removeLayer(value.square.id);
                map.current?.removeLayer(value.oval.id);
            } else {
                map.current?.removeLayer(value.id);
            }
        });
    }
};
