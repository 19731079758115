import {
    Geometry,
    PointDataInput,
    SavedPolygon,
    SubscriptionResponse,
    useCreateSavedPolygonMutation,
    useFetchSnowflakePointDataQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Button,
    CellItem,
    CircularLoading,
    Divider,
    FlexScrollArea,
    FlexScrollAreaContainer,
    Grid,
    IconButton,
    MobileDesktopEmulator,
    Stack,
    TopAppBar,
    Typography,
} from "@biggeo/bg-ui/lab";
import { CloseOutline, SearchOutline } from "@biggeo/bg-ui/lab/icons";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import startCase from "lodash/startCase";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ErrorPage } from "../common/components/ErrorPage";
import { useDatabaseMetaDataArray } from "../database-meta-data/redux/hooks";
import { databaseMetaDataActions } from "../database-meta-data/redux/model";

const PointDataContainer = ({
    selectedPoint,
    setSelectedPoint,
    responses,
    filterByPolygon,
}: {
    selectedPoint: PointDataInput;
    setSelectedPoint: (f?: PointDataInput) => void;
    responses: Partial<Record<string, SubscriptionResponse | null>>;
    filterByPolygon: (p: SavedPolygon) => void;
}) => {
    const dispatch = useDispatch();
    const toPage = useNavigate();
    const { executeMutation: save } = useCreateSavedPolygonMutation();
    const savePolygon = (polygon: Geometry) => {
        const poly = polygon.polygon;
        if (poly) {
            save({
                variables: {
                    input: {
                        id: 0,
                        name: `${polygon.id}`,
                        polygon: {
                            outer: {
                                points: poly.outer.points.map((p) => {
                                    return {
                                        latitude: p.latitude,
                                        longitude: p.longitude,
                                    };
                                }),
                            },
                            inners: [],
                        },
                        createdAt: new Date(),
                    },
                },
            }).then((v) => {
                if (v.data) {
                    filterByPolygon(v.data.createSavedPolygon);
                    dispatch(
                        databaseMetaDataActions.addSavedPolygon(
                            v.data.createSavedPolygon
                        )
                    );
                }
            });
        }
    };

    const tables = useDatabaseMetaDataArray();
    const foundtable = tables.find((d) => d.id === selectedPoint.databaseId);

    const hasTableId = pipe(
        foundtable,
        O.fromNullable,
        O.fold(
            () => false,
            (data) => Boolean(data.tableId)
        )
    );

    const {
        queryReturn: { data, error },
    } = useFetchSnowflakePointDataQuery({
        variables: {
            input: selectedPoint,
        },
        skip: !hasTableId,
    });

    const pointData = data?.fetchSnowflakePointData.data;
    const polygon =
        responses[selectedPoint.databaseId]?.geometry?.nonPoints.find(
            (p) => p.id === selectedPoint.id
        ) || undefined;

    return (
        <FlexScrollAreaContainer width="25vw">
            <TopAppBar
                title={
                    pipe(
                        foundtable,
                        O.fromNullable,
                        O.fold(
                            () => {
                                return startCase(selectedPoint.databaseId);
                            },
                            (data) => {
                                return data.label;
                            }
                        )
                    ) || ""
                }
                density="dense"
                subEndNode={
                    <IconButton
                        variant={"minimal"}
                        onClick={() => {
                            setSelectedPoint(undefined);
                        }}
                    >
                        <CloseOutline />
                    </IconButton>
                }
            />
            <Divider orientation={"horizontal"} />

            <FlexScrollArea flexDirection="column">
                <Stack
                    justifyContent={"center"}
                    sx={{
                        padding: 4,
                        height: error || !hasTableId ? "100%" : undefined,
                    }}
                >
                    {polygon?.polygon && (
                        <Grid
                            container
                            justifyContent="center"
                            flexDirection="column"
                            gap={1}
                        >
                            <Grid
                                item
                                container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item>
                                    <IconButton
                                        onClick={() => {
                                            savePolygon(polygon);
                                        }}
                                    >
                                        <SearchOutline />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {pipe(
                        pointData,
                        O.fromNullable,
                        O.fold(
                            () =>
                                error || !hasTableId ? (
                                    <MobileDesktopEmulator
                                        links={[
                                            {
                                                rel: "stylesheet",
                                                href: "https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap",
                                            },
                                            {
                                                rel: "preconnect",
                                                href: "https://fonts.googleapis.com",
                                            },
                                            {
                                                rel: "preconnect",
                                                href: "https://fonts.gstatic.com",
                                            },
                                            {
                                                rel: "icon",
                                                href: "https://assets-global.website-files.com/6345ad9c87035c200bff8d84/65501dcde85a825aba284630_BigGeo%20Fav%20Icon.png",
                                            },
                                        ]}
                                        width="100%"
                                        height="100%"
                                        disableResize
                                    >
                                        <ErrorPage
                                            title={
                                                error
                                                    ? undefined
                                                    : "We need to re-index your dataset"
                                            }
                                            subtitle={
                                                error
                                                    ? undefined
                                                    : "The Dataset that you're trying to access is missing the table id. Please re-index the dataset by clicking the button below."
                                            }
                                            action={
                                                !error && (
                                                    <Button
                                                        color="primary"
                                                        onClick={() =>
                                                            toPage("/datasets")
                                                        }
                                                    >
                                                        Re-index Dataset
                                                    </Button>
                                                )
                                            }
                                        />
                                    </MobileDesktopEmulator>
                                ) : (
                                    <Stack
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                    >
                                        <CircularLoading />
                                    </Stack>
                                ),
                            (pointData) => (
                                <Stack gap={4}>
                                    <Grid item xs>
                                        <CellItem
                                            variant="outlined"
                                            readonly
                                            title={
                                                <Typography
                                                    variant="body4"
                                                    fontWeight="bold"
                                                    color={"info"}
                                                    truncate
                                                >{`${selectedPoint.id.uint64?.low || selectedPoint.id.string}`}</Typography>
                                            }
                                        />
                                    </Grid>
                                    <Stack flexDirection={"column"} gap={4}>
                                        {Object.entries(pointData).map(
                                            ([k, v]) => {
                                                return (
                                                    <Stack
                                                        key={k}
                                                        flexDirection="column"
                                                    >
                                                        <Stack>
                                                            <Typography variant="title3">
                                                                {k}
                                                            </Typography>
                                                        </Stack>
                                                        <Stack>
                                                            <Typography
                                                                variant="title3"
                                                                fontWeight="bold"
                                                            >
                                                                {typeof v ===
                                                                "string"
                                                                    ? v
                                                                    : JSON.stringify(
                                                                          v
                                                                      )}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                );
                                            }
                                        )}
                                    </Stack>
                                </Stack>
                            )
                        )
                    )}
                </Stack>
            </FlexScrollArea>
        </FlexScrollAreaContainer>
    );
};

export default PointDataContainer;
