import {
    CreateEditDeleteMapTemplate,
    MapTemplate,
    MapTemplateDataset,
    MapTemplateExtended,
} from "@biggeo/bg-server-lib/datascape-ai";
import { WithLoading } from "@biggeo/bg-ui";
import {
    BreadcrumbsButton,
    BreadcrumbsGroup,
    Button,
    Stack,
    StickyFabPlacementHelper,
    Typography,
} from "@biggeo/bg-ui/lab";
import { CenteredNestedLayoutWithHeader } from "@biggeo/bg-ui/lab/layouts";
import { Formik } from "formik";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import { isEqual } from "lodash";
import startCase from "lodash/startCase";
import { useDispatch } from "react-redux";
import Zod from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { OnPageLeave } from "../../common/components/OnPageLeave.tsx";
import { modalActions } from "../../modal/redux/model.tsx";
import { Routes } from "../../navigation/redux/model.ts";
import { CallBacksType } from "../../utils/types";
import { AvailableDataset } from "../pages/CreateOrManageMapTemplatePage.tsx";
import { DeleteMapTemplateModal } from "./DeleteMapTemplateModal.tsx";
import MapTemplateFormView from "./MapTemplateFormView";

interface IMapTemplateForm {
    readonly map?: MapTemplate;
    readonly navigate: (to?: string) => void;
    readonly save: (
        input: CreateEditDeleteMapTemplate,
        callbacks?: CallBacksType<MapTemplateExtended>
    ) => void;
    readonly datasets: AvailableDataset[];
    readonly linkedDatasets?: MapTemplateDataset[];
    readonly loading: boolean;
}

const MapTemplateForm = ({
    map,
    navigate,
    save,
    datasets,
    linkedDatasets,
    loading,
}: IMapTemplateForm) => {
    const type = map ? "manage" : "create";
    const isEdit = !!map;
    const dispatch = useDispatch();

    const openModal = (map: CreateEditDeleteMapTemplate) => {
        dispatch(
            modalActions.openModal({
                modalType: "new-dialog",
                dialogProps: {
                    variant: "centered-medium",
                },
                component: (
                    <DeleteMapTemplateModal
                        template={map}
                        navigate={navigate}
                    />
                ),
            })
        );
    };

    const setFormValues = (map?: MapTemplateExtended) => ({
        id: map?.mapTemplate.id,
        name: map?.mapTemplate.name || "",
        thumbnail: map?.mapTemplate.thumbnail || undefined,
        description: map?.mapTemplate.description || undefined,
        fkDataSourceIds: pipe(
            map?.datasets,
            O.fromNullable,
            O.fold(
                () => [],
                (d) =>
                    pipe(
                        d,
                        A.map((t) => t.fkDataSourceId)
                    )
            )
        ),
    });

    const initialValues = setFormValues(
        map && linkedDatasets
            ? { mapTemplate: map, datasets: linkedDatasets }
            : undefined
    );

    return (
        <Formik<CreateEditDeleteMapTemplate>
            validateOnMount
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                save(values, {
                    onSuccess: (data) => {
                        actions.setSubmitting(false);
                        actions.resetForm({
                            values: setFormValues(data),
                        });
                        navigate(Routes.mapTemplates);
                    },
                });
            }}
            validationSchema={toFormikValidationSchema(
                Zod.object({
                    id: Zod.number().optional(),
                    name: Zod.string(),
                    thumbnail: Zod.string().optional(),
                    description: Zod.string().optional(),
                    fkDataSourceIds: Zod.array(Zod.string()).optional(),
                })
            )}
        >
            {({
                values,
                setValues,
                handleSubmit,
                dirty,
                isValid,
                isSubmitting,
            }) => {
                const onChange = (i: Partial<CreateEditDeleteMapTemplate>) => {
                    setValues((p) => ({ ...p, ...i }));
                };

                return (
                    <OnPageLeave
                        trigger={isEdit && !isEqual(values, initialValues)}
                        save={({ navigate }) =>
                            save(values, { onSuccess: () => navigate() })
                        }
                    >
                        <CenteredNestedLayoutWithHeader
                            title={
                                <BreadcrumbsGroup
                                    value={`${type}-map-template`}
                                >
                                    <BreadcrumbsButton
                                        value="map-templates"
                                        onClick={() => navigate()}
                                    >
                                        Map templates
                                    </BreadcrumbsButton>
                                    <BreadcrumbsButton
                                        value={`${type}-map-template`}
                                        onClick={() =>
                                            navigate(
                                                map
                                                    ? `/map-templates/${map.id}`
                                                    : "/map-templates/create"
                                            )
                                        }
                                        hideSeparator
                                    >
                                        {`${startCase(type)} map template`}
                                    </BreadcrumbsButton>
                                </BreadcrumbsGroup>
                            }
                        >
                            <Stack gap={4}>
                                <Stack gap={0.5}>
                                    <Typography
                                        variant="title1"
                                        fontWeight="bold"
                                    >
                                        {startCase(type)} Map Template
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="disabled"
                                        colorSet="container"
                                        invertColors
                                    >
                                        {`${type === "manage" ? "Edit" : "Enter"} map template details`}
                                    </Typography>
                                </Stack>
                                <MapTemplateFormView
                                    values={values}
                                    onChange={onChange}
                                    datasets={datasets}
                                />
                                <StickyFabPlacementHelper
                                    placement="right"
                                    sx={{
                                        paddingY: 4,
                                        backgroundColor: (theme) =>
                                            theme.palette.background.container,
                                    }}
                                >
                                    <Stack
                                        flexDirection="row"
                                        justifyContent="flex-end"
                                    >
                                        {map ? (
                                            <Stack flexDirection="row" gap={4}>
                                                <Button
                                                    type="submit"
                                                    variant={"outlined"}
                                                    color={"error"}
                                                    disabled={isSubmitting}
                                                    onClick={() =>
                                                        openModal(map)
                                                    }
                                                >
                                                    Remove
                                                </Button>
                                                <WithLoading
                                                    loading={loading}
                                                    text={"Saving Changes..."}
                                                >
                                                    <Button
                                                        type="submit"
                                                        disabled={
                                                            !isValid || !dirty
                                                        }
                                                        onClick={() => {
                                                            handleSubmit();
                                                        }}
                                                    >
                                                        Save Changes
                                                    </Button>
                                                </WithLoading>
                                            </Stack>
                                        ) : (
                                            <WithLoading
                                                loading={loading}
                                                text={"Creating..."}
                                            >
                                                <Button
                                                    type="submit"
                                                    disabled={
                                                        !isValid || !dirty
                                                    }
                                                    onClick={() =>
                                                        handleSubmit()
                                                    }
                                                >
                                                    Create Map Template
                                                </Button>
                                            </WithLoading>
                                        )}
                                    </Stack>
                                </StickyFabPlacementHelper>
                            </Stack>
                        </CenteredNestedLayoutWithHeader>
                    </OnPageLeave>
                );
            }}
        </Formik>
    );
};

export default MapTemplateForm;
