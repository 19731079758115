import { CellItem, NavigationRailIcon, Radio, Stack } from "@biggeo/bg-ui/lab";
import {
    BookmarkOutline,
    CropFreeOutline,
    DoneOutline,
    MapOutline,
    MonitoringOutline,
    TableRowsOutline,
    TuneOutline,
} from "@biggeo/bg-ui/lab/icons";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import { isNil } from "lodash";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import { useEffect, useState } from "react";
import { P, match } from "ts-pattern";

import { ApolloQueryResult } from "@apollo/client";
import {
    AreaExtended,
    Exact,
    FetchAllAreasExtendedQuery,
    FetchSavedViewsQuery,
    FilterObject,
    ReqOptions,
    SavedArea,
    SubscriptionResponse,
    useFetchAllAreasExtendedQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { SelectableTreeMenuItem } from "@biggeo/bg-ui";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import { useMapEngines } from "../../common/redux/hooks.ts";
import type { Consumers } from "../../common/redux/model.ts";
import type { DatasetPointShape } from "../../components/DatapointShape/types.ts";
import { NestedSubSidebar } from "../../layouts/NestedSubSidebar.tsx";
import { ResultsContainer } from "../../results/containers/ResultsContainer.tsx";
import SavedViewSubMenuContainer from "../../saved-views/containers/SavedViewSubMenuContainer.tsx";
import DebugContainer from "../DebugContainer";
import { InputPolygonWithId } from "../hooks/pure-data-string-hook.ts";
import { useMapData } from "../redux/hooks.ts";
import { FunctionType } from "../utils/utils";
import { SavedAreasSubmenu } from "../views/SavedAreasSubmenu";

export enum SubMenu {
    dataset = "dataset",
    savedViews = "savedViews",
    areas = "areas",
    mapStyles = "mapStyles",
    configuration = "configuration",
    lastSearchQuery = "lastSearchQuery",
}

const decodeId = (item: "area" | "savedArea", id: number): string => item + id;

export const MapSubMenu = ({
    options,
    setOptions,
    // multiFilters,
    // addRemoveDataset,
    selectedDataset,
    setSelectedDataset,
    setFunctionType,
    setSavedPolygons,
    activeConsumption,
    changeConsumption,
    showFiltered,
    showPoints,
    showTriangles,
    setShowFiltered,
    setShowPoints,
    setShowTriangles,
    clearShapes,
    reFetchSavedAreas,
    setReFetchSavedAreas,
    selectedAreaId,
    filterItems,
    setFilterItems,
    resetAreasFilters,
    menuOpen,
    setMultiFilters,
    setMenuOpen,
    // datasetShape,
    // setDatasetShape,
    selectedSavedView,
    setSelectedSavedView,
    recentResponse,
    handleSavedPolygons,
    savedAreasData,
    refetchSavedAreas,
    savedViewsData,
    loadingSavedViews,
}: {
    readonly multiFilters: FilterObject[];
    readonly addRemoveDataset: (v: string) => void;
    readonly selectedDataset: readonly string[];
    readonly setSelectedDataset: (datasets: readonly string[]) => void;
    readonly setFunctionType: (v: FunctionType) => void;
    readonly setSavedPolygons: (p?: readonly SavedArea[]) => void;
    readonly options: ReqOptions;
    readonly setOptions: (p: ReqOptions) => void;
    readonly activeConsumption: Consumers;
    readonly changeConsumption: (c: Consumers) => void;
    readonly showTriangles: boolean;
    readonly showPoints: boolean;
    readonly showFiltered: boolean;
    readonly setShowFiltered: (b: boolean) => void;
    readonly setShowPoints: (b: boolean) => void;
    readonly setShowTriangles: (b: boolean) => void;
    readonly clearShapes: () => void;
    readonly reFetchSavedAreas?: boolean;
    readonly selectedAreaId?: number;
    readonly setReFetchSavedAreas?: (value: boolean) => void;
    readonly filterItems: readonly SelectableTreeMenuItem[];
    readonly setFilterItems: React.Dispatch<
        React.SetStateAction<readonly SelectableTreeMenuItem[]>
    >;
    readonly resetAreasFilters: () => void;
    readonly menuOpen: boolean;
    readonly setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
    readonly setMultiFilters: (mf: FilterObject[]) => void;
    readonly datasetShape?: readonly DatasetPointShape[];
    readonly setDatasetShape?: (datasetShape: DatasetPointShape) => void;
    readonly selectedSavedView?: number;
    readonly setSelectedSavedView: (id: number) => void;
    readonly handleSavedPolygons: (p: InputPolygonWithId[]) => void;
    readonly savedAreasData: FetchAllAreasExtendedQuery | undefined;
    readonly refetchSavedAreas?: (
        variables?:
            | Partial<
                  Exact<{
                      [key: string]: never;
                  }>
              >
            | undefined
    ) => Promise<ApolloQueryResult<FetchAllAreasExtendedQuery>>;
    readonly savedViewsData: FetchSavedViewsQuery | undefined;
    readonly loadingSavedViews: boolean;
    readonly recentResponse?: SubscriptionResponse;
}) => {
    const mapEngines = useMapEngines();
    const [selectedSubMenu, setSelectedSubMenu] = useState<SubMenu>(
        SubMenu.dataset
    );

    const handleUpdateMapStyle = (c: Consumers) => {
        changeConsumption(c);
    };

    const subMenus: ReadonlyArray<{
        readonly menu: SubMenu;
        readonly icon: JSX.Element;
    }> = [
        {
            menu: SubMenu.dataset,
            icon: <TableRowsOutline size="sm" />,
        },
        {
            menu: SubMenu.savedViews,
            icon: <BookmarkOutline size="sm" />,
        },
        {
            menu: SubMenu.areas,
            icon: <CropFreeOutline size="sm" />,
        },
        {
            menu: SubMenu.mapStyles,
            // TODO: replace with MapOutline
            icon: <MapOutline size="sm" />,
        },
        {
            menu: SubMenu.configuration,
            icon: <TuneOutline size="sm" />,
        },
        {
            menu: SubMenu.lastSearchQuery,
            icon: <MonitoringOutline size="sm" />,
        },
    ];

    const mapData = useMapData();

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        const data = pipe(
            convertSavedAreas(
                savedAreasData?.fetchAllAreasExtended || [],
                selectedAreaId
            ),
            A.filter((d) => !isEmpty(d.subItems))
        );
        setFilterItems(!isEmpty(data) ? data : []);
    }, [savedAreasData, selectedAreaId]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        if (reFetchSavedAreas) {
            refetchSavedAreas?.();
            setReFetchSavedAreas?.(false);
        }
    }, [reFetchSavedAreas]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        match(mapData)
            .with({ id: P.when((id) => !isNil(id)) }, () => {
                mapData?.id && setSelectedSavedView(mapData?.id);
                selectedSubMenu === SubMenu.savedViews
                    ? undefined
                    : handleSelectedMenu(SubMenu.savedViews);
            })
            .with(
                {
                    fkSavedAreaId: P.when(
                        (fkSavedAreaId) => !isNil(fkSavedAreaId)
                    ),
                },
                () => handleSelectedMenu(SubMenu.areas)
            )
            .with(
                {
                    datasets: P.when((datasets) => !isEmpty(datasets)),
                },
                () => handleSelectedMenu(SubMenu.dataset)
            )
            .when(
                () =>
                    !isEmpty(selectedDataset) &&
                    !selectedSavedView &&
                    !menuOpen,
                () => {
                    handleSelectedMenu(SubMenu.dataset);
                }
            );
    }, [mapData]);

    const handleSelectedMenu = (menu: SubMenu) => {
        setSelectedSubMenu(menu);
        setMenuOpen((prev) => !(isEqual(menu, selectedSubMenu) && prev));
        setFunctionType(
            menu === SubMenu.areas
                ? FunctionType.savedPolygon
                : FunctionType.viewport
        );
    };

    const convertSavedAreas = (
        savedAreas: readonly AreaExtended[],
        selectedId?: number
    ): SelectableTreeMenuItem[] => {
        return pipe(
            savedAreas,
            toNonReadonlyArray,
            A.map((area) => ({
                id: decodeId("area", area.area.id),
                selected: false,
                title: area.area.name.trim(),
                subItems: pipe(
                    area.savedAreas,
                    toNonReadonlyArray,
                    A.filter((sa) => sa.isEnabled),
                    A.map(
                        (sa): SelectableTreeMenuItem => ({
                            id: decodeId("savedArea", sa.id),
                            title: sa.name.trim(),
                            selected: selectedId === sa.id,
                        })
                    )
                ),
            }))
        );
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        const selectedIds = pipe(
            filterItems,
            toNonReadonlyArray,
            A.flatMap((item) =>
                pipe(
                    item.subItems,
                    toNonReadonlyArray,
                    A.filter((sub) => sub.selected),
                    A.map((sub) => sub.id)
                )
            )
        );
        const polygons = pipe(
            savedAreasData?.fetchAllAreasExtended,
            toNonReadonlyArray,
            A.flatMap(({ savedAreas }) =>
                pipe(
                    savedAreas,
                    A.filter((sa) =>
                        includes(selectedIds, decodeId("savedArea", sa.id))
                    )
                )
            )
        );

        const selectedPolygons = pipe(
            polygons,
            A.map(({ id, geometries }) => ({
                id,
                geometries,
            })),
            A.flatMap(({ id, geometries }) =>
                pipe(
                    geometries,
                    A.map((g) => ({
                        id,
                        polygon: {
                            inners: [],
                            outer: {
                                points: pipe(
                                    g.coordinates,
                                    // biome-ignore lint/correctness/noFlatMapIdentity: <explanation>
                                    A.flatMap((c) => c),
                                    A.map((c) => ({
                                        latitude: c[1],
                                        longitude: c[0],
                                    }))
                                ),
                            },
                        },
                    }))
                )
            )
        );

        const multiPolygons = selectedPolygons.map((polygon, i) => ({
            id: `${polygon.id}-${i}`,
            inners: polygon.polygon.inners,
            outer: polygon.polygon.outer,
        }));

        selectedSubMenu === SubMenu.areas
            ? setSavedPolygons(!isEmpty(polygons) ? polygons : undefined)
            : handleSavedPolygons(multiPolygons);

        if (isEmpty(polygons)) {
            setFunctionType(FunctionType.viewport);
            clearShapes();
        } else {
            setFunctionType(FunctionType.savedPolygon);
        }
    }, [filterItems]);

    const resetMap = () => {
        resetAreasFilters();
        setSelectedDataset([]);
        setMultiFilters([]);
        clearShapes();
    };

    return (
        <NestedSubSidebar
            title={startCase(selectedSubMenu)}
            drawerContent={match(selectedSubMenu)
                .with(SubMenu.dataset, () => (
                    <></>
                    // <MapLayoutLeftContent
                    //     multiFilters={multiFilters}
                    //     addRemoveDataset={addRemoveDataset}
                    //     selectedDataset={selectedDataset}
                    //     setSelectedDataset={setSelectedDataset}
                    //     // datasetShape={datasetShape}
                    //     // setDatasetShape={setDatasetShape}
                    // />
                ))
                .with(SubMenu.savedViews, () => (
                    <SavedViewSubMenuContainer
                        resetMap={resetMap}
                        selectedSavedView={selectedSavedView}
                        setSelectedSavedView={setSelectedSavedView}
                        savedViewsData={savedViewsData}
                        loadingSavedViews={loadingSavedViews}
                    />
                ))
                .with(SubMenu.areas, () => (
                    <></>
                    // <SavedAreasSubmenu
                    //     filterItems={filterItems}
                    //     setFilterItems={setFilterItems}
                    //     //  resetAreasFilters={resetAreasFilters}
                    // />
                ))
                .with(SubMenu.mapStyles, () => (
                    <Stack width="100%">
                        {mapEngines.map((engine, index) => (
                            <CellItem
                                key={`${engine.id}-${index}`}
                                title={startCase(engine.name)}
                                selected={activeConsumption === engine.consumer}
                                onClick={() =>
                                    handleUpdateMapStyle(engine.consumer)
                                }
                                sx={{
                                    display: engine.isActive ? "flex" : "none",
                                }}
                                startNode={
                                    <Radio
                                        checked={
                                            activeConsumption ===
                                            engine.consumer
                                        }
                                    />
                                }
                                endNode={
                                    activeConsumption === engine.consumer && (
                                        <DoneOutline />
                                    )
                                }
                            />
                        ))}
                    </Stack>
                ))
                .with(SubMenu.configuration, () => (
                    <DebugContainer
                        options={options}
                        setOptions={setOptions}
                        showTriangles={showTriangles}
                        setShowTriangles={setShowTriangles}
                        showPoints={showPoints}
                        setShowPoints={setShowPoints}
                        showFiltered={showFiltered}
                        setShowFiltered={setShowFiltered}
                    />
                ))
                .with(SubMenu.lastSearchQuery, () => (
                    <ResultsContainer recentResponse={recentResponse} />
                ))
                .exhaustive()}
            open={menuOpen}
            onOpenChange={setMenuOpen}
            initialWidth={330}
            slotProps={{
                sidebarContainer: {
                    sx: {
                        justifyContent: "space-around",
                        alignItems: "center",
                        padding: 3,
                        borderTop: 1,
                        borderColor: (theme) => theme.palette.stroke[100],
                        backgroundColor: (theme) =>
                            theme.palette.background.container,
                        breakpoints: {
                            md: {
                                justifyContent: "flex-start",
                                borderTop: 0,
                                borderRight: 1,
                                borderColor: (theme) =>
                                    theme.palette.stroke[100],
                            },
                        },
                    },
                },
                drawerContainer: {
                    sx: {
                        zIndex: (theme) => theme.zIndex.drawer - 200,
                        backgroundColor: (theme) =>
                            theme.palette.background.container,
                        breakpoints: {
                            xs: { maxHeight: "60vh" },
                        },
                    },
                },
                drawerContentWrapper: {
                    sx: {
                        padding: 2,
                    },
                },
            }}
        >
            {pipe(
                subMenus,
                toNonReadonlyArray,
                A.map((item) => (
                    <NavigationRailIcon
                        key={item.menu}
                        selected={isEqual(item.menu, selectedSubMenu)}
                        onClick={() => handleSelectedMenu(item.menu)}
                        density="dense"
                        sx={{
                            borderRadius: (theme) => theme.radius.default,
                        }}
                    >
                        {item.icon}
                    </NavigationRailIcon>
                ))
            )}
        </NestedSubSidebar>
    );
};
