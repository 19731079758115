import {
    DatabaseType,
    DbColumnType,
    FilterData,
    FilterObject,
    FilterType,
    LogicOperator,
    WhereOperator,
} from "@biggeo/bg-server-lib/datascape-ai";
import { WithPartialValues, areValuesEmpty } from "@biggeo/bg-utils";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import { match } from "ts-pattern";
import Zod from "zod";
import { ColorSwatchOption } from "../../../common/components/ColorSwatchSelector";
import { MapContextDataset, MapContextFilter } from "../../mapbox/context";
import { MapShapeColorType } from "../../views/MapShapeLayerStyles";

// ███████╗███╗   ██╗██╗   ██╗███╗   ███╗
// ██╔════╝████╗  ██║██║   ██║████╗ ████║
// █████╗  ██╔██╗ ██║██║   ██║██╔████╔██║
// ██╔══╝  ██║╚██╗██║██║   ██║██║╚██╔╝██║
// ███████╗██║ ╚████║╚██████╔╝██║ ╚═╝ ██║
// ╚══════╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝     ╚═╝

export enum MapFilterCriteriaSection {
    details = "details",
    filterCriteria = "filterCriteria",
    styles = "styles",
}

export enum MapFilterCriteriaStyleType {
    shape = "shape",
    fill = "fill",
    stroke = "stroke",
    customMarker = "customMarker",
    dataAggregation = "dataAggregation",
}

export enum MapFilterCriteriaShapeStyle {
    oval = "oval",
    square = "square",
}

export enum FilterCriteriaDataAggregationDensity {
    heatmap = "heatmap",
    cluster = "cluster",
}

//  ██████╗ ██████╗ ███╗   ██╗███████╗████████╗ █████╗ ███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗  ██║██╔════╝╚══██╔══╝██╔══██╗████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔██╗ ██║███████╗   ██║   ███████║██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╗██║╚════██║   ██║   ██╔══██║██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚████║███████║   ██║   ██║  ██║██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═══╝   ╚═╝

export const mapFilterCriteriaOperators: MapFilterCriteriaOperators = {
    [FilterType.BooleanType]: [
        WhereOperator.equals,
        WhereOperator.isEmpty,
        WhereOperator.isNotEmpty,
    ],
    [FilterType.StringType]: [
        WhereOperator.equals,
        WhereOperator.contains,
        WhereOperator.startsWith,
        WhereOperator.endsWith,
        WhereOperator.isEmpty,
        WhereOperator.isNotEmpty,
    ],
    [FilterType.NumberType]: [
        WhereOperator.eq,
        WhereOperator.neq,
        WhereOperator.gt,
        WhereOperator.gte,
        WhereOperator.lt,
        WhereOperator.lte,
        WhereOperator.isEmpty,
        WhereOperator.isNotEmpty,
    ],
    [FilterType.DateType]: [
        WhereOperator.eq,
        WhereOperator.neq,
        WhereOperator.gt,
        WhereOperator.gte,
        WhereOperator.lt,
        WhereOperator.lte,
        WhereOperator.isEmpty,
        WhereOperator.isNotEmpty,
    ],
};

// ████████╗██╗   ██╗██████╗ ███████╗
// ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝
//    ██║    ╚████╔╝ ██████╔╝█████╗
//    ██║     ╚██╔╝  ██╔═══╝ ██╔══╝
//    ██║      ██║   ██║     ███████╗
//    ╚═╝      ╚═╝   ╚═╝     ╚══════╝

export type MapFilterCriteriaForm = {
    details: MapFilterCriteriaDetails;
    filterCriteria: MapFilterCriteriaDataset[];
    styles: MapFilterCriteriaStyle;
};

export type MapFilterCriteriaDetails = {
    readonly name: string;
    readonly description?: string;
};

export type MapFilterCriteriaDataset = {
    readonly mapTemplateDatasetId: number;
    readonly dataSourceId: string;
    readonly label: string;
    readonly collection: string;
    readonly filters: Partial<MapFilterCriteriaDatasetItem>[];
    readonly logicOperator?: LogicOperator;
};

export type MapFilterCriteriaDatasetItem = {
    readonly column: string;
    readonly type: FilterType;
    readonly operator: WhereOperator;
    readonly value?: string;
};

export type MapFilterCriteriaStyle = {
    shape: MapFilterCriteriaShapeStyle;
    fill: MapShapeColorType;
    stroke: MapShapeColorType;
    customMarker: string;
    dataAggregation: MapFilterCriteriaStyleDataAggregation;
};

export type MapFilterCriteriaStyleDataAggregation = Partial<{
    heatmap: ColorSwatchOption;
    cluster: ColorSwatchOption; // TODO
}>;

export type MapFilterCriteriaOperators = {
    [T in FilterType]: Array<WhereOperator>;
};

// ███████╗ ██████╗██╗  ██╗███████╗███╗   ███╗ █████╗
// ██╔════╝██╔════╝██║  ██║██╔════╝████╗ ████║██╔══██╗
// ███████╗██║     ███████║█████╗  ██╔████╔██║███████║
// ╚════██║██║     ██╔══██║██╔══╝  ██║╚██╔╝██║██╔══██║
// ███████║╚██████╗██║  ██║███████╗██║ ╚═╝ ██║██║  ██║
// ╚══════╝ ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝     ╚═╝╚═╝  ╚═╝

export const mapFilterCriteriaSchema = Zod.object({
    details: Zod.object({
        name: Zod.string(),
        description: Zod.string().optional(),
    }),
    filterCriteria: Zod.array(
        Zod.object({
            mapTemplateDatasetId: Zod.number(),
            dataSourceId: Zod.string(),
            label: Zod.string(),
            collection: Zod.string(),
            logicOperator: Zod.nativeEnum(LogicOperator).optional(),
            filters: Zod.array(
                Zod.object({
                    column: Zod.string(),
                    type: Zod.nativeEnum(FilterType),
                    operator: Zod.nativeEnum(WhereOperator),
                    value: Zod.string(),
                })
            ),
        })
    ).nonempty(),
    styles: Zod.object({
        shape: Zod.nativeEnum(MapFilterCriteriaShapeStyle).optional(),
        fill: Zod.object({
            color: Zod.string().optional(),
            opacity: Zod.number().optional(),
        }).optional(),
        stroke: Zod.object({
            color: Zod.string().optional(),
            opacity: Zod.number().optional(),
        }).optional(),
        customMarker: Zod.string().optional(),
        dataAggregation: Zod.object({
            heatmap: Zod.object({
                id: Zod.union([Zod.number(), Zod.string()]),
                swatch: Zod.array(
                    Zod.object({
                        range: Zod.number(),
                        color: Zod.string(),
                    })
                ),
            }).optional(),
            cluster: Zod.object({
                id: Zod.union([Zod.number(), Zod.string()]),
                swatch: Zod.array(
                    Zod.object({
                        range: Zod.number(),
                        color: Zod.string(),
                    })
                ),
            }).optional(),
        }).optional(),
    }).optional(),
});

// ██╗  ██╗███████╗██╗     ██████╗ ███████╗██████╗
// ██║  ██║██╔════╝██║     ██╔══██╗██╔════╝██╔══██╗
// ███████║█████╗  ██║     ██████╔╝█████╗  ██████╔╝
// ██╔══██║██╔══╝  ██║     ██╔═══╝ ██╔══╝  ██╔══██╗
// ██║  ██║███████╗███████╗██║     ███████╗██║  ██║
// ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝

export const mapFilterCriteriaSign = (operator: WhereOperator): string =>
    match(operator)
        .with(WhereOperator.eq, () => "=")
        .with(WhereOperator.neq, () => "!=")
        .with(WhereOperator.gt, () => ">")
        .with(WhereOperator.gte, () => ">=")
        .with(WhereOperator.lt, () => "<")
        .with(WhereOperator.lte, () => "<=")
        .with(WhereOperator.isEmpty, () => "Is Empty")
        .with(WhereOperator.isNotEmpty, () => "Is Not Empty")
        .with(WhereOperator.isAnyOf, () => "Is Any Of")
        .with(WhereOperator.startsWith, () => "Starts With")
        .with(WhereOperator.endsWith, () => "Ends With")
        .with(WhereOperator.equals, () => "Equals")
        .otherwise(() => "Contains");

export const getFilterCriteriaOperators = (type: FilterType) =>
    mapFilterCriteriaOperators[type];

export const mapColumnType = (type: DbColumnType): FilterType =>
    match(type)
        .with(DbColumnType.Boolean, () => FilterType.BooleanType)
        .with(DbColumnType.Date, () => FilterType.DateType)
        .with(DbColumnType.Number, () => FilterType.NumberType)
        .otherwise(() => FilterType.StringType);

export const mapFilterData = (type: FilterType, value?: string): FilterData => {
    return omitBy(
        {
            booleanData:
                isEqual(type, FilterType.BooleanType) && value
                    ? value === "true"
                    : undefined,
            dateData:
                isEqual(type, FilterType.DateType) && value
                    ? new Date(value)
                    : undefined,
            numberData:
                isEqual(type, FilterType.NumberType) && value
                    ? Number.parseInt(value)
                    : undefined,
            stringData:
                isEqual(type, FilterType.StringType) && value
                    ? value
                    : undefined,
        },
        isNil
    );
};

export const mapToMultifilterType = (
    filter: MapFilterCriteriaForm
): FilterObject[] => {
    return pipe(
        filter.filterCriteria,
        A.map((f) => {
            const logicOperator =
                f.filters.length > 1 ? LogicOperator.and : LogicOperator.or;

            const filters = pipe(
                f.filters,
                compact,
                A.map((item) => {
                    const e = item as MapFilterCriteriaDatasetItem;

                    return {
                        column: e.column,
                        operator: e.operator,
                        type: e.type,
                        data: mapFilterData(e.type, e.value),
                    };
                }),
                A.map((item) => (areValuesEmpty(item) ? undefined : item)),
                compact
            );

            return {
                collection: f.collection,
                databaseId: f.dataSourceId,
                databaseType: DatabaseType.point,
                filters,
                logicOperator: f.logicOperator ?? logicOperator,
            };
        })
    );
};

export const mapFilterCriteriaInitialValues = (
    filter?: MapContextFilter,
    lastSelectedDataset?: MapContextDataset
): WithPartialValues<MapFilterCriteriaForm> => {
    const color = lastSelectedDataset
        ? lastSelectedDataset.dataSource.color
        : undefined;

    const heatmap = lastSelectedDataset?.currentHeatMapValue;

    return {
        details: {
            name: filter ? filter.details.name : undefined,
            description: filter ? filter.details.description : undefined,
        },
        filterCriteria: filter?.filterCriteria || [],
        styles: {
            shape: filter
                ? filter.styles.shape
                : MapFilterCriteriaShapeStyle.oval,
            fill: filter
                ? filter.styles.fill
                : color
                  ? {
                        color,
                        opacity: 0.9,
                    }
                  : undefined,
            stroke: filter
                ? filter.styles.stroke
                : color
                  ? {
                        color,
                        opacity: 0.9,
                    }
                  : undefined,
            customMarker: filter ? filter.styles.customMarker : undefined,
            dataAggregation: {
                heatmap: filter?.styles.dataAggregation.heatmap ?? heatmap,
                cluster: filter
                    ? filter.styles.dataAggregation.cluster
                    : undefined,
            },
        },
    };
};

export const clearMultifilters = (
    multiFilters: FilterObject[],
    setMultiFilters: (mf: FilterObject[]) => void
) =>
    setMultiFilters(
        pipe(
            multiFilters,
            A.map((f) => ({ ...f, filters: [] }))
        )
    );
