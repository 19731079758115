import {
    CreateEditDeleteMapTemplate,
    MapTemplateExtended,
    useFetchDataSourcesQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { LoadingBar } from "@biggeo/bg-ui/lab";
import { match } from "@vividtheory/remotedata";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import { CallBacksType } from "../../utils/types";
import { AvailableDataset } from "../pages/CreateOrManageMapTemplatePage";
import MapTemplateForm from "../views/MapTemplateForm";

interface IMapTemplateFormContainer {
    readonly navigate: (to?: string) => void;
    readonly save: (
        input: CreateEditDeleteMapTemplate,
        callbacks?: CallBacksType<MapTemplateExtended>
    ) => void;
    readonly loading: boolean;
}

const MapTemplateFormContainer = ({
    navigate,
    save,
    loading,
}: IMapTemplateFormContainer) => {
    const { remote: dataSourcesRd } = useFetchDataSourcesQuery({
        variables: {
            input: {
                isConnected: true,
            },
        },
    });

    return match(dataSourcesRd, {
        _: () => <LoadingBar />,
        Success: (data) => {
            const convertedDS: AvailableDataset[] = pipe(
                data.fetchDataSources.dataSources,
                A.map((item) => {
                    const ds: AvailableDataset = {
                        id: item.id,
                        image: item.icon || "",
                        title: item.label || item.collectionName,
                    };
                    return ds;
                })
            );
            return (
                <MapTemplateForm
                    navigate={navigate}
                    save={save}
                    datasets={convertedDS}
                    loading={loading}
                />
            );
        },
    });
};

export default MapTemplateFormContainer;
