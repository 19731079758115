import { DatasetInfo } from "../map/mapbox/views/MarketplaceDataView";

export const datasetInfo: DatasetInfo[] = [
    {
        id: 1,
        name: "Points of Interest Calgary",
        size: 100,
        price: 3000,
        image: "https://biggeo.blob.core.windows.net/media/Company-Avatar.png",
        progress: 10,
        processing: true,
        gradient: [
            { id: 0, offset: 0, color: "#FCEDE8" },
            { id: 1, offset: 1, color: "#E14616" },
        ],
        preview: (datasetId: number) => {
            console.log(datasetId);
        },
    },
    {
        id: 2,
        name: "Dataset Name One",
        size: 150,
        price: 99,
        image: "https://biggeo.blob.core.windows.net/media/s&p.png",
        progress: 0,
        processing: false,
        gradient: [
            { id: 0, offset: 0, color: "#EAFAF4" },
            { id: 1, offset: 1, color: "#2BC48C" },
        ],
        preview: (datasetId: number) => {
            console.log(datasetId);
        },
    },
    {
        id: 3,
        name: "Points of Interest Edmonton",
        size: 180,
        price: 2000,
        image: "https://biggeo.blob.core.windows.net/media/Company-Avatar.png",
        progress: 0,
        processing: false,
        gradient: [
            { id: 0, offset: 0, color: "#F1F3DF" },
            { id: 1, offset: 1, color: "#C5DF15" },
        ],
        preview: (datasetId: number) => {
            console.log(datasetId);
        },
    },
    {
        id: 4,
        name: "Points of Interest Alberta",
        size: 200,
        price: 200,
        image: "https://biggeo.blob.core.windows.net/media/Company-Avatar.png",
        progress: 0,
        processing: false,
        gradient: [
            { id: 0, offset: 0, color: "#FEF8D8" },
            { id: 1, offset: 0.3, color: "#F9DD55" },
            { id: 2, offset: 0.4, color: "#F1AF41" },
            { id: 3, offset: 0.45, color: "#DB753F" },
            { id: 4, offset: 0.58, color: "#AD2B22" },
            { id: 5, offset: 0.7, color: "#96241D" },
            { id: 6, offset: 1, color: "#8B2C55" },
        ],
        preview: (datasetId: number) => {
            console.log(datasetId);
        },
    },
    {
        id: 5,
        name: "Dataset Name Two",
        size: 300,
        price: 600,
        image: "https://biggeo.blob.core.windows.net/media/s&p.png",
        progress: 0,
        processing: false,
        gradient: [
            { id: 0, offset: 0, color: "#EBE5D5" },
            { id: 1, offset: 0.4, color: "#FF6F42" },
            { id: 2, offset: 0.6, color: "#287D80" },
            { id: 3, offset: 1, color: "#2BC48C" },
        ],
        preview: (datasetId: number) => {
            console.log(datasetId);
        },
    },
];
