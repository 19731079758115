import {
    Box,
    FlexScrollArea,
    FlexScrollAreaContainer,
    IconAvatar,
    NavigationRailCell,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    ActionKeyOutline,
    BigGeoLogo,
    DatabaseOutline,
    HomeOutline,
    PublicOutline,
    SupportOutline,
    UnknownDocumentOutline,
} from "@biggeo/bg-ui/lab/icons";
import startsWith from "lodash/startsWith";
import { Link, useLocation } from "react-router-dom";

import { isSnp } from "../common/redux/hooks.ts";
import { PortalMenu } from "../home/views/PortalMenu.tsx";
import { Routes } from "../navigation/redux/model.ts";
import SlackIntegrationCard from "./SlackIntegrationCard.tsx";

export type SidebarProps = {
    onItemClick?: () => void;
    isTemporary?: boolean;
};

export const Sidebar = ({ onItemClick, isTemporary }: SidebarProps) => {
    const path = useLocation().pathname;
    const isSNP = isSnp();

    return (
        <FlexScrollAreaContainer>
            <FlexScrollArea
                sx={{
                    flexDirection: "column",
                    gap: 2,
                    paddingX: 3,
                    paddingTop: 4,
                    backgroundColor: (theme) => theme.palette.background.main,
                    borderRadius: (theme) => theme.radius.default,
                }}
            >
                <PortalMenu />
                <Stack>
                    <Link to={Routes.home}>
                        <NavigationRailCell
                            startNode={<HomeOutline size="xs" />}
                            selected={path === Routes.home}
                            onClick={onItemClick}
                        >
                            Home
                        </NavigationRailCell>
                    </Link>
                    <Link to={Routes.marketplace}>
                        <NavigationRailCell
                            startNode={<ActionKeyOutline size="xs" />}
                            selected={startsWith(path, Routes.marketplace)}
                            onClick={onItemClick}
                        >
                            Marketplace
                        </NavigationRailCell>
                    </Link>
                </Stack>
                <Typography
                    variant="body4"
                    fontWeight="semibold"
                    color="disabled"
                    colorSet="container"
                    invertColors
                    sx={{
                        paddingLeft: 2,
                        cursor: "default",
                    }}
                >
                    Datascape AI
                </Typography>
                <Stack>
                    <Link to={Routes.mapTemplates}>
                        <NavigationRailCell
                            startNode={<PublicOutline size="xs" />}
                            selected={startsWith(path, Routes.mapTemplates)}
                            onClick={onItemClick}
                        >
                            Map Templates
                        </NavigationRailCell>
                    </Link>
                    <Link to={`${Routes.data}/available`}>
                        <NavigationRailCell
                            startNode={<DatabaseOutline size="xs" />}
                            selected={startsWith(path, `${Routes.data}`)}
                            onClick={onItemClick}
                        >
                            Manage Data
                        </NavigationRailCell>
                    </Link>
                    {/* <Link to={Routes.recipes}>
                            <NavigationRailCell
                                startNode={<ExperimentOutline size="xs" />}
                                selected={startsWith(path, Routes.recipes)}
                                onClick={onItemClick}
                            >
                                Recipes
                            </NavigationRailCell>
                        </Link> */}
                    {/* <Link to={Routes.team}>
                            <NavigationRailCell
                                startNode={<GroupsOutline size="xs" />}
                                selected={path === Routes.team}
                                onClick={onItemClick}
                            >
                                Team
                            </NavigationRailCell>
                        </Link> */}
                </Stack>
            </FlexScrollArea>
            <Stack
                gap={2}
                sx={{
                    padding: 3,
                }}
            >
                <Stack>
                    <Link tabIndex={-1} to={"/help"}>
                        <NavigationRailCell
                            startNode={<SupportOutline size="xs" />}
                            selected={path === "/help"}
                            onClick={onItemClick}
                        >
                            Help Center
                        </NavigationRailCell>
                    </Link>
                    <Link
                        tabIndex={-1}
                        to={"https://docs.biggeo.com/reference/introduction"}
                    >
                        <NavigationRailCell
                            startNode={<UnknownDocumentOutline size="xs" />}
                            onClick={onItemClick}
                        >
                            Documentation
                        </NavigationRailCell>
                    </Link>
                </Stack>
                {/* this component will be hidden until BE is ready
                <UpgradeBGVelocityCard
                    startAvatarURL={
                        "https://biggeo.blob.core.windows.net/media/fdsa.png"
                    }
                /> */}
                {/* TODO: Enable when slack link ready */}
                {/*<Link to={"#"} tabIndex={-1}>*/}
                <SlackIntegrationCard
                    isTemporary={isTemporary}
                    startAvatarURL={
                        "https://biggeo.blob.core.windows.net/media/SnowglakeImage.png"
                    }
                    endAvatarURL={
                        "https://biggeo.blob.core.windows.net/media/slackImage.png"
                    }
                />
                {/*</Link>*/}
            </Stack>
            {isSNP && (
                <Box sx={{ padding: 3 }}>
                    <Stack
                        flexDirection="row"
                        alignItems="center"
                        sx={{
                            padding: 1,
                            backgroundColor: (theme) =>
                                theme.palette.primary.container,
                            borderRadius: (theme) => theme.radius.default,
                        }}
                        gap={1}
                    >
                        <IconAvatar
                            square
                            size="sm"
                            sx={{
                                border: (theme) =>
                                    `${theme.spacing(0.3)} solid ${theme.palette.surface.onMain}`,
                            }}
                        >
                            <BigGeoLogo size="sm" />
                        </IconAvatar>
                        <Typography variant="body3">
                            Powered by BigGeo
                        </Typography>
                    </Stack>
                </Box>
            )}
        </FlexScrollAreaContainer>
    );
};
