import {
    AllFilter,
    ColFilterItem,
    ColFilterType,
    FilterItem,
    FilterType,
    matchFilterProps,
} from "@biggeo/bg-ui/lab";
export const matchFilter = <T>(
    value: AllFilter,
    match: matchFilterProps<T>
) => {
    switch (value._type) {
        case ColFilterType.boolean:
            return match[ColFilterType.boolean](value);
        case ColFilterType.multiSelect:
            return match[ColFilterType.multiSelect](value);
        case ColFilterType.date:
            return match[ColFilterType.date](value);
        case ColFilterType.number:
            return match[ColFilterType.number](value);
        default:
            return match[ColFilterType.string](value);
    }
};

export const convertFilterItem = (input: ColFilterItem): FilterItem => {
    const f = input.filterItem;
    const column = input.columnName;
    return matchFilter(f, {
        boolean: (f) =>
            ({
                column,
                data: {
                    booleanData: f.data,
                },
                operator: f.operation,
                type: FilterType.BooleanType,
            }) as FilterItem,
        number: (f) => ({
            column,
            data: {
                numberData: f.data,
            },
            operator: f.operation,
            type: FilterType.NumberType,
        }),
        string: (f) => ({
            column,
            data: {
                stringData: f.data,
            },
            operator: f.operation,
            type: FilterType.StringType,
        }),
        multiSelect: (f) => ({
            column,
            data: {},
            operator: f.operation,
            type: FilterType.StringType,
        }),
        date: (f) => ({
            column,
            data: { dateData: new Date() },
            operator: f.operation,
            type: FilterType.DateType,
        }),
    });
};
