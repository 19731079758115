import {
    Button,
    FlexScrollArea,
    FlexScrollAreaContainer,
    Grid,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";

export type ConflictAreasModalProps = {
    readonly onClickDecline: () => void;
    readonly onClickAccept: () => void;
};

const ConflictAreasModal = ({
    onClickDecline,
    onClickAccept,
}: ConflictAreasModalProps) => {
    return (
        <FlexScrollAreaContainer>
            <FlexScrollArea
                sx={{
                    flexDirection: "column",
                    padding: 4,
                    minHeight: (theme) => theme.spacing(26),
                }}
                gap={4}
            >
                <Stack gap={1}>
                    <Typography variant="h6" fontWeight="bold">
                        Hold On ✋
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            color: (theme) =>
                                theme.palette.disabled.onContainer,
                        }}
                    >
                        Shapes outside area boundary will be discarded. Are you
                        sure you want to proceed?
                    </Typography>
                </Stack>
            </FlexScrollArea>
            <Stack
                gap={4}
                sx={{
                    padding: 4,
                }}
            >
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={onClickDecline}
                        >
                            Nervermind
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button fullWidth onClick={onClickAccept}>
                            Yes
                        </Button>
                    </Grid>
                </Grid>
            </Stack>
        </FlexScrollAreaContainer>
    );
};

export default ConflictAreasModal;
