import {
    Grid,
    LinearGradientPicker,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import { useEffect, useState } from "react";
import { ColorSwatchOption, ColorSwatchSelector } from "./ColorSwatchSelector";

export type HeatMapColorMapperProps = {
    value?: ColorSwatchOption;
    onChange?: (value: ColorSwatchOption) => void;
};

const swatchOptions: ColorSwatchOption[] = [
    {
        id: 0,
        swatch: [
            {
                range: 0,
                color: "rgba(252, 237, 232, 0.0)",
            },
            {
                range: 100,
                color: "#F9DAD0",
            },
            {
                range: 200,
                color: "#F6C8BA",
            },
            {
                range: 300,
                color: "#F3B5A2",
            },
            {
                range: 400,
                color: "#EFA28A",
            },
            {
                range: 500,
                color: "#ED9073",
            },
            {
                range: 600,
                color: "#EA7E5C",
            },
            {
                range: 700,
                color: "#E76B45",
            },
            {
                range: 800,
                color: "#E4592E",
            },
            {
                range: 900,
                color: "#E14616",
            },
        ],
    },
    {
        id: 1,
        swatch: [
            {
                range: 0,
                color: "rgba(235, 239, 254, 0.0)",
            },
            {
                range: 100,
                color: "#D5DEFC",
            },
            {
                range: 200,
                color: "#C1CEFB",
            },
            {
                range: 300,
                color: "#ACBDF9",
            },
            {
                range: 400,
                color: "#97ACF7",
            },
            {
                range: 500,
                color: "#829CF6",
            },
            {
                range: 600,
                color: "#6E8CF5",
            },
            {
                range: 700,
                color: "#597BF3",
            },
            {
                range: 800,
                color: "#456BF2",
            },
            {
                range: 900,
                color: "#2F5AF0",
            },
        ],
    },
    {
        id: 2,
        swatch: [
            {
                range: 0,
                color: "rgba(234, 250, 244, 0.0)",
            },
            {
                range: 100,
                color: "#D5F3E8",
            },
            {
                range: 200,
                color: "#C0EEDD",
            },
            {
                range: 300,
                color: "#AAE7D1",
            },
            {
                range: 400,
                color: "#94E1C5",
            },
            {
                range: 500,
                color: "#80DCBA",
            },
            {
                range: 600,
                color: "#6BD6AF",
            },
            {
                range: 700,
                color: "#55D0A3",
            },
            {
                range: 800,
                color: "#41CA98",
            },
            {
                range: 900,
                color: "#2BC48C",
            },
        ],
    },
    {
        id: 4,
        swatch: [
            {
                range: 0,
                color: "rgba(252, 159, 128, 0.0)",
            },
            {
                range: 100,
                color: "#FBC489",
            },
            {
                range: 200,
                color: "#F8EA95",
            },
            {
                range: 300,
                color: "#ED7D96",
            },
            {
                range: 400,
                color: "#D063A6",
            },
            {
                range: 500,
                color: "#B14FB3",
            },
            {
                range: 600,
                color: "#7C58AF",
            },
            {
                range: 700,
                color: "#69419F",
            },
            {
                range: 800,
                color: "#482677",
            },
            {
                range: 900,
                color: "#2E116A",
            },
        ],
    },
    {
        id: 5,
        swatch: [
            {
                range: 0,
                color: "rgba(242, 240, 225, 0.0)",
            },
            {
                range: 100,
                color: "#E0F0D2",
            },
            {
                range: 200,
                color: "#B2E8C6",
            },
            {
                range: 300,
                color: "#7BCEBC",
            },
            {
                range: 400,
                color: "#37A7B9",
            },
            {
                range: 500,
                color: "#192052",
            },
            {
                range: 600,
                color: "#01779F",
            },
            {
                range: 700,
                color: "#00508A",
            },
            {
                range: 800,
                color: "#003C83",
            },
            {
                range: 900,
                color: "#002671",
            },
        ],
    },
    {
        id: 6,
        swatch: [
            {
                range: 0,
                color: "rgba(249, 221, 85, 0.0)",
            },
            {
                range: 100,
                color: "#F1AF41",
            },
            {
                range: 200,
                color: "#DB753F",
            },
            {
                range: 300,
                color: "#D54E34",
            },
            {
                range: 400,
                color: "#C63127",
            },
            {
                range: 500,
                color: "#B43027",
            },
            {
                range: 600,
                color: "#AD2B22",
            },
            {
                range: 700,
                color: "#96241D",
            },
            {
                range: 800,
                color: "#8B2C55",
            },
            {
                range: 900,
                color: "#7F1E18",
            },
        ],
    },
    {
        id: 7,
        swatch: [
            {
                range: 0,
                color: "rgba(255, 245, 253, 0.0)",
            },
            {
                range: 100,
                color: "#EBDCEE",
            },
            {
                range: 200,
                color: "#BFBEE3",
            },
            {
                range: 300,
                color: "#73A5DA",
            },
            {
                range: 400,
                color: "#0A7E79",
            },
            {
                range: 500,
                color: "#08634D",
            },
            {
                range: 600,
                color: "#0390AB",
            },
            {
                range: 700,
                color: "#026A65",
            },
            {
                range: 800,
                color: "#004534",
            },
            {
                range: 900,
                color: "#00382A",
            },
        ],
    },
];

export const HeatMapColorMapper = ({
    value: _value,
    onChange,
}: HeatMapColorMapperProps) => {
    const [value, setValue] = useState<ColorSwatchOption | undefined>(
        _value ?? swatchOptions[0]
    );

    useEffect(() => {
        _value && setValue(_value);
    }, [_value]);

    return (
        <Stack gap={2} width="100%">
            <Typography variant="body3" fontWeight="semibold">
                Heat map density
            </Typography>
            <Grid container gap={2} alignItems="center">
                <Grid item xs={2}>
                    <Typography
                        variant="body3"
                        sx={{
                            color: (theme) =>
                                theme.palette.disabled.onContainer,
                        }}
                    >
                        Fill
                    </Typography>
                </Grid>
                <Grid item xs>
                    <ColorSwatchSelector
                        variant="gradient"
                        value={value}
                        onChange={(value) => {
                            setValue(value);
                            onChange?.(value);
                        }}
                        options={swatchOptions}
                    />
                </Grid>
            </Grid>
            <LinearGradientPicker
                gradient={value?.swatch.map((c) => ({
                    offset: c.range / 1000,
                    color: c.color,
                }))}
                onGradientChange={(gradient) => {
                    const newValue: ColorSwatchOption = {
                        id: "custom",
                        swatch: gradient.map((g) => ({
                            range: g.offset * 1000,
                            color: g.color,
                        })),
                    };
                    setValue(newValue);
                    onChange?.(newValue);
                }}
            />
        </Stack>
    );
};
