import {
    Box,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    OverflowingTypography,
    Stack,
    Tooltip,
} from "@biggeo/bg-ui/lab";
import {
    Circle,
    CloseOutline,
    EditOutline,
    InfoOutline,
    MoreVertOutline,
    TableRowsOutline,
    VisibilityOffOutline,
    VisibilityOutline,
} from "@biggeo/bg-ui/lab/icons";
import isEqual from "lodash/isEqual";
import React from "react";
import { MapShapeColorType } from "../../map/views/MapShapeLayerStyles";
import { convertToHex8String } from "../../utils/color";

export type FilterCriteriaChipProps = {
    id: string;
    label: string;
    description?: string;
    color: MapShapeColorType;
    borderColor: MapShapeColorType;
    gradient?: string;

    visible?: boolean;
    selected?: boolean;
    disabled?: boolean;

    onVisibilityClick: (id: string) => void;
    onDeleteClick: (id: string) => void;
    onEditClick: (id: string) => void;
    onViewTable: (id: string) => void;
};

export const FilterCriteriaChip = ({
    id,
    label,
    description,
    color,
    borderColor,
    gradient,

    visible = true,
    selected = false,
    disabled = false,

    onVisibilityClick,
    onDeleteClick,
    onEditClick,
    onViewTable,
}: FilterCriteriaChipProps) => {
    const isDashed =
        (isEqual(visible, false) &&
            isEqual(selected, false) &&
            isEqual(disabled, false)) ||
        (isEqual(visible, false) &&
            isEqual(selected, false) &&
            isEqual(disabled, true));

    const menu: {
        icon: React.JSX.Element;
        label: string;
        onClick: () => void;
    }[] = [
        {
            icon: <EditOutline size="xs" />,
            label: "Edit",
            onClick: () => onEditClick(id),
        },
        {
            icon: <TableRowsOutline size="xs" />,
            label: "View table",
            onClick: () => onViewTable(id),
        },
    ];

    return (
        <Box
            sx={{
                borderRadius: (theme) => theme.radius.full,
                width: (theme) => theme.spacing(62.5),
                border: 1,
                borderStyle: isDashed ? "dashed" : undefined,
                borderColor: (theme) => theme.palette.outline,
                backgroundColor:
                    isEqual(visible, true) && isEqual(selected, true)
                        ? (theme) => theme.palette.surface.container
                        : undefined,
                overflow: "hidden",
            }}
        >
            <Box
                sx={{
                    background: gradient
                        ? `${gradient}`
                        : `linear-gradient(90deg, #EBEFFE 0%, ${color.color} 100%)`,
                    height: 1,
                    borderTopLeftRadius: (theme) => theme.radius.full,
                    borderTopRightRadius: (theme) => theme.radius.full,
                }}
            />
            <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                gap={1}
                aria-disabled={disabled}
                sx={{
                    paddingX: 2,
                    paddingY: 1,
                }}
            >
                <Stack gap={1} flexDirection="row" alignItems="center">
                    <Tooltip title={"Delete filter criteria"}>
                        <IconButton
                            variant="minimal"
                            onClick={() => onDeleteClick(id)}
                            disabled={disabled}
                        >
                            <CloseOutline size="xs" />
                        </IconButton>
                    </Tooltip>
                    <IconButton
                        variant="minimal"
                        onClick={() => onVisibilityClick(id)}
                        disabled={disabled}
                    >
                        {visible ? (
                            <VisibilityOutline size="xs" />
                        ) : (
                            <VisibilityOffOutline size="xs" />
                        )}
                    </IconButton>
                    <Divider
                        orientation="vertical"
                        color={200}
                        sx={{
                            height: (theme) => theme.spacing(4),
                        }}
                    />
                </Stack>
                <Stack
                    gap={1}
                    flexDirection="row"
                    alignItems="center"
                    sx={{
                        ...(disabled && {
                            opacity: 0.5,
                            pointerEvents: "none",
                        }),
                        paddingRight: description ? undefined : 5,
                    }}
                >
                    <Circle
                        size="xxs"
                        sx={{
                            color: convertToHex8String(
                                color.color,
                                color.opacity
                            ),
                            backgroundColor: convertToHex8String(
                                color.color,
                                color.opacity
                            ),
                            borderRadius: (theme) => theme.radius.full,
                            border: 1,
                            borderColor: convertToHex8String(
                                borderColor.color,
                                borderColor.opacity
                            ),
                        }}
                    />
                    <OverflowingTypography
                        variant="body3"
                        fontWeight="semibold"
                        sx={{
                            width: (theme) => theme.spacing(31.25),
                        }}
                    >
                        {label}
                    </OverflowingTypography>
                    {description && (
                        <Tooltip title={description}>
                            <IconButton variant="minimal" disabled={disabled}>
                                <InfoOutline size="xs" />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
                <Stack flexDirection="row">
                    <Menu
                        placement="bottom-end"
                        content={
                            <Stack gap={1}>
                                {menu.map((item) => (
                                    <MenuItem
                                        key={item.label}
                                        label={item.label}
                                        density="dense"
                                        startNode={item.icon}
                                        onClick={() => item.onClick()}
                                    />
                                ))}
                            </Stack>
                        }
                    >
                        <IconButton variant="minimal" disabled={disabled}>
                            <MoreVertOutline size="xs" />
                        </IconButton>
                    </Menu>
                </Stack>
            </Stack>
        </Box>
    );
};
