import {
    DataSource,
    InputSwitchCompute,
    MapTemplateDataset,
    MapTemplateDatasetExtended,
    UpdateMapTemplateDataset,
    useSwitchComputeMutation,
    useUpdateMapTemplateDatasetMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { GridCellParams, WithLoading } from "@biggeo/bg-ui";
import {
    Box,
    Button,
    CircularLoading,
    ColFilterType,
    Grid,
    GridColDef,
    IFilterSearchPaginate,
    IconAvatar,
    IconButton,
    ProgressBar,
    Stack,
    Switch,
    Tooltip,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    BigGeoLogo,
    Info,
    PlayArrowOutline,
    StopCircle,
    SyncOutline,
} from "@biggeo/bg-ui/lab/icons";
import clsx from "clsx";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import { useState } from "react";
import { useNavigate } from "react-router";
import { DataGridContainer } from "../../../components/DataGrid/DataGridContainer";
import { MemoizedField } from "../../../components/MemoizedField";
import { Routes } from "../../../navigation/redux/model";
import { formatNumberWithCommas } from "../../../utils/utils";
import { collectionStorageDirectory } from "../../../utils/variables";

const tooltips: {
    computeUsage: string;
    mapUse: string;
    enable: string;
    compute: string;
    defaultColor: string;
} = {
    computeUsage: "The percentage this dataset uses in your available compute",
    mapUse: "Make dataset accessible in the maps interface datasets panel",
    enable: "This determines whether or not this map template has access to the dataset",
    compute:
        "This determines whether or not your data is actively using your available compute",
    defaultColor:
        "The default color for this dataset starts with on blank templates",
};

interface IMapDatasetsConfigGrid {
    readonly mapTemplateDatasets: MapTemplateDatasetExtended[];
    readonly filterSearchPaginateProps: IFilterSearchPaginate;
    readonly route?: "data" | "configuration";
    readonly setMapTemplateDataset: (i: {
        mapTemplateDatasetId: number;
        mapTemplateDataset?: MapTemplateDataset;
        dataSource?: DataSource;
    }) => void;
    readonly isRunningOnSF: boolean;
}

const MapDatasetsConfigGrid = ({
    mapTemplateDatasets,
    filterSearchPaginateProps,
    route,
    setMapTemplateDataset,
    isRunningOnSF,
}: IMapDatasetsConfigGrid) => {
    const [currentRowId, setCurrentRowId] = useState<number | undefined>(
        undefined
    );

    const {
        executeMutation: updateMapTemplateDataset,
        mutationReturn: [_d, { loading }],
    } = useUpdateMapTemplateDatasetMutation();

    const {
        executeMutation: switchCompute,
        mutationReturn: [_c, { loading: computeLoading }],
    } = useSwitchComputeMutation();

    const update = (input: UpdateMapTemplateDataset) => {
        setCurrentRowId(input.id);

        updateMapTemplateDataset({
            variables: {
                input,
            },
            onCompleted: (data) => {
                setCurrentRowId(undefined);
                setMapTemplateDataset({
                    mapTemplateDatasetId: data.updateMapTemplateDataset.id,
                    mapTemplateDataset: data.updateMapTemplateDataset,
                });
            },
        });
    };

    const handleCompute = (
        mapTemplateDatasetId: number,
        input: InputSwitchCompute
    ) => {
        setCurrentRowId(mapTemplateDatasetId);

        switchCompute({
            variables: {
                input,
            },
            onCompleted: (data) => {
                setCurrentRowId(undefined);
                setMapTemplateDataset({
                    mapTemplateDatasetId,
                    dataSource: data.switchCompute,
                });
            },
        });
    };

    const columns: GridColDef<MapTemplateDatasetExtended>[] = [
        {
            field: "label",
            headerName: "Name",
            flex: 1,
            minWidth: 378,
            sortable: false,
            resizable: false,
            type: ColFilterType.string,
            filterable: true,
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            cellClassName: (params: GridCellParams<any, number>) => {
                if (params.value == null) {
                    return "";
                }

                return clsx("super-app", {
                    negative: params.value < 0,
                    positive: params.value > 0,
                });
            },
            renderCell: (params) => (
                <MemoizedField
                    disable={Boolean(!params.row.dataSource.compute)}
                    title={
                        params.row.dataSource.label ||
                        params.row.dataSource.tableName
                    }
                    subTitle={params.row.dataSource.description || undefined}
                    typographyProps={{
                        truncate: true,
                    }}
                />
            ),
        },
        {
            field: "defaultColor",
            headerName: "Default Color",
            headerAlign: "center",
            flex: 1,
            minWidth: 176,
            sortable: false,
            resizable: false,
            type: ColFilterType.string,
            filterable: true,

            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.colDef.headerName)}
                    </Typography>
                    <Tooltip title={tooltips.defaultColor} sx={{ width: 45 }}>
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: (params) => {
                return (
                    <MemoizedField
                        disable={Boolean(!params.row.dataSource.compute)}
                    >
                        <Box
                            sx={{
                                backgroundColor: isRunningOnSF
                                    ? params.row.mapTemplateDataset.color ||
                                      params.row.dataSource.color ||
                                      "#ffffff"
                                    : params.row.dataSource.color || "#ffffff",
                                width: 6,
                                height: 6,
                            }}
                        />
                    </MemoizedField>
                );
            },
        },
        {
            field: "src",
            headerName: "Src",
            minWidth: 150,
            sortable: false,
            headerAlign: "center",
            resizable: false,
            type: ColFilterType.string,
            renderCell: (params) => (
                <MemoizedField
                    disable={Boolean(!params.row.dataSource.compute)}
                >
                    <IconAvatar color="primary" size="xs">
                        <BigGeoLogo />
                    </IconAvatar>
                </MemoizedField>
            ),
        },
        {
            field: "size",
            headerName: "Size",
            minWidth: 150,
            sortable: false,
            headerAlign: "left",
            type: ColFilterType.number,
            filterable: true,
            renderCell: (params) => (
                <MemoizedField
                    disable={Boolean(!params.row.dataSource.compute)}
                    title={formatNumberWithCommas(params.row.dataSource.size)}
                    sx={{ justifyContent: "flex-start", textAlign: "left" }}
                />
            ),
        },
        {
            field: "compute",
            headerName: "compute",
            minWidth: 110,
            sortable: false,
            type: ColFilterType.boolean,
            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.field)}
                    </Typography>
                    <Tooltip title={tooltips.compute}>
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: (params) => {
                const isOn = isEqual(params.row.dataSource.compute, true);
                const isOff =
                    !params.row.dataSource.compute ||
                    isEqual(params.row.dataSource.compute, false);

                const isLoading = isEqual(
                    currentRowId,
                    params.row.mapTemplateDataset.id
                )
                    ? computeLoading
                    : false;

                return (
                    <MemoizedField
                        disable={
                            Boolean(!params.row.dataSource.compute) || isLoading
                        }
                    >
                        <Grid container alignItems="center" gap={1}>
                            <IconButton
                                rounded
                                variant="tonal"
                                density="dense"
                                disabled={isLoading}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleCompute(
                                        params.row.mapTemplateDataset.id,
                                        {
                                            id: params.row.dataSource.id,
                                            collectionName:
                                                params.row.dataSource
                                                    .collectionName,
                                            compute: !!isOff,
                                            location: `${collectionStorageDirectory}/${
                                                params.row.dataSource
                                                    .collectionName
                                            }.cereal`,
                                        }
                                    );
                                }}
                            >
                                {isLoading && (
                                    <CircularLoading
                                        color="primary"
                                        size="md"
                                    />
                                )}
                                {isOn && !isLoading && (
                                    <StopCircle
                                        color={isOn ? "primary" : "disabled"}
                                    />
                                )}
                                {isOff && !isLoading && (
                                    <PlayArrowOutline
                                        color={isOn ? "primary" : "surface"}
                                    />
                                )}
                            </IconButton>
                            <Typography variant="body3" fontWeight="semibold">
                                {isOff ? "Off" : "On"}
                            </Typography>
                        </Grid>
                    </MemoizedField>
                );
            },
        },
        {
            field: "computeUsage",
            headerName: "Compute Usage",
            minWidth: 160,
            sortable: false,
            headerAlign: "left",
            type: ColFilterType.boolean,
            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.colDef.headerName)}
                    </Typography>
                    <Tooltip title={tooltips.computeUsage} sx={{ width: 45 }}>
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: (params) => {
                return (
                    <MemoizedField
                        disable={Boolean(!params.row.dataSource.compute)}
                    >
                        <Stack sx={{ width: "100%" }} gap={2}>
                            <Typography variant="body4" fontWeight="semibold">
                                {`${params.row.dataSource.progress || 0}%`}
                            </Typography>
                            <ProgressBar
                                value={10}
                                sx={{
                                    height: (theme) => theme.spacing(1.5),
                                    borderRadius: 4,
                                    backgroundColor: (theme) =>
                                        theme.palette.disabled.main,
                                }}
                            />
                        </Stack>
                    </MemoizedField>
                );
            },
        },
        {
            field: "enable",
            sortable: false,
            minWidth: 150,
            headerName: "Enable",
            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.colDef.headerName)}
                    </Typography>
                    <Tooltip title={tooltips.enable}>
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: (params) => {
                return (
                    <MemoizedField
                        disable={Boolean(!params.row.dataSource.compute)}
                    >
                        <WithLoading
                            loading={
                                isEqual(
                                    currentRowId,
                                    params.row.mapTemplateDataset.id
                                )
                                    ? loading
                                    : false
                            }
                        >
                            <Switch
                                size="small"
                                color="surface"
                                switched={params.row.mapTemplateDataset.enable}
                                onSwitchChange={() => {
                                    const enable =
                                        !params.row.mapTemplateDataset.enable;

                                    update(
                                        {
                                            id: params.row.mapTemplateDataset
                                                .id,
                                            enable,
                                            mapUse: isEqual(enable, false)
                                                ? false
                                                : undefined,
                                        }
                                        // params.row.mapTemplateDataset.id
                                    );
                                }}
                            />
                        </WithLoading>
                    </MemoizedField>
                );
            },
        },
        {
            field: "manage",
            sortable: false,
            headerName: "",
            minWidth: 110,
            renderCell: (params) => {
                const refresh = !params.row.dataSource.isConnected;
                const currentUrl = window.location.href;
                const toPage = useNavigate();
                return (
                    <Stack sx={{ width: "100%" }}>
                        {refresh ? (
                            <Button
                                fullWidth
                                variant="outlined"
                                density="dense"
                                endNode={<SyncOutline size="xs" />}
                                onClick={() => {}}
                            >
                                Refresh
                            </Button>
                        ) : (
                            <Button
                                fullWidth
                                variant="outlined"
                                density="dense"
                                onClick={() =>
                                    toPage(
                                        `${Routes.dataManage}/${params.row.dataSource.id}`,
                                        {
                                            state: {
                                                returnUrl: currentUrl,
                                            },
                                        }
                                    )
                                }
                            >
                                Manage
                            </Button>
                        )}
                    </Stack>
                );
            },
        },
        {
            field: "mapUse",
            headerName: "Map use",
            minWidth: 115,
            sortable: false,
            type: ColFilterType.boolean,
            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.colDef.headerName)}
                    </Typography>
                    <Tooltip title={tooltips.mapUse} sx={{ width: 45 }}>
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: (params) => (
                <MemoizedField
                    disable={Boolean(!params.row.dataSource.compute)}
                >
                    <WithLoading
                        loading={
                            isEqual(
                                currentRowId,
                                params.row.mapTemplateDataset.id
                            )
                                ? loading
                                : false
                        }
                    >
                        <Switch
                            size="small"
                            color="surface"
                            switched={params.row.mapTemplateDataset.mapUse}
                            onSwitchChange={() => {
                                setCurrentRowId(
                                    params.row.mapTemplateDataset.id
                                );

                                update({
                                    id: params.row.mapTemplateDataset.id,
                                    mapUse:
                                        !params.row.mapTemplateDataset.mapUse,
                                });
                            }}
                        />
                    </WithLoading>
                </MemoizedField>
            ),
        },
    ];

    return (
        <DataGridContainer
            getRowId={(row) => row.mapTemplateDataset.id}
            pinnedColumns={{ right: ["mapUse", "manage"] }}
            columnVisibilityModel={{
                mapUse: route === "configuration",
                enable: route === "data",
                manage: route === "data",
                compute: route === "data",
            }}
            columns={columns}
            rows={mapTemplateDatasets}
            rowCount={mapTemplateDatasets.length}
            loading={loading}
            title={route === "configuration" ? "Datasets" : "Data source"}
            filterSearchPaginateProps={filterSearchPaginateProps}
            disableColumnResize
        />
    );
};

export default MapDatasetsConfigGrid;
