import {
    DataSource,
    FilterObject,
    MapTemplateDataset,
    MapTemplateDatasetExtended,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Stack } from "@biggeo/bg-ui/lab";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router";
import { ColorSwatchOption } from "../../common/components/ColorSwatchSelector";
import { GenericDraggableItem } from "../../common/components/GenericDraggableItem";
import { getLinearGradient } from "../../common/utils/gradient";
import { DatasetCardMapItem } from "../../components/DatasetCardMapItem";
import { AddDataSetSubMenuLayout } from "../../layouts/AddDatasetSubMenuLayout";
import { LeftContent } from "../../layouts/LeftContentItem";
import {
    HeatMapColorSwatchOption,
    getHeatMapValue,
} from "../../map/mapbox/utils/heatmap";
import { generateColorScale } from "../../map/utils/utils";
import { MapColorSetterType } from "../../map/views/MapColorSetter";
import { Routes } from "../../navigation/redux/model";
import { convertToHex8String } from "../../utils/color";

export const MapLayoutLeftContentContainer = ({
    fetchedDataSource,
    isRunningOnSF,
    updatedMapTemplateDataSetColors,
    currentMapTemplateDataset,
    updatedDataSourceColors,
    currentDataSource,
    setCurrentDataSource,
    updateDataSource,
    heatMapValue,
    onClickHeatMapValue,
    updateDataSourceColor,
    updateMapTemplateDatasetColor,
    fetchedData,
    mapTemplateId,
    multiFilters,
    isStylingDatapoints,
    toggleDatapointsView,
    handleLeftContentClick,
    selectedDataset,
    setSelectedDataset,
    addRemoveDataset,
}: {
    readonly fetchedDataSource: DataSource[];
    readonly isRunningOnSF: boolean;
    readonly updatedMapTemplateDataSetColors: Pick<
        MapTemplateDataset,
        "color" | "id"
    >[];
    readonly updatedDataSourceColors: Pick<DataSource, "color" | "id">[];
    readonly currentMapTemplateDataset: Partial<MapTemplateDataset>;
    readonly currentDataSource: Partial<DataSource>;
    readonly setCurrentDataSource: (d: Partial<DataSource>) => void;
    readonly updateDataSource: (p: Partial<DataSource>) => void;
    readonly heatMapValue?: HeatMapColorSwatchOption[];
    readonly onClickHeatMapValue: (
        datasetId?: string
    ) => (value: ColorSwatchOption) => void;
    readonly updateDataSourceColor: (value: MapColorSetterType) => void;
    readonly updateMapTemplateDatasetColor: (value: MapColorSetterType) => void;
    fetchedData: MapTemplateDatasetExtended[];
    readonly mapTemplateId: number;
    readonly multiFilters: FilterObject[];
    readonly isStylingDatapoints: boolean;
    readonly toggleDatapointsView: (value: boolean) => void;
    readonly handleLeftContentClick: (
        value: MapTemplateDatasetExtended
    ) => void;
    readonly selectedDataset: readonly string[];
    readonly setSelectedDataset: (datasets: readonly string[]) => void;
    readonly addRemoveDataset: (v: string) => void;
    readonly idArray: string[];
}) => {
    const toPage = useNavigate();

    const currentColor = () => {
        return pipe(
            isRunningOnSF,
            O.fromPredicate((x) => x),
            O.fold(
                () =>
                    pipe(
                        updatedDataSourceColors.find(
                            (d) => d.id === currentDataSource?.id
                        ),
                        O.fromNullable,
                        O.chain((i) => O.fromNullable(i.color)),
                        O.getOrElse(() => "#ffffff")
                    ),
                () =>
                    pipe(
                        updatedMapTemplateDataSetColors.find(
                            (d) => d.id === currentMapTemplateDataset?.id
                        ),
                        O.fromNullable,
                        O.chain((i) => O.fromNullable(i.color)),
                        O.getOrElse(() => "#ffffff")
                    )
            )
        );
    };
    const currentHexColor = () =>
        convertToHex8String(currentColor(), currentDataSource?.opacity || 0.9);

    const manageCompute = () =>
        toPage(`${Routes.data}/map-template/${mapTemplateId}`);

    return (
        <Stack
            gap={3}
            width="100%"
            sx={{
                padding: 3,
            }}
        >
            <AddDataSetSubMenuLayout existingDatasets={fetchedDataSource} />

            {isStylingDatapoints ? (
                <DatasetCardMapItem
                    currentDataSource={currentDataSource}
                    toggleDatapointsView={toggleDatapointsView}
                    currentHexColor={currentHexColor}
                    setCurrentDataSource={setCurrentDataSource}
                    updateDataSource={updateDataSource}
                    currentColor={currentColor}
                    heatMapValue={heatMapValue}
                    onClickHeatMapValue={onClickHeatMapValue}
                    isRunningOnSF={isRunningOnSF}
                    updateDataSourceColor={updateDataSourceColor}
                    updateMapTemplateDatasetColor={
                        updateMapTemplateDatasetColor
                    }
                />
            ) : (
                <Stack gap={2}>
                    <DndProvider backend={HTML5Backend}>
                        {pipe(
                            fetchedData,
                            toNonReadonlyArray,
                            A.mapWithIndex((idx, d) => {
                                const heatmapColorSet = generateColorScale(
                                    d.dataSource.color || "#ffffff",
                                    1.5,
                                    3
                                );

                                const heatmapGradient = `linear-gradient(to right, rgba(255,255,255,0) 0%, ${heatmapColorSet[0]} 10%, ${heatmapColorSet[1]} 50%, ${heatmapColorSet[2]} 100%  )`;
                                const heatmap = getHeatMapValue(
                                    heatMapValue,
                                    d.dataSource.id
                                );

                                return (
                                    <GenericDraggableItem<DataSource>
                                        key={d.mapTemplateDataset.id}
                                        type="Map Template Datasources"
                                        index={idx}
                                        state={fetchedData.map(
                                            (i) => i.dataSource
                                        )}
                                        onHoverAndDrop={(state) => {
                                            const orderedStateIds = state.map(
                                                (s) => s.id
                                            );
                                            state.sort(
                                                (a, b) =>
                                                    orderedStateIds.indexOf(
                                                        a.id
                                                    ) -
                                                    orderedStateIds.indexOf(
                                                        b.id
                                                    )
                                            );
                                        }}
                                    >
                                        <LeftContent
                                            key={d.dataSource.id}
                                            gradient={
                                                heatmap
                                                    ? getLinearGradient(heatmap)
                                                    : heatmapGradient
                                            }
                                            manageCompute={manageCompute}
                                            onItemClick={() => {
                                                let color = "success";
                                                const mapTemplateColor = pipe(
                                                    updatedMapTemplateDataSetColors.find(
                                                        (i) =>
                                                            i.id ===
                                                            d.mapTemplateDataset
                                                                .id
                                                    ),
                                                    (i) => {
                                                        return (
                                                            i?.color ||
                                                            d.mapTemplateDataset
                                                                .color ||
                                                            d.dataSource.color
                                                        );
                                                    }
                                                );
                                                const dataSourceColor = pipe(
                                                    updatedDataSourceColors.find(
                                                        (i) =>
                                                            i.id ===
                                                            d.dataSource.id
                                                    ),
                                                    (i) => {
                                                        return (
                                                            i?.color ||
                                                            d.dataSource.color
                                                        );
                                                    }
                                                );

                                                if (isRunningOnSF) {
                                                    if (mapTemplateColor) {
                                                        color =
                                                            mapTemplateColor;
                                                    }
                                                } else {
                                                    if (dataSourceColor) {
                                                        color = dataSourceColor;
                                                    }
                                                }

                                                if (isRunningOnSF) {
                                                    handleLeftContentClick({
                                                        ...d,
                                                        mapTemplateDataset: {
                                                            ...d.mapTemplateDataset,
                                                            color: color,
                                                        },
                                                    });
                                                } else {
                                                    handleLeftContentClick({
                                                        ...d,
                                                        dataSource: {
                                                            ...d.dataSource,
                                                            color: color,
                                                        },
                                                    });
                                                }
                                            }}
                                            item={{
                                                ...d.dataSource,
                                                color: pipe(
                                                    isRunningOnSF,
                                                    O.fromPredicate((x) => x),
                                                    O.fold(
                                                        () => {
                                                            const datasetColors =
                                                                updatedDataSourceColors.find(
                                                                    (i) =>
                                                                        i.id ===
                                                                        d
                                                                            .dataSource
                                                                            .id
                                                                );
                                                            return (
                                                                datasetColors?.color ||
                                                                d.dataSource
                                                                    .color
                                                            );
                                                        },
                                                        () => {
                                                            const templateColor =
                                                                updatedMapTemplateDataSetColors.find(
                                                                    (i) =>
                                                                        i.id ===
                                                                        d
                                                                            .mapTemplateDataset
                                                                            .id
                                                                );
                                                            return (
                                                                templateColor?.color ||
                                                                d
                                                                    .mapTemplateDataset
                                                                    .color
                                                            );
                                                        }
                                                    )
                                                ),
                                            }}
                                            multiFilters={multiFilters}
                                            addRemoveDataset={addRemoveDataset}
                                            index={idx}
                                            selected={
                                                !pipe(
                                                    multiFilters,
                                                    A.filter(
                                                        (filter) =>
                                                            filter.databaseId ===
                                                            d.dataSource.id
                                                    ),
                                                    isEmpty
                                                )
                                            }
                                            selectedDataset={selectedDataset}
                                            setSelectedDataset={
                                                setSelectedDataset
                                            }
                                            currentMapTemplateDataset={
                                                d.mapTemplateDataset
                                            }
                                            currentHeatMapValue={heatmap}
                                        />
                                    </GenericDraggableItem>
                                );
                            })
                        )}
                    </DndProvider>
                </Stack>
            )}
        </Stack>
    );
};
