import { reduceNumber } from "@biggeo/bg-common";
import {
    InputUpdateSavedView,
    SavedView,
    useDeleteSavedViewMutation,
    useFetchSavedViewByIdQuery,
    useUpdateSavedViewMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { useNavigate, useParams } from "react-router";
import { ErrorPage } from "../../common/components/ErrorPage";

import { LoadingBar, Stack } from "@biggeo/bg-ui/lab";
import { useDispatch } from "react-redux";

import { Routes } from "../../navigation/redux/model.ts";
import { toasterActions } from "../../toaster/containers/redux/model.ts";
import { CallBacksType } from "../../utils/types";
import ManageSavedViewFormView from "../views/ManageSavedViewFormView.tsx";

const ManageSavedViewPage = () => {
    const { id } = useParams();
    const savedViewId = reduceNumber(id);
    if (!savedViewId) return <ErrorPage />;

    const {
        queryReturn: { data, loading, refetch },
    } = useFetchSavedViewByIdQuery({ variables: { id: savedViewId } });

    const {
        executeMutation: updateSavedView,
        mutationReturn: [_, { loading: updateLoading }],
    } = useUpdateSavedViewMutation();
    const {
        executeMutation: deleteSavedView,
        mutationReturn: [_d, { loading: deleteLoading }],
    } = useDeleteSavedViewMutation();

    const dispatch = useDispatch();
    const toPage = useNavigate();

    const navigate = () => {
        toPage(-1);
    };

    const save = (
        input: InputUpdateSavedView,
        callbacks?: CallBacksType<SavedView>
    ) => {
        updateSavedView({
            variables: {
                input: {
                    id: input.id,
                    name: input.name,
                    description: input.description || "",
                },
            },
            onCompleted: (data) => {
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        title: "Saved view updated successfully",
                        autoHideDuration: 5000,
                    })
                );
                callbacks?.onSuccess?.(data.updateSavedView);
                toPage(
                    `${Routes.mapView}/${data.updateSavedView.id}/saved-views`
                );
            },
        });
    };

    const onRemove = (id: number, callbacks?: CallBacksType<SavedView>) => {
        deleteSavedView({
            variables: { id },
            onCompleted: (data) => {
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        title: "Saved view deleted successfully",
                        autoHideDuration: 5000,
                    })
                );
                callbacks?.onSuccess?.(data.deleteSavedView);
                navigate();
            },
        });
    };

    return (
        <Stack width={"100%"}>
            {loading ? (
                <LoadingBar />
            ) : data?.fetchSavedViewById ? (
                <ManageSavedViewFormView
                    savedView={data.fetchSavedViewById}
                    navigate={navigate}
                    save={save}
                    deleteLoading={deleteLoading}
                    updateLoading={updateLoading}
                    onRemove={onRemove}
                />
            ) : (
                <ErrorPage />
            )}
        </Stack>
    );
};

export default ManageSavedViewPage;
