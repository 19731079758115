import {
    type DataSource,
    DatabaseType,
    type FilterObject,
    MapTemplateDataset,
    MapTemplateDatasetExtended,
    SubscriptionResponse,
    useFetchMapTemplateDatasetsExtendedQuery,
    useUpdateMapTemplateDatasetMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { EmptyState } from "@biggeo/bg-ui";
import {
    Accordion,
    Chip,
    Divider,
    Grid,
    LoadingBar,
    Severity,
    Stack,
} from "@biggeo/bg-ui/lab";
import { AddOutline, TableRowsOutline } from "@biggeo/bg-ui/lab/icons";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import { ArrowBackOutline, Circle } from "@vividtheory/minerva/lab/icons";
import { match } from "@vividtheory/remotedata";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ColorSwatchOption } from "../common/components/ColorSwatchSelector.tsx";
import { HeatMapClusterColorMapper } from "../common/components/HeatMapClusterColorMapper.tsx";
import { isAppRunningOnSF } from "../common/redux/hooks.ts";
import { getLinearGradient } from "../common/utils/gradient.ts";
import { DatasetPointShape } from "../components/DatapointShape/types.ts";
import { MapLayoutLeftContentContainer } from "../data/containers/MapLayoutLeftContentContainer.tsx";
import {
    useCurrentDataSource,
    useCurrentMapTemplateDataSet,
    useUpdatedDataSourceColors,
    useUpdatedMapTemplateDataSetColors,
} from "../database-meta-data/redux/hooks";
import { databaseMetaDataActions } from "../database-meta-data/redux/model";
import { MapType } from "../map/mapbox/context/map.ts";
import {
    HeatMapColorSwatchOption,
    getHeatMapValue,
} from "../map/mapbox/utils/heatmap.ts";
import DatasetCustomMarker from "../map/mapbox/views/DatasetCustomMarker.tsx";
import { getLayerHeatmapStyle } from "../map/utils/style-utils.ts";
import { generateColorScale, moveItemToIndex } from "../map/utils/utils.ts";
import MapColorSetter, {} from "../map/views/MapColorSetter.tsx";
import { MapColorSetterType } from "../map/views/MapColorSetter.tsx";
import { Routes } from "../navigation/redux/model.ts";
import { toasterActions } from "../toaster/containers/redux/model.ts";

type MapLayoutLeftContentProps = React.PropsWithChildren<{
    readonly multiFilters: FilterObject[];
    readonly addRemoveDataset: (v: string) => void;
    readonly selectedDataset: readonly string[];
    readonly setSelectedDataset: (datasets: readonly string[]) => void;
    readonly mapTemplateId: number;
    readonly datasetShape?: readonly DatasetPointShape[];
    readonly setDatasetShape?: (datasetShape: DatasetPointShape) => void;
    readonly recentResponse?: SubscriptionResponse;
    readonly map: MapType;
    readonly heatMapValue?: HeatMapColorSwatchOption[];
    readonly onClickHeatMapValue: (
        datasetId?: string
    ) => (value: ColorSwatchOption) => void;
}>;

export const MapLayoutLeftContent = ({
    multiFilters,
    addRemoveDataset,
    selectedDataset,
    setSelectedDataset,
    mapTemplateId,
    map,
    heatMapValue,
    onClickHeatMapValue,
}: MapLayoutLeftContentProps) => {
    const toPage = useNavigate();
    const dispatch = useDispatch();
    const isRunningOnSF = isAppRunningOnSF();
    const currentDataSource = useCurrentDataSource();
    const currentMapTemplateDataset = useCurrentMapTemplateDataSet();

    const { remote } = useFetchMapTemplateDatasetsExtendedQuery({
        variables: {
            input: {
                fkMapTemplateId: mapTemplateId,
                mapUse: true,
            },
        },
    });

    const { executeMutation: updateMapTemplateDataset } =
        useUpdateMapTemplateDatasetMutation();

    const setCurrentDataSource = (d: Partial<DataSource>) =>
        dispatch(databaseMetaDataActions.setCurrentDataSource(d));

    const setCurrentMapTemplateDataset = (d: Partial<MapTemplateDataset>) =>
        dispatch(databaseMetaDataActions.setCurrentMapTemplateDataset(d));

    const updatedDataSourceColors = useUpdatedDataSourceColors();
    const updatedMapTemplateDataSetColors =
        useUpdatedMapTemplateDataSetColors();

    const [isStylingDatapoints, setIsStylingDatapoints] =
        useState<boolean>(false);
    const toggleDatapointsView = (value: boolean): void => {
        setIsStylingDatapoints(value);
    };

    const handleLeftContentClick = (data: MapTemplateDatasetExtended) => {
        if (data.dataSource) {
            setCurrentDataSource({
                id: data.dataSource.id,
                label: data.dataSource.label,
                color: data.dataSource.color,
                type: data.dataSource.type,
                icon: data.dataSource.icon,
                opacity: data.dataSource.opacity,
                collectionName: data.dataSource.collectionName,
                tableName: data.dataSource.tableName,
                size: data.dataSource.size,
                isConnected: data.dataSource.isConnected,
                progress: data.dataSource.progress,
            });
        }
        if (data.mapTemplateDataset) {
            setCurrentMapTemplateDataset({
                id: data.mapTemplateDataset.id,
                color: data.mapTemplateDataset.color,
                fkDataSourceId: data.mapTemplateDataset.fkDataSourceId,
                fkMapTemplateId: data.mapTemplateDataset.fkMapTemplateId,
                mapUse: data.mapTemplateDataset.mapUse,
                enable: data.mapTemplateDataset.enable,
            });
        }
        toggleDatapointsView(true);
    };

    const updateDataSource = (updated: Partial<DataSource>) => {
        dispatch(databaseMetaDataActions.updateDataSource(updated));
    };

    const toDataManagementPage = () => {
        toPage(`${Routes.data}/map-template/${mapTemplateId}`);
    };

    const updateLocalDataSetColor = ({
        color,
        opacity,
    }: MapColorSetterType) => {
        if (isRunningOnSF) {
            if (currentMapTemplateDataset) {
                setCurrentMapTemplateDataset({
                    ...currentMapTemplateDataset,
                    color: color,
                });
            }
        } else {
            if (currentDataSource) {
                setCurrentDataSource({
                    ...currentDataSource,
                    color: color,
                    opacity: opacity || 0.9,
                });
            }
        }

        updateDataSource({
            ...currentDataSource,
            color,
            opacity,
        });
    };

    const updateDataSourceColor = debounce(
        ({ color, opacity }: MapColorSetterType) => {
            updateLocalDataSetColor({ color, opacity });
        },
        300
    );

    const updateMapTemplateDatasetColor = debounce(
        ({ color, opacity }: MapColorSetterType) => {
            const id = currentMapTemplateDataset?.id;

            if (isRunningOnSF && id) {
                updateLocalDataSetColor({ color, opacity });

                updateMapTemplateDataset({
                    variables: {
                        input: {
                            id: id,
                            color: color,
                        },
                    },
                    onCompleted: () => {
                        dispatch(
                            toasterActions.openToast({
                                open: true,
                                title: "Succesfully saved the color",
                                autoHideDuration: 5000,
                                severity: Severity.success,
                            })
                        );
                    },
                    onError: () => {
                        dispatch(
                            toasterActions.openToast({
                                open: true,
                                title: "Failed to save the color",
                                autoHideDuration: 5000,
                                severity: Severity.error,
                            })
                        );
                    },
                });
            } else {
                updateLocalDataSetColor({ color, opacity });
            }
        },
        300
    );

    return match(remote, {
        _: () => <LoadingBar />,
        Success: (d) => {
            const mappedFetchedData = pipe(
                d.fetchMapTemplateDatasetsExtended,
                A.map((m) => m)
            );

            const idArray = mappedFetchedData.map((d) => d.dataSource.id);
            const fetchedDataSource = mappedFetchedData.map(
                (d) => d.dataSource
            );
            return pipe(
                mappedFetchedData,
                O.fromPredicate((d) => !isEmpty(d)),
                O.fold(
                    () => (
                        <EmptyState
                            icon={<TableRowsOutline />}
                            title={"No Datasets Enabled"}
                            subtitle={"Enable datasets to use on this map"}
                            border={false}
                            buttonText="Add Datasets"
                            onClick={toDataManagementPage}
                        />
                    ),
                    (fetchedData) => {
                        return (
                            <MapLayoutLeftContentContainer
                                fetchedDataSource={fetchedDataSource}
                                isRunningOnSF={isRunningOnSF}
                                updatedMapTemplateDataSetColors={
                                    updatedMapTemplateDataSetColors
                                }
                                updatedDataSourceColors={
                                    updatedDataSourceColors
                                }
                                currentMapTemplateDataset={
                                    currentMapTemplateDataset
                                }
                                currentDataSource={currentDataSource}
                                setCurrentDataSource={setCurrentDataSource}
                                updateDataSource={updateDataSource}
                                onClickHeatMapValue={onClickHeatMapValue}
                                fetchedData={fetchedData}
                                multiFilters={multiFilters}
                                mapTemplateId={mapTemplateId}
                                updateMapTemplateDatasetColor={
                                    updateMapTemplateDatasetColor
                                }
                                updateDataSourceColor={updateDataSourceColor}
                                isStylingDatapoints={isStylingDatapoints}
                                toggleDatapointsView={toggleDatapointsView}
                                handleLeftContentClick={handleLeftContentClick}
                                selectedDataset={selectedDataset}
                                setSelectedDataset={setSelectedDataset}
                                addRemoveDataset={addRemoveDataset}
                                idArray={idArray}
                                heatMapValue={heatMapValue}
                            />
                        );
                    }
                )
            );
        },
    });
};
