import {
    DataSource,
    DataSourcesExtended,
} from "@biggeo/bg-server-lib/datascape-ai";
import { GridColDef } from "@biggeo/bg-ui";
import {
    AvatarGroup,
    Button,
    ColFilterType,
    EmptyScreen,
    Grid,
    IFilterSearchPaginate,
    IconAvatar,
    IconButton,
    ProgressBar,
    Stack,
    ThumbnailAvatar,
    Tooltip,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    ActionKeyOutline,
    BigGeoLogo,
    Info,
    KeyOutline,
    PlayCircleOutline,
    StopCircle,
    SyncOutline,
} from "@biggeo/bg-ui/lab/icons";
import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";
import { DataGridContainer } from "../../components/DataGrid/DataGridContainer.tsx";
import { MemoizedField } from "../../components/MemoizedField/MemoizedField.tsx";
import { Routes } from "../../navigation/redux/model.ts";
import { formatNumberWithCommas } from "../../utils/utils.ts";
import { DataManagementTab } from "./DataManagementContainer.tsx";

export interface IAvailableDatasetsContainer {
    readonly dataSourcesExtended: DataSourcesExtended;
    readonly tab: DataManagementTab;
    readonly toPage: (path: string) => void;
    readonly isRunningOnSF: boolean;
    readonly loading: boolean;
    readonly filterSearchPaginateProps: IFilterSearchPaginate;
    readonly isSNP: boolean;
}

export const AvailableDatasetsContainer = ({
    dataSourcesExtended,
    tab,
    loading,
    filterSearchPaginateProps,
    toPage,
}: IAvailableDatasetsContainer) => {
    const tooltips: {
        compute: string;
        computeUsage: string;
        usedIn: string;
        mapView: string;
        enable: string;
        mapUse: string;
    } = {
        compute:
            "This determines whether or not your data is actively using your available compute",
        computeUsage:
            "The percentage this dataset uses in your available compute",
        usedIn: "The map templates that currently have this dataset enabled",
        mapView: "Quick navigate to the map with this dataset on",
        enable: "This determines wether or the dataset is available in your map configuration",
        mapUse: "This determines whether or not the dataset is available in your map configuration",
    };

    const columns: GridColDef<DataSource>[] = [
        {
            field: "id",
            headerName: "ID",
            filterable: false,
            sortable: false,
        },
        {
            field: "label",
            headerName: "Name",
            flex: 1,
            minWidth: 378,
            sortable: false,
            type: ColFilterType.string,
            filterable: true,
            renderCell: (params) => (
                <MemoizedField
                    disable={Boolean(!params.row.compute)}
                    title={params.row.label || params.row.tableName}
                    subTitle={params.row.description || undefined}
                />
            ),
        },
        {
            field: "src",
            headerName: "Src",
            minWidth: 64,
            sortable: false,
            headerAlign: "center",
            type: ColFilterType.string,
            renderCell: (params) => (
                <MemoizedField disable={Boolean(!params.row.compute)}>
                    <IconAvatar color="primary" size="xs">
                        <BigGeoLogo />
                    </IconAvatar>
                </MemoizedField>
            ),
        },
        {
            field: "size",
            headerName: "Size",
            minWidth: 84,
            sortable: false,
            headerAlign: "left",
            type: ColFilterType.number,
            filterable: true,
            align: "left",
            renderCell: (params) => (
                <MemoizedField
                    disable={Boolean(!params.row.compute)}
                    title={formatNumberWithCommas(params.row.size)}
                />
            ),
        },
        {
            field: "usedIn",
            headerName: "Used In",
            minWidth: 96,
            sortable: false,
            type: ColFilterType.boolean,
            headerAlign: "center",
            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.field)}
                    </Typography>

                    <Tooltip title={tooltips.usedIn} sx={{ maxWidth: 45 }}>
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: (params) => {
                return (
                    <MemoizedField disable={Boolean(!params.row.compute)}>
                        <AvatarGroup size="xs" max={2}>
                            <ThumbnailAvatar src={""} color="surface" />
                            <ThumbnailAvatar src={""} color="surface" />
                            <ThumbnailAvatar src={""} color="surface" />
                            <ThumbnailAvatar src={""} color="surface" />
                        </AvatarGroup>
                    </MemoizedField>
                );
            },
        },
        {
            field: "compute",
            headerName: "Compute",
            minWidth: 110,
            filterable: true,
            sortable: false,
            type: ColFilterType.boolean,
            headerAlign: "center",
            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.field)}
                    </Typography>
                    <Tooltip title={tooltips.compute} sx={{ maxWidth: 52 }}>
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: (params) => {
                const isOnDisabled = false;
                const isOffDisabled = false;

                return (
                    <MemoizedField disable={Boolean(!params.row.compute)}>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                            gap={1}
                        >
                            {params.row.compute ? (
                                <>
                                    <IconButton
                                        variant="tonal"
                                        density="dense"
                                        disabled={isOnDisabled}
                                        onClick={(_e) => {}}
                                        sx={{
                                            borderRadius: (theme) =>
                                                theme.radius.full,
                                        }}
                                    >
                                        <StopCircle
                                            color={
                                                isOnDisabled
                                                    ? "disabled"
                                                    : "primary"
                                            }
                                        />
                                    </IconButton>
                                    <Typography
                                        variant="body3"
                                        fontWeight="semibold"
                                    >
                                        On
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <IconButton
                                        variant="tonal"
                                        density="dense"
                                        disabled={isOffDisabled}
                                        onClick={(_e) => {}}
                                        sx={{
                                            borderRadius: (theme) =>
                                                theme.radius.full,
                                        }}
                                    >
                                        <PlayCircleOutline />
                                    </IconButton>
                                    <Typography
                                        variant="body3"
                                        fontWeight="semibold"
                                    >
                                        Off
                                    </Typography>
                                </>
                            )}
                        </Grid>
                    </MemoizedField>
                );
            },
        },
        {
            field: "computeUsage",
            headerName: "Compute Usage",
            minWidth: 160,
            sortable: false,
            headerAlign: "left",
            type: ColFilterType.boolean,
            align: "left",
            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.colDef.headerName)}
                    </Typography>
                    <Tooltip
                        title={tooltips.computeUsage}
                        sx={{ maxWidth: 45 }}
                    >
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: (params) => {
                return (
                    <MemoizedField disable={Boolean(!params.row.compute)}>
                        <Stack sx={{ width: "100%" }} gap={2}>
                            <Typography variant="body4" fontWeight="semibold">
                                {`${params.row.progress || 0}%`}
                            </Typography>

                            <ProgressBar
                                value={10}
                                sx={{
                                    height: (theme) => theme.spacing(1.5),
                                    borderRadius: 4,
                                    backgroundColor: (theme) =>
                                        theme.palette.disabled.main,
                                }}
                            />
                        </Stack>
                    </MemoizedField>
                );
            },
        },
        {
            field: "manage",
            sortable: false,
            headerName: "",
            minWidth: 110,
            renderCell: (params) => {
                // TODO update with the right data
                const isNeedRefresh = !params.row.isConnected;

                return (
                    <Stack sx={{ width: "100%" }}>
                        {isNeedRefresh ? (
                            <Button
                                fullWidth
                                variant="outlined"
                                density="dense"
                                endNode={<SyncOutline size="xs" />}
                                onClick={() => {
                                    // TODO
                                }}
                            >
                                Refresh
                            </Button>
                        ) : (
                            <Button
                                fullWidth
                                variant="outlined"
                                density="dense"
                                onClick={() => {
                                    toPage(`${Routes.dataManage}/${params.id}`);
                                }}
                            >
                                Manage
                            </Button>
                        )}
                    </Stack>
                );
            },
        },
    ];

    return (
        <>
            {!loading &&
            !filterSearchPaginateProps.searchText &&
            isEmpty(filterSearchPaginateProps.filterObject) &&
            dataSourcesExtended.total === 0 ? (
                <EmptyScreen
                    title={"No Datasets Available"}
                    subtitle={
                        "Your datasets that need to be indexed by BigGeo first will appear here."
                    }
                    image={
                        "https://biggeo.blob.core.windows.net/media/DataManagmentEmpty.png"
                    }
                    buttons={[
                        {
                            startNode: <ActionKeyOutline />,
                            onClick: () => {
                                toPage(`${Routes.marketplace}/datasets`);
                            },
                            children: "Add Dataset",
                            variant: "filled",
                            color: "primary",
                        },
                        {
                            onClick: () => {
                                toPage(
                                    `${Routes.marketplace}/unlock/dataset-access-key`
                                );
                            },
                            children: "Dataset Access Key",
                            startNode: <KeyOutline />,
                        },
                    ]}
                />
            ) : (
                <DataGridContainer
                    pinnedColumns={{ right: ["manage", "mapUse"] }}
                    columnVisibilityModel={{
                        id: false,
                        usedIn: tab === "available",
                    }}
                    columns={columns}
                    rows={dataSourcesExtended.dataSources}
                    rowCount={dataSourcesExtended.total}
                    loading={loading}
                    title={"Data sources"}
                    filterSearchPaginateProps={filterSearchPaginateProps}
                    enableColumnResize
                />
            )}
        </>
    );
};
