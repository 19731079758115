import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import { ActionType as ActionT, action } from "typesafe-actions";
import { MapPopupType } from "../../../map/mapbox/views/MapPromptPopup";
import { ToastModel } from "../ToastContainer";

type ToastState = {
    toast: ToastModel;
    mapPopup: MapPopupType;
};

const initialState: ToastState = {
    toast: {
        title: undefined,
        message: undefined,
        open: false,
        autoHideDuration: 6000,
    },
    mapPopup: {
        message: undefined,
        sx: undefined,
    },
};

export const toasterActions = {
    openToast: (p: ToastModel) => action("OPEN_TOAST", p),
    resetToast: () => action("RESET_TOAST"),
    setOpen: (p: boolean) => action("SET_OPEN", p),
    openMapPopup: (p: MapPopupType) => action("OPEN_MAP_POPUP", p),
};

export type CombinedActions = ActionT<typeof toasterActions>;

export const toasterReducer = (
    // biome-ignore lint/style/useDefaultParameterLast: <explanation>
    state: ToastState = initialState,
    action: CombinedActions
): ToastState => {
    if (!action) return state;
    switch (action.type) {
        case "OPEN_TOAST":
            return {
                ...state,
                toast: action.payload,
            };
        case "RESET_TOAST":
            return {
                ...state,
                toast: {
                    ...state.toast,
                    title: undefined,
                    message: undefined,
                },
            };
        case "SET_OPEN":
            return {
                ...state,
                toast: {
                    ...state.toast,
                    open: action.payload,
                },
            };
        case "OPEN_MAP_POPUP":
            return {
                ...state,
                mapPopup: {
                    ...state.mapPopup,
                    ...omitBy(action.payload, isNil),
                    additionalMessage: action.payload.additionalMessage,
                    sx: {
                        ...state.mapPopup.sx,
                        ...action.payload.sx,
                    },
                },
            };
        default:
            return state;
    }
};
