import { DoubleColumnPageLayout } from "@biggeo/bg-ui";
import { Grid, LoadingBar, TopAppBar } from "@biggeo/bg-ui/lab";
import debounce from "lodash/debounce";
import { useEffect, useMemo, useState } from "react";
import { DatasourcesBanner } from "../components/Banner/DatasourcesBanner.tsx";
import { DatasourcesCard } from "../components/DatasourceCard";
import { DatasourcesLeftContentLayout } from "./DatasourcesLeftContentLayout.tsx";

import {
    AsyncErrors,
    DataSource,
    InputConnectDataSource,
    InputInsertProgress,
    UserSnowflakeTableData,
    useConnectDataSourceMutation,
    useFetchDataSourcesQuery,
    useRefreshDataSetMutation,
    useUpdateInsertProgressMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Severity } from "@biggeo/bg-ui/lab";
import { isPending } from "@vividtheory/remotedata";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import { compact, isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { useAsyncError } from "../common/components/AsyncErrorProvider";
import { isAppRunningOnSF, isSnp } from "../common/redux/hooks.ts";
import { MappingModal } from "../components/MappingModal.tsx";
import { databaseMetaDataActions } from "../database-meta-data/redux/model.ts";
import { modalActions } from "../modal/redux/model.tsx";
import { toasterActions } from "../toaster/containers/redux/model.ts";
import { CallBacksType } from "../utils/types.ts";

export type LayoutTab = {
    readonly title: "All" | "In Process";
    readonly logoUrl?: string;
};

const layoutTabs: LayoutTab[] = [
    { title: "All", logoUrl: undefined },
    { title: "In Process", logoUrl: undefined },
];

export const DatasourcesLayout = () => {
    const dispatch = useDispatch();
    const isRunningOnSF = isAppRunningOnSF();
    const isSNP = isSnp();
    const { asyncError, setAsyncError } = useAsyncError();
    const tabs = useMemo(
        () =>
            isRunningOnSF
                ? layoutTabs
                : pipe(
                      layoutTabs,
                      A.filter((tab) => tab.title !== "In Process")
                  ),
        [isRunningOnSF]
    );

    const [clickedDataSetId, setClickedDataSetId] = useState<
        string | undefined
    >(undefined);
    const [allDataSources, setAllDatasources] = useState<DataSource[]>([]);
    const [selected, setSelected] = useState<string>("All");
    const [renderedDataSources, setRenderedDataSources] = useState<
        DataSource[]
    >([]);
    const [snowflakeData, setSnowflakeData] = useState<
        UserSnowflakeTableData[]
    >([]);

    const addOrUpdateDataset = (dataSource: DataSource) => {
        dispatch(databaseMetaDataActions.addOrUpdateDataset(dataSource));
    };

    const {
        executeMutation: connectDataSourceMutation,
        mutationReturn: [_, { loading }],
    } = useConnectDataSourceMutation();

    const connectDataSource = (input: InputConnectDataSource) => {
        setAsyncError(undefined);
        connectDataSourceMutation({
            variables: { input },
            onCompleted: debounce((data) => {
                addOrUpdateDataset(data.connectDataSource);
                if (asyncError?.type !== AsyncErrors.ConnectDataSourceError)
                    setRenderedDataSources((prev) =>
                        prev.map((dataSource) =>
                            dataSource.id === data.connectDataSource.id
                                ? {
                                      ...dataSource,
                                      ...data.connectDataSource,
                                  }
                                : dataSource
                        )
                    );
            }, 300),
            onError: (e) => {
                console.log(e);
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        severity: Severity.error,
                        title: e.message || "Error connecting datasource",
                        autoHideDuration: 5000,
                    })
                );
            },
        });
    };

    const { executeMutation } = useRefreshDataSetMutation();

    const refreshDataSet = (
        id: string,
        tableId: string,
        collectionName: string,
        tableIdType: string,
        callbacks?: CallBacksType<DataSource>
    ) => {
        setAsyncError(undefined);
        executeMutation({
            variables: { input: { id, tableId, collectionName, tableIdType } },
            onError: (e) => callbacks?.onError?.(e.message),
            onCompleted: (data) => callbacks?.onSuccess?.(data.refreshDataSet),
        });
    };

    const openMappingModal = (
        snowflakeData: UserSnowflakeTableData,
        dataSource: DataSource
    ) => {
        setClickedDataSetId(dataSource.id);
        dispatch(
            modalActions.openModal({
                modalType: "dialog",
                component: (
                    <MappingModal
                        snowflakeData={snowflakeData}
                        dataSource={dataSource}
                        connectDataSource={connectDataSource}
                    />
                ),
            })
        );
    };

    const [searchText, setSearchText] = useState<string>("");
    const handleSearchTextChange = debounce((text: string) => {
        setSearchText(text);
        if (text) {
            const filtered = allDataSources.filter((dataSource) => {
                const match =
                    dataSource.collectionName.toLowerCase().includes(text) ||
                    dataSource.description?.toLowerCase().includes(text);

                return match;
            });
            setRenderedDataSources(filtered);
            return filtered;
        }
        if (selected === "All") {
            setRenderedDataSources(allDataSources);
        } else {
            setRenderedDataSources(
                allDataSources.filter((datasource) => {
                    return datasource.progress && datasource.progress < 100;
                })
            );
        }
    }, 50);

    const {
        queryReturn: { loading: isLoading },
    } = useFetchDataSourcesQuery({
        variables: {
            input: {
                dataGridFetchInput: { pageLimit: 20, pageIndex: 1 },
            },
        },
        onCompleted: (d) => {
            setAllDatasources(d.fetchDataSources.dataSources);
            setRenderedDataSources(d.fetchDataSources.dataSources);
            setSnowflakeData(d.fetchDataSources.snowflakeData);
        },
    });

    const {
        executeMutation: updateInsertProgress,
        remote: remoteDataInsertProgress,
    } = useUpdateInsertProgressMutation();

    const handleOnClickLeftLayoutItem = (label: string) => {
        switch (label) {
            case "All":
                setRenderedDataSources(allDataSources);
                setSelected("All");
                break;
            case "In Process":
                setRenderedDataSources((prevState) => {
                    return prevState.filter((datasource) => {
                        return datasource.progress && datasource.progress < 100;
                    });
                });
                setSelected("In Process");
                break;
            default:
                break;
        }
    };

    const updateDataSourceProgress = ({
        input,
    }: {
        input: InputInsertProgress[];
    }) => {
        if (!isPending(remoteDataInsertProgress))
            updateInsertProgress({
                variables: {
                    input,
                },
                onCompleted: (data) => {
                    setRenderedDataSources((prev) =>
                        prev.map((dataSource) => {
                            const updatedDataSource =
                                data.updateInsertProgress.find(
                                    (u) => u.id === dataSource.id
                                );

                            if (updatedDataSource) {
                                const updatedDS = {
                                    ...dataSource,
                                    progress: updatedDataSource.progress,
                                    isConnected: updatedDataSource.isConnected,
                                    color: updatedDataSource.color,
                                };
                                addOrUpdateDataset(updatedDS);
                                return {
                                    ...dataSource,
                                    progress: updatedDataSource.progress,
                                    isConnected: updatedDataSource.isConnected,
                                };
                            }

                            return dataSource;
                        })
                    );
                },
            });
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependencies
    useEffect(() => {
        const dataSources = renderedDataSources;

        const intervalId = setInterval(() => {
            if (dataSources) {
                const filteredDataSources = pipe(
                    dataSources,
                    A.filter(
                        (d: DataSource) =>
                            !d.isConnected &&
                            d.progress !== undefined &&
                            d.progress !== null &&
                            d.progress < 100
                    )
                );

                const input = pipe(
                    filteredDataSources,
                    A.map((d): InputInsertProgress | undefined =>
                        d.geographyColumn
                            ? {
                                  id: d.id,
                                  collectionName: d.collectionName,
                                  geographyColumn: d.geographyColumn,
                                  sfAlias: d.sfAlias,
                              }
                            : undefined
                    ),
                    compact
                );
                if (
                    !isEmpty(input) &&
                    (!asyncError ||
                        asyncError?.type === AsyncErrors.ConnectDataSourceError)
                )
                    updateDataSourceProgress({ input });
            }
        }, 3000);

        return () => {
            clearInterval(intervalId);
        };
    }, [renderedDataSources]);
    return (
        <DoubleColumnPageLayout
            disablePadding
            disableSpacing
            slotProps={{
                headerContainer: {
                    borderBottom: 1,
                    borderColor: (theme) => theme.palette.stroke[100],
                },
                leftSidePanelContainer: {
                    borderRight: 1,
                    borderColor: (theme) => theme.palette.stroke[100],
                },
                leftSidePanel: {
                    slotProps: {
                        root: {
                            bgcolor: "transparent",
                        },
                    },
                },
                childrenContainer: {
                    padding: 2,
                },
            }}
            header={<TopAppBar title="Data Sources" density="dense" />}
            leftSidePanelContent={
                <DatasourcesLeftContentLayout
                    selected={selected}
                    onClickItem={handleOnClickLeftLayoutItem}
                    tabs={tabs}
                />
            }
        >
            <Grid container rowGap={4}>
                <Grid item xs={12}>
                    <DatasourcesBanner
                        searchText={searchText}
                        onSearchTextChange={handleSearchTextChange}
                    />
                </Grid>
                <Grid item container spacing={4}>
                    {isLoading ? (
                        <Grid item xs>
                            <LoadingBar />
                        </Grid>
                    ) : (
                        renderedDataSources.map((dataSource) => {
                            const dataFromSnowflake = snowflakeData.find(
                                (data) =>
                                    data.tableName === dataSource.tableName
                            );

                            const tableIdType = dataFromSnowflake?.columns.find(
                                (c) => c.name === dataSource?.tableId
                            );

                            const dataSourceEntry = allDataSources.find(
                                (data) =>
                                    data.tableName === dataSource.tableName
                            );

                            const progress = dataSource.progress;
                            const isConnected = dataSource.isConnected;

                            return (
                                <Grid
                                    key={dataSource.id}
                                    item
                                    xs={12}
                                    md={6}
                                    lg={4}
                                >
                                    <DatasourcesCard
                                        key={dataSource.id}
                                        title={dataSource.tableName}
                                        description={dataSource.description}
                                        progress={
                                            asyncError?.type !==
                                            AsyncErrors.ConnectDataSourceError
                                                ? dataSource.progress
                                                : undefined
                                        }
                                        actualSize={dataSource.size}
                                        isConnected={
                                            isConnected || progress === 100
                                        }
                                        isRunningOnSF={isRunningOnSF}
                                        isSNP={isSNP}
                                        onClickConnect={() =>
                                            isConnected || progress === 100
                                                ? dataSourceEntry?.collectionName &&
                                                  refreshDataSet(
                                                      dataSource.id,
                                                      dataSource.tableId || "",
                                                      dataSourceEntry.collectionName,
                                                      tableIdType?.type || "",
                                                      {
                                                          onSuccess: (data) => {
                                                              setRenderedDataSources(
                                                                  (prev) =>
                                                                      prev.map(
                                                                          (
                                                                              dataSource
                                                                          ) =>
                                                                              dataSource.id ===
                                                                              data.id
                                                                                  ? {
                                                                                        ...dataSource,
                                                                                        ...data,
                                                                                    }
                                                                                  : dataSource
                                                                      )
                                                              );
                                                          },
                                                      }
                                                  )
                                                : dataFromSnowflake &&
                                                  dataSourceEntry &&
                                                  openMappingModal(
                                                      dataFromSnowflake,
                                                      dataSourceEntry
                                                  )
                                        }
                                        loading={
                                            dataSource.id === clickedDataSetId
                                                ? loading
                                                : undefined
                                        }
                                    />
                                </Grid>
                            );
                        })
                    )}
                </Grid>
            </Grid>
        </DoubleColumnPageLayout>
    );
};
