import {
    CompanyAvatar,
    Grid,
    IconAvatar,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import { Link } from "react-router-dom";
import { Routes } from "../../navigation/redux/model";

export const BigGeoIndexedCard = ({
    image,
    id,
    name,
    subTitle,
}: {
    readonly image: string;
    readonly id: string;
    readonly name: string;
    readonly subTitle: string;
}) => {
    return (
        <Stack gap={4} sx={{ padding: 8 }}>
            <Grid container gap={2}>
                <IconAvatar size="sm" color="surface" square>
                    <CompanyAvatar
                        square
                        size="xs"
                        src={image}
                        alt={id.toString()}
                    />
                </IconAvatar>

                <Grid item xs minWidth={0}>
                    <Typography
                        variant="body3"
                        fontWeight="bold"
                        sx={{
                            color: (theme) => theme.palette.background.onMain,
                        }}
                        align="left"
                        truncate
                    >
                        {name}
                    </Typography>
                    <Typography
                        variant="body4"
                        fontWeight="regular"
                        sx={{
                            color: (theme) =>
                                theme.palette.disabled.onContainer,
                        }}
                        align="left"
                        truncate
                    >
                        By {subTitle}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item sx={{ textAlign: "left" }}>
                <Typography
                    variant="body3"
                    fontWeight="regular"
                    color="background"
                    colorSet="main"
                    invertColors
                    sx={{
                        display: "inline",
                    }}
                >
                    By using this dataset you agree to it's{" "}
                </Typography>
                <Typography
                    variant="body3"
                    fontWeight="bold"
                    color="background"
                    colorSet="main"
                    invertColors
                    sx={{ display: "inline", textDecoration: "underline" }}
                >
                    <Link to={`${Routes.home}`}>terms of use</Link>
                </Typography>
            </Grid>
        </Stack>
    );
};
