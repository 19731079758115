import { RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import { Testsql } from "./Testsql";
import { AvailableAreasContainer } from "./available-areas/containers/AvailableAreasContainer";
import { AsyncErrorProvider } from "./common/components/AsyncErrorProvider";
import { ErrorPage } from "./common/components/ErrorPage";
import DatascapeMainLayout from "./common/layouts/DatascapeMainLayout.tsx";
import { FullMapLayout } from "./common/layouts/FullMapLayout.tsx";
import { useAppRunningOnSF, useIsSnp } from "./common/redux/hooks";
import { ManageDatasetContainer } from "./data-sets/containers/ManageDatasetContainer.tsx";
import DataManagementPage from "./data/pages/DataManagementPage";
import UnIndexedManagementPage from "./data/pages/UnIndexedManagementPage.tsx";
import { DatasetsRouterContainer } from "./database-meta-data/containers/DatasetsRouterContainer";
import DatasourcesContainer from "./database-meta-data/containers/DatasourcesContainer";
import HomeContainer from "./home/containers/HomeContainer.tsx";
import CreateOrManageMapTemplatePage from "./map-templates/pages/CreateOrManageMapTemplatePage";
import ManageSavedViewPage from "./map-templates/pages/ManageSavedViewPage.tsx";
import MapTemplatesPage from "./map-templates/pages/MapTemplatesPage";
import ViewAllMapTemplateSavedViewsPage from "./map-templates/pages/ViewAllMapTemplateSavedViewsPage.tsx";
import FullmapContainer from "./map/containers/FullmapContainer";
import { MapTabs } from "./map/map-wrappers/MapViewWrapper.tsx";
import { MarketplaceContainer } from "./marketplace/containers/MarketplaceContainer";
import { UnlockDatasetAccessKeyPage } from "./marketplace/pages/UnlockDatasetAccessKeyPage.tsx";
import UpgradeToBGVelocityPage from "./marketplace/pages/UpgradeToBGVelocityPage.tsx";
import { BGVelocityUnlocked } from "./marketplace/views/BGVelocityUnlocked.tsx";
import { Routes as AppRoutes } from "./navigation/redux/model.ts";
import { RecipesContainer } from "./recipes/containers/RecipesContainer.tsx";
import { TeamContainers } from "./team/containers/TeamContainers.tsx";
import TutorialContainer from "./tutorial/containers/TutorialContainer";

const router = createBrowserRouter([
    // Fallback when route is undefined
    {
        path: "*",
        element: (
            <DatascapeMainLayout>
                <ErrorPage
                    title="Sorry, this page doesn't exist!"
                    subtitle="It appears the page you're looking for is no longer available."
                />
            </DatascapeMainLayout>
        ),
    },
    {
        path: AppRoutes.home,
        element: (
            <DatascapeMainLayout>
                <HomeContainer />
            </DatascapeMainLayout>
        ),
    },
    {
        path: `${AppRoutes.mapView}/:mapTemplateId/map`,
        element: (
            <FullMapLayout>
                <FullmapContainer currentTab={MapTabs.map} />
            </FullMapLayout>
        ),
    },
    {
        path: `${AppRoutes.mapView}/:mapTemplateId/configuration`,
        element: (
            <FullMapLayout>
                <FullmapContainer currentTab={MapTabs.configuration} />
            </FullMapLayout>
        ),
    },
    {
        path: `${AppRoutes.mapView}/:mapTemplateId/saved-views`,
        element: (
            <FullMapLayout>
                <FullmapContainer currentTab={MapTabs.savedViews} />
            </FullMapLayout>
        ),
    },
    {
        path: `${AppRoutes.mapView}/:mapTemplateId/configuration/:savedAreaId`,
        element: (
            <FullMapLayout>
                <FullmapContainer
                    currentTab={MapTabs.configuration}
                    isSavedAreaForm={true}
                />
            </FullMapLayout>
        ),
    },
    {
        path: AppRoutes.datasets,
        element: (
            <DatascapeMainLayout>
                <DatasetsRouterContainer />
            </DatascapeMainLayout>
        ),
    },
    {
        path: AppRoutes.availableAreas,
        element: (
            <DatascapeMainLayout>
                <AvailableAreasContainer />
            </DatascapeMainLayout>
        ),
    },
    {
        path: AppRoutes.datasources,
        element: (
            <DatascapeMainLayout>
                <DatasourcesContainer />
            </DatascapeMainLayout>
        ),
    },
    {
        path: `${AppRoutes.data}/:menuItem?`,
        element: (
            <DatascapeMainLayout>
                <DataManagementPage />
            </DatascapeMainLayout>
        ),
    },
    {
        path: `${AppRoutes.data}/index/:id`,
        element: (
            <DatascapeMainLayout>
                <UnIndexedManagementPage />
            </DatascapeMainLayout>
        ),
    },
    {
        path: `${AppRoutes.dataManage}/:id`,
        element: (
            <DatascapeMainLayout>
                <ManageDatasetContainer />
            </DatascapeMainLayout>
        ),
    },
    {
        path: `${AppRoutes.mapTemplateData}/:mapTemplateId`,
        element: (
            <DatascapeMainLayout>
                <DataManagementPage />
            </DatascapeMainLayout>
        ),
    },
    {
        path: AppRoutes.tutorial,
        element: (
            <DatascapeMainLayout>
                <TutorialContainer />
            </DatascapeMainLayout>
        ),
    },
    {
        path: `${AppRoutes.mapTemplates}/:tab?`,
        element: (
            <DatascapeMainLayout>
                <MapTemplatesPage />
            </DatascapeMainLayout>
        ),
    },
    {
        path: AppRoutes.mapTemplatesCreate,
        element: (
            <DatascapeMainLayout>
                <CreateOrManageMapTemplatePage />
            </DatascapeMainLayout>
        ),
    },
    {
        path: `${AppRoutes.mapTemplateSavedViews}/:id`,
        element: (
            <DatascapeMainLayout>
                <ViewAllMapTemplateSavedViewsPage />
            </DatascapeMainLayout>
        ),
    },
    {
        path: `${AppRoutes.mapTemplateSavedViews}/manage/:id`,
        element: (
            <DatascapeMainLayout>
                <ManageSavedViewPage />
            </DatascapeMainLayout>
        ),
    },
    {
        path: `${AppRoutes.mapTemplatesManage}/:id`,
        element: (
            <DatascapeMainLayout>
                <CreateOrManageMapTemplatePage />
            </DatascapeMainLayout>
        ),
    },
    {
        path: `${AppRoutes.marketplace}/upgrade`,
        element: (
            <DatascapeMainLayout>
                <UpgradeToBGVelocityPage />
            </DatascapeMainLayout>
        ),
    },
    {
        path: `${AppRoutes.unlocked}`,
        element: (
            <DatascapeMainLayout>
                <BGVelocityUnlocked />
            </DatascapeMainLayout>
        ),
    },
    {
        path: "/sql",
        element: <Testsql />,
    },
    {
        path: `${AppRoutes.marketplace}/:menuItem?`,
        element: (
            <DatascapeMainLayout>
                <MarketplaceContainer />
            </DatascapeMainLayout>
        ),
    },
    {
        path: `${AppRoutes.marketplace}/unlock/dataset-access-key`,
        element: (
            <DatascapeMainLayout>
                <UnlockDatasetAccessKeyPage />
            </DatascapeMainLayout>
        ),
    },
    {
        path: `${AppRoutes.recipes}/:menuItem?`,
        element: (
            <DatascapeMainLayout>
                <RecipesContainer />
            </DatascapeMainLayout>
        ),
    },
    {
        path: AppRoutes.team,
        element: (
            <DatascapeMainLayout>
                <TeamContainers />
            </DatascapeMainLayout>
        ),
    },
    {
        path: AppRoutes.help,
        element: <DatascapeMainLayout>Help Page</DatascapeMainLayout>,
    },
]);

const Routes = () => {
    useAppRunningOnSF();
    useIsSnp();

    return (
        <AsyncErrorProvider>
            <RouterProvider router={router} />
        </AsyncErrorProvider>
    );
};

export default Routes;
