import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import {
    CssBaseline,
    ThemeProvider as LegacyThemeProvider,
    LicenseInfo,
} from "@biggeo/bg-ui";
import { ThemeProvider } from "@biggeo/bg-ui/lab";
import { theme } from "@biggeo/bg-ui/lab/theme";
import { theme as legacyTheme } from "@biggeo/bg-ui/theme";
import { lazy } from "react";
import { Provider } from "react-redux";

import Routes from "./Routes.tsx";
import { splitLink } from "./map/utils/subscription";
import { Modal } from "./modal/Modal";
import store from "./redux/store";

export const XGRID_API_KEY = import.meta.env.VITE_XGRID_KEY as string;

LicenseInfo.setLicenseKey(XGRID_API_KEY);

const ToastContainer = lazy(
    () => import("./toaster/containers/ToastContainer")
);

const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "no-cache",
            errorPolicy: "ignore",
        },
        query: {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
        },
        mutate: {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
        },
    },
});

const App = () => {
    return (
        <ApolloProvider client={client}>
            <LegacyThemeProvider theme={legacyTheme}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Provider store={store}>
                        <Modal />
                        <ToastContainer />
                        <Routes />
                    </Provider>
                </ThemeProvider>
            </LegacyThemeProvider>
        </ApolloProvider>
    );
};

export default App;
