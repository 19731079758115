import {
    type AiResponse,
    type DataSource,
    type FilterObject,
    type FilterObjectType,
    InputPoint,
    type InputPolygon,
    type InputViewBox,
    usePromptAiLazyQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import * as turf from "@turf/turf";
import { withDefault } from "@vividtheory/remotedata";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import mapboxgl from "mapbox-gl";
import React from "react";
import { useDispatch } from "react-redux";
import { useDataSources } from "../../../database-meta-data/redux/hooks";
import { databaseMetaDataActions } from "../../../database-meta-data/redux/model";
import type { CallBacksType } from "../../../utils/types";
import { snapToView } from "../../../utils/utils";
import type { InputPolygonWithId } from "../../hooks/pure-data-string-hook";
import { FunctionType, getInputPolygon, updateArray } from "../../utils/utils";
import type { AiChatType } from "../components/AiChatMessage";
import { usePrompts } from "../redux/hooks";
import { aiActions } from "../redux/model";
import AiPromptButton from "../views/AiPromptButton";

interface IAiPromptContainer {
    readonly prompt: string;
    readonly setPrompt: (v: string) => void;
    readonly multiFilters: FilterObject[];
    readonly setMultiFilters: (f: FilterObject[]) => void;
    readonly setFunctionType: (functionType: FunctionType) => void;
    readonly handleMultiPolygons: ({
        polygon,
    }: {
        polygon: InputPolygonWithId | InputPolygonWithId[];
    }) => void;
    readonly handleViewportChange: ({
        viewport,
    }: {
        viewport: InputViewBox;
    }) => void;
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly snapToView: ({
        polygons,
        map,
    }: {
        readonly polygons: InputPolygon[];
        readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    }) => void;
    readonly draw: React.MutableRefObject<MapboxDraw | null>;
    readonly polygons?: InputPolygon[];
    readonly setShowAiPrompt: (v: boolean) => void;
    readonly showAiPrompt: boolean;
    readonly addRemoveDataset: (v: string) => void;
    readonly setSelectedDataset: (datasets: readonly string[]) => void;
    readonly selectedDataset: readonly string[];
    readonly addMultipleDatasets: (v: readonly FilterObjectType[]) => void;
}

const AiPromptContainer = ({
    prompt,
    setPrompt,
    setMultiFilters,
    multiFilters,
    handleMultiPolygons,
    handleViewportChange,
    map,
    setFunctionType,
    draw,
    polygons,
    setShowAiPrompt,
    showAiPrompt,
    addRemoveDataset,
    setSelectedDataset,
    addMultipleDatasets,
    selectedDataset,
}: IAiPromptContainer) => {
    const dispatch = useDispatch();
    const databaseMetaDataRd = withDefault(
        { dataSources: [], total: 0, snowflakeData: [] },
        useDataSources()
    );
    const {
        executeQuery,
        queryReturn: [_, { data, loading }],
    } = usePromptAiLazyQuery();
    const updateDataset = (updated: DataSource) => {
        dispatch(databaseMetaDataActions.updateDataSource(updated));
    };

    const prompts: AiChatType[] = pipe(
        usePrompts(),
        A.chain((p) => [
            {
                type: "user",
                messageId: p.data.id,
                sender: "You",
                message: p.prompt,
            },
            {
                type: "ai",
                messageId: p.data.id,
                sender: "BigGeo",
                message: p.response,
            },
        ])
    );
    const onClickSend = (callbacks?: CallBacksType<AiResponse>) => {
        executeQuery({
            variables: {
                prompt: {
                    prompt: prompt,
                    tableMeta: databaseMetaDataRd.dataSources.map((c) => ({
                        id: c.id,
                        collectionName: c.collectionName,
                        tableName: c.tableName,
                    })),
                },
            },
            onError: (error) => {
                callbacks?.onError?.(error.message);
            },
            onCompleted: ({ promptAi: aiData }) => {
                callbacks?.onSuccess?.(aiData);

                dispatch(aiActions.addPrompt(aiData));

                const { data } = aiData;

                if (data.multiFilters) {
                    // biome-ignore lint/complexity/noForEach: <explanation>
                    data.multiFilters.forEach((c) => {
                        const findDatasetMetadata =
                            databaseMetaDataRd.dataSources.find(
                                (d) => d.id === c.databaseId
                            );
                        if (findDatasetMetadata) {
                            updateDataset({
                                ...findDatasetMetadata,
                                color: c.color || findDatasetMetadata.color,
                            });
                        }
                    });
                    addMultipleDatasets(
                        compact(
                            data.multiFilters.map((c) => {
                                return {
                                    databaseId: c.databaseId,
                                    collection: c.collection,
                                    databaseType: c.databaseType,
                                    filters: c.filters,
                                    color: c.color,
                                    logicOperator: c.logicOperator,
                                };
                            })
                        )
                    );

                    setSelectedDataset(
                        updateArray(
                            compact(
                                data.multiFilters.map((c) =>
                                    c.databaseId
                                        ? {
                                              id: c.databaseId,
                                          }
                                        : undefined
                                )
                            ),
                            selectedDataset.map((c) => ({ id: c }))
                        ).map((c) => c.id)
                    );
                }

                if (data.radius) {
                    const c = turf.circle(
                        [
                            data.radius.center.longitude,
                            data.radius.center.latitude,
                        ],
                        data.radius.radiusKm
                    );

                    const polygon: InputPolygonWithId = getInputPolygon(
                        c,
                        data.id
                    );

                    snapToView({
                        polygons: [...(polygons || []), polygon],
                        map,
                    });
                    setFunctionType(FunctionType.radius);

                    draw.current?.add({
                        features: [{ ...c, id: data.id }],
                        type: "FeatureCollection",
                    });

                    handleMultiPolygons({
                        polygon: polygon,
                    });
                }

                if (data.polygon) {
                    handleMultiPolygons({
                        polygon: {
                            id: "0-from-ai",
                            ...data.polygon,
                        },
                    });
                }
                if (data.multipolygon) {
                    handleMultiPolygons({
                        polygon: data.multipolygon.map((p, i) => ({
                            id: `${i}`,
                            ...p,
                        })),
                    });
                }

                if (data.viewport) {
                    const sw = new mapboxgl.LngLat(
                        data.viewport.lngBounds.min,
                        data.viewport.latBounds.min
                    );
                    const ne = new mapboxgl.LngLat(
                        data.viewport.lngBounds.max,
                        data.viewport.latBounds.max
                    );
                    map.current?.fitBounds(new mapboxgl.LngLatBounds(sw, ne));
                    handleViewportChange({
                        viewport: data.viewport,
                    });
                }
            },
        });
    };

    return (
        <AiPromptButton
            onClickSend={onClickSend}
            setShowAiPrompt={setShowAiPrompt}
            showAiPrompt={showAiPrompt}
            setPrompt={setPrompt}
            loading={loading}
            prompts={prompts}
        />
    );
};

export default AiPromptContainer;
