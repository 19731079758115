import {
    DataProvider,
    MarketplaceDataset,
    useFetchDataProvidersQuery,
    useFetchMarketplaceDatasetsQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Box, Grid, LoadingBar, Stack, Tab, TabGroup } from "@biggeo/bg-ui/lab";
import { DoubleColumnLayout } from "@biggeo/bg-ui/lab/layouts";
import { debouncedFn } from "@biggeo/bg-utils";
import { match as vtMatch } from "@vividtheory/remotedata";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import { startCase } from "lodash";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { match as tsMatch } from "ts-pattern";
import { MarketplaceDatasets } from "../views/MarketplaceDatasets";
import { MarketplaceRecipes } from "../views/MarketplaceRecipes";
import { MarketplaceSidebar, SidebarMenu } from "../views/MarketplaceSidebar";

export const MarketplaceContainer = () => {
    const { menuItem } = useParams();
    const toPage = useNavigate();
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedSearch = debouncedFn<string>();

    const getSelectedMenu = (param?: string): SidebarMenu => {
        return tsMatch(param)
            .with("datasets", () => SidebarMenu.datasets)
            .with("recipes", () => SidebarMenu.recipes)
            .otherwise(() => SidebarMenu.datasets);
    };

    const [selectedView, setSelectedView] = useState<string>("list-view");
    const [selectedProvider, setSelectedProvider] = useState<number>(0);
    const [selectedIndustry, setSelectedIndustry] = useState<string>("");

    const {
        queryReturn: { data: dataProvidersData },
    } = useFetchDataProvidersQuery({
        variables: {
            input: {},
        },
    });

    const convertToAvailableDataSource = (data: MarketplaceDataset) => {
        return {
            collectionName: data.name,
            id: data.id,
            isConnected: false,
            size: data.size,
            tableName: data.name,
            type: data.type,
            name: data.name,
            description: data.description,
            icon: data.img,
            price: data.price,
            fkProviderId: data.fkProviderId,
            createdAt: data.createdAt,
            updatedAt: data.updatedAt,
            deletedAt: data.deletedAt,
            geographyColumn: data.geographyColumn,
            tableId: data.tableId,
            path: data.path,
            opacity: 0,
            isPurchased: false,
        };
    };

    const { remote: mktplaceRD } = useFetchMarketplaceDatasetsQuery({
        variables: {
            input: {
                ...(searchQuery && { searchQuery }),
                ...(selectedProvider && { fkProviderIds: [selectedProvider] }),
                ...(selectedIndustry && { category: selectedIndustry }),
            },
        },
    });

    const [showAllProviders, setShowAllProviders] = useState<boolean>(false);

    const [showAllIndustries, setShowAllIndustries] = useState<boolean>(false);

    const onIndustryClick = (industry: string) => {
        setSelectedProvider(0);
        setSelectedIndustry(industry);
    };

    const onProviderClick = (providerId: number) => {
        setSelectedIndustry("");
        setSelectedProvider(providerId);
    };

    const sliceProvidersByMax = ({
        providers,
        max,
        condition,
    }: { providers: DataProvider[]; max: number; condition: boolean }) => {
        if (condition) {
            return providers;
        }
        return providers.slice(0, max);
    };

    return (
        <Stack width={"100%"}>
            <Grid
                container
                sx={{
                    paddingY: 4,
                    paddingX: 2,
                }}
            >
                <TabGroup variant="tonal" value={selectedView}>
                    <Tab
                        value="map-view"
                        onClick={() => setSelectedView("map-view")}
                    >
                        Map View
                    </Tab>
                    <Tab
                        value="list-view"
                        onClick={() => setSelectedView("list-view")}
                    >
                        List View
                    </Tab>
                </TabGroup>
            </Grid>
            <DoubleColumnLayout
                sx={{
                    borderTop: (theme) =>
                        `1px solid ${theme.palette.stroke[100]}`,
                }}
                height="100%"
                leftHeader={startCase(getSelectedMenu(menuItem))}
                left={
                    <Box
                        sx={{
                            border: "none",
                            height: "100%",
                            width: "100%",
                            breakpoints: {
                                cmd: {
                                    width: 60,
                                    borderRight: 1,
                                    borderColor: (theme) =>
                                        theme.palette.stroke[100],
                                },
                            },
                        }}
                    >
                        <MarketplaceSidebar
                            selected={getSelectedMenu(menuItem)}
                            onSearch={(query) =>
                                debouncedSearch(setSearchQuery, query)
                            }
                            toPage={toPage}
                            datasetProviders={
                                dataProvidersData?.fetchDataProviders
                                    .dataProviders || []
                            }
                            selectedIndustry={selectedIndustry}
                            setSelectedIndustry={setSelectedIndustry}
                            selectedProvider={selectedProvider}
                            setSelectedProvider={setSelectedProvider}
                            showAllIndustries={showAllIndustries}
                            setShowAllIndustries={setShowAllIndustries}
                            showAllProviders={showAllProviders}
                            setShowAllProviders={setShowAllProviders}
                            sliceProvidersByMax={sliceProvidersByMax}
                            onIndustryClick={onIndustryClick}
                            onProviderClick={onProviderClick}
                        />
                    </Box>
                }
            >
                {tsMatch(getSelectedMenu(menuItem))
                    .with(SidebarMenu.datasets, () => {
                        return vtMatch(mktplaceRD, {
                            _: () => <LoadingBar />,
                            Success: (data) => (
                                <MarketplaceDatasets
                                    datasources={pipe(
                                        data.fetchMarketplaceDatasets
                                            .marketplaceDatasets,
                                        A.map((d) =>
                                            convertToAvailableDataSource(d)
                                        )
                                    )}
                                    onBannerButtonClick={() => {
                                        // TODO: add toPage for routing
                                    }}
                                />
                            ),
                        });
                    })
                    .with(SidebarMenu.recipes, () => (
                        <MarketplaceRecipes
                            recipes={[]}
                            onBannerButtonClick={() => {
                                // TODO: add toPage for routing
                            }}
                        />
                    ))
                    .exhaustive()}
            </DoubleColumnLayout>
        </Stack>
    );
};
