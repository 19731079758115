import {
    AvatarGroup,
    Stack,
    ThumbnailAvatar,
    Typography,
} from "@biggeo/bg-ui/lab";
import { useState } from "react";

const SlackIntegrationCard = ({
    isTemporary,
    startAvatarURL,
    endAvatarURL,
}: {
    readonly isTemporary?: boolean;
    readonly startAvatarURL: string;
    readonly endAvatarURL: string;
}) => {
    const [hover, setHover] = useState(false);

    return (
        isTemporary && (
            <Stack
                gap={2}
                sx={{
                    padding: 3,
                    cursor: "pointer",
                    backgroundColor: (theme) => theme.palette.background.main,
                    boxShadow: (theme) =>
                        hover ? theme.shadows.allAround : undefined,
                    borderRadius: (theme) => theme.radius.xs3,
                    border: 1,
                    borderColor: (theme) => theme.palette.stroke[100],
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <AvatarGroup>
                    <ThumbnailAvatar size={"xxs"} src={startAvatarURL} />
                    <ThumbnailAvatar size={"xxs"} src={endAvatarURL} />
                </AvatarGroup>
                <Stack>
                    <Typography variant={"body4"} fontWeight={"semibold"}>
                        BigGeo Datascape Slack Community
                    </Typography>
                    <Typography
                        variant={"body4"}
                        color={"disabled"}
                        colorSet={"container"}
                        invertColors
                    >
                        Get help from our team and freely share your ideas
                    </Typography>
                </Stack>
            </Stack>
        )
    );
};

export default SlackIntegrationCard;
