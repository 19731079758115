import {
    CreateSavedArea,
    useCreateSavedAreaMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Box,
    Button,
    Severity,
    Stack,
    Tooltip,
    buttonClasses,
} from "@biggeo/bg-ui/lab";
import { CropFreeOutline } from "@biggeo/bg-ui/lab/icons";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { match } from "ts-pattern";
import { toasterActions } from "../toaster/containers/redux/model";
import { FunctionType, getInputPolygon } from "./utils/utils";
import DrawMode from "./views/DrawMode";
import SelectMode from "./views/SelectMode";

export type FunctionContainerProps = {
    functionType: FunctionType;
    setFunctionType: (functionType: FunctionType) => void;
    draw: React.MutableRefObject<MapboxDraw | null>;
    selectMode: (mode: boolean) => void;
    isSelectMode: boolean;
    selectedShapes: GeoJSON.FeatureCollection<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >;
    snapToView: () => void;
    onClose: () => void;
    setReFetchSavedAreas?: (value: boolean) => void;
    isDrawMode: boolean;
    lastFunctionType?: Extract<
        FunctionType,
        | FunctionType.polygon
        | FunctionType.square
        | FunctionType.radius
        | FunctionType.fillArea
        | FunctionType.draw
        | FunctionType.data
    >;
    setLastFunctionType: React.Dispatch<
        React.SetStateAction<
            | Extract<
                  FunctionType,
                  | FunctionType.polygon
                  | FunctionType.square
                  | FunctionType.radius
                  | FunctionType.fillArea
                  | FunctionType.draw
                  | FunctionType.data
              >
            | undefined
        >
    >;
    hasDrawnShapes: boolean;
    readonly mapTemplateId: number;
};

export const FunctionContainer = ({
    functionType,
    setFunctionType,
    draw,
    selectMode,
    isSelectMode,
    selectedShapes,
    snapToView,
    setReFetchSavedAreas,
    onClose,
    isDrawMode,
    lastFunctionType,
    setLastFunctionType,
    hasDrawnShapes,
    mapTemplateId,
}: FunctionContainerProps) => {
    const dispatch = useDispatch();
    const [draggablePopper, setDraggablePopper] = useState<HTMLElement | null>(
        null
    );

    const hasSelectedShapes = !isEmpty(selectedShapes.features);

    const {
        executeMutation,
        mutationReturn: [_, { loading }],
    } = useCreateSavedAreaMutation();

    const saveArea = (
        input: Pick<
            CreateSavedArea,
            "name" | "isEnabled" | "description" | "mapUse"
        >
    ) => {
        const { name, isEnabled, description, mapUse } = input;

        const polygons = pipe(
            selectedShapes.features,
            A.map((feature) => {
                const polygon = getInputPolygon(
                    feature as GeoJSON.Feature<GeoJSON.Polygon>
                );

                return {
                    inners: polygon.inners,
                    outer: polygon.outer,
                };
            })
        );

        executeMutation({
            variables: {
                input: {
                    name,
                    polygons,
                    isEnabled,
                    description,
                    isSavedViewArea: false,
                    mapUse,
                    fkMapTemplateId: mapTemplateId,
                },
            },
            onError: (error) => {
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        title: error.message,
                        autoHideDuration: 5000,
                        severity: Severity.error,
                    })
                );
            },
            onCompleted: () => {
                setReFetchSavedAreas?.(true);
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        title: "New area saved successfully.",
                        autoHideDuration: 5000,
                    })
                );
            },
        });
    };

    const deleteArea = () => {
        const polygonsToDelete = pipe(
            selectedShapes.features,
            A.map((feature) => {
                const polygon = getInputPolygon(
                    feature as GeoJSON.Feature<GeoJSON.Polygon>
                );
                return {
                    id: polygon.id,
                };
            })
        );
        draw.current?.delete(polygonsToDelete.map((polygon) => polygon.id));
    };

    const onShapeClick = (
        functionType: Extract<
            FunctionType,
            | FunctionType.polygon
            | FunctionType.square
            | FunctionType.radius
            | FunctionType.fillArea
            | FunctionType.draw
            | FunctionType.data
        >
    ) =>
        match(functionType)
            .with(FunctionType.polygon, () => {
                setFunctionType(FunctionType.polygon);
                setLastFunctionType(FunctionType.polygon);
                draw.current?.changeMode("draw_polygon");
            })
            .with(FunctionType.radius, () => {
                setFunctionType(FunctionType.radius);
                setLastFunctionType(FunctionType.radius);
                draw.current?.changeMode("draw_circle");
            })
            .with(FunctionType.square, () => {
                setFunctionType(FunctionType.square);
                setLastFunctionType(FunctionType.square);
                draw.current?.changeMode("draw_square");
            })
            .with(FunctionType.fillArea, () => {
                setFunctionType(FunctionType.fillArea);
                setLastFunctionType(FunctionType.fillArea);
            })
            .with(FunctionType.draw, () => {
                setFunctionType(FunctionType.draw);
                setLastFunctionType(FunctionType.draw);
            })
            .with(FunctionType.data, () => {
                setFunctionType(FunctionType.data);
                setLastFunctionType(FunctionType.data);
            })
            .exhaustive();

    return (
        <Box
            sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                position: "absolute",
                right: "50%",
                top: 4,
                transform: "translateX(50%)",
            }}
        >
            <Stack flexDirection="row" gap={2}>
                <DrawMode
                    functionType={functionType}
                    lastFunctionType={lastFunctionType}
                    onShapeClick={onShapeClick}
                    onClose={onClose}
                    selectMode={selectMode}
                    isDrawMode={isDrawMode}
                />
                {hasDrawnShapes && (
                    <SelectMode
                        isSelectMode={isSelectMode}
                        selectMode={selectMode}
                        hasSelectedShapes={hasSelectedShapes}
                        draggablePopper={draggablePopper}
                        setDraggablePopper={setDraggablePopper}
                        loading={loading}
                        saveArea={saveArea}
                        deleteArea={deleteArea}
                        onClose={onClose}
                    />
                )}
                <Tooltip title={"Re-center"}>
                    <span>
                        <Button
                            variant="outlined"
                            onClick={snapToView}
                            sx={{
                                [`&.${buttonClasses.outlined}`]: {
                                    backgroundColor: (theme) =>
                                        theme.palette.background.main,
                                    border: 1,
                                    borderColor: (theme) =>
                                        theme.palette.stroke[100],
                                    padding: 2.5,
                                    borderRadius: (theme) =>
                                        theme.radius.default,
                                },
                            }}
                        >
                            <CropFreeOutline />
                        </Button>
                    </span>
                </Tooltip>
            </Stack>
        </Box>
    );
};
