import {
    Box,
    CellItem,
    CompanyAvatar,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    ProgressBar,
    Stack,
    Tooltip,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    DeleteOutline,
    DragIndicatorOutline,
    EditOutline,
    MoreVertOutline,
    TableRowsOutline,
    VisibilityOffOutline,
    VisibilityOutline,
} from "@biggeo/bg-ui/lab/icons";
import { useState } from "react";
import { DatasetInfo } from "../../map/mapbox/views/MarketplaceDataView";

export type LinearGradientWithId = {
    offset: number;
    color: string;
    id: number;
};
export const PreviewDatasetSingleCard = ({
    dataset,
    onClickCard,
    onView,
    onEdit,
    onRemove,
    title = "Download preview",
    disabled,
}: {
    readonly dataset: DatasetInfo;
    readonly onClickCard: (data: DatasetInfo) => void;
    readonly onView: () => void;
    readonly onEdit: () => void;
    readonly onRemove: () => void;
    readonly title?: string;
    readonly disabled?: boolean;
}) => {
    const [hover, setHover] = useState(false);
    const [visibility, setVisibility] = useState(false);
    return (
        <Stack
            sx={{
                borderRadius: (theme) => theme.radius.default,
                overflow: "hidden",
                border: 1,
                borderColor: (theme) => theme.palette.stroke[100],
            }}
            onClick={() => onClickCard(dataset)}
        >
            {dataset.processing ? (
                <Stack
                    gap={1}
                    width={"100%"}
                    sx={{
                        paddingTop: 1,
                        paddingLeft: 1,
                        paddingRight: 1,
                    }}
                >
                    <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                    >
                        <Typography
                            variant="body4"
                            fontWeight="semibold"
                            truncate
                        >
                            {title}
                        </Typography>

                        <Typography variant="body4" fontWeight="semibold">
                            {dataset.progress}% Done
                        </Typography>
                    </Stack>
                    <Tooltip title="Aggregation style" sx={{ maxWidth: 30 }}>
                        <ProgressBar
                            value={dataset.progress || 0}
                            color={"success"}
                        />
                    </Tooltip>
                </Stack>
            ) : (
                <Tooltip title="Aggregation style" sx={{ maxWidth: 30 }}>
                    <Box
                        height={2}
                        sx={{
                            backgroundImage: `linear-gradient(to right, ${dataset.gradient
                                .map(
                                    (color) =>
                                        `${color.color} ${color.offset * 100}%`
                                )
                                .join(", ")})`,
                        }}
                    />
                </Tooltip>
            )}
            <CellItem
                disabled={disabled}
                density="none"
                disableHoverEffect
                onMouseEnter={(e) => {
                    e.stopPropagation();
                    setHover(true);
                }}
                onMouseLeave={(e) => {
                    e.stopPropagation();
                    setHover(false);
                }}
                sx={{
                    "&.MinervaCellItem-root": {
                        padding: 1,
                    },
                    opacity: disabled ? 0.25 : 1,
                }}
                title={
                    <Typography
                        variant="body3"
                        fontWeight="regular"
                        color="background"
                        colorSet="main"
                        invertColors
                        truncate
                    >
                        {dataset.name}
                    </Typography>
                }
                startNode={
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        {hover && (
                            <Grid item alignSelf="center">
                                <DragIndicatorOutline />
                            </Grid>
                        )}
                        <CompanyAvatar square size="xxs" src={dataset.image} />
                    </Box>
                }
                endNode={
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        {hover ? (
                            visibility ? (
                                <VisibilityOutline
                                    size="xs"
                                    sx={{
                                        alignSelf: "center",
                                    }}
                                    onClick={() => setVisibility(!visibility)}
                                />
                            ) : (
                                <VisibilityOffOutline
                                    size="xs"
                                    sx={{
                                        alignSelf: "center",
                                    }}
                                    onClick={() => setVisibility(!visibility)}
                                />
                            )
                        ) : null}
                        <Menu
                            placement="bottom-end"
                            content={
                                <Stack>
                                    <MenuItem
                                        label="Style"
                                        density="dense"
                                        startNode={<EditOutline size="xs" />}
                                        onClick={onEdit}
                                    />
                                    <MenuItem
                                        label="View table"
                                        density="dense"
                                        startNode={
                                            <TableRowsOutline size="xs" />
                                        }
                                        onClick={onView}
                                    />
                                    <MenuItem
                                        label="Remove"
                                        density="dense"
                                        startNode={<DeleteOutline size="xs" />}
                                        onClick={onRemove}
                                        color="error"
                                    />
                                </Stack>
                            }
                        >
                            <IconButton variant="minimal">
                                <MoreVertOutline size="sm" />
                            </IconButton>
                        </Menu>
                    </Box>
                }
            />
        </Stack>
    );
};
