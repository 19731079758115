import type {
    FilterObject,
    ReqOptions,
} from "@biggeo/bg-server-lib/datascape-ai";
import { useEffect } from "react";
import type { InputPolygonWithId } from "../../hooks/pure-data-string-hook";
import { FunctionType, getInputPolygon } from "../../utils/utils";

export const useSquare = ({
    functionType,
    map,
    handleMultiPolygons,
    deleteShape,
}: {
    functionType: FunctionType;
    map: React.MutableRefObject<mapboxgl.Map | null>;
    multiFilters: FilterObject[];
    handleMultiPolygons: ({ polygon }: { polygon: InputPolygonWithId }) => void;
    options: ReqOptions;
    deleteShape: (id: string) => void;
}) => {
    const current = map.current;

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handleSquare = (e: any) => {
        const id = e.features[0]?.id;

        if (e.type === "draw.delete") {
            // Delete shape by keyboard
            deleteShape(id);
        } else {
            const localSquare: InputPolygonWithId = getInputPolygon(
                e.features[0]
            );

            onSquareChange(localSquare);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (current && functionType === FunctionType.square) {
            current.on("draw.create", handleSquare);
            current.on("draw.delete", handleSquare);
            current.on("draw.update", handleSquare);
        }

        return () => {
            if (current) {
                current.off("draw.create", handleSquare);
                current.off("draw.delete", handleSquare);
                current.off("draw.update", handleSquare);
            }
        };
    }, [functionType, current]);

    const onSquareChange = (square: InputPolygonWithId) => {
        handleMultiPolygons({ polygon: square });
    };

    return { handleSquare };
};
