import { Box, EmptyScreen, Typography } from "@biggeo/bg-ui/lab";
import { OpenInNewOutline } from "@biggeo/bg-ui/lab/icons";

export const SnowflakesAds = () => {
    const handleClick = (page: string) => {
        window.location.href = `${page}`;
    };
    return (
        <EmptyScreen
            title={
                <Box>
                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        sx={{ display: "inline" }}
                    >
                        Now Available in{" "}
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            color: "#29B5E8",
                            display: "inline",
                        }}
                        fontWeight="bold"
                    >
                        Snowflake
                    </Typography>
                </Box>
            }
            subtitle={
                " BigGeo deployed inside of Snowflake Native Apps provides customers with the most powerful GeoSpatial platform in the market."
            }
            image={
                "https://biggeo.blob.core.windows.net/media/b5b3ad07-ed9b-464a-bfce-6024472c3ef1%20(2).png"
            }
            buttons={[
                {
                    children: "Deploy BigGeo on Snowflake",
                    endNode: <OpenInNewOutline />,
                    onClick: () =>
                        handleClick(
                            "https://app.snowflake.com/marketplace/listing/GZTYZOBOJLN/biggeo-biggeo-datascape-ai?originTab=provider&providerName=BigGeo"
                        ),
                },
                {
                    children: "Learn More",
                    onClick: () =>
                        handleClick("https://biggeo.com/deploy/snowflake"),
                },
            ]}
        />
    );
};
