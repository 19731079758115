import { Chip, Grid, Hidden, IconButton, Stack } from "@biggeo/bg-ui/lab";
import { MagicButtonOutline, MenuOutline } from "@biggeo/bg-ui/lab/icons";

export type HeaderProps = {
    openSidebar?: () => void;
};

export const Header = ({ openSidebar }: HeaderProps) => {
    return (
        <Stack gap={4}>
            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                flexWrap={"nowrap"}
                gap={4}
            >
                <Grid item>
                    <Grid container alignItems="center" gap={4}>
                        <Hidden variant="up" item>
                            <IconButton
                                variant="outlined"
                                density="dense"
                                onClick={openSidebar}
                            >
                                <MenuOutline />
                            </IconButton>
                        </Hidden>
                        <Hidden item>
                            <Chip
                                density="dense"
                                sx={{
                                    borderRadius: (theme) => theme.radius.full,
                                }}
                                variant="filled"
                                color="primary"
                                readonly
                                startNode={<MagicButtonOutline size="xxs" />}
                            >
                                Beta
                            </Chip>
                        </Hidden>
                    </Grid>
                </Grid>
                {/* Hidden at Jose's request */}
                {/* <Grid item>
                <Grid container gap={2} flexWrap="nowrap">
                    <IconButton variant={"outlined"}>
                        <ShoppingCartOutline size="xs" />
                    </IconButton>
                    <IconButton variant={"outlined"}>
                        <NotificationsOutline size="xs" />
                    </IconButton>
                </Grid>
            </Grid> */}
            </Grid>
        </Stack>
    );
};
