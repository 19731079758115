import {
    Chip,
    Divider,
    FlexScrollArea,
    FlexScrollAreaContainer,
    Grid,
    IconButton,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import { AddCircleOutline, ExpandMoreOutline } from "@biggeo/bg-ui/lab/icons";
import { datasetInfo } from "../../../marketplace/dummyData";
import { LinearGradientWithId } from "../../../marketplace/views/ExploreDatasetsView";
import MarketplaceDatasetPreviewCard from "../../../marketplace/views/MarketplaceDatasetPreviewCard";

const breakpoints = {
    xs: 12,
    sm: 6,
    md: 6,
    lg: 4,
    xl: 3,
};

export type DatasetInfo = {
    readonly id: number;
    readonly size: number;
    readonly price: number;
    readonly name: string;
    readonly image: string;
    readonly progress: number;
    readonly processing: boolean;
    readonly gradient: LinearGradientWithId[];
    readonly preview: (datasetId: number) => void;
};

interface IMarketplaceDataView {
    readonly setOpenBottomSlot: React.Dispatch<React.SetStateAction<boolean>>;
    readonly setDataClicked: React.Dispatch<
        React.SetStateAction<DatasetInfo | undefined>
    >;
    readonly setOpenRightSlot: React.Dispatch<React.SetStateAction<boolean>>;
}

const MarketplaceDataView = ({
    setOpenBottomSlot,
    setDataClicked,
    setOpenRightSlot,
}: IMarketplaceDataView) => {
    return (
        <Stack height={"100%"}>
            <Grid container gap={2} sx={{ padding: 2 }}>
                <Grid item xs minWidth={0} alignSelf="center">
                    <Typography
                        variant="title3"
                        fontWeight="bold"
                        color="background"
                        colorSet="main"
                        invertColors
                        truncate
                    >
                        Data Marketplace
                    </Typography>
                </Grid>
                <IconButton
                    variant="ghost"
                    onClick={() => setOpenBottomSlot(false)}
                    sx={{ padding: 2, borderRadius: "default" }}
                >
                    <ExpandMoreOutline size="xs" />
                </IconButton>
            </Grid>
            <Divider orientation="horizontal" color={100} />
            <FlexScrollAreaContainer>
                <FlexScrollArea
                    gap={4}
                    flexDirection="column"
                    sx={{ padding: 3 }}
                >
                    <Stack gap={4} height={"100%"}>
                        <Grid container gap={2}>
                            <Grid item>
                                <Chip
                                    startNode={<AddCircleOutline size="xs" />}
                                    density="dense"
                                    sx={{
                                        borderRadius: (theme) =>
                                            theme.radius.full,
                                        border: "1px dashed",
                                        borderColor: (theme) =>
                                            theme.palette.stroke[200],
                                        ":hover": {
                                            border: "none",
                                        },
                                    }}
                                >
                                    Name
                                </Chip>
                            </Grid>
                            <Grid item>
                                <Chip
                                    startNode={<AddCircleOutline size="xs" />}
                                    density="dense"
                                    sx={{
                                        borderRadius: (theme) =>
                                            theme.radius.full,
                                        border: "1px dashed",
                                        borderColor: (theme) =>
                                            theme.palette.stroke[200],
                                        ":hover": {
                                            border: "none",
                                        },
                                    }}
                                >
                                    Source
                                </Chip>
                            </Grid>
                            <Grid item>
                                <Chip
                                    startNode={<AddCircleOutline size="xs" />}
                                    density="dense"
                                    sx={{
                                        borderRadius: (theme) =>
                                            theme.radius.full,
                                        border: "1px dashed",
                                        borderColor: (theme) =>
                                            theme.palette.stroke[200],
                                        ":hover": {
                                            border: "none",
                                        },
                                    }}
                                >
                                    Size
                                </Chip>
                            </Grid>
                            <Grid item>
                                <Chip
                                    startNode={<AddCircleOutline size="xs" />}
                                    density="dense"
                                    sx={{
                                        borderRadius: (theme) =>
                                            theme.radius.full,
                                        border: "1px dashed",
                                        borderColor: (theme) =>
                                            theme.palette.stroke[200],
                                        ":hover": {
                                            border: "none",
                                        },
                                    }}
                                >
                                    Price
                                </Chip>
                            </Grid>
                            <Grid item>
                                <Chip
                                    startNode={<AddCircleOutline size="xs" />}
                                    density="dense"
                                    sx={{
                                        borderRadius: (theme) =>
                                            theme.radius.full,
                                        border: "1px dashed",
                                        borderColor: (theme) =>
                                            theme.palette.stroke[200],
                                        ":hover": {
                                            border: "none",
                                        },
                                    }}
                                >
                                    Industry
                                </Chip>
                            </Grid>
                            <Grid item>
                                <Chip
                                    startNode={<AddCircleOutline size="xs" />}
                                    density="dense"
                                    sx={{
                                        borderRadius: (theme) =>
                                            theme.radius.full,
                                        border: "1px dashed",
                                        borderColor: (theme) =>
                                            theme.palette.stroke[200],
                                        ":hover": {
                                            border: "none",
                                        },
                                    }}
                                >
                                    More Filters
                                </Chip>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            {datasetInfo.map((dataset) => {
                                return (
                                    <Grid
                                        item
                                        key={dataset.id}
                                        onClick={() => {
                                            setOpenRightSlot(true);
                                            setDataClicked(dataset);
                                        }}
                                        {...breakpoints}
                                    >
                                        <MarketplaceDatasetPreviewCard
                                            price={dataset.price}
                                            name={dataset.name}
                                            preview={dataset.preview}
                                            datasetId={dataset.id}
                                            image={dataset.image}
                                            size={dataset.size}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Stack>
                </FlexScrollArea>
            </FlexScrollAreaContainer>
        </Stack>
    );
};

export default MarketplaceDataView;
