import { SavedView } from "@biggeo/bg-server-lib/datascape-ai";
import {
    Grid,
    Stack,
    TextField,
    ThumbnailAvatar,
    Typography,
} from "@biggeo/bg-ui/lab";
import { SearchOutline } from "@biggeo/bg-ui/lab/icons";
import debounce from "lodash/debounce";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { mapDataActions } from "../../map/redux/model";
import { Routes } from "../../navigation/redux/model";
import SavedViewCard from "./SavedViewCard";

const SavedViewList = ({
    data,
    title,
    image,
    mapTemplateId,
}: {
    title: string;
    image?: string;
    data: readonly SavedView[];
    mapTemplateId: number;
}) => {
    const dispatch = useDispatch();

    const [searchSavedView, setSearchSavedView] = useState<string>("");
    const [search, setSearch] = useState("");
    const [_sortOpen, _setSortOpen] = useState(false);

    const onSearch = debounce((input: string) => {
        setSearchSavedView(input.toLowerCase());
    }, 300);

    const toPage = useNavigate();

    const onLoadSaveView = (currentSavedView: SavedView) => {
        dispatch(mapDataActions.updateMapData(currentSavedView));
    };

    return (
        <Stack gap={4} width="100%">
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                gap={2}
            >
                <Grid item xs minWidth={0}>
                    <Grid container alignItems="center" gap={1}>
                        {image && (
                            <ThumbnailAvatar src={image} square size="xxs" />
                        )}
                        <Typography variant="body2" fontWeight="bold" truncate>
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm="auto" minWidth={64}>
                    <TextField
                        fullWidth
                        startNode={<SearchOutline />}
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                            if (e) {
                                setSearch(e.currentTarget.value);
                                onSearch(e.currentTarget.value);
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                {data
                    .filter((item) => {
                        return item.name
                            .toLowerCase()
                            .includes(searchSavedView);
                    })
                    .map((item) => {
                        return (
                            <Grid
                                item
                                key={item.id}
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                            >
                                <Link
                                    to={`${Routes.mapView}/${mapTemplateId}/map?savedViewId=${item.id}`}
                                    target="_blank"
                                >
                                    <SavedViewCard
                                        title={item.name}
                                        image={item.image || ""}
                                        subTitle={item.description || ""}
                                        updatedAt={item.updatedAt}
                                        bodyOnClick={() => onLoadSaveView(item)}
                                        onClickManage={() =>
                                            toPage(
                                                `${Routes.mapTemplateSavedViews}/manage/${item.id}`
                                            )
                                        }
                                    />
                                </Link>
                            </Grid>
                        );
                    })}
            </Grid>
        </Stack>
    );
};

export default SavedViewList;
