import {
    FilterObject,
    LevelSet,
    SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import * as turf from "@turf/turf";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import mapValues from "lodash/mapValues";
import { GeoJSONSource } from "mapbox-gl";
import { useEffect } from "react";
import { MapFilterCriteriaStyle } from "../../filter-criteria/utils/utils";
import {
    getFiltersLayers,
    hideFilteredDataLayers,
    isShapeFilterLayer,
} from "../../utils/style-utils";
import { MapContextDataset, MapContextFilter } from "../context";
import { CustomShapeSource } from "./style-hooks";

export type FilteredDataHookProps = {
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly filters: MapContextFilter[];
    readonly multifilters: FilterObject[];
    readonly recentResponse?: SubscriptionResponse;
    readonly style?: mapboxgl.Style;
    readonly isLoaded: boolean;
    readonly currentDataset?: MapContextDataset;
    readonly isFilterCriteriaOpen: boolean;
};

export const useFilteredData = ({
    map,
    filters,
    recentResponse,
    style,
    isLoaded,
    currentDataset,
    isFilterCriteriaOpen,
    multifilters,
}: FilteredDataHookProps) => {
    const hasFilters = multifilters.some((d) => d.filters.length > 0);

    const visibleFilters = pipe(
        filters,
        A.filter((f) => isEqual(f.visible, true))
    );

    const onStyleLoad = (map: mapboxgl.Map) => {
        // For when they're filling the filter criteria form
        map.addSource(`${CustomShapeSource.filtering}-aggregate-preview`, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
        });
        map.addSource(`${CustomShapeSource.filtering}-points-preview`, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
        });
    };

    const toggleDefaultDatasetLayers = ({
        map,
        datasetId,
        levelSets,
        visibility,
        isLoaded,
    }: {
        map: mapboxgl.Map;
        datasetId?: string;
        levelSets: LevelSet[];
        visibility: "visible" | "none";
        isLoaded: boolean;
    }) => {
        map.setLayoutProperty(
            `${datasetId}-points-heatmap-layer`,
            "visibility",
            visibility
        );
        map.setLayoutProperty(
            `${datasetId}-points-layer`,
            "visibility",
            visibility
        );
        map.setLayoutProperty(
            `${datasetId}-polygons-outline`,
            "visibility",
            visibility
        );

        if (!isEmpty(levelSets)) {
            for (const [index, _] of levelSets.entries()) {
                map.setLayoutProperty(
                    `${datasetId}-levelset-${index}`,
                    "visibility",
                    visibility
                );
            }
        }

        if (isEmpty(levelSets)) {
            const style = isLoaded && map ? map.getStyle() : undefined;
            const layers =
                style?.layers.filter((l) =>
                    l.id.includes(`${datasetId}-levelset`)
                ) || [];

            if (!isEmpty(layers)) {
                layers.map((layer) => {
                    map.setLayoutProperty(layer.id, "visibility", visibility);
                });
            }
        }
    };

    const setFiltersFromResponse = (input: {
        suffix: string;
        response: SubscriptionResponse;
        map: mapboxgl.Map;
        dataset?: MapContextDataset;
        sources: {
            points: string;
            aggregate: string;
        };
        currentStyles?: Partial<MapFilterCriteriaStyle>;
        addedStyles?: Partial<MapFilterCriteriaStyle>;
        isLoaded: boolean;
    }) => {
        const {
            suffix,
            response,
            map,
            dataset,
            sources,
            addedStyles,
            currentStyles,
            isLoaded,
        } = input;

        const data = JSON.parse(response.data);
        const datasetId = dataset?.dataSource.id || response.databaseId;

        const levelSets = response.geometry?.levelSets || [];

        const layers = getFiltersLayers({
            sources,
            dataset,
            addedStyles,
            currentStyles,
            suffix,
        });

        toggleDefaultDatasetLayers({
            map,
            datasetId,
            levelSets,
            visibility: "none",
            isLoaded,
        });

        mapValues(layers, (value) => {
            if (isShapeFilterLayer(value)) {
                map.addLayer(value.square);
                map.addLayer(value.oval);
            } else {
                map.addLayer(value);
            }
        });

        const aggregatePreviewSource = map.getSource(
            sources.aggregate
        ) as GeoJSONSource;

        const pointsPreviewSource = map.getSource(
            sources.points
        ) as GeoJSONSource;

        if (!isNil(data?.filtered) || !isNil(data?.filteredPoints)) {
            const filteredAvgPoints = data.filtered
                ? data.filtered.map(
                      (d: { LON: number; LAT: number; COUNT: number }) => {
                          const point = [d.LON, d.LAT];
                          return turf.point(point, {
                              count: d.COUNT,
                              databaseId: response.databaseId,
                              label: "Filtered Aggregate",
                          });
                      }
                  )
                : [];

            const filteredPoints = data.filteredPoints
                ? data.filteredPoints.map(
                      (d: { LON: number; LAT: number; COUNT: number }) => {
                          const point = [d.LON, d.LAT];
                          return turf.point(point, {
                              count: d.COUNT,
                              databaseId: response.databaseId,
                              label: "Filtered Aggregate",
                          });
                      }
                  )
                : [];

            // biome-ignore lint/suspicious/noExplicitAny: allow any
            const filteredData: any = {
                type: "FeatureCollection",
                features: [...filteredAvgPoints, ...filteredPoints],
            } as const;

            if (aggregatePreviewSource) {
                aggregatePreviewSource.setData(filteredData);
            }
        }

        if (!isNil(data?.filteredPoints)) {
            const filteredPoints = data.filteredPoints.map(
                (d: { LON: number; LAT: number; ID: number }) => {
                    const point = [d.LON, d.LAT];
                    return turf.point(point, {
                        id: d.ID,
                        databaseId: response.databaseId,
                        label: `Filtered Point ${d.ID}`,
                        count: 1,
                    });
                }
            );

            // biome-ignore lint/suspicious/noExplicitAny: allow any
            const filteredPointsGeoJson: any = {
                type: "FeatureCollection",
                features: [...filteredPoints],
            } as const;

            if (pointsPreviewSource) {
                pointsPreviewSource.setData(filteredPointsGeoJson);
            }
        }

        if (isNil(data?.filtered) && isNil(data?.filteredPoints)) {
            toggleDefaultDatasetLayers({
                map,
                datasetId,
                levelSets,
                visibility: "visible",
                isLoaded,
            });
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            recentResponse?.data &&
            !isEmpty(visibleFilters) &&
            map.current &&
            isLoaded &&
            isEqual(isFilterCriteriaOpen, false)
        ) {
            // For when they create a new filter or they toggle its visibility
            for (const filter of visibleFilters) {
                const pointsSource = map.current.getSource(
                    `${CustomShapeSource.filtering}-points-${filter.id}`
                );

                const aggregateSource = map.current.getSource(
                    `${CustomShapeSource.filtering}-aggregate-${filter.id}`
                );

                if (!pointsSource) {
                    map.current.addSource(
                        `${CustomShapeSource.filtering}-points-${filter.id}`,
                        {
                            type: "geojson",
                            data: {
                                type: "FeatureCollection",
                                features: [],
                            },
                        }
                    );
                }

                if (!aggregateSource) {
                    map.current.addSource(
                        `${CustomShapeSource.filtering}-aggregate-${filter.id}`,
                        {
                            type: "geojson",
                            data: {
                                type: "FeatureCollection",
                                features: [],
                            },
                        }
                    );
                }

                setFiltersFromResponse({
                    suffix: filter.id,
                    response: recentResponse,
                    map: map.current,
                    dataset: currentDataset,
                    sources: {
                        points: `${CustomShapeSource.filtering}-points-${filter.id}`,
                        aggregate: `${CustomShapeSource.filtering}-aggregate-${filter.id}`,
                    },
                    addedStyles: filter.styles,
                    currentStyles: filter.styles,
                    isLoaded,
                });
            }
        }
    }, [
        filters,
        map.current,
        isLoaded,
        currentDataset,
        recentResponse?.uniqueId,
        visibleFilters,
        isFilterCriteriaOpen,
        multifilters,
    ]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        if (
            recentResponse?.data &&
            map.current &&
            isLoaded &&
            isEqual(isFilterCriteriaOpen, true)
        ) {
            const suffix = "preview";

            setFiltersFromResponse({
                suffix,
                response: recentResponse,
                map: map.current,
                dataset: currentDataset,
                sources: {
                    points: `${CustomShapeSource.filtering}-points-${suffix}`,
                    aggregate: `${CustomShapeSource.filtering}-aggregate-${suffix}`,
                },
                isLoaded,
            });
        }

        if (
            isEmpty(visibleFilters) &&
            map.current &&
            isLoaded &&
            isEqual(hasFilters, false)
        ) {
            toggleDefaultDatasetLayers({
                map: map.current,
                datasetId: currentDataset?.dataSource.id,
                levelSets: [],
                visibility: "visible",
                isLoaded,
            });

            for (const filter of filters) {
                hideFilteredDataLayers({
                    map,
                    isLoaded,
                    suffix: filter.id,
                    dataset: currentDataset,
                });
            }
        }
    }, [
        recentResponse?.uniqueId,
        style,
        currentDataset,
        filters,
        isFilterCriteriaOpen,
        multifilters,
        visibleFilters,
        hasFilters,
        isLoaded,
    ]);

    return { onStyleLoad };
};
