import {
    PointDataInput,
    SavedPolygon,
    SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Drawer } from "@biggeo/bg-ui";
import React from "react";
import PointDataContainer from "./PointDataContainer.tsx";

const ViewPointData = ({
    selectedPoint,
    setSelectedPoint,
    responses,
    filterByPolygon,
}: {
    selectedPoint?: PointDataInput;
    setSelectedPoint: (f?: PointDataInput) => void;
    responses: Partial<Record<string, SubscriptionResponse | null>>;
    filterByPolygon: (p: SavedPolygon) => void;
}) => {
    return (
        <Drawer
            anchor={"right"}
            open={selectedPoint !== undefined}
            onClose={() => setSelectedPoint(undefined)}
            sx={{ zIndex: 1000 }}
        >
            {selectedPoint && (
                <PointDataContainer
                    selectedPoint={selectedPoint}
                    setSelectedPoint={setSelectedPoint}
                    responses={responses}
                    filterByPolygon={filterByPolygon}
                />
            )}
        </Drawer>
    );
};

export default React.memo(ViewPointData);
