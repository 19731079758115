import { Stack, Typography } from "@biggeo/bg-ui/lab";

const TutorialList = ({
    tutorials,
}: {
    readonly tutorials: readonly {
        readonly title: string;
        readonly content: string;
    }[];
}) => {
    return (
        <Stack
            sx={{
                padding: 2,
                gap: 4,

                breakpoints: {
                    md: {
                        padding: 8,
                    },
                },
            }}
        >
            {tutorials.map((tutorial) => {
                return (
                    <Stack gap={4}>
                        <Typography variant={"h6"} fontWeight={"bold"}>
                            {tutorial.title}
                        </Typography>
                        <Typography
                            variant="body2"
                            fontWeight={"regular"}
                            dangerouslySetInnerHTML={{
                                __html: tutorial.content,
                            }}
                        />
                    </Stack>
                );
            })}
        </Stack>
    );
};
export default TutorialList;
