import {
    DataSource,
    DataSourcesExtended,
    DatabaseType,
    MapTemplateDataset,
    SavedPolygon,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Failure,
    Initialized,
    Pending,
    RemoteData,
    Success,
    match,
} from "@vividtheory/remotedata";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import {
    ActionType,
    action,
    createAsyncAction,
    getType,
} from "typesafe-actions";

export interface UpdateComputeUse {
    readonly id: string;
    readonly compute: boolean;
}
import { pipe } from "fp-ts/lib/function";
import { concat, includes, isEqual } from "lodash";

interface IModel {
    readonly dataSourcesRemoteData: RemoteData<string, DataSourcesExtended>;
    readonly currentDataSource: Partial<DataSource>;
    readonly currentMapTemplateDataset: Partial<MapTemplateDataset>;
    readonly dataSourceColors: Pick<DataSource, "id" | "color">[];
    readonly mapTemplateDatasetColors: Pick<
        MapTemplateDataset,
        "id" | "color"
    >[];
}

const initialState: IModel = {
    dataSourcesRemoteData: new Initialized(),
    currentDataSource: {
        id: undefined,
        label: undefined,
        color: "#ffffff",
        type: DatabaseType.point,
        icon: undefined,
        opacity: 0,
        collectionName: undefined,
        tableName: undefined,
        size: 0,
        isConnected: false,
        progress: 0,
    },
    currentMapTemplateDataset: {
        color: "#ffffff",
        enable: false,
        fkDataSourceId: undefined,
        fkMapTemplateId: undefined,
        id: undefined,
        mapUse: false,
    },
    dataSourceColors: [],
    mapTemplateDatasetColors: [],
};

const fetchDataSources = createAsyncAction(
    "F_fetchDataSources",
    "R_fetchDataSources",
    "E_fetchDataSources"
)<void, DataSourcesExtended, string>();

const reorderLayers = (p: readonly string[]) => action("REORDER_LAYERS", p);
const updateDataSource = (p: Partial<DataSource>) =>
    action("UPDATE_DATASOURCE", p);
const addSavedPolygon = (p: SavedPolygon) => action("ADD_SAVED_POLYGON", p);

const setCurrentMapTemplateDataset = (p: Partial<MapTemplateDataset>) =>
    action("SET_CURRENT_MAP_TEMPLATE_DATASET", p);
const setCurrentDataSource = (p: Partial<DataSource>) =>
    action("SET_CURRENT_DATASOURCE", p);
const setDataSourceColors = (p: Pick<DataSource, "id" | "color">[]) =>
    action("SET_DATASOURCE_COLORS", p);
const setMapTemplateDatasetColors = (
    p: Pick<MapTemplateDataset, "id" | "color">[]
) => action("SET_MAP_TEMPLATE_DATASET_COLORS", p);
const addOrUpdateDataset = (p: DataSource) =>
    action("ADD_OR_UPDATE_DATASET", p);

export const databaseMetaDataActions = {
    fetchDataSources,
    reorderLayers,
    updateDataSource,
    addSavedPolygon,
    setCurrentDataSource,
    setCurrentMapTemplateDataset,
    setDataSourceColors,
    setMapTemplateDatasetColors,
    addOrUpdateDataset,
};

export type DatabaseActionType = ActionType<typeof databaseMetaDataActions>;

type CombinedActions = DatabaseActionType;

export const dataSourcesReducer = (
    // biome-ignore lint/style/useDefaultParameterLast: <explanation>
    state: IModel = initialState,
    action: CombinedActions
): IModel => {
    switch (action.type) {
        case "UPDATE_DATASOURCE":
            return {
                ...state,
                dataSourcesRemoteData: match(state.dataSourcesRemoteData, {
                    _: () => state.dataSourcesRemoteData,
                    Success: (d) => {
                        return new Success({
                            ...d,
                            dataSources: d.dataSources.map((item) =>
                                item.id === action.payload.id
                                    ? { ...item, ...action.payload }
                                    : item
                            ),
                        });
                    },
                }),
                dataSourceColors: state.dataSourceColors.map((c) =>
                    c.id === action.payload.id
                        ? { id: c.id, color: action.payload.color }
                        : c
                ),
            };
        case "REORDER_LAYERS":
            return {
                ...state,
                dataSourcesRemoteData: match(state.dataSourcesRemoteData, {
                    _: () => state.dataSourcesRemoteData,

                    Success: (d) => {
                        const order = action.payload;
                        return new Success({
                            ...d,
                            dataSources: d.dataSources.sort((a, b) => {
                                const idxA = order.findIndex((v) => v === a.id);
                                const idxB = order.findIndex((v) => v === b.id);
                                if (idxA === -1) {
                                    return 1;
                                }
                                if (idxB === -1) {
                                    return -1;
                                }

                                if (idxA < idxB) {
                                    return -1;
                                }
                                return 1;
                            }),
                            total: d.total,
                            snowflakeData: d.snowflakeData,
                        });
                    },
                }),
            };
        case getType(fetchDataSources.request):
            return { ...state, dataSourcesRemoteData: new Pending() };
        case getType(fetchDataSources.success):
            return {
                ...state,
                dataSourcesRemoteData: new Success(action.payload),
            };
        case getType(fetchDataSources.failure):
            return {
                ...state,
                dataSourcesRemoteData: new Failure(action.payload),
            };
        case "SET_CURRENT_DATASOURCE": {
            const dataSourceColor =
                action.payload.id && action.payload.color
                    ? {
                          id: action.payload.id,
                          color: action.payload.color || undefined,
                      }
                    : undefined;

            return {
                ...state,
                currentDataSource: {
                    ...state.currentDataSource,
                    ...action.payload,
                },
                dataSourceColors: dataSourceColor
                    ? includes(
                          state.dataSourceColors.map((d) => d.id),
                          dataSourceColor.id
                      )
                        ? state.dataSourceColors.map((d) =>
                              d.id === dataSourceColor.id
                                  ? {
                                        ...d,
                                        color:
                                            action.payload.color || undefined,
                                    }
                                  : d
                          )
                        : [...state.dataSourceColors, dataSourceColor]
                    : state.dataSourceColors,
            };
        }
        case "SET_CURRENT_MAP_TEMPLATE_DATASET": {
            const mapTemplateDatasetColor =
                action.payload.id && action.payload.color
                    ? {
                          id: action.payload.id,
                          color: action.payload.color || undefined,
                      }
                    : undefined;

            return {
                ...state,
                currentMapTemplateDataset: {
                    ...state.currentMapTemplateDataset,
                    ...action.payload,
                },
                mapTemplateDatasetColors: mapTemplateDatasetColor
                    ? includes(
                          state.mapTemplateDatasetColors.map((d) => d.id),
                          mapTemplateDatasetColor.id
                      )
                        ? state.mapTemplateDatasetColors.map((d) =>
                              d.id === mapTemplateDatasetColor.id
                                  ? {
                                        ...d,
                                        color:
                                            action.payload.color || undefined,
                                    }
                                  : d
                          )
                        : [
                              ...state.mapTemplateDatasetColors,
                              mapTemplateDatasetColor,
                          ]
                    : state.mapTemplateDatasetColors,
            };
        }
        case "ADD_OR_UPDATE_DATASET":
            return {
                ...state,
                dataSourcesRemoteData: match(state.dataSourcesRemoteData, {
                    _: () => state.dataSourcesRemoteData,
                    Success: (d) => {
                        return new Success({
                            ...d,
                            dataSources: pipe(
                                d.dataSources,
                                A.findFirst((x) =>
                                    isEqual(x.id, action.payload.id)
                                ),
                                O.fold(
                                    () => concat(d.dataSources, action.payload),
                                    (e) =>
                                        d.dataSources.map((i) =>
                                            isEqual(i.id, e.id)
                                                ? { ...i, ...action.payload }
                                                : i
                                        )
                                )
                            ),
                        });
                    },
                }),
            };
        default:
            return state;
    }
};
