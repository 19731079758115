import {
    DoubleColumnPageLayout,
    DoubleColumnPageLayoutProps,
} from "@biggeo/bg-ui";
import { TopAppBar } from "@biggeo/bg-ui/lab";

export type DatasetLayoutProps = {
    backRoute?: string;
} & Pick<
    DoubleColumnPageLayoutProps,
    | "header"
    | "children"
    | "leftSidePanelContent"
    | "leftSidePanelFooter"
    | "leftSidePanelHeader"
>;

export const DatasetLayout = ({ children, ...props }: DatasetLayoutProps) => {
    return (
        <DoubleColumnPageLayout
            disablePadding
            disableSpacing
            header={<TopAppBar title="Data Sets" density="dense" />}
            slotProps={{
                headerContainer: {
                    borderBottom: 1,
                    borderColor: (theme) => theme.palette.stroke[100],
                },
                leftSidePanelContainer: {
                    borderRight: 1,
                    borderColor: (theme) => theme.palette.stroke[100],
                },
                leftSidePanel: {
                    slotProps: {
                        root: {
                            bgcolor: "transparent",
                        },
                    },
                },
            }}
            {...props}
        >
            {children}
        </DoubleColumnPageLayout>
    );
};
