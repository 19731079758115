import {
    DataSource,
    MapTemplateDataset,
    useFetchMapTemplateDatasetsExtendedQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Grid, LoadingBar } from "@biggeo/bg-ui/lab";
import * as R from "@vividtheory/remotedata";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import first from "lodash/first";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import { useEffect } from "react";
import { useDataGridOptions } from "../../common/redux/hooks";
import MapDatasetsConfigView from "../mapbox/views/MapDatasetsConfigView";
import MapTemplateManageCard from "../mapbox/views/MapTemplateManageCard";
import { iLike } from "../utils/utils";
import { IMapConfiguration } from "./MapConfiguration";

interface IMapTemplateDatasets extends IMapConfiguration {
    readonly route?: "data" | "configuration";
}

const MapTemplateDatasets = ({
    mapTemplateId,
    route = "configuration",
}: IMapTemplateDatasets) => {
    const { dataGridFetchInputProps, filterSearchPaginateProps } =
        useDataGridOptions();

    const { remote, setRemote } = useFetchMapTemplateDatasetsExtendedQuery({
        variables: {
            input: {
                fkMapTemplateId: mapTemplateId,
                enable: isEqual(route, "configuration") ? true : undefined,
            },
        },
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(
        () => filterSearchPaginateProps.setSearchText(undefined),
        [mapTemplateId]
    );

    return R.match(remote, {
        _: () => <LoadingBar />,
        Success: ({ fetchMapTemplateDatasetsExtended: data }) => {
            const mapTemplate = first(data)?.mapTemplate;
            const mapTemplateDatasets = pipe(
                data,
                A.filter((d) =>
                    iLike({
                        text:
                            d.dataSource.label ||
                            d.dataSource.tableName ||
                            d.dataSource.description,
                        pattern: dataGridFetchInputProps.searchText,
                    })
                )
            );

            const setMapTemplateDataset = (i: {
                mapTemplateDatasetId: number;
                mapTemplateDataset?: MapTemplateDataset;
                dataSource?: DataSource;
            }) => {
                const { mapTemplateDatasetId, mapTemplateDataset, dataSource } =
                    i;

                setRemote(
                    new R.Success({
                        fetchMapTemplateDatasetsExtended: pipe(
                            data,
                            A.map((dataset) =>
                                isEqual(
                                    dataset.mapTemplateDataset.id,
                                    mapTemplateDatasetId
                                )
                                    ? {
                                          ...dataset,
                                          ...omitBy(
                                              {
                                                  mapTemplateDataset,
                                                  dataSource,
                                              },
                                              isNil
                                          ),
                                      }
                                    : dataset
                            )
                        ),
                    })
                );
            };

            return (
                <>
                    {mapTemplate && isEqual(route, "configuration") && (
                        <MapTemplateManageCard
                            id={mapTemplateId}
                            name={mapTemplate.name}
                            thumbnail={mapTemplate.thumbnail || undefined}
                            manage={() => {}}
                        />
                    )}
                    <Grid item xs minHeight={0}>
                        <MapDatasetsConfigView
                            route={route}
                            mapTemplateDatasets={mapTemplateDatasets}
                            filterSearchPaginateProps={
                                filterSearchPaginateProps
                            }
                            setMapTemplateDataset={setMapTemplateDataset}
                        />
                    </Grid>
                </>
            );
        },
    });
};

export default MapTemplateDatasets;
