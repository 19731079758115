import { PointDataInput } from "@biggeo/bg-server-lib/datascape-ai";
// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
import Map from "ol/Map";
import MapBrowserEvent from "ol/MapBrowserEvent";
import { HoveredData } from "../../mapbox/hooks/view-data-hooks";

export const useViewDataHook = (
    setSelectedPoint: (num?: PointDataInput) => void,
    setHover: (hovered?: HoveredData) => void
) => {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const clickHandler = (event: MapBrowserEvent<any>, map: Map) => {
        map.forEachFeatureAtPixel(event.pixel, (feature): true => {
            const id = feature.get("id");
            const databaseId = feature.get("databaseId");

            if (id) {
                setSelectedPoint({
                    id,
                    databaseId,
                });
            }
            return true;
        });
    };

    const onStyleLoad = (map: Map) => {
        map.on("click", (d) => clickHandler(d, map));
        map.on("pointermove", (event) => {
            const pixel = map.getEventPixel(event.originalEvent);
            const hit = map.hasFeatureAtPixel(pixel);

            if (hit) {
                map.getTargetElement().style.cursor = "pointer";
                map.forEachFeatureAtPixel(
                    event.pixel,
                    (feature, layer): true => {
                        const id = layer ? layer.get("id") : undefined;
                        if (
                            id &&
                            id !== "polygon" &&
                            id !== "radius" &&
                            id !== "saved-polygons"
                        )
                            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                            setHover(feature.getProperties() as any);
                        return true;
                    }
                );
            } else {
                map.getTargetElement().style.cursor = "";
            }
        });
    };

    return { onStyleLoad };
};
