import { DataType } from "@biggeo/bg-ui/charts";
export const datasetCollections = [
    {
        id: 1,
        name: "Dataset Name",
        size: 100,
        price: 3000,
        image: "https://biggeo.blob.core.windows.net/media/s&p.png",
        preview: (datasetId: number) => {
            console.log(datasetId);
        },
    },
    {
        id: 2,
        name: "Points of Interest Calgary",
        size: 100,
        price: 99,
        image: "https://biggeo.blob.core.windows.net/media/Company-Avatar.png",
        preview: (datasetId: number) => {
            console.log(datasetId);
        },
    },
    {
        id: 3,
        name: "Dataset Name",
        size: 100,
        price: 3000,
        image: "https://biggeo.blob.core.windows.net/media/Company-Avatar.png",
        preview: (datasetId: number) => {
            console.log(datasetId);
        },
    },
    {
        id: 4,
        name: "Dataset Name",
        size: 100,
        price: 3000,
        image: "https://biggeo.blob.core.windows.net/media/s&p.png",
        preview: (datasetId: number) => {
            console.log(datasetId);
        },
    },
    {
        id: 5,
        name: "Dataset Name",
        size: 100,
        price: 3000,
        image: "https://biggeo.blob.core.windows.net/media/s&p.png",
        preview: (datasetId: number) => {
            console.log(datasetId);
        },
    },
];

export const horizontalFilledBarChartData: DataType[] = [
    {
        name: "Estimate",
        money: 27,
    },
];
