import {
    Button,
    Divider,
    Grid,
    Radio,
    Stack,
    TextField,
    ThumbnailAvatar,
    Typography,
} from "@biggeo/bg-ui/lab";
import { CheckCircle } from "@biggeo/bg-ui/lab/icons";
import { useState } from "react";
import { UpgradeInfo } from "./MarketplaceUpgradeForm.tsx";

export interface IMapTemplateFormView {
    readonly values: {
        price: number;
        key: string;
        keyMatched: boolean;
        snowflakeCredit: boolean;
        licenseKey: boolean;
    };
    readonly onChange: (i: Partial<UpgradeInfo>) => void;
}

const MarketplaceUpgradeFormView = ({
    values,
    onChange,
}: IMapTemplateFormView) => {
    const [license, setLicense] = useState<boolean>(values.licenseKey);
    const [sfCredit, setSfCredit] = useState<boolean>(values.snowflakeCredit);
    const [checkedKey, setCheckedKey] = useState<boolean>(values.keyMatched);
    const formattedValue = values.price.toLocaleString();

    const formatter = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
    const formattedSubTotal = formatter.format(values.price);

    const gst = values.price * 0.05;
    const formattedGST = formatter.format(gst);
    const total = values.price + gst;
    const formattedTotal = formatter.format(total);

    return (
        <Stack gap={4}>
            <Grid
                container
                alignItems="center"
                gap={2}
                sx={{
                    padding: 3,
                    cursor: "pointer",
                    borderRadius: (theme) => theme.radius.xs3,
                    border: 1,
                    borderColor: (theme) => theme.palette.stroke[100],
                }}
            >
                <ThumbnailAvatar
                    src="https://biggeo.blob.core.windows.net/media/fdsa.png"
                    square
                    size="md"
                    color="tertiary"
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                />
                <Grid item xs minWidth={0}>
                    <Stack alignSelf="stretch">
                        <Typography
                            variant="body2"
                            fontWeight="bold"
                            color="background"
                            colorSet="main"
                            invertColors
                            truncate
                        >
                            Velocity
                        </Typography>
                        <Typography
                            variant="body3"
                            color="disabled"
                            colorSet="container"
                            invertColors
                            truncate
                        >
                            By BigGeo
                        </Typography>
                    </Stack>
                </Grid>
                <Typography
                    variant="body2"
                    fontWeight="bold"
                    color="background"
                    colorSet="main"
                    invertColors
                >
                    ${formattedValue}/Mo
                </Typography>
            </Grid>
            <Stack>
                <Typography
                    variant="title1"
                    fontWeight="bold"
                    color="background"
                    colorSet="main"
                    invertColors
                >
                    Payment info
                </Typography>
                <Typography
                    variant="body2"
                    color="disabled"
                    colorSet="container"
                    invertColors
                >
                    Select your payment method
                </Typography>
            </Stack>
            <Stack gap={4}>
                <Grid
                    container
                    alignItems="center"
                    gap={2}
                    sx={{
                        padding: 3,
                        cursor: "pointer",
                        borderRadius: (theme) => theme.radius.xs3,
                        border: 1,
                        borderColor: (theme) => theme.palette.stroke[100],
                    }}
                    onClick={() => {
                        setLicense(true);
                        setSfCredit(false);
                    }}
                >
                    <Radio
                        size="xs"
                        checked={license}
                        onClick={() => {
                            setLicense(true);
                            setSfCredit(false);
                        }}
                    />
                    <ThumbnailAvatar
                        src="https://biggeo.blob.core.windows.net/media/fdsa.png"
                        size="md"
                        color="tertiary"
                        sx={{ justifyContent: "center", alignItems: "center" }}
                    />
                    <Grid item xs minWidth={0} alignSelf="center">
                        <Typography
                            variant="body3"
                            fontWeight="semibold"
                            color="background"
                            colorSet="main"
                            invertColors
                            truncate
                        >
                            Use License Key
                        </Typography>
                    </Grid>
                </Grid>
                {license && (
                    <Stack>
                        <TextField
                            value={values.key || undefined}
                            placeholder="BG12-CD34-EF56-GH78-IJ90-KL12-MN34-OP56"
                            slotProps={{
                                inputRoot: {
                                    sx: {
                                        padding: 4,
                                    },
                                },
                            }}
                            fullWidth
                            onChange={(_, v) =>
                                onChange({ key: v === "" ? undefined : v })
                            }
                            endNode={
                                <Grid sx={{ display: "flex", gap: 2 }}>
                                    <Divider
                                        orientation="vertical"
                                        color={300}
                                    />
                                    <Button
                                        variant="ghost"
                                        color="primary"
                                        onClick={() => {
                                            setCheckedKey(!checkedKey);
                                        }}
                                    >
                                        Check Key
                                    </Button>
                                </Grid>
                            }
                        />
                        {checkedKey && (
                            <Grid
                                sx={{ display: "flex" }}
                                alignItems="center"
                                gap={1}
                            >
                                <CheckCircle size="xxs" color="success" />
                                <Typography variant="body4">
                                    Key matched successfully
                                </Typography>
                            </Grid>
                        )}
                    </Stack>
                )}
                <Grid
                    container
                    alignItems="center"
                    gap={2}
                    sx={{
                        padding: 3,
                        cursor: "pointer",
                        borderRadius: (theme) => theme.radius.xs3,
                        border: 1,
                        borderColor: (theme) => theme.palette.stroke[100],
                    }}
                    onClick={() => {
                        setSfCredit(true);
                        setLicense(false);
                    }}
                >
                    <Radio
                        size="xs"
                        checked={sfCredit}
                        onClick={() => {
                            setSfCredit(true);
                            setLicense(false);
                        }}
                    />
                    <ThumbnailAvatar
                        src="https://biggeo.blob.core.windows.net/media/sf.png"
                        square
                        size="md"
                        sx={{ justifyContent: "center", alignItems: "center" }}
                    />
                    <Grid item xs minWidth={0} alignSelf="center">
                        <Typography
                            variant="body3"
                            fontWeight="semibold"
                            color="background"
                            colorSet="main"
                            invertColors
                            truncate
                        >
                            Use Snowflake Credits
                        </Typography>
                    </Grid>
                </Grid>
            </Stack>

            <Stack>
                <Grid
                    container
                    gap={4}
                    alignItems="center"
                    sx={{ paddingTop: 1, paddingBottom: 1 }}
                >
                    <Grid item xs minWidth={0}>
                        <Typography
                            variant="body2"
                            fontWeight="regular"
                            truncate
                        >
                            Subtotal
                        </Typography>
                    </Grid>

                    <Typography variant="body3" fontWeight="bold">
                        ${formattedSubTotal}
                    </Typography>
                </Grid>
                <Grid
                    container
                    gap={4}
                    alignItems="center"
                    sx={{ paddingTop: 1, paddingBottom: 1 }}
                >
                    <Grid item xs minWidth={0}>
                        <Typography
                            variant="body2"
                            fontWeight="regular"
                            truncate
                        >
                            GST
                        </Typography>
                    </Grid>

                    <Typography variant="body3" fontWeight="bold">
                        ${formattedGST}
                    </Typography>
                </Grid>
                <Grid
                    container
                    gap={4}
                    alignItems="center"
                    sx={{ paddingTop: 2, paddingBottom: 2 }}
                >
                    <Grid item xs minWidth={0}>
                        <Typography variant="body2" fontWeight="bold" truncate>
                            Total
                        </Typography>
                    </Grid>

                    <Typography variant="body1" fontWeight="bold">
                        ${formattedTotal}
                    </Typography>
                </Grid>
            </Stack>
        </Stack>
    );
};

export default MarketplaceUpgradeFormView;
