import {
    Button,
    FlexScrollArea,
    FlexScrollAreaContainer,
    MaxWidthContainer,
    Stack,
    TopAppBar,
} from "@biggeo/bg-ui/lab";
import { Link } from "react-router-dom";
import TutorialList from "../views/TutorialList";

const TutorialContainer = () => {
    const tutorials = [
        {
            title: "Big Geo",
            content:
                "This demo showcases BigGeo’s proprietary Discrete Global Grid System (DGGS) to index and aggregate data, such that our BG-Search can quickly query large geospatial data sets and display them based on the user’s viewport or provided region of interest.\n" +
                "BigGeo has a custom index for geospatial data based on our proprietary DGGS, this index allows us to quickly query for all data in a geospatial region (lat-long bounding box, polyline, radius and square.). Additionally, this index can provide aggregate views (count, mean point, etc.) on data instead of or in addition to the raw data, enabling a configurable experience optimized for interactivity.\n" +
                "For a given query, if a region of the query (DGGS cell) has few data points, BG-search will return the actual data; for cells with many points, BG-search will return the aggregated view instead, indicating more data exists in that geographic region. In this demo, for the point data set, the heat map visualization is made using the aggregate views returned by BG-Search. The level-sets (contour lines) rendered on the map for the polygon dataset are aggregated views showing the regions with lots of data; zooming into these areas will reveal individual data points.",
        },
        {
            title: "ViewPort",
            content:
                "Viewport searches for all the data that is bounded by the current map view. As you move around the map the data will be updated.",
        },
        {
            title: "Polyline",
            content:
                "Polyline allows you to draw a custom polygon on the map. When you select polyline, go to the map and click to start drawing a polygon. While moving in a counter-clockwise motion, for each point/corner of the polygon, click to anchor it. To complete the polygon, double-click on the last point and BigGeo will do a search for data inside that polygon, provided that a dataset is switched on. Once the polygon is set, it can be dragged anywhere on the map to search for data within that polygon, over that section of the map.",
        },
        {
            title: "Radius",
            content:
                "Radius allows you to search for data inside of a circle. After selecting radius, click and drag to draw a circle. BigGeo will then be able to search for data inside the circle. Please note you can change the radius of the circle by selecting it (Clicking on the circle) and dragging the edges.",
        },
        {
            title: "Square",
            content:
                "The Sqaure works in a similar manner to Radius. Click and drag to draw a square and BigGeo will search for data within the shape.",
        },
        {
            title: "Draw Mode",
            content:
                "The map allows you to draw multiple shapes: polygons, square and radius. When you select a shape from the top dropdown you can go to the map and start drawing. Once you are in this state, you can continue to draw shapes of the type currently selected. For example, if you clicked Radius and draw a circle. You can immediately draw another without the need of clicking the top dropdown again. To start drawing another shape, selct the dropdown and choose the relevant shape. Draw mode allows to continuously draw shapes. To exit draw mode, click the 'x' on the dropdown. Once you are out of draw mode you can select and drag shapes. You can also delete shapes by clicking on delete or backspace on your keyboard, once a shape is selected.",
        },
        {
            title: "Select Mode and Save Areas",
            content:
                "Once you have at least one shape drawn on the Map, click the Select Mode Icon (Circle with Cursor), to be able to click on shapes and select them. Once Shapes are selected click on the Select Mode drop down, and then click Save Area to be able to save the shapes drawn as a single area in the database for future use when querying data.",
        },
        {
            title: "Reset View",
            content:
                "Reset View is a button that shows at the bottom right of the map when a shape is drawn. By clicking on Reset View, all shapes are cleared from the map and modes are exited",
        },
        {
            title: "AI",
            content:
                "At the bottom center we have a button that when clicked, will reveal an AI Prompt Textfield which will help you visualize queries you are interested in. For example, let's assume you have a dataset called CELL TOWERS. You could ask the AI to show you all the Cell Towers withing a 20km radius of Calgary, or ask the AI to change the color of Cell Towers to brown. Please note, AI is currently available on Snowflake only.",
        },
        {
            title: "Map SearchBar",
            content:
                "The SearchBar at the top right allows for you to quickly lookup a location on the map and be flown to that location once selected",
        },
        {
            title: "Maximum Geometries",
            content:
                "This parameter describes the maximum number of geometries before BG-Search returns an aggregate view for that cell.",
        },
        {
            title: "Resolution Offset",
            content:
                "This parameter helps control the resolution of the aggregated cells returned.",
        },
    ];
    return (
        <FlexScrollAreaContainer>
            <TopAppBar
                density="dense"
                variant="small"
                title={"How to Use"}
                divider
                endNode={
                    <Stack flexDirection="row" gap={2} alignItems="center">
                        <Link
                            to="https://docs.biggeo.com/reference/introduction"
                            target="_blank"
                        >
                            <Button variant={"minimal"}>Read The Docs</Button>
                        </Link>
                        <Link
                            to="https://biggeo.com/deploy/contact-sales"
                            target="_blank"
                        >
                            <Button
                                variant={"filled"}
                                density={"dense"}
                                color="primary"
                                sx={{
                                    borderRadius: (theme) => theme.radius.lg,
                                }}
                            >
                                Contact Sales
                            </Button>
                        </Link>
                    </Stack>
                }
            />
            <FlexScrollArea>
                <MaxWidthContainer maxWidth="xl">
                    <TutorialList tutorials={tutorials} />
                </MaxWidthContainer>
            </FlexScrollArea>
        </FlexScrollAreaContainer>
    );
};
export default TutorialContainer;
