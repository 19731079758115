import { useFetchMapTemplateWithSavedViewsByIdQuery } from "@biggeo/bg-server-lib/datascape-ai";
import { EmptyScreen, LoadingBar, Stack } from "@biggeo/bg-ui/lab";
import { AddOutline } from "@biggeo/bg-ui/lab/icons";
import { Routes } from "../../navigation/redux/model";
import SavedViewList from "../../saved-views/views/SavedViewList";
import { MapTabs } from "../map-wrappers/MapViewWrapper";
import { IMapConfiguration } from "./MapConfiguration";

interface IMapSavedViews extends IMapConfiguration {}

const MapSavedViews = ({ mapTemplateId, toPage }: IMapSavedViews) => {
    const {
        queryReturn: { data, loading },
    } = useFetchMapTemplateWithSavedViewsByIdQuery({
        variables: { mapTemplateId: mapTemplateId },
    });
    return (
        <Stack gap={4} sx={{ padding: 4 }}>
            {loading ? (
                <LoadingBar />
            ) : data &&
              data.fetchMapTemplateWithSavedViewsById.savedViewExtended.total >
                  0 ? (
                <SavedViewList
                    data={
                        data.fetchMapTemplateWithSavedViewsById
                            .savedViewExtended.savedViews || []
                    }
                    mapTemplateId={data.fetchMapTemplateWithSavedViewsById.id}
                    title={data.fetchMapTemplateWithSavedViewsById.name}
                    image={
                        data.fetchMapTemplateWithSavedViewsById.thumbnail ||
                        undefined
                    }
                />
            ) : (
                <EmptyScreen
                    title={"No Saved Views"}
                    subtitle={
                        "When you create a saved view on this map template, they will appear here."
                    }
                    image={
                        "https://biggeo.blob.core.windows.net/media/config-empty-saved-view.png"
                    }
                    buttons={[
                        {
                            startNode: <AddOutline />,
                            onClick: () => {
                                toPage?.(
                                    `${Routes.mapView}/${mapTemplateId}/${MapTabs.map}`
                                );
                            },
                            children: "Create View",
                            variant: "filled",
                            color: "primary",
                        },
                        {
                            onClick: () => {},
                            children: "Learn More",
                        },
                    ]}
                />
            )}
        </Stack>
    );
};

export default MapSavedViews;
