import { FlexScrollArea, FlexScrollAreaContainer } from "@biggeo/bg-ui/lab";
import { MapHeader } from "../../components/MapHeader";

import { PointDataInput } from "@biggeo/bg-server-lib/datascape-ai";
import isEmpty from "lodash/isEmpty";
import kebabCase from "lodash/kebabCase";
import { useReducer, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router";
import { match } from "ts-pattern";
import { Consumers } from "../../common/redux/model";
import { Routes } from "../../navigation/redux/model";
import MapConfiguration from "../containers/MapConfiguration";
import MapSavedViews from "../containers/MapSavedViews";
import { usePureDataString } from "../hooks/pure-data-string-hook";
import { useRenderControls } from "../hooks/render-data-hooks";
import MapMainContainer from "../mapbox/containers/MapMainContainer";
import { MapContext, MapReducer } from "../mapbox/context";
import { HoveredData } from "../mapbox/hooks/view-data-hooks";

export enum MapTabs {
    map = "map",
    configuration = "configuration",
    savedViews = "savedViews",
}

interface IMapViewWrapper {
    readonly currentTab?: MapTabs;
    readonly activeConsumption: Consumers;
    readonly setConsumption: (consumption: Consumers) => void;
    readonly mapTemplateId: number;
    readonly isSavedAreaForm?: boolean;
}

const MapViewWrapper = ({
    currentTab,
    activeConsumption,
    setConsumption,
    mapTemplateId,
    isSavedAreaForm,
}: IMapViewWrapper) => {
    const toPage = useNavigate();
    const tab = currentTab ?? MapTabs.map;

    const [selectedDataset, setSelectedDataset] = useState<readonly string[]>(
        []
    );

    const map = useRef<mapboxgl.Map | null>(null);
    const draw = useRef<MapboxDraw | null>(null);
    const [state, dispatch] = useReducer(MapReducer, {
        map,
        draw,
        isLoaded: false,
        mapStates: [],
        selectedShapes: {
            type: "FeatureCollection",
            features: [],
        },
        filters: [],
    });

    const {
        polygons,
        datasetPointsShapes,
        functionType,
        multiFilters,
        recentResponse,
        viewport,
        handleViewportChange,
        savedPolygons,
        handleMultiPolygons,
        handleSavedPolygons,
        handlePolygonsOnZoom,
        options,
        deleteShape,
        ...hookProps
    } = usePureDataString();

    const controlProps = useRenderControls();

    const [hoveredData, setHoveredData] = useState<HoveredData>();

    const [openSaveViewPopper, setOpenSaveViewPopper] = useState(false);

    const [selectedPoint, setSelectedPoint] = useState<PointDataInput>();

    const handleMapTabs = (t: MapTabs) => {
        toPage(`${Routes.mapView}/${mapTemplateId}/${kebabCase(t)}`);

        // Clear the datasets when you leave the map
        if (!isEmpty(selectedDataset)) {
            setSelectedDataset([]);
        }
    };

    const canSaveView = !isEmpty(polygons) || !isEmpty(selectedDataset);

    return (
        <DndProvider backend={HTML5Backend}>
            <MapContext.Provider value={{ ...state, dispatch }}>
                <FlexScrollAreaContainer width="100%">
                    <MapHeader
                        currentTab={tab}
                        handleMapTabs={handleMapTabs}
                        canSaveView={canSaveView}
                        saveView={() => setOpenSaveViewPopper(true)}
                    />
                    <FlexScrollArea flexDirection="column">
                        {match(tab)
                            .with(MapTabs.configuration, () => (
                                <MapConfiguration
                                    mapTemplateId={mapTemplateId}
                                    toPage={toPage}
                                    isSavedAreaForm={isSavedAreaForm}
                                />
                            ))
                            .with(MapTabs.savedViews, () => (
                                <MapSavedViews
                                    mapTemplateId={mapTemplateId}
                                    toPage={toPage}
                                />
                            ))
                            .with(MapTabs.map, () => (
                                <MapMainContainer
                                    {...hookProps}
                                    {...controlProps}
                                    tab={tab}
                                    mapTemplateId={mapTemplateId}
                                    polygons={polygons}
                                    datasetPointsShapes={datasetPointsShapes}
                                    activeConsumption={activeConsumption}
                                    setConsumption={setConsumption}
                                    setSelectedPoint={setSelectedPoint}
                                    selectedDataset={selectedDataset}
                                    setOpenSaveViewPopper={
                                        setOpenSaveViewPopper
                                    }
                                    openSaveViewPopper={openSaveViewPopper}
                                    setSelectedDataset={setSelectedDataset}
                                    functionType={functionType}
                                    handleMultiPolygons={handleMultiPolygons}
                                    multiFilters={multiFilters}
                                    recentResponse={recentResponse}
                                    handleViewportChange={handleViewportChange}
                                    savedPolygons={savedPolygons}
                                    options={options}
                                    deleteShape={deleteShape}
                                    handlePolygonsOnZoom={handlePolygonsOnZoom}
                                    handleSavedPolygons={handleSavedPolygons}
                                    viewport={viewport}
                                    hoveredData={hoveredData}
                                    setHoveredData={setHoveredData}
                                    selectedPoint={selectedPoint}
                                />
                            ))
                            .exhaustive()}
                    </FlexScrollArea>
                </FlexScrollAreaContainer>
            </MapContext.Provider>
        </DndProvider>
    );
};

export default MapViewWrapper;
