import { Button } from "@biggeo/bg-ui/lab";
import {
    AutoStoriesOutline,
    BacklightHighOutline,
    BugReportOutline,
    ChatBubbleOutline,
    MoveToInboxOutline,
    RouteOutline,
    SupportOutline,
    UnknownDocumentOutline,
} from "@biggeo/bg-ui/lab/icons";
import { Link } from "react-router-dom";
import { Routes } from "../navigation/redux/model.ts";

export const user = {
    firstName: "Olivia",
    lastName: "Bergson",
    email: "olivia@northstarsystems.com",
    img: "https://randomuser.me/api/portraits/women/2.jpg",
    phoneCountryCode: "1",
    phone: "4015691234",
    currentWorkspace: {
        name: "Northstar Systems Canada",
        img: "https://avatars.slack-edge.com/2023-11-02/6131551269222_3106f6809e8ce1db2fcd_36.png",
        industry: "Logistics",
        country: "Canada",
        phone: "4031231234",
        website: "www.northstarsystems.com",
        description: undefined,
    },
    workspaces: [
        {
            name: "Northstar Systems Canada",
            img: "https://avatars.slack-edge.com/2023-11-02/6131551269222_3106f6809e8ce1db2fcd_36.png",
        },
        {
            name: "Northstar Systems USA",
            img: "https://avatars.slack-edge.com/2023-11-02/6131551269222_3106f6809e8ce1db2fcd_36.png",
        },
    ],
    role: "owner",
};

export const bannerUsers = [
    {
        id: "1",
        email: "test1@test.com",
        imgUrl: "https://randomuser.me/api/portraits/men/20.jpg",
    },
    {
        id: "2",
        email: "test2@test.com",
        imgUrl: "https://randomuser.me/api/portraits/men/20.jpg",
    },
    {
        id: "3",
        email: "test3@test.com",
        imgUrl: "https://randomuser.me/api/portraits/men/20.jpg",
    },
    {
        id: "4",
        email: "test4@test.com",
        imgUrl: "https://randomuser.me/api/portraits/men/20.jpg",
    },
    {
        id: "5",
        email: "test5@test.com",
        imgUrl: "https://randomuser.me/api/portraits/men/20.jpg",
    },
    {
        id: "6",
        email: "test6@test.com",
        imgUrl: "https://randomuser.me/api/portraits/men/20.jpg",
    },
];

export const changeLogs = [
    {
        id: "1",
        date: "Jan 1, 2024",
        title: "Your Change Log Title Goes Here",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis consequatur aliquid iusto ratione aliquam? Ea minima omnis odio vero doloribus blanditiis at quasi excepturi officia corporis quas, veritatis architecto magni!",
        onClick: () => {},
    },
    {
        id: "2",
        date: "Jan 1, 2024",
        title: "Your Change Log Title Goes Here",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis consequatur aliquid iusto ratione aliquam? Ea minima omnis odio vero doloribus blanditiis at quasi excepturi officia corporis quas, veritatis architecto magni!",
        onClick: () => {},
    },
    {
        id: "3",
        date: "Jan 1, 2024",
        title: "Your Change Log Title Goes Here",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis consequatur aliquid iusto ratione aliquam? Ea minima omnis odio vero doloribus blanditiis at quasi excepturi officia corporis quas, veritatis architecto magni!",
        onClick: () => {},
    },
    {
        id: "4",
        date: "Jan 1, 2024",
        title: "Your Change Log Title Goes Here",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis consequatur aliquid iusto ratione aliquam? Ea minima omnis odio vero doloribus blanditiis at quasi excepturi officia corporis quas, veritatis architecto magni!",
        onClick: () => {},
    },
    {
        id: "5",
        date: "Jan 1, 2024",
        title: "Your Change Log Title Goes Here",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis consequatur aliquid iusto ratione aliquam? Ea minima omnis odio vero doloribus blanditiis at quasi excepturi officia corporis quas, veritatis architecto magni!",
        onClick: () => {},
    },
];

export const useCases = [
    {
        id: "1",
        title: "Real Estate",
        imgUrl: "",
        onClick: () => {},
    },
    {
        id: "2",
        title: "Logistics",
        imgUrl: "",
        onClick: () => {},
    },
    {
        id: "3",
        title: "Micro Mobility",
        imgUrl: "",
        onClick: () => {},
    },
    {
        id: "4",
        title: "Aviation",
        imgUrl: "",
        onClick: () => {},
    },
    {
        id: "5",
        title: "Mining",
        imgUrl: "",
        onClick: () => {},
    },
    {
        id: "6",
        title: "Finance",
        imgUrl: "",
        onClick: () => {},
    },
];

export const blogs = [
    {
        id: "1",
        title: "Blog #1",
        imgUrl: "",
        content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do, Lorem ipsum dolor sit amet, consectetur adipiscing elit, do...",
        onClick: () => {},
    },
    {
        id: "2",
        title: "Blog #2",
        imgUrl: "",
        content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do, Lorem ipsum dolor sit amet, consectetur adipiscing elit, do...",
        onClick: () => {},
    },
    {
        id: "3",
        title: "Blog #3",
        imgUrl: "",
        content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do, Lorem ipsum dolor sit amet, consectetur adipiscing elit, do...",
        onClick: () => {},
    },
    {
        id: "4",
        title: "Blog #4",
        imgUrl: "",
        content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do, Lorem ipsum dolor sit amet, consectetur adipiscing elit, do...",
        onClick: () => {},
    },
    {
        id: "5",
        title: "Blog #5",
        imgUrl: "",
        content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do, Lorem ipsum dolor sit amet, consectetur adipiscing elit, do...",
        onClick: () => {},
    },
    {
        id: "6",
        title: "Blog #6",
        imgUrl: "",
        content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do, Lorem ipsum dolor sit amet, consectetur adipiscing elit, do...",
        onClick: () => {},
    },
];

export const resources = [
    {
        id: "1",
        logo: <UnknownDocumentOutline />,
        onClick: () => {},
        title: "Developer Documentation",
        description: "Visualize your datasets and unlock for all of your team",
    },
    {
        id: "2",
        logo: <AutoStoriesOutline />,
        onClick: () => {},
        title: "Knowledge Base",
        description: "Visualize your datasets and unlock for all of your team",
    },
    {
        id: "3",
        logo: <ChatBubbleOutline />,
        onClick: () => {},
        title: "Ai Chat Support",
        description: "Visualize your datasets and unlock for all of your team",
    },
    {
        id: "4",
        logo: <BacklightHighOutline />,
        onClick: () => {},
        title: "Feature Request",
        description: "Visualize your datasets and unlock for all of your team",
    },
];

/*TODO: Use the following icons when available in icon library:
 *   move_to_inbox_outline
 *   bug_report_outline
 *   route_outline
 * */

const CtaButton = ({
    to,
    text,
}: {
    readonly to: string;
    readonly text: string;
}) => (
    <Link to={to} target={"_blank"}>
        <Button density={"dense"} variant={"outlined"}>
            {text}
        </Button>
    </Link>
);

export const temporaryResources = [
    {
        id: "1",
        logo: <BacklightHighOutline />,
        onClick: () => {},
        title: "Feature request",
        description:
            "Let us know what we should be building next to make Datascape better for you",
        cta: (
            <CtaButton text={"Request a feature"} to={Routes.featureRequest} />
        ),
    },
    {
        id: "2",
        logo: <MoveToInboxOutline />,
        onClick: () => {},
        title: "Give feedback",
        description:
            "Give us feedback on how you’re finding the use of Datascape",
        cta: <CtaButton text={"Give feedback"} to={Routes.feedback} />,
    },
    {
        id: "3",
        logo: <BugReportOutline />,
        onClick: () => {},
        title: "Report a bug",
        description:
            "Something is not working quite right? let us know immediatley",
        cta: <CtaButton text={"Create Issue"} to={Routes.issues} />,
    },
    {
        id: "4",
        logo: <RouteOutline />,
        onClick: () => {},
        title: "Product roadmap",
        description: "Visualize your datasets and unlock for all of your team",
        cta: <CtaButton text={"View roadmap"} to={Routes.roadmap} />,
    },
    {
        id: "5",
        logo: <ChatBubbleOutline />,
        onClick: () => {},
        title: "Change Log",
        description:
            "See what’s changed since your last time here with Datascape",
        cta: <CtaButton text={"View Change Log"} to={Routes.changeLog} />,
    },
    {
        id: "6",
        logo: <SupportOutline />,
        onClick: () => {},
        title: "Help Center",
        description: "Get product help with using BigGeo Datascape",
        cta: <CtaButton text={"Get Support"} to={Routes.support} />,
    },
];
