import { DataSource } from "@biggeo/bg-server-lib/datascape-ai";
import { WithLoading } from "@biggeo/bg-ui";
import {
    Button,
    Stack,
    StickyFabPlacementHelper,
    TextField,
    ThumbnailAvatar,
} from "@biggeo/bg-ui/lab";
import { Typography } from "@mui/material";
import { FormikProps } from "formik";
import { FormValuesType } from "../data/containers/UnIndexedManagementContainer";

export type MappingModalProps = {
    readonly dataSource: DataSource;
    readonly props: FormikProps<FormValuesType>;
};

export const MappingColumnForm = ({ dataSource, props }: MappingModalProps) => {
    return (
        <Stack gap={4} width={"100%"}>
            <ThumbnailAvatar
                sx={{
                    borderRadius: (theme) => theme.radius.default,
                    border: (theme) =>
                        `1px solid ${theme.palette.background.main}`,
                    backgroundColor: (theme) => theme.palette.primary.container,
                }}
                size="lg"
                src="https://biggeo.blob.core.windows.net/media/sf.png"
            />
            <Stack>
                <Typography
                    variant="body3"
                    fontWeight="regular"
                    color={(theme) => theme.palette.disabled.onContainer}
                >
                    Original name
                </Typography>
                <Typography
                    variant="body2"
                    fontWeight="bold"
                    sx={{
                        color: (theme) => theme.palette.background.onMain,
                    }}
                >
                    {dataSource.tableName}
                </Typography>
            </Stack>

            <TextField
                fullWidth
                label="Display name"
                subLabel="(Optional)"
                type="name"
                required
                placeholder="Enter name"
                value={props.values.label}
                onChange={(_e, v) => {
                    props.setFieldValue("label", v);
                }}
            />

            <TextField
                fullWidth
                label="Description"
                subLabel="(Optional)"
                type="description"
                required
                placeholder="Enter description"
                value={props.values.description}
                onChange={(_e, v) => {
                    props.setFieldValue("description", v);
                }}
            />
            <StickyFabPlacementHelper
                placement="right"
                sx={{
                    gap: 4,
                    paddingY: 4,
                    backgroundColor: (theme) =>
                        theme.palette.background.container,
                }}
            >
                <WithLoading loading={props.isSubmitting} text="Indexing...">
                    <Button
                        disabled={!props.isValid || !props.dirty}
                        onClick={() => {
                            props.handleSubmit();
                        }}
                    >
                        Index Data
                    </Button>
                </WithLoading>
            </StickyFabPlacementHelper>
        </Stack>
    );
};
