import {
    LogicOperator,
    SavedAreaSortOptions,
    SlimFilterObject,
    useFetchAllAreasQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { DoubleColumnPageLayout } from "@biggeo/bg-ui";
import { LoadingBar, Stack, TopAppBar } from "@biggeo/bg-ui/lab";
import { FolderOutline } from "@biggeo/bg-ui/lab/icons";
import { match } from "@vividtheory/remotedata";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";

import startCase from "lodash/startCase";
import { useState } from "react";
import { NavigationRail } from "../../components/NavigationRail";
import { toNonReadonlyArray } from "../../utils/utils";
import SavedAreasTableContainer from "./SavedAreasTableContainer";

export const AvailableAreasContainer = () => {
    const { remote } = useFetchAllAreasQuery();

    const [selected, setSelected] = useState<number>(1);

    const handleSelectArea = (area: number) => {
        setSelected(area);
    };

    return match(remote, {
        Success: ({ fetchAllAreas: areas }) => {
            return (
                <DoubleColumnPageLayout
                    disablePadding
                    disableSpacing
                    header={
                        <TopAppBar
                            title="Areas"
                            density="dense"
                            alignItems="center"
                        />
                    }
                    leftSidePanelContent={
                        <Stack gap={1}>
                            {pipe(
                                areas,
                                toNonReadonlyArray,
                                A.map((area) => (
                                    <NavigationRail
                                        key={area.id}
                                        selected={selected === area.id}
                                        onClick={() =>
                                            handleSelectArea(area.id)
                                        }
                                        icon={<FolderOutline />}
                                    >
                                        {startCase(area.name)}
                                    </NavigationRail>
                                ))
                            )}
                        </Stack>
                    }
                    slotProps={{
                        headerContainer: {
                            sx: {
                                borderBottom: 1,
                                borderColor: (theme) =>
                                    theme.palette.stroke[100],
                            },
                        },
                        leftSidePanel: {
                            slotProps: {
                                root: {
                                    bgcolor: "transparent",
                                },
                            },
                        },
                    }}
                >
                    <SavedAreasTableContainer areaId={selected} />
                </DoubleColumnPageLayout>
            );
        },
        _: () => <LoadingBar />,
    });
};
