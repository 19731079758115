import { ColorSwatchOption } from "../../../common/components/ColorSwatchSelector";

export type HeatMapColorSwatchOption = {
    readonly datasetId: string;
    readonly swatch: ColorSwatchOption;
};

export const getHeatMapValue = (
    heatMapValue: HeatMapColorSwatchOption[] | undefined,
    dataSourceId?: string
) => heatMapValue?.find((val) => val.datasetId === dataSourceId)?.swatch;
