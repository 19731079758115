import {
    DatabaseType,
    FilterObject,
    SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import * as turf from "@turf/turf";
import compact from "lodash/compact";
import includes from "lodash/includes";
import reverse from "lodash/reverse";
import Feature, { FeatureLike } from "ol/Feature";
// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
import Map from "ol/Map";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import LayerGroup from "ol/layer/Group";
import HeatmapLayer from "ol/layer/Heatmap";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Circle, Fill, Icon, Stroke, Style } from "ol/style";
import { useEffect } from "react";
import { useDatabaseMetaDataArray } from "../../../database-meta-data/redux/hooks";
import { closePolygon } from "../../../utils/utils";
import { FunctionType, generateColorScale } from "../../utils/utils";
import {
    hexToRgb,
    getLayer as initGetLayer,
    getLayerGroup as initGetLayerGroup,
} from "../utils/utils";

export const useData = ({
    map,
    functionType,
    multifilters,
    recentResponse,
    selectedDataset,
    showFiltered,
    showPoints,
    showTriangles,
}: {
    readonly map: React.MutableRefObject<Map | null>;
    readonly functionType: FunctionType;
    readonly multifilters: FilterObject[];
    readonly recentResponse: SubscriptionResponse | undefined;
    readonly showTriangles: boolean;
    readonly showPoints: boolean;
    readonly showFiltered: boolean;
    readonly selectedDataset: readonly string[];
}) => {
    const datasets = useDatabaseMetaDataArray();
    const reversedDatasets = reverse([...datasets]);

    const getIconStyle = () => {
        return new Style({
            image: new Icon({
                src: "/icons/oil-well.png",
                scale: 0.05,
            }),
        });
    };

    const getColor = (feature: FeatureLike, selected?: boolean) => {
        const datasetId: string = feature.get("databaseId");
        const idCadence: number = feature.get("idCadence");
        const dataset = datasets.find((d) => datasetId === d.id);
        const colorSet = generateColorScale(
            selected ? "#ffffff" : dataset?.color || "#ffffff",
            3,
            20
        );

        const color = colorSet.find((_d, idx) => idx === idCadence);
        return new Style({
            fill: new Fill({
                color: color
                    ? `rgba(${hexToRgb(color)}, 0.8)`
                    : "rgba(255, 255, 255, 0.6)",
            }),
            stroke: new Stroke({
                color: "black",
                width: 1,
            }),
        });
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (map.current) {
            const getLayerGroup = initGetLayerGroup(map.current);
            const l = initGetLayer(map.current);
            reverse([...datasets]).forEach((dataset, index) => {
                const _isPolygon = dataset.type === DatabaseType.polygon;
                const selected = includes(selectedDataset, dataset.id);
                const getLayer = l(dataset.id);
                const isOnline = !!multifilters.find(
                    (mf) => mf.databaseId === dataset.id
                );
                const layerGroupVisibility = isOnline && selected;
                const pointVisibility = isOnline && showPoints && selected; //open layer doesn't take the string value, only boolean

                const _pointOpacity = isOnline && showPoints ? 1 : 0; //this pointOpacity is used to color the map ? when it was commented then uncommented, the color shows up then doesn't go away
                const aggregateOpacity =
                    isOnline && showPoints ? dataset.opacity : 0;

                const filteredVisibility = isOnline && showFiltered;
                const _triangleVisibility = isOnline && showTriangles;

                const layerGroup = getLayerGroup<LayerGroup>(dataset.id);
                layerGroup?.setOpacity(dataset.opacity);
                layerGroup?.setVisible(layerGroupVisibility);
                layerGroup?.setZIndex(index);

                const pointLayer =
                    getLayer<VectorLayer<VectorSource<Feature<Geometry>>>>(
                        "points"
                    );

                const filterPointLayer =
                    getLayer<VectorLayer<VectorSource<Feature<Geometry>>>>(
                        "filtered-points"
                    );

                if (pointLayer) {
                    pointLayer.setStyle(
                        dataset.icon === "oil-well"
                            ? getIconStyle()
                            : new Style({
                                  image: new Circle({
                                      radius: 5,
                                      fill: new Fill({
                                          color: dataset.color,
                                      }),
                                      stroke: new Stroke({
                                          color: "black",
                                          width: 2,
                                      }),
                                  }),
                              })
                    );
                }
                if (filterPointLayer) {
                    filterPointLayer.setStyle(
                        new Style({
                            image: new Circle({
                                radius: 5,
                                fill: new Fill({
                                    color: dataset.color,
                                }),
                                stroke: new Stroke({
                                    color: "red",
                                    width: 2,
                                }),
                            }),
                        })
                    );
                }

                const triangleLayer =
                    getLayer<VectorLayer<VectorSource<Feature<Geometry>>>>(
                        "triangle"
                    );

                const polygonsLayer =
                    getLayer<VectorLayer<VectorSource<Feature<Geometry>>>>(
                        "polygons-outline"
                    );
                if (polygonsLayer) {
                    polygonsLayer.setOpacity(aggregateOpacity);
                    polygonsLayer.setStyle((feature) =>
                        getColor(feature, selected)
                    );
                }

                const aggregationLayer = getLayer<HeatmapLayer>("aggregates");
                const filteredAggregationLayer =
                    getLayer<HeatmapLayer>("filtered-aggregate");

                if (aggregationLayer) {
                    const source = aggregationLayer.getSource();
                    const features = source?.getFeatures();

                    aggregationLayer.setOpacity(aggregateOpacity);
                    aggregationLayer.setZIndex(index);

                    aggregationLayer.setGradient(
                        !selected
                            ? ["white", "white", "white", "white"] //initial color when dataset is selected
                            : [
                                  "white",
                                  dataset.color || "white",
                                  "white",
                                  dataset.color || "white",
                                  dataset.color || "white",
                              ]
                    );
                    source?.refresh();
                    if (features) source?.addFeatures(features);
                }

                if (filteredAggregationLayer) {
                    const source = filteredAggregationLayer.getSource();
                    const features = source?.getFeatures();

                    filteredAggregationLayer.setZIndex(index);
                    filteredAggregationLayer.setOpacity(aggregateOpacity);
                    filteredAggregationLayer.setVisible(filteredVisibility);

                    filteredAggregationLayer.setGradient(
                        selected
                            ? ["red", "red", "red", "white", "white"]
                            : [
                                  "red",
                                  "red",
                                  "red",
                                  dataset.color || "#1b97d1",
                                  dataset.color || "#1b97d1",
                              ]
                    );
                    source?.refresh();
                    if (features) source?.addFeatures(features);
                }
                if (triangleLayer) {
                    triangleLayer.setVisible(pointVisibility);
                    triangleLayer.setZIndex(10);
                    triangleLayer.setStyle(
                        new Style({
                            fill: new Fill({
                                color: `rgba(${
                                    dataset.color
                                        ? hexToRgb(dataset.color)
                                        : "0, 0, 0"
                                }, 0.5)`,
                            }),
                        })
                    );
                }
                layerGroup?.changed();
            });
        }
    }, [
        multifilters,
        showPoints,
        showFiltered,
        showTriangles,
        datasets,
        selectedDataset,
    ]);

    const clearData = () => {
        const current = map.current;
        if (current) {
            const layerGroups: LayerGroup[] = current
                .getLayers()
                .getArray()
                .slice(1) as LayerGroup[];

            layerGroups.map((d: LayerGroup) => {
                const id = d.get("id");

                if (
                    id === "radius" ||
                    id === "saved-polygons" ||
                    id === "polygon"
                )
                    return;

                const layers = d.getLayers();

                // biome-ignore lint/complexity/noForEach: <explanation>
                layers.getArray().forEach((layer) => {
                    const vlayer = layer as VectorLayer<
                        VectorSource<Feature<Geometry>>
                    >;

                    vlayer.getSource()?.clear();
                });
            });
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        clearData();
    }, [functionType]);

    const onStyleLoad = (map: Map, _reset?: boolean) => {
        for (const dataset of reverse([...datasets])) {
            const filterHeatmap = new HeatmapLayer({
                properties: {
                    id: `${dataset.id}-filtered-aggregate-layer`,
                },
                source: new VectorSource({ format: new GeoJSON() }),
                blur: 8,
                opacity: dataset.opacity,
                zIndex: reversedDatasets.indexOf(dataset),
                gradient: [
                    "red",
                    "red",
                    "red",
                    dataset.color || "#638746",
                    dataset.color || "#638746",
                ],
                maxZoom: 15,
                radius: 25,
            });

            const heatMapLayer = new HeatmapLayer({
                properties: { id: `${dataset.id}-aggregates-layer` },
                source: new VectorSource({ format: new GeoJSON() }),
                maxZoom: 15,
                opacity: dataset.opacity,
                zIndex: reversedDatasets.indexOf(dataset),
                gradient: [
                    "white",
                    dataset.color || "white",
                    "white",
                    dataset.color || "#638746",
                    dataset.color || "#638746",
                ],
                // maxZoom: 15,

                radius: 25,
            });
            const filterLayer = new VectorLayer({
                source: new VectorSource(),
                properties: {
                    id: `${dataset.id}-filtered-points-layer`,
                },
                minZoom: 14,

                style: new Style({
                    image: new Circle({
                        radius: 5,
                        fill: new Fill({ color: dataset.color }),
                        stroke: new Stroke({
                            color: "red",
                            width: 2,
                        }),
                    }),
                }),
            });
            const layer = new VectorLayer({
                source: new VectorSource(),
                properties: { id: `${dataset.id}-points-layer` },
                minZoom: 14,

                style: new Style({
                    image: new Circle({
                        radius: 5,
                        fill: new Fill({ color: dataset.color }),
                        stroke: new Stroke({
                            color: "black",
                            width: 2,
                        }),
                    }),
                }),
            });

            const _triangleLayer = new VectorLayer({
                source: new VectorSource(),
                properties: { id: `${dataset.id}-triangle-layer` },
                visible: false,
                zIndex: reversedDatasets.indexOf(dataset),
                style: new Style({
                    fill: new Fill({
                        color: `rgba(${
                            dataset.color ? hexToRgb(dataset.color) : "0, 0, 0"
                        }, 0.5)`,
                    }),
                }),
            });

            const polygonsLayer = new VectorLayer({
                source: new VectorSource(),
                properties: {
                    id: `${dataset.id}-polygons-outline-layer`,
                },

                zIndex: reversedDatasets.indexOf(dataset),
                style: (feature) => {
                    const datasetId: string = feature.get("databaseId");
                    const idCadence: number = feature.get("idCadence");
                    const dataset = datasets.find((d) => datasetId === d.id);
                    const colorSet = generateColorScale(
                        dataset?.color || "#ffffff",
                        3,
                        20
                    );
                    const color = colorSet.find((_d, idx) => idx === idCadence);
                    return new Style({
                        fill: new Fill({
                            color: color
                                ? `rgba(${hexToRgb(color)}, 0.8)`
                                : "rgba(255, 255, 255, 0.6)",
                        }),
                        stroke: new Stroke({
                            color: "black",
                            width: 1,
                        }),
                    });
                },
            });

            const layerGroup = new LayerGroup({
                properties: { id: `${dataset.id}` },
                layers: [
                    polygonsLayer,
                    // triangleLayer, //not including triangeLayer as it creates triangles shadows when dataset visibility is selected
                    layer,
                    heatMapLayer,
                    filterLayer,
                    filterHeatmap,
                ],
                zIndex: reversedDatasets.indexOf(dataset),
            });
            map.addLayer(layerGroup);
        }
    };

    const addNonGeoDataFromResponse = (response: SubscriptionResponse) => {
        const format = new GeoJSON({
            dataProjection: "EPSG:4326",
            featureProjection: "EPSG:3857",
        });
        const data = response.data;

        const layerGroups: LayerGroup[] = map.current
            ?.getLayers()
            .getArray()
            .slice(1) as LayerGroup[];

        layerGroups.map((d: LayerGroup) => {
            const id = d.get("id");

            if (response.databaseId !== id) return;

            const layers = d.getLayers();

            // biome-ignore lint/complexity/noForEach: <explanation>
            layers.getArray().forEach((layer) => {
                const layerId = layer.get("id");

                if (layerId === `${id}-filtered-points-layer`) {
                    if (data?.filteredPoints !== undefined) {
                        const filteredPoints = data.filteredPoints.map(
                            (d: { LON: number; LAT: number; ID: number }) => {
                                // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                                const point = [d["LON"], d["LAT"]];
                                return turf.point(point, {
                                    // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                                    id: d["ID"],
                                    databaseId: response.databaseId,
                                    count: 1,
                                    label: `Point ${
                                        // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                                        d["ID"]
                                    }`,
                                });
                            }
                        );
                        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                        const filteredData: any = {
                            type: "FeatureCollection",
                            features: [...filteredPoints],
                        } as const;

                        const source = new VectorSource({
                            features: format.readFeatures(filteredData),
                        }) as VectorSource<Feature<Geometry>>;

                        const vectorLayer = layer as VectorLayer<
                            VectorSource<Feature<Geometry>>
                        >;

                        vectorLayer.setSource(source);
                    }
                }

                if (layerId === `${id}-filtered-aggregate-layer`) {
                    if (data?.filtered !== undefined) {
                        const arrayOfCounts = data.filtered.map(
                            (d: { LON: number; LAT: number; COUNT: number }) =>
                                d.COUNT
                        );
                        const max = Math.max(...arrayOfCounts);

                        const min = Math.min(...arrayOfCounts);

                        const filteredAvgPoints = data.filtered.map(
                            (d: {
                                LON: number;
                                LAT: number;
                                COUNT: number;
                            }) => {
                                // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                                const point = [d["LON"], d["LAT"]];
                                return turf.point(point, {
                                    // biome-ignore lint/complexity/useLiteralKeys: <explanation>
                                    count: d["COUNT"],
                                    max: max,
                                    min: min,
                                    // biome-ignore lint/style/noUnusedTemplateLiteral: <explanation>
                                    label: `Filtered Aggregate`,
                                    databaseId: response.databaseId,
                                });
                            }
                        );

                        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                        const filteredData: any = {
                            type: "FeatureCollection",
                            features: [...filteredAvgPoints],
                        } as const;

                        const source = new VectorSource({
                            features: format.readFeatures(filteredData),
                        }) as VectorSource<Feature<Geometry>>;

                        const heatmapLayer = layer as HeatmapLayer;

                        const heatmapSource = heatmapLayer.getSource();

                        // Clearing old data
                        heatmapSource?.refresh();
                        // Adding new data
                        heatmapSource?.addFeatures(source.getFeatures());
                    }
                }
            });
        });
    };

    const addDataFromResponse = (response: SubscriptionResponse) => {
        const dataset = datasets.find((d) => response.databaseId === d.id);
        const format = new GeoJSON({
            dataProjection: "EPSG:4326",
            featureProjection: "EPSG:3857",
        });
        const data = response.geometry || {
            aggregation: [],
            points: [],
            nonPoints: [],
        };

        const maxRez =
            data.aggregation[data.aggregation.length - 1]?.cellID.split("_")[1]
                ?.length;
        const arrayOfCounts = data.aggregation.map((d) => d.count);
        const max = Math.max(...arrayOfCounts);

        const min = Math.min(...arrayOfCounts);

        const triangles = compact(
            data.aggregation.map((d) => {
                const points = d.cellVertices.points.map((p) => {
                    return [p.longitude, p.latitude];
                });

                const res = d.cellID.split("_")[1].length;

                if (res !== maxRez) {
                    return undefined;
                }
                return turf.polygon([closePolygon(points)], {
                    count: d.count,

                    databaseId: response.databaseId,
                });
            })
        );

        const polygons = compact(
            data.nonPoints.map((p) => {
                const polypoints = p?.polygon?.outer?.points || [];
                const points = polypoints.map((p) => {
                    return [p.longitude, p.latitude];
                });
                if (points.length === 0) {
                    return undefined;
                }
                return turf.polygon([closePolygon(points)], {
                    count: 1,
                    label: `Polygon ${p.id}`,
                    id: p.id,
                    databaseId: response.databaseId,
                    idCadence: p.id.uint64 ? p.id.uint64.low % 20 : p.id.string,
                });
            })
        );

        const points = data.points.map((p) => {
            const point = p.point
                ? [p.point.longitude, p.point.latitude]
                : [0, 0];
            return turf.point(point, {
                count: 1,
                id: p.id,
                label: `Point ${p.id}`,
                databaseId: response.databaseId,
            });
        });

        const actuallyCentroids = data.aggregation.map((d) => {
            const point = [d.meanPoint.longitude, d.meanPoint.latitude];
            return turf.point(point, {
                count: d.count,
                label: `Aggregate ${d.cellID}`,
                databaseId: response.databaseId,
                max: max,
                min: min,
            });
        });

        const lines = compact(
            data.nonPoints.map((l) => {
                const linePoints = l?.lineStrip?.points || [];
                const points = linePoints.map((p) => {
                    return [p.longitude, p.latitude];
                });
                if (points.length === 0) {
                    return undefined;
                }

                return turf.lineString(points, {
                    count: 1,
                    label: `Line ${l.id}`,
                    id: l.id,
                    databaseId: response.databaseId,
                    idCadence: l.id.uint64 ? l.id.uint64.low % 20 : l.id.string,
                    line: "true",
                });
            })
        );

        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        const triangleData: any = turf.featureCollection(triangles);
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        const polygonsData: any = turf.featureCollection([
            ...polygons,
            ...lines,
        ]);
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        const centroidData: any = {
            type: "FeatureCollection",
            features: [...actuallyCentroids, ...points],
        } as const;

        const polygonsSource = new VectorSource({
            features: format.readFeatures(polygonsData),
        }) as VectorSource<Feature<Geometry>>;

        const triangleSource = new VectorSource({
            features: format.readFeatures(triangleData),
        }) as VectorSource<Feature<Geometry>>;

        const source = new VectorSource({
            features: format.readFeatures(centroidData),
        }) as VectorSource<Feature<Geometry>>;
        // Slice out the TileLayer
        const layerGroups: LayerGroup[] = map.current
            ?.getLayers()
            .getArray()
            .slice(1) as LayerGroup[];

        const zoom = map.current?.getView().getZoom();
        // Map over all Layer Groups and update data where necessary
        layerGroups.map((d: LayerGroup) => {
            const id = d.get("id");

            if (response.databaseId !== id) return;

            const layers = d.getLayers();

            // biome-ignore lint/complexity/noForEach: <explanation>
            layers.getArray().forEach((layer) => {
                const layerId = layer.get("id");

                if (layerId === `${id}-polygons-outline-layer`) {
                    const vectorLayer = layer as VectorLayer<
                        VectorSource<Feature<Geometry>>
                    >;

                    vectorLayer.getSource()?.clear();
                    vectorLayer
                        ?.getSource()
                        ?.addFeatures(polygonsSource.getFeatures());
                }

                if (layerId === `${id}-triangle-layer`) {
                    const vectorLayer = layer as VectorLayer<
                        VectorSource<Feature<Geometry>>
                    >;

                    vectorLayer.getSource()?.clear();
                    vectorLayer
                        ?.getSource()
                        ?.addFeatures(triangleSource.getFeatures());
                }
                if (layerId === `${id}-points-layer`) {
                    // Handle Points Layer - Points
                    const vectorLayer = layer as VectorLayer<
                        VectorSource<Feature<Geometry>>
                    >;

                    vectorLayer.setSource(source);
                }

                // Handle Aggregate Layer - Heatmap
                if (layerId === `${id}-aggregates-layer`) {
                    const heatmapLayer = layer as HeatmapLayer;
                    if (
                        layerId === `${id}-aggregates-layer` ||
                        layerId === `${id}-filtered-aggregate-layer`
                    ) {
                        if (zoom && zoom < 15) {
                            heatmapLayer.setRadius(25);
                            heatmapLayer.setOpacity(dataset?.opacity || 0);
                        }
                        if (zoom && zoom > 14) {
                            heatmapLayer.setOpacity(0.5);
                        }
                        if (zoom && zoom > 15) {
                            heatmapLayer.setOpacity(0);
                            heatmapLayer.setRadius(20);
                        }
                    }

                    const heatmapSource = heatmapLayer.getSource();

                    // Clearing old data
                    heatmapSource?.clear();
                    // Adding new data
                    heatmapSource?.addFeatures(source.getFeatures());
                }
            });
        });
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (recentResponse) {
            if (recentResponse.geometry) {
                addDataFromResponse(recentResponse);
            }
            if (recentResponse.data) {
                addNonGeoDataFromResponse(recentResponse);
            }
        }
    }, [recentResponse?.uniqueId]);

    return { onStyleLoad };
};
