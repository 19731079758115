import { WithLoading } from "@biggeo/bg-ui";
import {
    BreadcrumbsButton,
    BreadcrumbsGroup,
    Button,
    Stack,
    StickyFabPlacementHelper,
    Typography,
} from "@biggeo/bg-ui/lab";
import { CenteredNestedLayoutWithHeader } from "@biggeo/bg-ui/lab/layouts";
import { Formik } from "formik";
import Zod from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { Routes } from "../../navigation/redux/model.ts";
import MarketplaceUpgradeFormView from "./MarketplaceUpgradeFormView.tsx";

interface IUpgradeForm {
    readonly navigate: (to?: string) => void;
    readonly confirm: (input: UpgradeInfo) => void;
    readonly loading: boolean;
}

export type UpgradeInfo = {
    price: number;
    licenseKey: boolean;
    snowflakeCredit: boolean;
    key: string;
    keyMatched: boolean;
};

const MarketplaceUpgradeForm = ({
    confirm,
    navigate,
    loading,
}: IUpgradeForm) => {
    const initialValues = {
        price: 5000,
        licenseKey: true,
        snowflakeCredit: false,
        key: "",
        keyMatched: false,
    };

    return (
        <Formik
            validateOnMount
            initialValues={initialValues}
            onSubmit={(values) => {
                confirm(values);
            }}
            validationSchema={toFormikValidationSchema(
                Zod.object({
                    key: Zod.string(),
                    licenseKey: Zod.boolean(),
                    snowflakeCredit: Zod.boolean(),
                    keyMatched: Zod.boolean(),
                })
            )}
        >
            {({
                values,
                setValues,
                handleSubmit,
                dirty,
                isValid,
                isSubmitting,
            }) => {
                const onChange = (i: Partial<UpgradeInfo>) => {
                    setValues((p) => ({ ...p, ...i }));
                };

                return (
                    <CenteredNestedLayoutWithHeader
                        title={
                            <BreadcrumbsGroup value={"upgrade-velocity"}>
                                <BreadcrumbsButton
                                    value="marketplace"
                                    onClick={() => navigate()}
                                >
                                    Marketplace
                                </BreadcrumbsButton>
                                <BreadcrumbsButton
                                    value={"upgrade-velocity"}
                                    onClick={() =>
                                        navigate(
                                            `${Routes.marketplace}/upgrade`
                                        )
                                    }
                                    hideSeparator
                                >
                                    Upgrade Velocity
                                </BreadcrumbsButton>
                            </BreadcrumbsGroup>
                        }
                    >
                        <Stack gap={4}>
                            <Stack gap={0.5}>
                                <Typography variant="title1" fontWeight="bold">
                                    Your Order
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="disabled"
                                    colorSet="container"
                                    invertColors
                                >
                                    See the details of your order below
                                </Typography>
                            </Stack>
                            <MarketplaceUpgradeFormView
                                values={values}
                                onChange={onChange}
                            />
                            <StickyFabPlacementHelper
                                placement="right"
                                sx={{
                                    paddingY: 4,
                                    backgroundColor: (theme) =>
                                        theme.palette.background.container,
                                }}
                            >
                                <Stack
                                    flexDirection="row"
                                    justifyContent="flex-end"
                                >
                                    <WithLoading
                                        loading={loading}
                                        text={"Creating..."}
                                    >
                                        <Button
                                            type="submit"
                                            disabled={
                                                !isValid ||
                                                !dirty ||
                                                isSubmitting
                                            }
                                            onClick={() => handleSubmit()}
                                        >
                                            Confirm Order
                                        </Button>
                                    </WithLoading>
                                </Stack>
                            </StickyFabPlacementHelper>
                        </Stack>
                    </CenteredNestedLayoutWithHeader>
                );
            }}
        </Formik>
    );
};

export default MarketplaceUpgradeForm;
