import { SvgIcon, SvgIconProps } from "@biggeo/bg-ui";
const SvgTwoDOutline = (props: SvgIconProps) => (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
        <path
            d="M5.577 12.3718H9.07056V11.3782H6.57056V10.3686H8.33339C8.5428 10.3686 8.71801 10.2981 8.85904 10.157C9.00005 10.016 9.07056 9.84082 9.07056 9.63142V8.3654C9.07056 8.15599 9.00005 7.98078 8.85904 7.83975C8.71801 7.69872 8.5428 7.62821 8.33339 7.62821H5.577V8.62179H8.077V9.63142H6.31416C6.10476 9.63142 5.92955 9.70193 5.78854 9.84296C5.64751 9.98397 5.577 10.1592 5.577 10.3686V12.3718ZM10.7693 12.3718H13.8141C14.0556 12.3718 14.2602 12.2879 14.4279 12.1202C14.5957 11.9525 14.6795 11.7479 14.6795 11.5064V8.4936C14.6795 8.25215 14.5957 8.04755 14.4279 7.87981C14.2602 7.71208 14.0556 7.62821 13.8141 7.62821H10.7693V12.3718ZM11.7629 11.3782V8.62179H13.5577C13.5898 8.62179 13.6192 8.63515 13.6459 8.66185C13.6726 8.68856 13.686 8.71794 13.686 8.75V11.25C13.686 11.2821 13.6726 11.3114 13.6459 11.3381C13.6192 11.3649 13.5898 11.3782 13.5577 11.3782H11.7629ZM4.42316 17.0833C4.00222 17.0833 3.64591 16.9375 3.35425 16.6458C3.06258 16.3541 2.91675 15.9978 2.91675 15.5769V4.4231C2.91675 4.00216 3.06258 3.64585 3.35425 3.35419C3.64591 3.06252 4.00222 2.91669 4.42316 2.91669H15.577C15.9979 2.91669 16.3542 3.06252 16.6459 3.35419C16.9375 3.64585 17.0834 4.00216 17.0834 4.4231V15.5769C17.0834 15.9978 16.9375 16.3541 16.6459 16.6458C16.3542 16.9375 15.9979 17.0833 15.577 17.0833H4.42316ZM4.42316 15.8333H15.577C15.6411 15.8333 15.6998 15.8066 15.7533 15.7532C15.8067 15.6998 15.8334 15.641 15.8334 15.5769V4.4231C15.8334 4.35899 15.8067 4.30022 15.7533 4.24679C15.6998 4.19338 15.6411 4.16667 15.577 4.16667H4.42316C4.35905 4.16667 4.30028 4.19338 4.24685 4.24679C4.19344 4.30022 4.16673 4.35899 4.16673 4.4231V15.5769C4.16673 15.641 4.19344 15.6998 4.24685 15.7532C4.30028 15.8066 4.35905 15.8333 4.42316 15.8333Z"
            fill="currentColor"
        />
    </SvgIcon>
);
export default SvgTwoDOutline;
