import {
    DatabaseType,
    type FilterObject,
    type SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import * as turf from "@turf/turf";
import compact from "lodash/compact";
import includes from "lodash/includes";
import reverse from "lodash/reverse";
import type mapboxgl from "mapbox-gl";
import type { GeoJSONSource } from "mapbox-gl";
import { useEffect } from "react";
import { type DatasetPointShape } from "../../../components/DatapointShape/types";
import { useDatabaseMetaDataArray } from "../../../database-meta-data/redux/hooks";
import { closePolygon } from "../../../utils/utils";
import { levelSetPercentiles } from "../../../utils/variables";
import { generateColorScale, handleAntiMeridian } from "../../utils/utils";
import { DEFAULT_SHAPE_COLOR } from "./style-hooks";

export type DataHookProps = {
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly multifilters: FilterObject[];
    readonly recentResponse: SubscriptionResponse | undefined;
    readonly showTriangles: boolean;
    readonly showPoints: boolean;
    readonly showFiltered: boolean;
    readonly selectedDataset: readonly string[];
    readonly datasetShape?: readonly DatasetPointShape[];
    readonly style?: mapboxgl.Style;
    readonly isLoaded: boolean;
};

export const useData = ({
    map,
    multifilters,
    recentResponse,
    showFiltered,
    showPoints,
    showTriangles,
    selectedDataset,
    datasetShape,
    style,
    isLoaded,
}: DataHookProps) => {
    const datasets = useDatabaseMetaDataArray();

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        // Set visibility
        if (map.current && isLoaded) {
            for (const dataset of reverse([...datasets])) {
                const isPolygon = dataset.type === DatabaseType.polygon;
                const isPoint = dataset.type === DatabaseType.point;

                const colorSet = generateColorScale(
                    dataset.color || "#ffffff",
                    3,
                    20
                );

                const colorMap = colorSet.flatMap((color, idx) => [
                    idx,
                    `${color}`,
                ]);
                const isOnline = !!multifilters.find(
                    (mf) => mf.databaseId === dataset.id
                );
                const pointVisibility =
                    isOnline &&
                    showPoints &&
                    includes(selectedDataset, dataset.id)
                        ? "visible"
                        : "none";

                const triangleVisibility =
                    isOnline && showTriangles ? "visible" : "none";
                // Visibility
                map.current?.setLayoutProperty(
                    `${dataset.id}-icons`,
                    "visibility",
                    dataset.icon ? pointVisibility : "none"
                );
                map.current?.setLayoutProperty(
                    `${dataset.id}-icons`,
                    "icon-image",
                    dataset.icon || "oil-well"
                );
                map.current?.setLayoutProperty(
                    `${dataset.id}-icons`,
                    "icon-size",
                    dataset.icon === "oil-well" ? 0.05 : 1.25
                );
                map.current?.setLayoutProperty(
                    `${dataset.id}-points-layer`,
                    "visibility",
                    dataset.icon ? "none" : pointVisibility
                );
                map.current?.setLayoutProperty(
                    `${dataset.id}-points-heatmap-layer`,
                    "visibility",
                    pointVisibility
                );

                for (let i = 0; i < levelSetPercentiles.length; ++i) {
                    map.current?.setLayoutProperty(
                        `${dataset.id}-levelset-${i}`,
                        "visibility",
                        isPolygon
                            ? pointVisibility
                            : isPoint &&
                                triangleVisibility === "visible" &&
                                pointVisibility === "visible"
                              ? "visible"
                              : "none"
                    );
                }
                map.current?.setLayoutProperty(
                    `${dataset.id}-polygons`,
                    "visibility",
                    pointVisibility
                );
                map.current?.setLayoutProperty(
                    `${dataset.id}-polygons-outline`,
                    "visibility",
                    pointVisibility
                );

                // zIndex
                map.current.moveLayer(`${dataset.id}-icons`);
                map.current.moveLayer(`${dataset.id}-points-layer`);
                map.current.moveLayer(`${dataset.id}-points-heatmap-layer`);
                for (let i = 0; i < levelSetPercentiles.length; ++i) {
                    map.current.moveLayer(`${dataset.id}-levelset-${i}`);
                }
                map.current.moveLayer(`${dataset.id}-polygons`);
                map.current.moveLayer(`${dataset.id}-polygons-outline`);
                map.current.moveLayer("gl-map-shapes-fill.cold");
                map.current.moveLayer("gl-map-shapes-line.cold");
                map.current.moveLayer("gl-map-shapes-fill.hot");
                map.current.moveLayer("gl-map-shapes-line.hot");

                // Color
                map.current?.setPaintProperty(
                    `${dataset.id}-points-layer`,
                    "circle-color",
                    `${dataset.color || "blue"}`
                );
                map.current?.setPaintProperty(
                    `${dataset.id}-points-heatmap-layer`,
                    "heatmap-opacity",
                    {
                        default: dataset.opacity,
                        stops: [
                            [14, dataset.opacity],
                            [15, 0],
                        ],
                    }
                );
                map.current?.setPaintProperty(
                    `${dataset.id}-points-layer`,
                    "circle-stroke-opacity",
                    {
                        default: dataset.opacity,
                        stops: [
                            [14, 0],
                            [15, dataset.opacity],
                        ],
                    }
                );
                map.current?.setPaintProperty(
                    `${dataset.id}-points-layer`,
                    "circle-opacity",
                    {
                        default: dataset.opacity,
                        stops: [
                            [14, 0],
                            [15, dataset.opacity],
                        ],
                    }
                );

                map.current?.setPaintProperty(
                    `${dataset.id}-polygons`,
                    "fill-opacity",
                    ["match", ["get", "line"], "true", 0, dataset.opacity]
                );
                map.current?.setPaintProperty(
                    `${dataset.id}-polygons-outline`,
                    "line-opacity",
                    dataset.opacity
                );
                map.current?.setPaintProperty(
                    `${dataset.id}-polygons`,
                    "fill-color",
                    [
                        "match",
                        ["get", "idCadence"],
                        ...colorMap,
                        dataset.color || "blue", // Default color
                    ]
                );

                map.current?.setPaintProperty(
                    `${dataset.id}-polygons-outline`,
                    "line-color",
                    [
                        "match",
                        ["get", "line"],
                        "true",
                        dataset.color || "blue", // Default color,
                        "black",
                    ]
                );
            }
            map.current.moveLayer("settlement-minor-label");
            map.current.moveLayer("settlement-major-label");
            map.current.moveLayer("state-label");
            map.current.moveLayer("country-label");
        }
    }, [
        multifilters,
        showPoints,
        showFiltered,
        showTriangles,
        datasets,
        selectedDataset,
        datasetShape,
        isLoaded,
    ]);

    const onStyleLoad = (map: mapboxgl.Map) => {
        map.addSource("polygons-from-ai", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
        });

        // LAYERS
        map.addLayer({
            type: "circle",
            source: "polygons-from-ai",
            paint: {
                "circle-radius": 3,
                "circle-color": DEFAULT_SHAPE_COLOR,
            },
            id: "polygons-from-ai-layer",
        });
        for (const dataset of reverse([...datasets])) {
            const colorSet = generateColorScale(
                dataset.color || "#ffffff",
                3,
                20
            );

            const colorMap = colorSet.flatMap((color, idx) => [
                idx,
                `${color}`,
            ]);

            // SOURCE
            map.addSource(`${dataset.id}-points`, {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [],
                },
            });
            map.addSource(`${dataset.id}-aggregate`, {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [],
                },
            });
            map.addSource(`${dataset.id}-polygons`, {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [],
                },
            });

            // This really should be synced up with the percentiles somewhere

            for (let i = 0; i < levelSetPercentiles.length; ++i) {
                map.addSource(`${dataset.id}-levelset-${i}`, {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [],
                    },
                });
            }

            // LAYERS
            map.addLayer({
                id: `${dataset.id}-points-layer`,
                type: "circle",
                source: `${dataset.id}-points`,
                layout: {
                    visibility: "visible",
                },
                paint: {
                    "circle-radius": 4,
                    "circle-color": `${dataset.color || "blue"}`,
                    "circle-stroke-color": "black",
                    "circle-stroke-width": 2,
                    "circle-stroke-opacity": {
                        default: dataset.opacity,
                        stops: [
                            [14, 0],
                            [15, dataset.opacity],
                        ],
                    },
                    "circle-opacity": {
                        default: dataset.opacity,
                        stops: [
                            [14, 0],
                            [15, dataset.opacity],
                        ],
                    },
                },
            });

            const heatmapColorSet = generateColorScale(
                dataset.color || "#ffffff",
                1.5,
                3
            );

            map.addLayer({
                id: `${dataset.id}-points-heatmap-layer`, //id: "data-heat", TODO
                type: "heatmap",
                source: `${dataset.id}-aggregate`,
                paint: {
                    "heatmap-weight": [
                        "interpolate",
                        ["linear"],
                        ["get", "count"],
                        0,
                        0,
                        3000,
                        20,
                    ],
                    // increase intensity as zoom level increases
                    "heatmap-intensity": {
                        stops: [
                            [8, 1],
                            [11, 3],
                        ],
                    },
                    "heatmap-radius": 15,
                    // assign color values be applied to points depending on their density
                    "heatmap-color": [
                        "interpolate",
                        ["linear"],
                        ["heatmap-density"],
                        0,
                        "rgba(255,255,255,0)",
                        0.1,
                        heatmapColorSet[0],
                        0.5,
                        heatmapColorSet[1],
                        1,
                        heatmapColorSet[2],
                    ],
                    // decrease opacity to transition into the circle layer
                    "heatmap-opacity": {
                        default: dataset.opacity,
                        stops: [
                            [14, dataset.opacity],
                            [15, 0],
                        ],
                    },
                },
            });
            map.addLayer({
                id: `${dataset.id}-polygons`,
                type: "fill",
                source: `${dataset.id}-polygons`, // reference the data source
                layout: {
                    visibility: "visible",
                },
                paint: {
                    "fill-color": [
                        "match",
                        ["get", "idCadence"],
                        ...colorMap,
                        dataset.color || "blue", // Default color
                    ],
                    "fill-opacity": [
                        "match",
                        ["get", "line"],
                        "true",
                        0,
                        dataset.opacity,
                    ],
                    "fill-antialias": false,
                },
            });

            // Level set
            for (let i = 0; i < levelSetPercentiles.length; ++i) {
                map.addLayer({
                    id: `${dataset.id}-levelset-${i}`,
                    type: "fill",
                    source: `${dataset.id}-levelset-${i}`, // reference the data source
                    layout: {
                        visibility: "visible",
                    },
                    paint: {
                        "fill-color": [
                            "match",
                            ["get", "idCadence"],
                            ...colorMap,
                            dataset.color || "blue", // Default color
                        ],
                        "fill-opacity": [
                            "match",
                            ["get", "line"],
                            "true",
                            0,
                            dataset.isMipmapped
                                ? 0.2
                                : dataset.type === DatabaseType.polygon
                                  ? 0.3
                                  : 0.15,
                        ],
                        "fill-antialias": false,
                    },
                });
                map.loadImage("/icons/oil-well.png", (error, image) => {
                    if (error) throw error;

                    // Add the image to the map style.
                    // biome-ignore lint/suspicious/noExplicitAny: allow any
                    map.addImage("oil-well", image as any);

                    // Add a layer to use the image to represent the data.
                    map.addLayer({
                        id: `${dataset.id}-icons`,
                        type: "symbol",
                        source: `${dataset.id}-points`, // reference the data source

                        layout: {
                            "icon-image": dataset.icon || "oil-well", // reference the image
                            "icon-size": 1,
                            visibility: dataset.icon ? "visible" : "none",
                        },
                        paint: {
                            "icon-opacity": {
                                default: dataset.opacity,
                                stops: [
                                    [14, 0],
                                    [15, dataset.opacity],
                                ],
                            },
                        },
                    });
                });
            }
        }
        for (const dataset of reverse([...datasets])) {
            map.addLayer({
                id: `${dataset.id}-polygons-outline`,
                type: "line",
                source: `${dataset.id}-polygons`,
                layout: {
                    visibility: "visible",
                },
                paint: {
                    "line-color": [
                        "match",
                        ["get", "line"],
                        "true",
                        dataset.color || "blue", // Default color,
                        "black",
                    ],
                    "line-width": 3,
                    "line-opacity": dataset.opacity || 0.5,
                },
            });
        }
    };

    const clearData = () => {
        // biome-ignore lint/suspicious/noExplicitAny: allow any
        const newData: any = {
            type: "FeatureCollection",
            features: [],
        } as const;
        for (const dataset of datasets) {
            (
                map.current?.getSource(
                    `${dataset.id}-aggregate`
                ) as GeoJSONSource
            )?.setData(newData);
            (
                map.current?.getSource(`${dataset.id}-points`) as GeoJSONSource
            )?.setData(newData);
            (
                map.current?.getSource(
                    `${dataset.id}-polygons`
                ) as GeoJSONSource
            )?.setData(newData);
            (
                map.current?.getSource(
                    `${dataset.id}-filtered-aggregate`
                ) as GeoJSONSource
            )?.setData(newData);
        }
    };

    const addDataFromResponse = (response: SubscriptionResponse) => {
        const data = response.geometry || {
            aggregation: [],
            points: [],
            nonPoints: [],
            levelSets: [],
        };

        const _maxRez =
            data.aggregation[data.aggregation.length - 1]?.cellID.split("_")[1]
                ?.length;

        const actuallyCentroids = compact(
            data.aggregation.map((d) => {
                const point = [d.meanPoint.longitude, d.meanPoint.latitude];
                return d.count
                    ? response.geometry?.pointCount === 0
                        ? undefined
                        : turf.point(point, {
                              label: `Aggregate ${d.cellID}`,
                              count: d.count,
                              databaseId: response.databaseId,
                          })
                    : undefined;
            })
        );

        const points = data.points.map((p) => {
            const point = p.point
                ? [p.point.longitude, p.point.latitude]
                : [0, 0];
            return turf.point(point, {
                label: `Point ${p.id.uint64 ? p.id.uint64.low : p.id.string}`,
                count: 1,
                id: JSON.stringify(p.id),
                databaseId: response.databaseId,
            });
        });

        const polygons = compact(
            data.nonPoints.map((p) => {
                const polypoints = p?.polygon?.outer?.points || [];
                const points = polypoints.map((p) => {
                    return [p.longitude, p.latitude];
                });
                if (points.length < 3) {
                    return undefined;
                }

                return turf.polygon([closePolygon(points)], {
                    count: 1,
                    label: `Polygon ${p.id.uint64 ? p.id.uint64.low : p.id.string}`,
                    id: JSON.stringify(p.id),
                    databaseId: response.databaseId,
                    idCadence: p.id.uint64 ? p.id.uint64.low % 20 : p.id.string,
                });
            })
        );
        const multiPolygons =
            data?.nonPoints?.flatMap((mp) => {
                const polygons = compact(
                    mp.multipolygon?.polygons.map((p) => {
                        const polypoints = p?.outer?.points || [];
                        const points = polypoints.map((p) => {
                            return [p.longitude, p.latitude];
                        });
                        if (points.length < 3) {
                            return undefined;
                        }

                        return turf.polygon([closePolygon(points)], {
                            count: 1,
                            label: `Multi-Polygon ${mp.id}`,
                            id: JSON.stringify(mp.id),
                            databaseId: response.databaseId,
                            idCadence: mp.id.uint64
                                ? mp.id.uint64.low % 20
                                : mp.id.string,
                        });
                    })
                );

                return polygons;
            }) || [];

        const lines = compact(
            data.nonPoints.map((l) => {
                const linePoints = l?.lineStrip?.points || [];

                const points = handleAntiMeridian(linePoints);

                return points.length < 3
                    ? []
                    : turf.lineString(points, {
                          count: 1,
                          label: `Line ${l.id}`,
                          id: l.id,
                          databaseId: response.databaseId,
                          //   idCadence: l.id % 20,
                          line: "true",
                      });
            })
        );

        const levelSets = data.levelSets.map((ls) => {
            return compact(
                ls?.rings.map((r) => {
                    const ringPoints = r?.points || [];

                    const points = handleAntiMeridian(ringPoints);

                    return turf.polygon([closePolygon(points)], {
                        count: 1,
                        label: "Levelset triangle",
                        id: 12,
                        databaseId: response.databaseId,
                        idCadence: 12 % 20,
                    });
                })
            );
        });
        // biome-ignore lint/suspicious/noExplicitAny: allow any
        const centroidData: any = {
            type: "FeatureCollection",
            features: [...actuallyCentroids, ...points],
        } as const;
        // biome-ignore lint/suspicious/noExplicitAny: allow any
        const pointData: any = {
            type: "FeatureCollection",
            features: [...points],
        } as const;
        // biome-ignore lint/suspicious/noExplicitAny: allow any
        const polygonData: any = {
            type: "FeatureCollection",
            features: [...polygons, ...multiPolygons, ...lines],
        } as const;

        // biome-ignore lint/suspicious/noExplicitAny: allow any
        const levelSetData: any = levelSets.map((ls) => {
            return {
                type: "FeatureCollection",
                features: [...ls],
            };
        });

        // TODO: Don't do this work, layer is disabled
        (
            map.current?.getSource(
                `${response.databaseId}-aggregate`
            ) as GeoJSONSource
        )?.setData(centroidData);
        (
            map.current?.getSource(
                `${response.databaseId}-points`
            ) as GeoJSONSource
        )?.setData(pointData);
        (
            map.current?.getSource(
                `${response.databaseId}-polygons`
            ) as GeoJSONSource
        )?.setData(polygonData);

        for (let i = 0; i < levelSetData.length; ++i) {
            (
                map.current?.getSource(
                    `${response.databaseId}-levelset-${i}`
                ) as GeoJSONSource
            )?.setData(levelSetData[i]);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        if (recentResponse) {
            if (recentResponse.geometry?._tag !== "error") {
                addDataFromResponse(recentResponse);
            }
        }
    }, [recentResponse?.uniqueId, style]);

    return {
        onStyleLoad,
        clearData,
        showTriangles,
        showPoints,
        showFiltered,
    };
};
