import {
    AspectRatio,
    Button,
    Image,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import { OpenInNewOutline } from "@biggeo/bg-ui/lab/icons";
import { theme } from "@biggeo/bg-ui/theme";

const EmptyDatasetView = () => {
    return (
        <Stack
            gap={6}
            height="100%"
            sx={{
                paddingX: 2,
                paddingTop: 2,
                breakpoints: {
                    md: {
                        paddingX: 8,
                        paddingTop: 8,
                    },
                },
            }}
        >
            <Stack gap={4}>
                <Stack gap={2}>
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                        sx={{ display: "inline" }}
                    >
                        No Data Sources Added
                    </Typography>
                    <Typography
                        variant="body1"
                        fontWeight="regular"
                        sx={{
                            color: theme.palette.disabled.onContainer,
                        }}
                    >
                        Add and map your datasources to use with BigGeo
                        Datascape AI
                    </Typography>
                </Stack>
                <Stack gap={2} flexDirection="row">
                    <a href="/datasources">
                        <Button
                            endNode={<OpenInNewOutline />}
                            color="primary"
                            density="dense"
                        >
                            Add Data Sources
                        </Button>
                    </a>
                </Stack>
            </Stack>
            <AspectRatio variant="auto" width="100%">
                <Image
                    src="https://biggeo.blob.core.windows.net/media/Image.png"
                    height="100%"
                    width="100%"
                    sx={{ objectFit: "contain", objectPosition: "top left" }}
                />
            </AspectRatio>
        </Stack>
    );
};

export default EmptyDatasetView;
