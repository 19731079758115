import { LoadingBar } from "@biggeo/bg-ui/lab";
import { isFailure, isSuccess } from "@vividtheory/remotedata";
import { useState } from "react";
import { useParams } from "react-router";
import { ErrorPage } from "../../common/components/ErrorPage";
import { Consumers } from "../../common/redux/model";
import { useDataSources } from "../../database-meta-data/redux/hooks";
import MapViewWrapper, { MapTabs } from "../map-wrappers/MapViewWrapper";
import OpenLayersWrapper from "../map-wrappers/OpenLayersWrapper";

interface IFullmapContainer {
    readonly currentTab?: MapTabs;
    readonly isSavedAreaForm?: boolean;
}

const FullmapContainer = ({
    currentTab,
    isSavedAreaForm,
}: IFullmapContainer) => {
    const [consumption, setConsumption] = useState(Consumers.mapbox);

    const { mapTemplateId } = useParams();

    const dataSourcesRd = useDataSources();

    if (isFailure(dataSourcesRd)) {
        return <ErrorPage />;
    }

    if (!isSuccess(dataSourcesRd)) {
        return <LoadingBar />;
    }

    return (
        <>
            {consumption === Consumers.openLayers && (
                <OpenLayersWrapper
                    setConsumption={setConsumption}
                    activeConsumption={consumption}
                />
            )}
            {consumption === Consumers.mapbox && (
                <MapViewWrapper
                    currentTab={currentTab}
                    setConsumption={setConsumption}
                    activeConsumption={consumption}
                    mapTemplateId={Number(mapTemplateId)}
                    isSavedAreaForm={isSavedAreaForm}
                />
            )}
        </>
    );
};

export default FullmapContainer;
