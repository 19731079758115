import { MapTemplate } from "@biggeo/bg-server-lib/datascape-ai";
import {
    NavigationRailCell,
    Stack,
    ThumbnailAvatar,
    Typography,
} from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useNavigate } from "react-router";
import { NoMapTemplateView } from "../../map-templates/views/NoMapTemplatesView";
import { Routes } from "../../navigation/redux/model";

interface IMapTemplateDataManagement {
    readonly currentMapTemplate?: number;
    readonly mapTemplates: MapTemplate[];
}

const MapTemplateDataManagement = ({
    currentMapTemplate,
    mapTemplates,
}: IMapTemplateDataManagement) => {
    const toPage = useNavigate();

    return (
        <Stack gap={2}>
            <Typography
                variant="body3"
                fontWeight="semibold"
                sx={{
                    color: (theme) => theme.palette.disabled.onContainer,
                }}
            >
                Manage by map template
            </Typography>
            {pipe(
                mapTemplates,
                O.fromPredicate((x) => !isEmpty(x)),
                O.fold(
                    () => <NoMapTemplateView />,
                    (templates) => (
                        <Stack gap={1}>
                            {pipe(
                                templates,
                                A.map((template) => (
                                    <NavigationRailCell
                                        key={template.id}
                                        startNode={
                                            <ThumbnailAvatar
                                                alt={template.name}
                                                size="xxs"
                                                src={
                                                    template.thumbnail ||
                                                    undefined
                                                }
                                                color="surface"
                                            />
                                        }
                                        selected={isEqual(
                                            template.id,
                                            currentMapTemplate
                                        )}
                                        onClick={() =>
                                            toPage(
                                                `${Routes.data}/map-template/${template.id}`
                                            )
                                        }
                                    >
                                        {template.name}
                                    </NavigationRailCell>
                                ))
                            )}
                        </Stack>
                    )
                )
            )}
        </Stack>
    );
};

export default MapTemplateDataManagement;
