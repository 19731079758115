import { WithLoading } from "@biggeo/bg-ui";
import {
    Button,
    Grid,
    IconAvatar,
    OverflowingTypography,
    ProgressBar,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    BigGeoLogo,
    CompareArrowsOutline,
    SnowflakeLogo,
} from "@biggeo/bg-ui/lab/icons";
import { formatNumberWithCommas } from "../../utils/utils.ts";

export const DatasourcesCard = ({
    title,
    description,
    progress,
    actualSize,
    isConnected,
    onClickConnect,
    loading,
    isRunningOnSF,
    isSNP,
}: {
    readonly title: string;
    readonly description?: string | null;
    readonly progress?: number | null;
    readonly actualSize: number;
    readonly isConnected: boolean;
    readonly onClickConnect?: () => void;
    readonly loading?: boolean;
    readonly isRunningOnSF?: boolean;
    readonly isSNP: boolean;
}) => {
    const MAX_PROGRESS: number = 100;

    return (
        <Stack
            gap={3}
            width="100%"
            sx={{
                border: 1,
                borderColor: (theme) => theme.palette.stroke[100],
                padding: 4,
                borderRadius: (theme) => theme.radius.xs3,
                color: (theme) => theme.palette.background.onMain,
            }}
        >
            <Grid container justifyContent="space-between" gap={2}>
                <Grid item>
                    <Stack
                        flexDirection={"row"}
                        gap={1}
                        alignItems="center"
                        sx={{
                            padding: 2,
                            backgroundColor: (theme) =>
                                theme.palette.surface.container,
                            color: (theme) => theme.palette.disabled.main,
                            borderRadius: (theme) => theme.radius.xs3,
                        }}
                    >
                        <IconAvatar
                            color="background"
                            sx={{
                                borderRadius: (theme) => theme.radius.xs3,
                            }}
                        >
                            <SnowflakeLogo />
                        </IconAvatar>
                        <CompareArrowsOutline color={"disabled"} />
                        <IconAvatar
                            color="primary"
                            sx={{
                                borderRadius: (theme) => theme.radius.xs3,
                            }}
                        >
                            <BigGeoLogo />
                        </IconAvatar>
                    </Stack>
                </Grid>
                <Typography variant={"body3"} fontWeight={"semibold"}>
                    {isConnected && isRunningOnSF && !isSNP ? "Connected" : ""}
                </Typography>
            </Grid>
            <Stack
                width="100%"
                sx={{
                    minHeight: (theme) => theme.spacing(18),
                }}
            >
                <Typography variant={"body3"} fontWeight={"semibold"} truncate>
                    {title}
                </Typography>
                <OverflowingTypography
                    variant={"body3"}
                    color={"disabled"}
                    lineClamp={3}
                    sx={{
                        color: (theme) => theme.palette.disabled.onContainer,
                    }}
                >
                    {description}
                </OverflowingTypography>
            </Stack>
            {progress !== undefined &&
            progress !== null &&
            progress < MAX_PROGRESS &&
            !isConnected ? (
                <Stack
                    flexDirection={"column"}
                    gap={2}
                    width="100%"
                    sx={{
                        minHeight: (theme) => theme.spacing(10.5),
                    }}
                >
                    <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                    >
                        <Typography variant={"body3"} fontWeight={"semibold"}>
                            Processing
                        </Typography>
                        <Typography
                            variant={"body3"}
                            fontWeight={"semibold"}
                        >{`${progress}% Done`}</Typography>
                    </Stack>
                    <ProgressBar value={progress || 0} color={"success"} />
                    {/* <Stack justifyContent="flex-end" flexDirection="row">
                        <Button variant={"minimal"} onClick={() => {}}>
                            Cancel
                        </Button>
                    </Stack> */}
                </Stack>
            ) : (
                <Stack
                    justifyContent="space-between"
                    flexDirection={"row"}
                    alignItems="flex-end"
                    width="100%"
                    gap={2}
                >
                    <Stack flexDirection={"row"} gap={1}>
                        <Typography
                            variant={"body3"}
                            fontWeight={"regular"}
                            sx={{
                                color: (theme) =>
                                    theme.palette.disabled.onContainer,
                            }}
                        >
                            Size:
                        </Typography>
                        <Typography variant={"body3"} fontWeight={"semibold"}>
                            {formatNumberWithCommas(actualSize)} rows
                        </Typography>
                    </Stack>
                    <WithLoading loading={loading} text="Connecting...">
                        <Button
                            variant={"filled"}
                            color={"primary"}
                            disabled={loading || !isRunningOnSF || isSNP}
                            onClick={onClickConnect}
                        >
                            {!isRunningOnSF || isSNP
                                ? "Connected"
                                : isConnected
                                  ? "Refresh Data"
                                  : "Connect"}
                        </Button>
                    </WithLoading>
                </Stack>
            )}
        </Stack>
    );
};
