import { DataProvider } from "@biggeo/bg-server-lib/datascape-ai";
import {
    Button,
    CompanyAvatar,
    Divider,
    FlexScrollArea,
    FlexScrollAreaContainer,
    Grid,
    LoadingBar,
    NavigationRailCell,
    Stack,
    TextField,
    Typography,
} from "@biggeo/bg-ui/lab";
import { ActionKeyOutline } from "@biggeo/bg-ui/lab/icons";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import { isEmpty } from "lodash";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";

export enum SidebarMenu {
    datasets = "datasets",
    recipes = "recipes",
}

export type MarketplaceSidebarProps = {
    readonly onSearch: (query: string) => void;
    readonly toPage: (menu: string) => void;
    readonly setSelectedProvider: (providerId: number) => void;
    readonly setSelectedIndustry: (industry: string) => void;
    readonly selectedIndustry: string;
    readonly selectedProvider: number;
    readonly selected: SidebarMenu;
    readonly datasetProviders: DataProvider[];
    readonly showAllProviders: boolean;
    readonly setShowAllProviders: (value: boolean) => void;
    readonly showAllIndustries: boolean;
    readonly setShowAllIndustries: (value: boolean) => void;
    readonly onIndustryClick: (industry: string) => void;
    readonly onProviderClick: (providerId: number) => void;
    readonly sliceProvidersByMax: ({
        providers,
        max,
        condition,
    }: {
        providers: DataProvider[];
        max: number;
        condition: boolean;
    }) => DataProvider[];
};

export const MarketplaceSidebar = ({
    onSearch,
    toPage,
    selected,
    datasetProviders,
    selectedIndustry,
    selectedProvider,
    showAllProviders,
    setShowAllProviders,
    sliceProvidersByMax,
    showAllIndustries,
    setShowAllIndustries,
    onProviderClick,
    onIndustryClick,
}: MarketplaceSidebarProps) => {
    const subMenu: readonly SidebarMenu[] = [
        SidebarMenu.datasets,
        // TODO: Hidden at Jose's request
        // SidebarMenu.recipes,
    ];
    const MAX_RENDERED_PROVIDERS = 3;
    const MAX_RENDERED_INDUSTRIES = 5;

    return (
        <FlexScrollAreaContainer>
            <Grid container sx={{ padding: 3 }}>
                <TextField
                    name="query"
                    fullWidth
                    placeholder="Search marketplace"
                    onChange={(e) => {
                        onSearch(e?.currentTarget.value || "");
                    }}
                    startNode={<ActionKeyOutline />}
                />
            </Grid>
            <FlexScrollArea
                flexDirection="column"
                gap={4}
                sx={{
                    paddingX: 4,
                    paddingBottom: 4,
                }}
            >
                <Stack gap={1}>
                    {pipe(
                        subMenu,
                        toNonReadonlyArray,
                        A.map((item) => {
                            return (
                                <Stack
                                    key={`id-${item}`}
                                    gap={4}
                                    justifyContent="center"
                                >
                                    <NavigationRailCell
                                        selected={isEqual(item, selected)}
                                        onClick={() =>
                                            toPage(`/marketplace/${item}`)
                                        }
                                    >
                                        {startCase(item)}
                                    </NavigationRailCell>

                                    {!isEmpty(datasetProviders) &&
                                    isEqual(item, SidebarMenu.datasets) ? (
                                        <Stack gap={2}>
                                            <Divider />
                                            <Typography
                                                variant="body3"
                                                fontWeight="semibold"
                                                color="disabled"
                                                colorSet="container"
                                                invertColors
                                            >
                                                By Provider
                                            </Typography>
                                            <Stack>
                                                {pipe(
                                                    sliceProvidersByMax({
                                                        providers:
                                                            datasetProviders,
                                                        condition:
                                                            showAllProviders,
                                                        max: MAX_RENDERED_PROVIDERS,
                                                    }),
                                                    A.mapWithIndex(
                                                        (index, data) => {
                                                            return (
                                                                <NavigationRailCell
                                                                    selected={isEqual(
                                                                        selectedProvider,
                                                                        data.id
                                                                    )}
                                                                    onClick={() =>
                                                                        onProviderClick(
                                                                            data.id
                                                                        )
                                                                    }
                                                                    startNode={
                                                                        <CompanyAvatar
                                                                            sx={{
                                                                                borderRadius:
                                                                                    (
                                                                                        theme
                                                                                    ) =>
                                                                                        theme
                                                                                            .radius
                                                                                            .default,
                                                                            }}
                                                                            size="xxs"
                                                                            src={
                                                                                data.img ||
                                                                                ""
                                                                            }
                                                                            alt={
                                                                                data.name
                                                                            }
                                                                        />
                                                                    }
                                                                    key={index}
                                                                >
                                                                    {startCase(
                                                                        data.name
                                                                    )}
                                                                </NavigationRailCell>
                                                            );
                                                        }
                                                    )
                                                )}

                                                <Button
                                                    variant="minimal"
                                                    color="primary"
                                                    fontWeight="semibold"
                                                    onClick={() =>
                                                        setShowAllProviders(
                                                            !showAllProviders
                                                        )
                                                    }
                                                >
                                                    {showAllProviders
                                                        ? "Show Less"
                                                        : "Show All"}
                                                </Button>
                                            </Stack>

                                            <Divider />
                                            <Typography
                                                variant="body3"
                                                fontWeight="semibold"
                                                color="disabled"
                                                colorSet="container"
                                                invertColors
                                            >
                                                By Industry
                                            </Typography>
                                            <Stack>
                                                {pipe(
                                                    sliceProvidersByMax({
                                                        providers:
                                                            datasetProviders,
                                                        condition:
                                                            showAllIndustries,
                                                        max: MAX_RENDERED_INDUSTRIES,
                                                    }),
                                                    A.mapWithIndex(
                                                        (index, data) => {
                                                            return (
                                                                <NavigationRailCell
                                                                    selected={isEqual(
                                                                        selectedIndustry,
                                                                        data.category
                                                                    )}
                                                                    key={index}
                                                                    onClick={() =>
                                                                        onIndustryClick(
                                                                            data.category
                                                                        )
                                                                    }
                                                                >
                                                                    {startCase(
                                                                        data.category
                                                                    )}
                                                                </NavigationRailCell>
                                                            );
                                                        }
                                                    )
                                                )}
                                                {datasetProviders.length >
                                                    MAX_RENDERED_INDUSTRIES && (
                                                    <Button
                                                        variant="minimal"
                                                        color="primary"
                                                        fontWeight="semibold"
                                                        onClick={() =>
                                                            setShowAllIndustries(
                                                                !showAllIndustries
                                                            )
                                                        }
                                                    >
                                                        {showAllIndustries
                                                            ? "Show Less"
                                                            : "Show All"}
                                                    </Button>
                                                )}
                                            </Stack>
                                        </Stack>
                                    ) : (
                                        isEqual(item, SidebarMenu.datasets) && (
                                            <LoadingBar />
                                        )
                                    )}
                                </Stack>
                            );
                        })
                    )}
                </Stack>
            </FlexScrollArea>
        </FlexScrollAreaContainer>
    );
};
