import {
    InputSavedArea,
    SavedArea,
    SlimSavedArea,
    useDeleteSavedAreaMutation,
    useFetchAreaByIdQuery,
    useFetchSavedAreaByIdQuery,
    useUpdateSavedAreaMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { LoadingBar, Stack } from "@biggeo/bg-ui/lab";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { ErrorPage } from "../../common/components/ErrorPage";
import { Routes } from "../../navigation/redux/model";
import { toasterActions } from "../../toaster/containers/redux/model";
import { CallBacksType } from "../../utils/types";
import { SavedAreaFormView } from "./SavedAreaFormView";
const SavedAreaForm = ({
    toPage,
}: { readonly toPage: (to: string) => void }) => {
    const params = useParams<{ mapTemplateId: string; savedAreaId: string }>();

    if (!params.mapTemplateId || !params.savedAreaId) return <ErrorPage />;
    const mapTemplateId = params.mapTemplateId
        ? Number.parseInt(params.mapTemplateId, 10)
        : 0;
    const savedAreaId = params.savedAreaId
        ? Number.parseInt(params.savedAreaId, 10)
        : 0;

    const dispatch = useDispatch();

    const [areaId, setAreaId] = useState<number>(1);
    const {
        queryReturn: {
            data: savedAreaData,
            loading: savedAreaLoading,
            refetch,
        },
    } = useFetchSavedAreaByIdQuery({
        variables: { id: savedAreaId },
        onCompleted: (data) => {
            if (data?.fetchSavedAreaById?.areaId) {
                setAreaId(data.fetchSavedAreaById?.areaId);
            }
        },
    });

    const {
        queryReturn: { data: areaData, loading: areaLoading },
    } = useFetchAreaByIdQuery({
        variables: { id: areaId },
        skip: !areaId,
        onCompleted: (fetchedAreaData) => {
            if (
                fetchedAreaData?.fetchAreaById.name.toUpperCase() ===
                    "CANADA" ||
                fetchedAreaData?.fetchAreaById.name.toUpperCase() === "USA"
            ) {
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        title: "Saved area belongs to area of USA or CANADA cannot be edited",
                        autoHideDuration: 5000,
                    })
                );
                toPage(`${Routes.mapView}/${mapTemplateId}/configuration`);
            }
        },
    });

    const {
        executeMutation: updateSavedArea,
        mutationReturn: [_, { loading: updateLoading }],
    } = useUpdateSavedAreaMutation();
    const {
        executeMutation: deleteSavedArea,
        mutationReturn: [_d, { loading: deleteLoading }],
    } = useDeleteSavedAreaMutation();

    const save = (
        input: InputSavedArea,
        callbacks?: CallBacksType<SlimSavedArea>
    ) => {
        updateSavedArea({
            variables: {
                input,
            },
            onCompleted: (data) => {
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        title: "Saved area updated successfully",
                        autoHideDuration: 5000,
                    })
                );
                callbacks?.onSuccess?.(data.updateSavedArea);
                refetch();
                toPage(`${Routes.mapView}/${mapTemplateId}/configuration`);
            },
        });
    };
    const onRemove = (id: number, callbacks?: CallBacksType<SavedArea>) => {
        deleteSavedArea({
            variables: { id: id },
            onCompleted: (data) => {
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        title: "Saved area deleted successfully",
                        autoHideDuration: 5000,
                    })
                );
                callbacks?.onSuccess?.(data.deleteSavedArea);
                toPage(`${Routes.mapView}/${mapTemplateId}/configuration`);
            },
        });
    };

    return (
        <Stack width={"100%"} height={"100%"}>
            {savedAreaLoading && areaLoading ? (
                <LoadingBar />
            ) : savedAreaData?.fetchSavedAreaById ? (
                <SavedAreaFormView
                    navigate={toPage}
                    savedArea={savedAreaData.fetchSavedAreaById}
                    save={save}
                    deleteLoading={deleteLoading}
                    updateLoading={updateLoading}
                    onRemove={onRemove}
                    mapTemplateId={mapTemplateId}
                    savedAreaId={savedAreaId}
                />
            ) : (
                <ErrorPage />
            )}
        </Stack>
    );
};

export default SavedAreaForm;
