import {
    Box,
    Dropdown,
    IconButton,
    MenuItem,
    OverlayWrapper,
    Tooltip,
    buttonClasses,
    iconButtonClasses,
} from "@biggeo/bg-ui/lab";
import { CloseOutline, ExpandMoreOutline } from "@biggeo/bg-ui/lab/icons";
import {
    DatabaseOutline,
    StylusNoteOutline,
    TextureOutline,
} from "@biggeo/bg-ui/lab/icons";

import isEqual from "lodash/isEqual";
import { useState } from "react";
import ActivityZoneOutline from "../../icons/ActivityZoneOutline";
import AdjustOutline from "../../icons/AdjustOutline";
import PolylineOutline from "../../icons/PolylineOutline";
import { getShapeIcon } from "../utils/draw-modes-utils";
import { FunctionType, LastFunctionType } from "../utils/utils";

interface IDrawMode {
    readonly functionType: FunctionType;
    readonly onShapeClick: (f: LastFunctionType) => void;
    readonly onClose: () => void;
    readonly selectMode: (mode: boolean) => void;
    readonly isDrawMode: boolean;
    readonly lastFunctionType?: LastFunctionType;
}

const DrawMode = ({
    functionType,
    onShapeClick,
    onClose,
    selectMode,
    isDrawMode,
    lastFunctionType,
}: IDrawMode) => {
    const [open, setOpen] = useState(false);

    const InfoElementProps: {
        icon: JSX.Element;
        title: string;
        selected: boolean;
        type: LastFunctionType;
    }[] = [
        {
            icon: <PolylineOutline />,
            title: "Polyline",
            selected: functionType === FunctionType.polygon,
            type: FunctionType.polygon,
        },
        {
            icon: <ActivityZoneOutline />,
            title: "Square",
            selected: functionType === FunctionType.square,
            type: FunctionType.square,
        },
        {
            icon: <AdjustOutline />,
            title: "Radius",
            selected: functionType === FunctionType.radius,
            type: FunctionType.radius,
        },
        {
            icon: <TextureOutline />,
            title: "Fill Area",
            selected: functionType === FunctionType.fillArea,
            type: FunctionType.fillArea,
        },
        {
            icon: <StylusNoteOutline />,
            title: "Draw",
            selected: functionType === FunctionType.draw,
            type: FunctionType.draw,
        },
        {
            icon: <DatabaseOutline />,
            title: "Data",
            selected: functionType === FunctionType.data,
            type: FunctionType.data,
        },
    ];

    const InfoElement = ({
        title,
        selected,
        onClick,
        type,
    }: {
        readonly type: FunctionType;
        readonly title: string;
        readonly selected: boolean;
        readonly onClick: () => void;
    }) => (
        <MenuItem
            label={title}
            density="dense"
            onClick={onClick}
            selected={selected}
            disabled={
                isEqual(type, FunctionType.fillArea) ||
                isEqual(type, FunctionType.draw) ||
                isEqual(type, FunctionType.data)
            }
            startNode={getShapeIcon({
                functionType: type,
                lastFunctionType,
            })}
        />
    );

    return (
        <OverlayWrapper
            placement={{ horizontal: "right", vertical: "top" }}
            placementOffset={{ horizontal: -2, vertical: -1 }}
            content={
                isDrawMode && (
                    <CloseOutline
                        size="xxs"
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(false);
                            onClose();
                            selectMode(false);
                        }}
                        sx={{
                            cursor: "pointer",
                            padding: 0.5,
                            backgroundColor: (theme) =>
                                theme.palette.surface.main,
                            color: (theme) => theme.palette.surface.onMain,
                            borderRadius: (theme) => theme.radius.xs5,
                        }}
                    />
                )
            }
        >
            <Dropdown
                open={open}
                onOpenChange={(o) => setOpen(o)}
                placement="bottom-end"
                slotProps={{
                    button: {
                        disableHoverEffect: true,
                        density: "none",
                        sx: {
                            [`&.${buttonClasses.outlined}`]: {
                                backgroundColor: (theme) =>
                                    theme.palette.background.main,
                                border: 1,
                                borderColor: (theme) =>
                                    theme.palette.stroke[100],
                                padding: 1,
                                borderRadius: (theme) => theme.radius.default,
                            },
                        },
                        endNode: <MapModeExpandIcon open={open} />,
                    },
                }}
                label={
                    <Tooltip title={"Create shape"}>
                        <Box
                            onClick={(e) => {
                                e.stopPropagation();

                                if (lastFunctionType) {
                                    onShapeClick(lastFunctionType);
                                } else {
                                    onShapeClick(FunctionType.polygon);
                                }

                                selectMode(false);
                            }}
                            sx={{
                                backgroundColor: isDrawMode
                                    ? (theme) => theme.palette.stroke[100]
                                    : undefined,
                                padding: 1,
                                borderRadius: (theme) => theme.radius.default,
                            }}
                        >
                            {getShapeIcon({
                                functionType,
                                lastFunctionType,
                            })}
                        </Box>
                    </Tooltip>
                }
                content={InfoElementProps.map((infoElement) => (
                    <InfoElement
                        key={infoElement.title}
                        title={infoElement.title}
                        selected={infoElement.selected}
                        type={infoElement.type}
                        onClick={() => {
                            setOpen(false);
                            onShapeClick(infoElement.type);
                            selectMode(false);
                        }}
                    />
                ))}
            />
        </OverlayWrapper>
    );
};

export default DrawMode;

export const MapModeExpandIcon = ({ open }: { open: boolean }) => (
    <IconButton
        disableActiveEffect
        disableHoverEffect
        disableFocusEffect
        variant="minimal"
        sx={{
            [`&.${iconButtonClasses.minimal}`]: {
                padding: 1.5,
            },
        }}
    >
        <ExpandMoreOutline
            sx={{
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.2s",
            }}
        />
    </IconButton>
);
