import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import range from "lodash/range";
import round from "lodash/round";
import capitalize from "lodash/capitalize";
import { match } from "ts-pattern";

export const singularOrPlural = (
    count: number,
    singular: string,
    plural?: string
) => {
    const formattedCount = count
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `${formattedCount} ${
        count === 1 ? singular : plural ?? `${singular}s`
    }`;
};

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export const shortenDay = (name: string) => {
    return capitalize(name.slice(0, 3));
};

export const yearsArray = (startYear: number) =>
    range(startYear, new Date().getFullYear() + 1, 1).reverse();

const addExtraZero = (n: number) => {
    if (n > 9) {
        return `${n}`;
    }
    return `0${n}`;
};

export const differenceInDays = (date1: Date, date2: Date) => {
    const diff = new Date(date1).getTime() - new Date(date2).getTime();
    return Math.ceil(diff / (1000 * 3600 * 24));
};

export const hourMinute = (date: Date) => {
    const _date = new Date(date);

    return new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "2-digit",
    }).format(_date);
};

export const monthDayTime = (dateTime: Date) => {
    const date = new Date(dateTime);
    const hr = date.getHours();
    const isPm = hr > 12;
    const hours = isPm ? hr - 12 : hr;
    return `${months[date.getMonth()]} ${date.getDate()}, ${addExtraZero(
        hours
    )}:${addExtraZero(date.getMinutes())}${isPm ? "pm" : "am"}`;
};

export const dateFormatter = (dateTime: Date) => {
    const date = new Date(dateTime);
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
};

export const dateToMonthDayYear = (dateTime: Date, type?: "numeric") => {
    const date = new Date(dateTime);
    if (type) {
        return `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
    }
    return `${
        months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
};

export const dateToMonthDay = (date: Date) =>
    `${months[date.getMonth()]} ${date.getDate()}`;

export const convertTimestampToDate = (timestamp: Date) => {
    const a = new Date(timestamp);
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    const time = month + " " + date + ", " + year;
    return time;
};

export const numToDate = (dateNum: number) => new Date(dateNum);

export const formatDateNum = (dateNum?: number) =>
    pipe(
        O.fromNullable(dateNum),
        O.fold(() => "", flow(numToDate, dateFormatter))
    );

export const dateTimeFormatter = (dateTime: Date) => {
    const date = new Date(dateTime);
    const time = date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
    return `${time} ${
        months[date.getMonth()]
    } ${date.getDate()} ${date.getFullYear()}`;
};

const timePeriod = [
    {
        name: "second",
        short: "s",
        value: 1000,
    },
    {
        name: "minute",
        short: "min",
        value: 60 * 1000,
    },
    {
        name: "hour",
        short: "hr",
        value: 60 * 60 * 1000,
    },
    {
        name: "day",
        short: "d",
        value: 24 * 60 * 60 * 1000,
    },
    {
        name: "month",
        short: "mo",
        value: 30 * 24 * 60 * 60 * 1000,
    },
    {
        name: "year",
        short: "y",
        value: 365 * 24 * 60 * 60 * 1000,
    },
];
const assignPluraltoString = (num: number) => (str: string) => {
    if (num === 1) {
        return str;
    }
    return str + "s";
};
export const convertDateTimeDuration = (
    date: Date,
    suffix?: string,
    short?: boolean
) => {
    const now = new Date();
    const diff = now.getTime() - new Date(date).getTime();
    return timePeriod.reduce((acc, curr) => {
        const value = Math.floor(diff / curr.value);
        if (value > 0) {
            return `${value} ${
                short ? curr.short : assignPluraltoString(value)(curr.name)
            } ${suffix}`;
        }
        return acc;
    }, "just now");
};
export const convertDateToTimePeriod = (date: Date, short?: boolean) =>
    convertDateTimeDuration(date, "ago", short);

export const formatDateYYYYDDMMMM = (date: Date) => {
    return new Date(date).toLocaleDateString("UTC", {
        month: "long",
        day: "2-digit",
        year: "numeric",
    });
};

export const daysBetweenDates = (date1: Date, date2: Date) => {
    const diff = Math.abs(
        new Date(date1).getTime() - new Date(date2).getTime()
    );
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    return diffDays;
};

export enum ExpiryOffset {
    day = "day",
    week = "week",
    month = "month",
    year = "year",
}

/**
 * @description checks if date a given date is expired or close to expiring given a months buffer
 * @returns "expired" | "expires-soon" | undefined if not close to expiry
 */
export const checkExpiryStatus = (
    date: Date,
    buffer = 1,
    offset: ExpiryOffset = ExpiryOffset.month
): "expired" | "expires-soon" | undefined => {
    const today = new Date();
    const dateFromNow = dateOffset(today, buffer, offset);

    if (date < today) {
        return "expired";
    } else if (date < dateFromNow) {
        return "expires-soon";
    } else {
        return undefined;
    }
};

export const dateOffset = (
    date: Date,
    buffer: number,
    offset: ExpiryOffset
) => {
    const dateFromNow = new Date();
    return match<typeof offset, Date>(offset)
        .with(ExpiryOffset.day, () => {
            dateFromNow.setDate(date.getDate() + buffer);
            return dateFromNow;
        })
        .with(ExpiryOffset.week, () => {
            dateFromNow.setDate(date.getDate() + buffer * 7);
            return dateFromNow;
        })
        .with(ExpiryOffset.month, () => {
            dateFromNow.setMonth(date.getMonth() + buffer);
            return dateFromNow;
        })
        .with(ExpiryOffset.year, () => {
            dateFromNow.setFullYear(date.getFullYear() + buffer);
            return dateFromNow;
        })
        .exhaustive();
};

export const convertFutureDateToTimePeriod = (date: Date, short?: boolean) =>
    convertDateTimeDuration(date, "", short);

export const getBillingPeriodUTC = () => {
    const now = new Date();
    const currentMonth = now.getUTCMonth();
    const currentYear = now.getUTCFullYear();
    const startDate = new Date(
        Date.UTC(currentYear, currentMonth, 5, 23, 59, 59)
    );
    const endDate = new Date(
        Date.UTC(currentYear, currentMonth + 1, 6, 0, 0, 0)
    );
    if (now.getUTCDate() < 6) {
        startDate.setUTCMonth(currentMonth - 1);
        endDate.setUTCMonth(currentMonth);
    }
    return { startDate, endDate };
};

export const convertSecondsIntoSecMinHrs = (time: number): string => {
    if (time < 60) {
        const seconds = round(time, 0);
        return `${singularOrPlural(seconds, "sec")}`;
    } else if (time < 3600) {
        const minutes = round(time / 60, 0);
        return `${singularOrPlural(minutes, "min")}`;
    } else {
        const hours = round(time / 3600, 0);
        return `${singularOrPlural(hours, "hr")}`;
    }
};
