import { SavedArea, SlimSavedArea } from "@biggeo/bg-server-lib/datascape-ai";
import { IconAvatar, Image, InfoCell } from "@biggeo/bg-ui/lab";
import { CropFreeOutline } from "@biggeo/bg-ui/lab/icons";
import { capitalize } from "lodash";
import { DeleteModal } from "../../common/components/DeleteModal";
import { CallBacksType } from "../../utils/types";
const DeleteSavedAreaModal = ({
    savedArea,
    onRemove,
}: {
    savedArea: SlimSavedArea;
    onRemove: (callbacks?: CallBacksType<SavedArea>) => void;
}) => {
    return (
        <DeleteModal
            subTitle={
                "This saved area will be removed permanently from your map template"
            }
            body={
                <InfoCell
                    startNode={
                        <IconAvatar
                            // biome-ignore lint/correctness/noChildrenProp: <explanation>
                            children={<CropFreeOutline />}
                            size="md"
                            sx={{ objectFit: "cover", layout: "fill" }}
                        />
                    }
                    title={capitalize(savedArea.name)}
                    density={"none"}
                    slotProps={{
                        title: {
                            fontWeight: "semibold",
                            variant: "body2",
                            sx: {
                                color: (theme) =>
                                    theme.palette.background.onMain,
                            },
                        },
                        subtitle: {
                            variant: "body4",
                            sx: {
                                color: (theme) =>
                                    theme.palette.disabled.onContainer,
                            },
                        },
                    }}
                    sx={{ padding: 2 }}
                    variant="outlined"
                />
            }
            description={savedArea.description || ""}
            onRemove={onRemove}
        />
    );
};

export default DeleteSavedAreaModal;
