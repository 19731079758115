import { Button, Stack, Typography } from "@biggeo/bg-ui/lab";
import { ActionKeyOutline } from "@biggeo/bg-ui/lab/icons";
import { useState } from "react";
import { GenericDraggableItem } from "../../common/components/GenericDraggableItem";
import { DatasetInfo } from "../../map/mapbox/views/MarketplaceDataView";
import { PreviewDatasetSingleCard } from "../components/PreviewDatasetSingleCard";

export type LinearGradientWithId = {
    offset: number;
    color: string;
    id: number;
};
export const ExploreDatasetsView = ({
    datasetInfo,
    exploreDataset,
    previewInfo,
}: {
    readonly datasetInfo: DatasetInfo[];
    readonly exploreDataset: () => void;
    readonly previewInfo: (data: DatasetInfo) => void;
}) => {
    const [data, setData] = useState(datasetInfo);

    const onEdit = (data: number) => {
        console.log("data", data);
    };
    const onRemove = (data: number) => {
        console.log("data", data);
    };
    const onView = (data: number) => {
        console.log("data", data);
    };

    return (
        <Stack
            gap={3}
            sx={{
                width: "100%",
                padding: 3,
                cursor: "pointer",
            }}
        >
            <Stack gap={3}>
                <Button
                    startNode={<ActionKeyOutline />}
                    onClick={() => exploreDataset()}
                    variant="outlined"
                >
                    <Typography variant="body4" fontWeight="semibold" truncate>
                        Data Marketplace
                    </Typography>
                </Button>
                <Stack gap={2}>
                    {data.map((dataset, index) => {
                        return (
                            <GenericDraggableItem<DatasetInfo>
                                key={dataset.id}
                                type="Data Marketplace"
                                index={index}
                                state={data}
                                onHoverAndDrop={(state) => {
                                    const orderedStateIds = state.map(
                                        (s) => s.id
                                    );
                                    state.sort(
                                        (a, b) =>
                                            orderedStateIds.indexOf(a.id) -
                                            orderedStateIds.indexOf(b.id)
                                    );
                                    setData([...state]);
                                }}
                            >
                                <PreviewDatasetSingleCard
                                    dataset={dataset}
                                    onClickCard={() => {
                                        previewInfo(dataset);
                                    }}
                                    onEdit={() => {
                                        onEdit(dataset.id);
                                    }}
                                    onView={() => {
                                        onView(dataset.id);
                                    }}
                                    onRemove={() => {
                                        onRemove(dataset.id);
                                    }}
                                />
                            </GenericDraggableItem>
                        );
                    })}
                </Stack>
            </Stack>
        </Stack>
    );
};
