import { ApolloQueryResult } from "@apollo/client";
import {
    DataSource,
    DatabaseType,
    Exact,
    FetchAllAreasExtendedQuery,
    FetchSavedViewsQuery,
    FilterObject,
    FilterObjectType,
    InputFetchSavedView,
    InputViewBox,
    ReqOptions,
    SavedArea,
    SavedView,
    SubscriptionResponse,
    useFetchSavedViewByIdLazyQuery,
    useSwitchComputeOffMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Fade, SelectableTreeMenuItem } from "@biggeo/bg-ui";
import {
    Accordion,
    Box,
    Divider,
    FlexScrollArea,
    Grid,
    OverflowingTypography,
    Severity,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import { toNonReadonlyArray, updateSelected } from "@biggeo/bg-utils";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { Circle, ExpandContentOutline } from "@biggeo/bg-ui/lab/icons";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import pick from "lodash/pick";
import pickBy from "lodash/pickBy";
import { GeoJSONSource } from "mapbox-gl";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SetURLSearchParams } from "react-router-dom";

import { match } from "ts-pattern";
import {
    isAppRunningOnSF,
    useResponseParseTimes,
    useSearchTimestamp,
} from "../../../common/redux/hooks";
import { DatasetColor } from "../../../common/redux/model";
import { IconLegendProps } from "../../../components/MapInterfaceLegend";
import MapInterfaceLegend from "../../../components/MapInterfaceLegend/MapInterfaceLegend";
import { useCurrentDataSource } from "../../../database-meta-data/redux/hooks";
import { databaseMetaDataActions } from "../../../database-meta-data/redux/model";
import { toasterActions } from "../../../toaster/containers/redux/model";
import { getDatasetIdsFromParams, snapToView } from "../../../utils/utils";
import { FunctionContainer } from "../../FunctionContainer";
import ViewPointData from "../../ViewPointData";
import AiPromptContainer from "../../ai/containers/AiPromptContainer";
import type {
    InputPolygonWithId,
    PureDataStringHookReturnType,
} from "../../hooks/pure-data-string-hook";
import { useMapData } from "../../redux/hooks";
import { mapDataActions } from "../../redux/model";
import { removeCustomLayers } from "../../utils/style-utils";
import {
    FunctionType,
    SavedPolygonSource,
    delay,
    getMapFeatures,
    isAggregationNonEmpty,
    isNonPointCountPositive,
    isNonPointsLengthPositive,
    pickGeospatialSelection,
    sumCounts,
} from "../../utils/utils";
import { InitializeMapProps, useInitializeMap } from "../hooks/hooks";
import { SavedPolygonType } from "../hooks/saved-polygon-hooks";
import type { HoveredData } from "../hooks/view-data-hooks";
import Accreditation from "../views/Accreditation";
import MapControls from "../views/MapControls";
import { MapPromptPopup } from "../views/MapPromptPopup";
import ResetView from "../views/ResetView";
import SaveView from "../views/SaveView";
import { IMapMainContainer } from "./MapMainContainer";
type DatasetResultType = {
    readonly databaseId: string;
    readonly color?: string;
    readonly label?: string;
    readonly found: number;
    readonly rendered: number;
    readonly timeTaken: IconLegendProps[];
};

export interface MapboxMapContainerProps
    extends Pick<
            PureDataStringHookReturnType,
            | "deleteShape"
            | "handleSavedPolygons"
            | "datasetPointsShapes"
            | "handlePolygonsOnZoom"
        >,
        Pick<
            IMapMainContainer,
            | "setSelectedPoint"
            | "showTriangles"
            | "showPoints"
            | "showFiltered"
            | "selectedPoint"
        >,
        Pick<InitializeMapProps, "handleSelectedShapes"> {
    readonly dataSources: DataSource[];
    readonly channelId: string;
    readonly functionType: FunctionType;
    readonly multiFilters: FilterObject[];
    readonly viewport: InputViewBox;
    readonly addMultipleDatasets: (v: readonly FilterObjectType[]) => void;
    readonly handleViewportChange: ({
        viewport,
    }: {
        viewport: InputViewBox;
    }) => void;
    readonly setMultiFilters: (f: FilterObject[]) => void;
    readonly recentResponse: SubscriptionResponse | undefined;
    readonly selectedDataset: readonly string[];
    readonly savedPolygons: SavedPolygonType;
    readonly hoveredData: HoveredData | undefined;
    readonly setHoveredData: (hoveredData: HoveredData | undefined) => void;
    readonly options: ReqOptions;
    readonly setOptions: React.Dispatch<React.SetStateAction<ReqOptions>>;
    readonly setFunctionType: React.Dispatch<
        React.SetStateAction<FunctionType>
    >;
    readonly setSavedPolygons: (p: SavedPolygonType) => void;
    readonly polygons?: InputPolygonWithId[];
    readonly handleMultiPolygons: ({
        polygon,
    }: {
        readonly polygon: InputPolygonWithId | InputPolygonWithId[];
    }) => void;
    readonly clearShapes?: () => void;
    readonly setReFetchSavedAreas?: (value: boolean) => void;
    readonly openSaveViewPopper?: boolean;
    readonly setOpenSaveViewPopper?: (value: boolean) => void;
    readonly setSelectedDataset: (datasets: readonly string[]) => void;
    readonly selectedAreaId?: number;
    readonly setSelectedAreaId?: (id?: number) => void;
    readonly addRemoveDataset: (v: string) => void;
    readonly resetAreasFilters: () => void;
    readonly handleSideMenu?: (v: boolean) => void;
    readonly selectedSavedView?: number;
    readonly setSelectedSavedView?: (id?: number) => void;
    readonly refetchSavedAreas: (
        variables?:
            | Partial<
                  Exact<{
                      [key: string]: never;
                  }>
              >
            | undefined
    ) => Promise<ApolloQueryResult<FetchAllAreasExtendedQuery>>;
    readonly refetchSavedViews: (
        variables?:
            | Partial<
                  Exact<{
                      input: InputFetchSavedView;
                  }>
              >
            | undefined
    ) => Promise<ApolloQueryResult<FetchSavedViewsQuery>>;
    readonly responses: Partial<Record<string, SubscriptionResponse | null>>;
    readonly handleSelectedSavedPolygons: (i: {
        savedAreas: SavedArea[];
        selectableItems: SelectableTreeMenuItem[];
    }) => void;
    readonly selectableItems: SelectableTreeMenuItem[];
    readonly selectedShapes: GeoJSON.FeatureCollection<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >;
    readonly savedAreaId: number;
    readonly savedViewId: number;
    readonly setSearchParams: SetURLSearchParams;
    readonly searchParams: URLSearchParams;
    readonly mapTemplateId: number;
    readonly isLoading?: boolean;
    readonly isFilterCriteriaOpen: boolean;
}

export const MapboxMapContainer = ({
    channelId,
    functionType,
    multiFilters,
    viewport,
    handleViewportChange,
    setMultiFilters,
    recentResponse,
    selectedDataset,
    savedPolygons,
    setHoveredData,
    options,
    setOptions,
    setFunctionType,
    clearShapes,
    setReFetchSavedAreas,
    openSaveViewPopper,
    setOpenSaveViewPopper,
    setSelectedDataset,
    selectedAreaId,
    setSelectedAreaId,
    addRemoveDataset,
    polygons,
    handleMultiPolygons,
    addMultipleDatasets,
    resetAreasFilters,
    selectedSavedView,
    setSelectedSavedView,
    refetchSavedAreas,
    refetchSavedViews,
    dataSources,
    responses,
    selectedPoint,
    setSelectedPoint,
    showTriangles,
    showPoints,
    showFiltered,
    deleteShape,
    handleSavedPolygons,
    datasetPointsShapes,
    handlePolygonsOnZoom,
    handleSelectedSavedPolygons,
    selectableItems,
    handleSelectedShapes,
    selectedShapes,
    setSavedPolygons,
    savedAreaId,
    savedViewId,
    setSearchParams,
    searchParams,
    mapTemplateId,
    isFilterCriteriaOpen,
}: MapboxMapContainerProps) => {
    const {
        isLoaded,
        map,
        draw,
        changeProjection,
        projection,
        zoomIn,
        zoomOut,
        selectMode,
        isSelectMode,
        showAiPrompt,
        setShowAiPrompt,
        isDrawMode,
    } = useInitializeMap({
        functionType,
        multiFilters,
        setSelectedPoint,
        recentResponse,
        viewport,
        handleViewportChange,
        polygons: polygons || [],
        showTriangles,
        showPoints,
        showFiltered,
        selectedDataset,
        savedPolygons,
        setHoveredData: (h) => {
            const mode = draw.current?.getMode();

            const isDrawing =
                isEqual(mode, "draw_polygon") ||
                isEqual(mode, "draw_square") ||
                isEqual(mode, "draw_circle");

            if (isEqual(isDrawing, false)) {
                setHoveredData(h);
            } else {
                setHoveredData(undefined);
            }
        },
        options,
        deleteShape,
        handleMultiPolygons,
        handleSavedPolygons,
        datasetShape: datasetPointsShapes,
        handlePolygonsOnZoom: (p) => handlePolygonsOnZoom?.(p),
        setFunctionType,
        setMultiFilters,
        setSelectedDataset,
        addMultipleDatasets,
        handleSelectedSavedPolygons,
        selectableItems,
        handleSelectedShapes,
        isFilterCriteriaOpen,
    });

    const dispatch = useDispatch();
    const currentDataSource = useCurrentDataSource();
    const mapReduxData = useMapData();
    const isRunningOnSF = isAppRunningOnSF();

    const { executeQuery } = useFetchSavedViewByIdLazyQuery();

    const bounds = map.current?.getBounds();

    const updateDataSource = (updated: DataSource) => {
        dispatch(databaseMetaDataActions.updateDataSource(updated));
    };

    const [localMapData, setLocalMapData] = useState<
        Partial<SavedView> | undefined
    >(undefined);

    const handleSavedView = ({
        savedArea,
        viewport,
    }: Pick<SavedView, "savedArea" | "viewport">) => {
        if (savedArea) {
            setSavedPolygons({
                source: SavedPolygonSource.savedView,
                polygons: [savedArea],
                isConflict: false,
            });

            const selectedPolygons = pipe(
                savedArea.geometries,
                A.map((g) => ({
                    id: savedArea.areaId,
                    polygon: {
                        inners: [],
                        outer: {
                            points: pipe(
                                g.coordinates,
                                // biome-ignore lint/correctness/noFlatMapIdentity: <explanation>
                                A.flatMap((c) => c),
                                A.map((c) => ({
                                    latitude: c[1],
                                    longitude: c[0],
                                }))
                            ),
                        },
                    },
                }))
            );

            const multiPolygons = selectedPolygons.map((polygon, i) => ({
                id: `${polygon.id}-${i}`,
                inners: polygon.polygon.inners,
                outer: polygon.polygon.outer,
            }));

            handleSavedPolygons(multiPolygons);
        }

        if (viewport) {
            handleViewportChange({ viewport: viewport });
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!isEmpty(mapReduxData)) {
            if (isLoaded) {
                mapReduxData.fkSavedAreaId &&
                    setFunctionType(FunctionType.savedPolygon);
            } else {
                map.current?.on("style.load", () => {
                    mapReduxData.fkSavedAreaId &&
                        setFunctionType(FunctionType.savedPolygon);
                });
            }
            setLocalMapData(mapReduxData);
            setSelectedAreaId?.(mapReduxData.fkSavedAreaId || undefined);
            if (mapReduxData?.viewport) {
                setSearchParams({
                    savedViewId: mapReduxData.id?.toString() || "0",
                });
            } else if (mapReduxData?.fkSavedAreaId) {
                setSearchParams({
                    savedAreaId: mapReduxData.fkSavedAreaId?.toString() || "0",
                });
            }
        }
    }, [mapReduxData]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        mapReduxData?.id !== savedViewId &&
            savedViewId &&
            executeQuery({
                variables: { id: savedViewId },
                onCompleted: ({ fetchSavedViewById }) => {
                    if (fetchSavedViewById) {
                        if (!selectedAreaId) {
                            setSelectedAreaId?.(savedAreaId);
                        }
                        if (!selectedSavedView) {
                            setSelectedSavedView?.(savedViewId);
                        }
                        dispatch(
                            mapDataActions.updateMapData(fetchSavedViewById)
                        );

                        const datasetColors = fetchSavedViewById.datasets?.map(
                            (dataset) => ({
                                ...pick(dataset, ["id", "color"]),
                            })
                        );
                        dispatch(
                            databaseMetaDataActions.setDataSourceColors(
                                datasetColors || []
                            )
                        );
                    }
                },
            });
    }, [savedViewId]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const updateData = () => {
            localMapData?.filters && setMultiFilters(localMapData.filters);
            localMapData?.viewport &&
                map.current?.fitBounds([
                    {
                        lat: localMapData.viewport.latBounds.min,
                        lng: localMapData.viewport.lngBounds.min,
                    },
                    {
                        lat: localMapData.viewport.latBounds.max,
                        lng: localMapData.viewport.lngBounds.max,
                    },
                ]);
            if (localMapData?.datasets) {
                setSelectedDataset?.(
                    localMapData.datasets.map((data) => data.id)
                );
                isRunningOnSF && localMapData.datasets.map(updateDataSource);
            }

            const savedArea = localMapData?.savedArea || undefined;
            const viewport = localMapData?.viewport || undefined;

            handleSavedView({ savedArea, viewport });
        };
        if (isLoaded) {
            updateData();
        } else {
            map.current?.on("style.load", () => {
                updateData();
            });
        }
    }, [localMapData]);

    const [prompt, setPrompt] = useState("");
    const isDrawFunctionType =
        functionType === FunctionType.polygon ||
        functionType === FunctionType.radius ||
        functionType === FunctionType.square;

    const [lastFunctionType, setLastFunctionType] = useState<
        | Extract<
              FunctionType,
              | FunctionType.polygon
              | FunctionType.square
              | FunctionType.radius
              | FunctionType.fillArea
              | FunctionType.draw
              | FunctionType.data
          >
        | undefined
    >(FunctionType.polygon);

    const searchResults: SubscriptionResponse[] = Object.values(
        pickBy(responses, (_, key) => selectedDataset.includes(key))
    ) as SubscriptionResponse[];

    enum DatasetMetric {
        velocity = "velocity",
        latency = "latency",
        parsing = "parsing",
    }

    const getMetricColorAndLabel = (metric: DatasetMetric) => {
        return match<DatasetMetric>(metric)
            .with(DatasetMetric.velocity, () => ({
                label: DatasetMetric.velocity,
                color: "#122159",
            }))
            .with(DatasetMetric.latency, () => ({
                label: DatasetMetric.latency,
                color: "#597BF3",
            }))
            .with(DatasetMetric.parsing, () => ({
                label: DatasetMetric.parsing,
                color: "#829CF6",
            }))
            .exhaustive();
    };

    const getDatasetFoundAndRendered = (
        databaseId: string
    ): { found: number; rendered: number } => {
        const dataset = searchResults.filter((result) =>
            isEqual(result.databaseId, databaseId)
        );

        const sumOfDatasetNonPointLengths: number = dataset.reduce(
            (acc, currentValue: SubscriptionResponse) =>
                currentValue.geometry
                    ? acc + currentValue.geometry.nonPoints.length
                    : acc,
            0
        );

        const sumOfDatasetPointCounts: number = dataset.reduce(
            (acc, currentValue: SubscriptionResponse) =>
                currentValue.geometry
                    ? acc + currentValue.geometry.pointCount
                    : acc,
            0
        );

        const sumOfDatasetNonPointCounts: number = dataset.reduce(
            (acc, currentValue: SubscriptionResponse) =>
                currentValue.geometry
                    ? acc + currentValue.geometry.nonPointCount
                    : acc,
            0
        );

        const sumOfDatasetAggregationCounts: number = dataset.reduce(
            (acc, curr) =>
                curr.geometry
                    ? acc + sumCounts(curr.geometry.aggregation)
                    : acc,
            0
        );

        const sumOfDatasetPoints = isNonPointsLengthPositive(dataset)
            ? sumOfDatasetNonPointLengths || 0
            : sumOfDatasetPointCounts || 0;

        const found =
            sumOfDatasetPoints +
            (isNonPointCountPositive(dataset)
                ? sumOfDatasetNonPointCounts
                : isAggregationNonEmpty(dataset)
                  ? sumOfDatasetAggregationCounts
                  : 0);

        const rendered = sumOfDatasetNonPointLengths
            ? sumOfDatasetNonPointLengths || 0
            : sumOfDatasetPointCounts || 0;

        return { found, rendered };
    };

    const responseParseTimes = useResponseParseTimes();
    const bgsearchTimestamp = useSearchTimestamp();

    const _getDatasetColor = (databaseId: string, dsColors: DatasetColor[]) => {
        return dsColors.find((dsColor) =>
            isEqual(dsColor.databaseId, databaseId)
        );
    };

    const getDatasetTimesTaken = (databaseId: string): IconLegendProps[] => {
        const dataset = searchResults.find((result) =>
            isEqual(result.databaseId, databaseId)
        );
        const velocity = dataset?.geometry?.timeMs || 0;
        const roundTripTime = bgsearchTimestamp - Number(dataset?.dateTime);
        const latency = roundTripTime - velocity;
        const datasetParseTimes = responseParseTimes.find((item) =>
            isEqual(databaseId, item.databaseId)
        );
        const parsing =
            (datasetParseTimes?.endTime || 0) -
            (datasetParseTimes?.startTime || 0);

        const metricValues = {
            velocity,
            response: roundTripTime,
            latency,
            parsing,
        };

        return pipe(
            Object.values(DatasetMetric),
            A.map((v) => ({
                ...getMetricColorAndLabel(v),
            })),
            A.map((item) => {
                return { ...item, time: metricValues[item.label] };
            })
        );
    };

    const datasetSearchResults: DatasetResultType[] = pipe(
        searchResults,
        A.map((result) => {
            const dataSource = dataSources.find(
                (data) => data.id === result.databaseId
            );

            const foundAndRendered = getDatasetFoundAndRendered(
                result.databaseId
            );
            const timeTaken = getDatasetTimesTaken(result.databaseId);
            return {
                ...foundAndRendered,
                timeTaken,
                color: dataSource?.color || "",
                label: dataSource?.label || "",
                databaseId: result.databaseId,
            };
        })
    );

    const onClose = () => {
        draw.current?.changeMode("simple_select");

        if (isDrawFunctionType) {
            setLastFunctionType(functionType);
        }

        setFunctionType(FunctionType.viewport);
    };

    const shapes = getMapFeatures(draw, isLoaded);
    const hasDrawnShapes = !isEmpty(shapes);
    const hasSelectedAreas = !isEmpty(savedPolygons);
    const hasDataSets = !isEmpty(selectedDataset);
    const hasPolygons = !isEmpty(polygons);

    const canResetView =
        hasDrawnShapes || hasSelectedAreas || hasDataSets || hasPolygons;

    const resetView = () => {
        if (draw.current && hasDrawnShapes) {
            draw.current.deleteAll();
            draw.current?.changeMode("simple_select");
            if (map.current) {
                removeCustomLayers(map.current);
            }
        }

        if (hasSelectedAreas) {
            resetAreasFilters();
        }

        if (hasDataSets) {
            setSelectedDataset([]);
            setMultiFilters([]);
            setHoveredData(undefined);
        }

        const source = map.current?.getSource(
            "saved-polygons"
        ) as GeoJSONSource;

        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        const EmptyPolyData: any = {
            type: "FeatureCollection",
            features: [],
        } as const;

        source?.setData(EmptyPolyData);
        setLocalMapData(undefined);
        setSelectedAreaId?.(undefined);
        setSelectedSavedView?.(undefined);
        clearShapes?.();
        setFunctionType(FunctionType.viewport);
        setLastFunctionType(undefined);
        selectMode(false);
        setSearchParams({});
        dispatch(mapDataActions.updateMapData({}));
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        // When they click on saved areas from map side menu
        if (functionType === FunctionType.savedPolygon) {
            draw.current?.changeMode("simple_select");
            selectMode(false);
        }
    }, [functionType]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const datasetIds = getDatasetIdsFromParams(searchParams);
        if (isLoaded && currentDataSource.id) {
            addRemoveDataset(currentDataSource.id);
            setSelectedDataset(
                updateSelected(
                    currentDataSource.id,
                    pipe(selectedDataset, toNonReadonlyArray)
                )
            );
            dispatch(
                databaseMetaDataActions.setCurrentDataSource({
                    id: undefined,
                    label: undefined,
                    color: "#ffffff",
                    type: DatabaseType.point,
                    icon: undefined,
                    opacity: 0,
                    collectionName: undefined,
                    tableName: undefined,
                    size: 0,
                    isConnected: false,
                    progress: 0,
                })
            );
        } else if (isLoaded && !isEmpty(datasetIds)) {
            setSelectedDataset(datasetIds);
            const dataSetsToRender = dataSources.filter((dSource) =>
                datasetIds.includes(dSource.id)
            );

            addMultipleDatasets(
                compact(
                    dataSetsToRender.map((c) => {
                        return {
                            databaseId: c.id,
                            collection: c.collectionName,
                            databaseType: c.type,
                            color: c.color,
                        };
                    })
                )
            );
        }
    }, [isLoaded]);

    const { executeMutation: switchComputeOff } = useSwitchComputeOffMutation();
    const switchOffDataset = (id: string) => {
        const updateDataset = (data: DataSource) => {
            dispatch(
                toasterActions.openToast({
                    open: true,
                    title: "Load collection failed. Please turn it back on from the data tab.",
                    autoHideDuration: 8000,
                    severity: Severity.error,
                })
            );
            dispatch(databaseMetaDataActions.updateDataSource(data));
            addRemoveDataset(id);
        };
        const foundDataset = dataSources.find((d) => d.id === id);

        foundDataset &&
            switchComputeOff({
                variables: {
                    input: { id, collectionName: foundDataset.collectionName },
                },
                onCompleted: (d) => {
                    updateDataset(d.switchComputeOff);
                },
                onError: () => {
                    updateDataset({
                        ...foundDataset,
                        isLoading: false,
                        compute: false,
                    });
                },
            });
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependencies
    useEffect(() => {
        if (
            recentResponse &&
            !isNil(recentResponse.collectionExist) &&
            !recentResponse.collectionExist
        ) {
            switchOffDataset(recentResponse.databaseId);
            setSelectedDataset(
                [...selectedDataset].filter(
                    (d) => d !== recentResponse.databaseId
                )
            );
        }
    }, [recentResponse]);

    return (
        <Fade in={isLoaded} timeout={1500}>
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                    position: "relative",
                }}
            >
                <Box
                    id={"map"}
                    sx={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        lineHeight: 0,
                        borderRadius: (theme) => theme.radius.default,
                        overflow: "hidden",
                    }}
                />
                <MapPromptPopup />
                <Grid
                    container
                    flexDirection={"column"}
                    gap={2}
                    justifyContent="flex-end"
                    sx={{
                        position: "absolute",
                        left: 4,
                        top: 4,
                        display: "none",
                        breakpoints: {
                            lg: {
                                width: 60,
                            },
                            md: {
                                display: "flex",
                            },
                            xs: {
                                width: 40,
                            },
                        },
                    }}
                >
                    <Box
                        id="geocoder"
                        sx={{
                            "& >.mapboxgl-ctrl-geocoder.mapboxgl-ctrl": {
                                minWidth: "100%",
                            },
                        }}
                    />
                    <Grid
                        item
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.background.main,
                            borderRadius: (theme) => theme.radius.xs5,
                        }}
                    >
                        <>
                            <Accordion
                                density={"dense"}
                                sx={{ paddingX: 0 }}
                                label={
                                    <Stack
                                        flexDirection={"row"}
                                        justifyContent={"space-between"}
                                        sx={{ paddingX: 2 }}
                                    >
                                        <Typography
                                            variant={"body3"}
                                            fontWeight={"semibold"}
                                        >
                                            Legend
                                        </Typography>
                                        <ExpandContentOutline size={"sm"} />
                                    </Stack>
                                }
                                hideExpandIcon
                            >
                                <FlexScrollArea
                                    sx={{ maxHeight: 80 }}
                                    flexDirection={"column"}
                                >
                                    {pipe(
                                        datasetSearchResults,
                                        A.mapWithIndex((i, result) => (
                                            <Accordion
                                                slotProps={{
                                                    expandedIcon: {
                                                        size: "sm",
                                                    },
                                                    expandIcon: { size: "sm" },
                                                }}
                                                density={"dense"}
                                                key={i}
                                                label={
                                                    <OverflowingTypography
                                                        variant={"body4"}
                                                    >
                                                        {result.label}
                                                    </OverflowingTypography>
                                                }
                                                startNode={
                                                    <Circle
                                                        size={"xxs"}
                                                        sx={{
                                                            color: result.color,
                                                        }}
                                                    />
                                                }
                                            >
                                                <FlexScrollArea flexDirection="column">
                                                    <Stack
                                                        flexDirection={"row"}
                                                        gap={2}
                                                        width={"54.5"}
                                                        sx={{ paddingLeft: 1 }}
                                                    >
                                                        <Divider
                                                            orientation={
                                                                "vertical"
                                                            }
                                                        />
                                                        <MapInterfaceLegend
                                                            databaseId={
                                                                result.databaseId
                                                            }
                                                            found={result.found}
                                                            rendered={
                                                                result.rendered
                                                            }
                                                            values={
                                                                result.timeTaken
                                                            }
                                                            map={map}
                                                            allPointsColor={
                                                                result.color
                                                            }
                                                        />
                                                    </Stack>
                                                </FlexScrollArea>
                                            </Accordion>
                                        ))
                                    )}
                                </FlexScrollArea>
                            </Accordion>
                        </>
                    </Grid>
                </Grid>
                <FunctionContainer
                    onClose={onClose}
                    functionType={functionType}
                    setFunctionType={setFunctionType}
                    draw={draw}
                    selectMode={selectMode}
                    isSelectMode={isSelectMode}
                    selectedShapes={selectedShapes}
                    snapToView={() =>
                        snapToView({
                            polygons: polygons || [],
                            map,
                        })
                    }
                    setReFetchSavedAreas={setReFetchSavedAreas}
                    isDrawMode={isDrawMode}
                    lastFunctionType={lastFunctionType}
                    setLastFunctionType={setLastFunctionType}
                    hasDrawnShapes={hasDrawnShapes}
                    mapTemplateId={mapTemplateId}
                />
                <MapControls
                    options={options}
                    setOptions={setOptions}
                    map={map}
                    changeProjection={changeProjection}
                    projection={projection}
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                    geospatialSelection={pickGeospatialSelection(functionType, {
                        viewport,
                        multipolygon: polygons?.map((c) => ({
                            inners: c.inners,
                            outer: c.outer,
                        })),
                    })}
                    multiFilters={multiFilters}
                    setMultiFilters={setMultiFilters}
                    channelId={channelId}
                />
                <SaveView
                    openSaveViewPopper={openSaveViewPopper}
                    setOpenSaveViewPopper={setOpenSaveViewPopper}
                    dataSources={dataSources}
                    bounds={bounds}
                    selectedDataset={selectedDataset}
                    viewport={viewport}
                    draw={draw}
                    multiFilters={multiFilters}
                    refetchSavedAreas={refetchSavedAreas}
                    refetchSavedViews={refetchSavedViews}
                    onCloseDraw={onClose}
                    savedViewId={savedViewId}
                    isLoaded={isLoaded}
                    handleSavedView={handleSavedView}
                    savedPolygons={savedPolygons}
                />
                <AiPromptContainer
                    addRemoveDataset={addRemoveDataset}
                    addMultipleDatasets={addMultipleDatasets}
                    handleViewportChange={handleViewportChange}
                    multiFilters={multiFilters}
                    setMultiFilters={setMultiFilters}
                    prompt={prompt}
                    setPrompt={setPrompt}
                    map={map}
                    draw={draw}
                    setFunctionType={setFunctionType}
                    snapToView={snapToView}
                    polygons={polygons}
                    handleMultiPolygons={handleMultiPolygons}
                    setShowAiPrompt={setShowAiPrompt}
                    showAiPrompt={showAiPrompt}
                    setSelectedDataset={setSelectedDataset}
                    selectedDataset={selectedDataset}
                />
                <Accreditation />
                {canResetView && !openSaveViewPopper && (
                    <ResetView resetView={resetView} />
                )}
                {!isSelectMode && !isDrawMode && (
                    <ViewPointData
                        selectedPoint={selectedPoint}
                        setSelectedPoint={setSelectedPoint}
                        responses={responses}
                        filterByPolygon={(_p) => {
                            setFunctionType(FunctionType.savedPolygon);
                            delay(2000).then(() => {
                                delay(500).then(() => {
                                    setSelectedPoint(undefined);
                                });
                            });
                        }}
                    />
                )}
            </Box>
        </Fade>
    );
};
