import {
    AvatarChip,
    AvatarGroup,
    Box,
    Button,
    CellItem,
    CompanyAvatar,
    InfoCell,
    Menu,
    OverflowingTypography,
    Radio,
    Stack,
    Typography,
    chipClasses,
} from "@biggeo/bg-ui/lab";
import {
    ArrowBackOutline,
    UnfoldMoreOutline,
    bigGeoLogoPng,
    sAndPLogoPng,
    snowflakeLogoPng,
} from "@biggeo/bg-ui/lab/icons";
import { useState } from "react";
import { isSnp } from "../../common/redux/hooks.ts";
import { user } from "../dummy-data.tsx";

// TODO: Display authenticated user
export const PortalMenu = () => {
    const [open, setOpen] = useState(false);

    const isSNP = isSnp();

    // TODO: Implement actual snowflake check
    const isSnowflake = false;

    // TODO: Implement actual version number
    const version = "1.0.0";

    return (
        <Menu
            open={open}
            onOpenChange={(open) => setOpen(open)}
            placement="bottom-end"
            sx={{
                padding: 0,
                //@UiDiscrepancy - Figma calls for 67.5 but bumped up to 72 to ensure the default text like "create new organization" does not truncate in the components base state
                maxWidth: 72,
                width: "100%",
                gap: 0,
                scrollbarGutter: "auto",
            }}
            content={
                <>
                    {/* @UiDiscrepancy - Figma is calling for a a color of disabled.onContainer but Typography does not expose control to invert colors or change the color set */}
                    {!isSNP && !isSnowflake && (
                        <Typography
                            variant="body3"
                            fontWeight="semibold"
                            sx={{
                                color: (theme) =>
                                    theme.palette.disabled.onContainer,
                                padding: 2,
                            }}
                        >
                            Switch product portal
                        </Typography>
                    )}
                    <Stack gap={1} sx={{ padding: 2 }}>
                        {isSNP ? (
                            <InfoCell
                                title={`Version ${version}`}
                                subtitle="Powered by BigGeo"
                                startNode={
                                    <CompanyAvatar
                                        alt="BigGeo"
                                        size="sm"
                                        src={bigGeoLogoPng}
                                    />
                                }
                                slotProps={{
                                    subtitle: {
                                        color: "disabled",
                                        colorSet: "container",
                                        invertColors: true,
                                    },
                                }}
                            />
                        ) : isSnowflake ? (
                            <InfoCell
                                title={version}
                                subtitle="Powered by BigGeo"
                                startNode={
                                    <CompanyAvatar
                                        alt="BigGeo"
                                        size="sm"
                                        src={bigGeoLogoPng}
                                    />
                                }
                                slotProps={{
                                    subtitle: {
                                        color: "disabled",
                                        colorSet: "container",
                                        invertColors: true,
                                    },
                                }}
                            />
                        ) : (
                            <>
                                <CellItem
                                    startNode={
                                        <CompanyAvatar
                                            color="primary"
                                            size="sm"
                                            src={
                                                "https://biggeo.blob.core.windows.net/media/datascape-ai.png"
                                            }
                                            alt={"product portal"}
                                        />
                                    }
                                    endNode={<Radio checked={true} />}
                                    title={"Datascape AI"}
                                    subtitle={
                                        <OverflowingTypography
                                            colorSet={"container"}
                                            color={"disabled"}
                                            invertColors
                                            variant={"body4"}
                                        >
                                            Northstar Systems Canada
                                        </OverflowingTypography>
                                    }
                                    onClick={
                                        //TODO Switch portal
                                        () => {}
                                    }
                                />
                                <CellItem
                                    startNode={
                                        <CompanyAvatar
                                            color="primary"
                                            size="sm"
                                            src={
                                                "https://biggeo.blob.core.windows.net/media/datascape-ai.png"
                                            }
                                            alt={"product portal"}
                                        />
                                    }
                                    endNode={<Radio />}
                                    title={"Datascape AI"}
                                    subtitle={
                                        <OverflowingTypography
                                            colorSet={"container"}
                                            color={"disabled"}
                                            invertColors
                                            variant={"body4"}
                                        >
                                            Northstar Systems Canada
                                        </OverflowingTypography>
                                    }
                                    onClick={
                                        //TODO Switch portal
                                        () => {}
                                    }
                                />
                                <CellItem
                                    startNode={
                                        <CompanyAvatar
                                            color="primary"
                                            size="sm"
                                            src={
                                                "https://biggeo.blob.core.windows.net/media/simulate.png"
                                            }
                                            alt={"product portal"}
                                        />
                                    }
                                    endNode={<Radio />}
                                    title={"Simulate"}
                                    subtitle={
                                        <OverflowingTypography
                                            colorSet={"container"}
                                            color={"disabled"}
                                            invertColors
                                            variant={"body4"}
                                        >
                                            Northstar Systems Canada
                                        </OverflowingTypography>
                                    }
                                    onClick={
                                        //TODO Switch portal
                                        () => {}
                                    }
                                />
                            </>
                        )}
                    </Stack>
                    {!isSNP && !isSnowflake && (
                        <Box
                            sx={{
                                padding: 2,
                            }}
                        >
                            <Button
                                variant="outlined"
                                fullWidth
                                density="dense"
                                startNode={<ArrowBackOutline />}
                                onClick={() => {}}
                            >
                                Back to Studio
                            </Button>
                        </Box>
                    )}
                </>
            }
        >
            <AvatarChip
                density="dense"
                focused={open}
                startNode={
                    <AvatarGroup size="xs">
                        {isSNP ? (
                            <CompanyAvatar alt="S&P" src={sAndPLogoPng} />
                        ) : isSnowflake ? (
                            <CompanyAvatar
                                alt="Snowflake"
                                src={snowflakeLogoPng}
                            />
                        ) : (
                            <CompanyAvatar
                                alt={user.currentWorkspace.name}
                                src={user.currentWorkspace.img}
                            />
                        )}
                        <CompanyAvatar
                            alt={`${user.firstName} ${user.lastName}`}
                            src={
                                "https://biggeo.blob.core.windows.net/media/datascape-ai.png"
                            }
                        />
                    </AvatarGroup>
                }
                endNode={
                    <UnfoldMoreOutline
                        size="sm"
                        sx={{
                            transform: open ? "scale(1.25)" : "none",
                            transition: "transform 0.05s ease-in-out",
                        }}
                    />
                }
                sx={{
                    [`&.${chipClasses.outlined}`]: {
                        boxShadow: (theme) =>
                            `inset 0 0 0 1px ${theme.palette.stroke[100]}`,
                    },
                }}
            >
                Datascape AI
            </AvatarChip>
        </Menu>
    );
};
