import {
    DataSource,
    useFetchDataSourcesQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    BasicDataWithSelect,
    Button,
    LoadingBar,
    Menu,
} from "@biggeo/bg-ui/lab";
import { AddOutline } from "@biggeo/bg-ui/lab/icons";
import { toNonReadonlyArray, updateSelected } from "@biggeo/bg-utils";
import { match } from "@vividtheory/remotedata";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import includes from "lodash/includes";
import { useState } from "react";
import { useNavigate } from "react-router";
import AddDatasetWithSubMenu from "../../components/AddDatasetWithSubMenu/AddDatasetWithSubMenu";

export const AddDatasetSubMenuContainer = ({
    existingDatasets,
}: { readonly existingDatasets: readonly DataSource[] }) => {
    const { remote } = useFetchDataSourcesQuery({
        variables: {
            input: {},
        },
    });
    const isInExistingDatasets = (dataset: DataSource) => {
        return existingDatasets.some((existing) => existing.id === dataset.id);
    };

    const toPage = useNavigate();

    const [localDataList, setLocalDataList] = useState<readonly string[]>([]);

    const handleOnCheck = (selectedData: BasicDataWithSelect) => {
        setLocalDataList(
            updateSelected(
                selectedData.id,
                pipe(localDataList, toNonReadonlyArray)
            )
        );
    };

    return (
        <Menu
            placement="bottom-start"
            sx={{
                maxWidth: 72,
                width: "100%",
                gap: 0,
                scrollbarGutter: "auto",
            }}
            content={match(remote, {
                _: () => <LoadingBar />,
                Success: (data) => {
                    const filteredExistingDataset: BasicDataWithSelect[] = pipe(
                        data.fetchDataSources.dataSources,
                        A.filter((dataset) => !isInExistingDatasets(dataset)),
                        A.map((item) => ({
                            id: item.id,
                            image: item.src || "",
                            title: item.label || item.tableName,
                            select: includes(localDataList, item.id),
                        }))
                    );
                    return (
                        <AddDatasetWithSubMenu
                            title={"Have a dataset access key?"}
                            subTitle={"Unlock dataset through access key"}
                            filteredExistingDataset={filteredExistingDataset}
                            toPage={toPage}
                            handleOnCheck={handleOnCheck}
                        />
                    );
                },
            })}
        >
            <Button
                variant="outlined"
                density="dense"
                startNode={<AddOutline size="xs" />}
            >
                Add Dataset
            </Button>
        </Menu>
    );
};
