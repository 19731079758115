import { UserSnowflakeTableData } from "@biggeo/bg-server-lib/datascape-ai";
import {
    AutoComplete,
    Button,
    CompanyAvatar,
    Divider,
    Grid,
    Radio,
    Stack,
    ThumbnailAvatar,
} from "@biggeo/bg-ui/lab";
import {
    CompareArrowsOutline,
    CropSquare,
    SearchOutline,
} from "@biggeo/bg-ui/lab/icons";
import { Typography } from "@mui/material";
import { FormikProps } from "formik";
import { useState } from "react";
import { FormValuesType } from "../data/containers/UnIndexedManagementContainer";
import ColorPicker from "./ColorPicker/ColorPicker";
export type MappingModalProps = {
    readonly snowflakeData: UserSnowflakeTableData;
    readonly props: FormikProps<FormValuesType>;
    readonly oneGeospatialColumn: boolean;
    readonly twoGeospatialColumn: boolean;
    readonly setOneGeospatialColumn: (value: boolean) => void;
    readonly setTwoGeospatialColumn: (value: boolean) => void;
};

export const NewMappingModal = ({
    snowflakeData,
    props,
    oneGeospatialColumn,
    twoGeospatialColumn,
    setOneGeospatialColumn,
    setTwoGeospatialColumn,
}: MappingModalProps) => {
    const [, setDatasetAnchor] = useState<HTMLButtonElement | null>(null);
    const addDatasetAnchor = (e?: React.MouseEvent<HTMLButtonElement>) =>
        setDatasetAnchor(e ? e.currentTarget : null);

    return (
        <Stack
            width="100%"
            sx={{
                backgroundColor: (theme) => theme.palette.surface.container,
                padding: 4,
                gap: 4,
                borderRadius: (theme) => theme.radius.xs2,
            }}
        >
            <Stack gap={4} alignItems="flex-start">
                <Stack
                    flexDirection={"row"}
                    gap={1}
                    alignItems="center"
                    sx={{
                        padding: 2,
                        backgroundColor: (theme) =>
                            theme.palette.surface.onMain,
                        color: (theme) => theme.palette.disabled.main,
                        borderRadius: (theme) => theme.radius.xs3,
                    }}
                >
                    <ThumbnailAvatar
                        color="background"
                        sx={{
                            borderRadius: (theme) => theme.radius.default,
                        }}
                        src="https://biggeo.blob.core.windows.net/media/sf.png"
                        size="md"
                    />

                    <CompareArrowsOutline color={"disabled"} />
                    <CompanyAvatar
                        color="primary"
                        sx={{
                            borderRadius: (theme) => theme.radius.default,
                        }}
                        src="https://biggeo.blob.core.windows.net/media/bg-background.png"
                        size="md"
                    />
                </Stack>
                <Stack>
                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        color={(theme) => theme.palette.background.onMain}
                    >
                        Map Columns
                    </Typography>
                    <Typography
                        variant="body3"
                        fontWeight="regular"
                        color={(theme) => theme.palette.disabled.onContainer}
                    >
                        We need you to select your geospatial columns.
                    </Typography>
                </Stack>
                <Stack gap={4} width="100%">
                    <Button
                        variant="minimal"
                        sx={{
                            color: (theme) => theme.palette.info.main,
                        }}
                    >
                        Learn More
                    </Button>
                    <Divider />
                </Stack>
            </Stack>
            <Stack gap={4}>
                <Stack>
                    <Typography
                        variant="title2"
                        fontWeight="bold"
                        color={(theme) => theme.palette.background.onMain}
                    >
                        1. Select Primary Key
                    </Typography>
                    <Typography
                        variant="body3"
                        fontWeight="regular"
                        color={(theme) => theme.palette.disabled.onContainer}
                    >
                        Specify primary key column for your dataset here
                    </Typography>
                </Stack>
                <AutoComplete
                    options={snowflakeData.columns.map((data) => ({
                        label: data.name,
                    }))}
                    required
                    label="Primary key"
                    placeholder="Select"
                    startNode={<SearchOutline />}
                    helperText="Select your primary key"
                    fullWidth
                    onChange={(_, value) => {
                        props.setFieldValue("tableId", value || "");
                    }}
                />

                <Divider />
            </Stack>
            <Stack gap={4}>
                <Stack>
                    <Typography
                        variant="title2"
                        fontWeight="bold"
                        color={(theme) => theme.palette.background.onMain}
                    >
                        2. Choose Number Of Geospatial Columns
                    </Typography>
                    <Typography
                        variant="body3"
                        fontWeight="regular"
                        color={(theme) => theme.palette.disabled.onContainer}
                    >
                        How many geospatial columns does your dataset have?
                    </Typography>
                </Stack>
                <Grid container gap={1}>
                    <Grid item>
                        <Radio
                            checked={oneGeospatialColumn}
                            onChange={() => {
                                setOneGeospatialColumn(true);
                                setTwoGeospatialColumn(false);
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="body3">1 Column</Typography>
                    </Grid>
                </Grid>
                <Grid container gap={1}>
                    <Grid item>
                        <Radio
                            checked={twoGeospatialColumn}
                            onChange={() => {
                                setOneGeospatialColumn(false);
                                setTwoGeospatialColumn(true);
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="body3">2 Columns</Typography>
                    </Grid>
                </Grid>
                <Divider />
            </Stack>
            <Stack gap={4}>
                <Stack>
                    <Typography
                        variant="title2"
                        fontWeight="bold"
                        color={(theme) => theme.palette.background.onMain}
                    >
                        3. Map Your Geospatial Columns
                    </Typography>
                    <Typography
                        variant="body3"
                        fontWeight="regular"
                        color={(theme) => theme.palette.disabled.onContainer}
                    >
                        Select the columns from your dataset that represent
                        their geospatial attribute
                    </Typography>
                </Stack>
                <Stack>
                    {oneGeospatialColumn && (
                        <AutoComplete
                            options={snowflakeData.columns
                                .filter(
                                    (data) =>
                                        data.type === "GEOGRAPHY" ||
                                        data.type === "GEOMETRY"
                                )
                                .map((data) => ({
                                    label: data.name,
                                }))}
                            required
                            label="Geospatial Column"
                            placeholder="Select"
                            startNode={<SearchOutline />}
                            fullWidth
                            onChange={(_, value) => {
                                props.setFieldValue(
                                    "geoSpatialColumn",
                                    value || ""
                                );
                            }}
                        />
                    )}
                    {twoGeospatialColumn && (
                        <Grid
                            container
                            gap={4}
                            sx={{
                                breakpoints: {
                                    md: {
                                        flexDirection: "row",
                                    },
                                    xs: {
                                        flexDirection: "column",
                                    },
                                },
                            }}
                        >
                            <Grid item md>
                                <AutoComplete
                                    options={snowflakeData.columns
                                        .filter((data) => data.type === "REAL")
                                        .map((data) => ({
                                            label: data.name,
                                        }))}
                                    required
                                    label="Latitude"
                                    placeholder="Search latitude"
                                    startNode={<SearchOutline />}
                                    fullWidth
                                    onChange={(_, value) => {
                                        props.setFieldValue("lat", value || "");
                                    }}
                                />
                            </Grid>
                            <Grid item md>
                                <AutoComplete
                                    options={snowflakeData.columns
                                        .filter((data) => data.type === "REAL")
                                        .map((data) => ({
                                            label: data.name,
                                        }))}
                                    required
                                    label="Longitude"
                                    placeholder="Search longitude"
                                    startNode={<SearchOutline />}
                                    fullWidth
                                    onChange={(_, value) => {
                                        props.setFieldValue(
                                            "long",
                                            value || ""
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Stack>
                <Stack gap={4}>
                    {(oneGeospatialColumn || twoGeospatialColumn) && (
                        <>
                            <Stack>
                                <Typography
                                    variant="title2"
                                    fontWeight="bold"
                                    color={(theme) =>
                                        theme.palette.background.onMain
                                    }
                                >
                                    4. Select Your Dataset Color{" "}
                                </Typography>
                                <Typography
                                    variant="body3"
                                    fontWeight="regular"
                                    color={(theme) =>
                                        theme.palette.disabled.onContainer
                                    }
                                >
                                    Pick the default color your want for this
                                    dataset on the map
                                </Typography>
                            </Stack>

                            <Stack gap={0.5}>
                                <Grid
                                    container
                                    gap={2}
                                    alignItems="center"
                                    sx={{ padding: 1 }}
                                >
                                    <Grid item>
                                        <CropSquare
                                            sx={{
                                                color:
                                                    props.values.color ||
                                                    "#fffff",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography
                                            variant="body3"
                                            fontWeight="regular"
                                        >
                                            {props.values.color || "#fffff"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item maxWidth={"400"}>
                                    <ColorPicker
                                        initialColor={props.values.color}
                                        getColor={(color) => {
                                            props.setFieldValue("color", color);
                                            addDatasetAnchor();
                                        }}
                                        applyButton
                                    />
                                </Grid>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};
