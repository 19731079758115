import { Grid, Stack } from "@biggeo/bg-ui/lab";
import { Resource } from "../types.ts";
import { MinimalHeader } from "./MinimalHeader.tsx";
import { ResourceCard } from "./ResourceCard.tsx";

type TemporaryHomePageProps = {
    readonly resources: readonly Resource[];
};
const breakpoints = {
    xs: 12,
    sm: 6,
    md: 6,
    lg: 6,
    xl: 4,
};

const TemporaryHomePage = ({ resources }: TemporaryHomePageProps) => {
    return (
        <Stack width={"100%"} gap={4}>
            <MinimalHeader title={"Resources"} />
            <Grid container spacing={4}>
                {resources.map((resource) => (
                    <Grid key={resource.id} item {...breakpoints}>
                        <ResourceCard
                            logo={resource.logo}
                            onClick={() => resource.onClick(resource.id)}
                            title={resource.title}
                            description={resource.description}
                            cta={resource.cta}
                        />
                    </Grid>
                ))}
            </Grid>
        </Stack>
    );
};

export default TemporaryHomePage;
