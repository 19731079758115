import { DataSource } from "@biggeo/bg-server-lib/datascape-ai";
import { Accordion, Chip, Divider, Grid, Stack } from "@biggeo/bg-ui/lab";
import { ArrowBackOutline, Circle } from "@biggeo/bg-ui/lab/icons";
import { ColorSwatchOption } from "../../common/components/ColorSwatchSelector";
import { HeatMapClusterColorMapper } from "../../common/components/HeatMapClusterColorMapper";
import {
    HeatMapColorSwatchOption,
    getHeatMapValue,
} from "../../map/mapbox/utils/heatmap";
import DatasetCustomMarker from "../../map/mapbox/views/DatasetCustomMarker";
import MapColorSetter, {
    MapColorSetterType,
} from "../../map/views/MapColorSetter";

export const DatasetCardMapItem = ({
    currentDataSource,
    toggleDatapointsView,
    currentHexColor,
    setCurrentDataSource,
    updateDataSource,
    heatMapValue,
    onClickHeatMapValue,
    currentColor,
    isRunningOnSF,
    updateDataSourceColor,
    updateMapTemplateDatasetColor,
}: {
    readonly currentDataSource: Partial<DataSource>;
    readonly toggleDatapointsView: (value: boolean) => void;
    readonly currentHexColor: () => string;
    readonly setCurrentDataSource: (value: Partial<DataSource>) => void;
    readonly updateDataSource: (value: Partial<DataSource>) => void;
    readonly heatMapValue?: HeatMapColorSwatchOption[];
    readonly onClickHeatMapValue: (
        datasetId?: string
    ) => (value: ColorSwatchOption) => void;
    readonly currentColor: () => string | undefined | null;
    readonly isRunningOnSF: boolean;
    readonly updateDataSourceColor: (
        value: Partial<MapColorSetterType>
    ) => void;
    readonly updateMapTemplateDatasetColor: (
        value: Partial<MapColorSetterType>
    ) => void;
}) => {
    const onChange = (v?: string) => {
        if (currentDataSource) {
            setCurrentDataSource({
                ...currentDataSource,
                icon: v || undefined,
            });
        }

        updateDataSource({
            ...currentDataSource,
            icon: v || undefined,
        });
    };
    return (
        <Stack gap={2} width="100%">
            <Chip
                variant="tonal"
                color="background"
                sx={{ width: "100%" }}
                onClick={() => toggleDatapointsView(false)}
                startNode={
                    //@UiDiscrepancy - The spec is calling for these to icons to be put in separate start slots. However, the Chip component does not support multiple start slots. The icons have been combined using a stack.
                    <Stack gap={2} flexDirection="row" alignItems="center">
                        <ArrowBackOutline />
                        <Circle
                            size={"sm"}
                            sx={{
                                color: currentHexColor() || "#fffff",
                            }}
                        />
                    </Stack>
                }
            >
                {currentDataSource.label}
            </Chip>
            <Accordion label="Custom marker" density={"dense"}>
                <Stack
                    flexDirection="row"
                    gap={2}
                    sx={{
                        paddingLeft: 2,
                    }}
                >
                    <Divider orientation={"vertical"} />
                    <DatasetCustomMarker
                        value={currentDataSource.icon || undefined}
                        onChange={onChange}
                    />
                </Stack>
            </Accordion>
            <Accordion label="Fill" density={"dense"}>
                <Grid container gap={2}>
                    <Grid item sx={{ paddingLeft: 4 }}>
                        <Divider
                            orientation={"vertical"}
                            sx={{ height: "100%" }}
                        />
                    </Grid>
                    <Grid item xs>
                        <MapColorSetter
                            color={currentColor() || undefined}
                            opacity={0.9}
                            onChange={({ color, opacity }) => {
                                updateDataSourceColor({
                                    color,
                                    opacity,
                                });
                            }}
                            saveColor={(color) => {
                                updateMapTemplateDatasetColor({ color });
                            }}
                            isRunningOnSF={isRunningOnSF}
                        />
                    </Grid>
                </Grid>
            </Accordion>
            <Accordion label="Data Aggregation" density={"dense"}>
                <HeatMapClusterColorMapper
                    heatMapValue={getHeatMapValue(
                        heatMapValue,
                        currentDataSource.id
                    )}
                    onClickHeatMapValue={onClickHeatMapValue(
                        currentDataSource.id
                    )}
                />
            </Accordion>
        </Stack>
    );
};
