import {
    DataSource,
    FilterObject,
    MapTemplateDataset,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Box,
    Button,
    CellItem,
    Grid,
    IconButton,
    Stack,
    Switch,
    Tooltip,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    Circle,
    DragIndicatorOutline,
    MoreVertOutline,
    VisibilityOffOutline,
    VisibilityOutline,
} from "@biggeo/bg-ui/lab/icons";
import { toNonReadonlyArray, updateSelected } from "@biggeo/bg-utils";
import { pipe } from "fp-ts/lib/function";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import React, { useState } from "react";
import { ColorSwatchOption } from "../common/components/ColorSwatchSelector";
import { useMap } from "../map/mapbox/context";
import { hideFilteredDataLayers } from "../map/utils/style-utils";
import { convertToHex8String } from "../utils/color";

type LeftContentProps = React.PropsWithChildren<{
    readonly index: number;
    readonly item: DataSource;
    readonly currentMapTemplateDataset: MapTemplateDataset;
    readonly currentHeatMapValue?: ColorSwatchOption;
    readonly selected: boolean;
    readonly addRemoveDataset: (str: string) => void;
    readonly selectedDataset: readonly string[];
    readonly setSelectedDataset: (datasets: readonly string[]) => void;
    readonly onItemClick: () => void;
    readonly multiFilters: FilterObject[];
    readonly manageCompute: () => void;
    readonly gradient?: string;
}>;

export const LeftContent = ({
    item,
    selected,
    addRemoveDataset,
    selectedDataset,
    setSelectedDataset,
    onItemClick,
    manageCompute,
    gradient,
    currentMapTemplateDataset,
    currentHeatMapValue,
}: LeftContentProps) => {
    const [hover, setHover] = useState<boolean>(false);
    const { dispatch, map, isLoaded } = useMap();

    const removeSelectedDataset = (id: string) => {
        const datasets = selectedDataset.filter((d) => d !== id);

        setSelectedDataset(datasets);
        dispatch?.({ type: "SET_LAST_SELECTED_DATASET", values: undefined });

        if (isEmpty(datasets)) {
            dispatch?.({ type: "RESET_FILTERS" });

            hideFilteredDataLayers({
                map,
                isLoaded,
                suffix: "preview",
                dataset: {
                    dataSource: item,
                    mapTemplateDataset: currentMapTemplateDataset,
                    currentHeatMapValue,
                },
            });
        }
    };
    const addSelectedDataset = (id: string) => {
        setSelectedDataset([...selectedDataset, id]);
        dispatch?.({
            type: "SET_LAST_SELECTED_DATASET",
            values: {
                dataSource: item,
                mapTemplateDataset: currentMapTemplateDataset,
                currentHeatMapValue,
            },
        });
    };

    return (
        <Stack
            gap={2}
            sx={{
                borderRadius: (theme) => theme.radius.default,
                overflow: "hidden",
                border: 1,
                borderColor: (theme) => theme.palette.stroke[100],
            }}
        >
            <Tooltip
                placement="right-start"
                title={
                    <DatasetTooltip
                        compute={item.compute || undefined}
                        manageCompute={manageCompute}
                    />
                }
                disableHover={Boolean(item.compute && !selected)}
            >
                <span>
                    <Box
                        sx={{
                            background: `${gradient}`,
                            height: 2,
                        }}
                    />
                    <Stack>
                        <CellItem
                            disableHoverEffect
                            key={item.id}
                            density="none"
                            title={
                                <Typography
                                    variant="body3"
                                    fontWeight="regular"
                                    color="background"
                                    colorSet="main"
                                    invertColors
                                    truncate
                                >
                                    {item.label || item.tableName}
                                </Typography>
                            }
                            sx={{
                                "&.MinervaCellItem-root": {
                                    padding: 1,
                                },
                                ...(gradient && {
                                    border: (theme) =>
                                        `1px solid ${theme.palette.stroke[100]}`,
                                    borderRadius: (theme) => theme.radius.none,
                                }),
                                backgroundColor: (theme) =>
                                    theme.palette.surface.onMain,
                            }}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                            readonly={
                                !item.compute || isEqual(item.compute, false)
                            }
                            startNode={
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 2,
                                    }}
                                >
                                    {hover && (
                                        <Grid item alignSelf="center">
                                            <DragIndicatorOutline />
                                        </Grid>
                                    )}
                                    <Switch
                                        switched={selected}
                                        disabled={
                                            !item.compute ||
                                            isEqual(item.compute, false)
                                        }
                                        onSwitchChange={() => {
                                            addRemoveDataset(item.id);
                                            !selected
                                                ? addSelectedDataset(item.id)
                                                : removeSelectedDataset(
                                                      item.id
                                                  );
                                        }}
                                    />

                                    <Circle
                                        sx={{
                                            color: convertToHex8String(
                                                item.color || "#ffffff",
                                                item.opacity
                                            ),
                                        }}
                                        size={gradient ? "xxs" : "sm"}
                                    />
                                </Box>
                            }
                            endNode={
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 2,
                                    }}
                                >
                                    {hover && (
                                        <IconButton
                                            density="dense"
                                            variant="minimal"
                                            onClick={(_e) => {
                                                setSelectedDataset(
                                                    updateSelected(
                                                        item.id,
                                                        pipe(
                                                            selectedDataset,
                                                            toNonReadonlyArray
                                                        )
                                                    )
                                                );
                                            }}
                                            sx={{
                                                borderRadius: (theme) =>
                                                    theme.radius.full,
                                            }}
                                            disabled={!selected}
                                        >
                                            {includes(
                                                selectedDataset,
                                                item.id
                                            ) ? (
                                                selected ? (
                                                    <VisibilityOutline size="xs" />
                                                ) : (
                                                    <VisibilityOffOutline size="xs" />
                                                )
                                            ) : (
                                                <VisibilityOffOutline />
                                            )}
                                        </IconButton>
                                    )}
                                    <IconButton
                                        density="dense"
                                        variant="minimal"
                                        onClick={onItemClick}
                                        sx={{
                                            borderRadius: (theme) =>
                                                theme.radius.full,
                                        }}
                                    >
                                        <MoreVertOutline size="sm" />
                                    </IconButton>
                                </Box>
                            }
                        />
                    </Stack>
                </span>
            </Tooltip>
        </Stack>
    );
};

export const DatasetTooltip = ({
    compute,
    manageCompute,
}: {
    readonly compute?: boolean;
    readonly manageCompute: () => void;
}) => (
    <Stack
        gap={1}
        justifyContent="center"
        alignItems="center"
        sx={{
            textAlign: "center",
            maxWidth: (theme) => theme.spacing(38.5),
        }}
    >
        <Typography variant="body3">
            {isEqual(compute, true)
                ? "This dataset is currently using your compute resources."
                : "Compute resources are currently off for this dataset."}
        </Typography>
        <Button variant="outlined" density="dense" onClick={manageCompute}>
            Manage Compute
        </Button>
    </Stack>
);
