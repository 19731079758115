import { Stack, Typography } from "@biggeo/bg-ui/lab";
import { CropSquare } from "@biggeo/bg-ui/lab/icons";
import tinycolor2 from "tinycolor2";
import ColorPicker from "../../components/ColorPicker/ColorPicker";
import { convertToHex8String } from "../../utils/color";

export type MapColorSetterType = Partial<{
    readonly color: string;
    readonly opacity: number;
}>;

type MapColorSetterProps = MapColorSetterType & {
    readonly onChange: (i: MapColorSetterType) => void;
    readonly saveColor?: (color: string) => void;
    readonly isRunningOnSF?: boolean;
};

const MapColorSetter = ({
    color,
    opacity,
    onChange,
    saveColor,
    isRunningOnSF,
}: MapColorSetterProps) => {
    const currentHexColor = () =>
        convertToHex8String(color || "#ffffff", opacity || 0.9);

    return (
        <Stack gap={0.5} width={"100%"}>
            <Stack flexDirection="row" alignItems="center" gap={2}>
                <CropSquare
                    sx={{
                        color: currentHexColor() || "#fffff",
                    }}
                />
                <Typography variant="body3" fontWeight="regular">
                    {(color || "#fffff").toUpperCase()}
                </Typography>
            </Stack>
            <ColorPicker
                initialColor={currentHexColor()}
                getColor={(color) => {
                    const tinyColor = tinycolor2(color);

                    onChange({
                        color: tinyColor.toHexString(),
                        opacity: tinyColor.getAlpha(),
                    });
                }}
                saveColor={saveColor}
                applyButton
                isRunningOnSF={isRunningOnSF}
            />
        </Stack>
    );
};

export default MapColorSetter;
