import { ReqOptions } from "@biggeo/bg-server-lib/datascape-ai";
import { Slider } from "@biggeo/bg-ui";
import {
    CellItem,
    Checkbox,
    Stack,
    Tooltip,
    Typography,
} from "@biggeo/bg-ui/lab";
import { Info } from "@biggeo/bg-ui/lab/icons";
import { isAppRunningOnSF } from "../common/redux/hooks";

const DebugContainer = ({
    options,
    setOptions,
    showFiltered,
    showPoints,
    showTriangles,
    setShowFiltered,
    setShowPoints,
    setShowTriangles,
}: {
    options: ReqOptions;
    setOptions: (p: ReqOptions) => void;
    showTriangles: boolean;
    showPoints: boolean;
    showFiltered: boolean;
    setShowFiltered: (b: boolean) => void;
    setShowPoints: (b: boolean) => void;
    setShowTriangles: (b: boolean) => void;
}) => {
    const isRunningOnSF = isAppRunningOnSF();
    return (
        <Stack gap={2} width="100%" sx={{ padding: 4 }}>
            <Stack
                flexDirection="row"
                gap={1}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Typography variant="title3" fontWeight="bold">
                    Points
                </Typography>
                <Tooltip
                    title={
                        <Stack gap={2}>
                            <Stack gap={1}>
                                <Typography variant="body2" fontWeight="bold">
                                    Maximum Geometries
                                </Typography>
                                <Typography variant="body3">
                                    This parameter describes the maximum number
                                    of geometries before BG-Search returns an
                                    aggregate view for that cell.
                                </Typography>
                            </Stack>
                            <Stack gap={1}>
                                <Typography variant="body2" fontWeight="bold">
                                    Resolution Offset
                                </Typography>
                                <Typography variant="body3">
                                    This parameter helps control the resolution
                                    of the aggregated cells returned.
                                </Typography>
                            </Stack>
                        </Stack>
                    }
                >
                    <span>
                        <Info color={"info"} size="sm" />
                    </span>
                </Tooltip>
            </Stack>
            <Typography variant="title4" fontWeight="bold">
                Maximum Geometries{" "}
                {isRunningOnSF
                    ? `(${options.pointMaxGeomsPerCell || 200})`
                    : `(${options.pointMaxGeomsPerCell || 101})`}
            </Typography>
            <Slider
                color="secondary"
                min={1}
                max={isRunningOnSF ? 300 : 200}
                defaultValue={
                    isRunningOnSF
                        ? options.pointMaxGeomsPerCell || 200
                        : options.pointMaxGeomsPerCell || 101
                }
                onChangeCommitted={(_e, v) => {
                    if (typeof v === "number")
                        setOptions({
                            ...options,
                            pointMaxGeomsPerCell: v,
                        });
                }}
            />
            <Typography variant="title4" fontWeight="bold">
                Resolution Offset{" "}
                {isRunningOnSF
                    ? `(${options.pointResolutionOffset || 3})`
                    : `(${options.pointResolutionOffset || 3})`}
            </Typography>
            <Slider
                color="secondary"
                min={1}
                max={isRunningOnSF ? 6 : 4}
                defaultValue={
                    isRunningOnSF
                        ? options.pointResolutionOffset || 3
                        : options.pointResolutionOffset || 3
                }
                onChangeCommitted={(_e, v) => {
                    if (typeof v === "number")
                        setOptions({
                            ...options,
                            pointResolutionOffset: v,
                        });
                }}
            />
            <Typography variant="title3" fontWeight="bold">
                Polygons
            </Typography>
            <Typography variant="title4" fontWeight="bold">
                Maximum Geometries{" "}
                {isRunningOnSF
                    ? `(${options.polygonMaxGeomsPerCell || 125})`
                    : `(${options.polygonMaxGeomsPerCell || 75})`}
            </Typography>
            <Slider
                color="secondary"
                min={1}
                max={isRunningOnSF ? 300 : 200}
                defaultValue={
                    isRunningOnSF
                        ? options.polygonMaxGeomsPerCell || 125
                        : options.polygonMaxGeomsPerCell || 75
                }
                onChangeCommitted={(_e, v) => {
                    if (typeof v === "number")
                        setOptions({
                            ...options,
                            polygonMaxGeomsPerCell: v,
                        });
                }}
            />
            <Typography variant="title4" fontWeight="bold">
                Resolution Offset{" "}
                {isRunningOnSF
                    ? `(${options.polygonResolutionOffset || 3})`
                    : `(${options.polygonResolutionOffset || 3})`}
            </Typography>
            <Slider
                color="secondary"
                min={1}
                max={isRunningOnSF ? 7 : 4}
                defaultValue={
                    isRunningOnSF
                        ? options.polygonResolutionOffset || 3
                        : options.polygonResolutionOffset || 3
                }
                onChangeCommitted={(_e, v) => {
                    if (typeof v === "number")
                        setOptions({
                            ...options,
                            polygonResolutionOffset: v,
                        });
                }}
            />
            <CellItem
                title={"Show Level Sets"}
                density="dense"
                selected={showTriangles}
                onClick={() => {
                    setShowTriangles(!showTriangles);
                }}
                startNode={<Checkbox checked={showTriangles} />}
            />
            <CellItem
                title={"Show Points"}
                density="dense"
                selected={showPoints}
                onClick={() => {
                    setShowPoints(!showPoints);
                }}
                startNode={<Checkbox checked={showPoints} />}
            />
            <CellItem
                title={"Show Filtered"}
                density="dense"
                selected={showFiltered}
                onClick={() => {
                    setShowFiltered(!showFiltered);
                }}
                startNode={<Checkbox checked={showFiltered} />}
            />
        </Stack>
    );
};

export default DebugContainer;
