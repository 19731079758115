import {
    DataSource,
    DataSourcesExtended,
    useRemoveDataSetMutation,
    useUpdateDataSourceMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { GridColDef, Switch, WithLoading } from "@biggeo/bg-ui";
import {
    Button,
    CircularLoading,
    ColFilterType,
    Grid,
    IFilterSearchPaginate,
    IconAvatar,
    Select,
    Stack,
    Tooltip,
    Typography,
} from "@biggeo/bg-ui/lab";
import { BigGeoLogo, Info, MapOutline } from "@biggeo/bg-ui/lab/icons";
import { toNonReadonlyArray, updateSelected } from "@biggeo/bg-utils";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import startCase from "lodash/startCase";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { isSnp } from "../../common/redux/hooks.ts";
import { DataGridContainer } from "../../components/DataGrid/DataGridContainer.tsx";
import { databaseMetaDataActions } from "../../database-meta-data/redux/model.ts";
import { formatNumberWithCommas } from "../../utils/utils.ts";
import { ComputeView } from "../views/ComputeView.tsx";
import EmptyDatasetView from "../views/EmptyDatasetView.tsx";

export interface IDataSetsContainer {
    readonly dataSources: DataSourcesExtended;
    readonly selectedDataset?: readonly string[];
    readonly setSelectedDataset?: (datasets: readonly string[]) => void;
    readonly isRunningOnSF: boolean;
    readonly setDataSources: (d: DataSource[]) => void;
    readonly toPage?: (path: string) => void;
    readonly hideViewButton?: boolean;
    readonly filterSearchPaginateProps: IFilterSearchPaginate;
}

const tooltips: {
    compute: string;
    computeUsage: string;
} = {
    compute:
        "This determines wether or not your data is actively using your available compute",
    computeUsage: "The percentage this dataset uses in your available compute",
};

export const BigGeoDataSetsContainer = ({
    dataSources,
    isRunningOnSF,
    setDataSources,
    toPage,
    filterSearchPaginateProps,
}: IDataSetsContainer) => {
    const dispatch = useDispatch();
    const isSNP = isSnp();

    const {
        executeMutation: removeDatasetMut,
        mutationReturn: [_remove, { loading: removeDatasetLoading }],
    } = useRemoveDataSetMutation();

    const updateDataset = (updated: DataSource) => {
        dispatch(databaseMetaDataActions.updateDataSource(updated));
    };

    const [selectedRowId, setSelectedRowId] = useState<string | undefined>();

    const setDataSource = (dataSource: Partial<DataSource>) =>
        setDataSources(
            pipe(
                dataSources.dataSources,
                A.map((d) =>
                    d.id === dataSource.id
                        ? {
                              ...d,
                              ...dataSource,
                          }
                        : d
                )
            )
        );

    const removeDataset = (dataSource: Partial<DataSource>) =>
        setDataSources(
            pipe(
                dataSources.dataSources,
                A.filter((d) => d.id !== dataSource.id)
            )
        );

    const handleMapViewClick = ({
        dataset,
    }: {
        readonly dataset: Partial<DataSource>;
    }) => {
        dispatch(databaseMetaDataActions.setCurrentDataSource(dataset));
        if (toPage) {
            toPage("/");
        }
    };

    const [disabledByCompute, setDisabledByCompute] = useState<
        readonly string[]
    >(
        dataSources.dataSources
            .filter((data) => !data.compute || data.isLoading)
            .map((val) => val.id)
    );

    const columns: GridColDef<DataSource>[] = [
        {
            field: "label",
            headerName: "Name",
            flex: 1,
            minWidth: 200,
            sortable: false,
            type: ColFilterType.string,
            filterable: true,
            renderCell: (params) => (
                <Typography variant="body3" fontWeight="semibold">
                    {params.row.label}
                </Typography>
            ),
        },
        {
            field: "tableName",
            headerName: "Mapped Table",
            flex: 1,
            minWidth: 150,
            sortable: false,
            type: ColFilterType.string,
            renderCell: (params) => {
                const [tableName, setTableName] = useState<string>(
                    params.row.tableName
                );

                const {
                    executeMutation: updateDataSource,
                    mutationReturn: [_, { loading: updateSourceLoading }],
                } = useUpdateDataSourceMutation();

                const snowflakeTablesObject = dataSources.snowflakeData.map(
                    (data) => ({
                        label: data.tableName,
                    })
                );

                return (
                    <Select
                        disabled={updateSourceLoading}
                        endNode={updateSourceLoading && <CircularLoading />}
                        options={snowflakeTablesObject}
                        value={tableName}
                        required
                        label="Table Name"
                        placeholder="Select"
                        helperText="Select the table that the dataset belongs to"
                        fullWidth
                        sx={{
                            cursor: "pointer",
                        }}
                        onChange={(value) => {
                            setTableName(`${value}`);
                            updateDataSource({
                                variables: {
                                    input: {
                                        id: params.row.id,
                                        tableName: value,
                                        sfAlias: dataSources.snowflakeData.find(
                                            (val) => val.tableName === value
                                        )?.alias,
                                    },
                                },
                                onCompleted: (data) => {
                                    setDataSource({
                                        id: data.updateDataSource.id,
                                        tableName:
                                            data.updateDataSource.tableName,
                                    });
                                },
                            });
                        }}
                    />
                );
            },
        },
        {
            field: "src",
            headerName: "Src",
            width: 75,
            sortable: false,
            type: ColFilterType.string,
            renderCell: () => (
                <Grid container justifyContent="center">
                    <IconAvatar color="primary" size="xs">
                        <BigGeoLogo />
                    </IconAvatar>
                </Grid>
            ),
            hideable: true,
        },
        {
            field: "size",
            headerName: "Size",
            width: 125,
            sortable: false,
            type: ColFilterType.number,
            filterable: true,
            renderHeader: (params) => (
                <Typography variant="body3" fontWeight="semibold">
                    {startCase(params.field)}
                </Typography>
            ),
            renderCell: (params) => (
                <Stack>
                    <Grid item>
                        <Typography variant="body3" fontWeight="semibold">
                            {formatNumberWithCommas(params.row.size)}
                        </Typography>
                    </Grid>
                </Stack>
            ),
        },
        {
            field: "compute",
            headerName: "compute",
            width: 150,
            sortable: false,
            type: ColFilterType.boolean,
            renderHeader: (params) => (
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="body3" fontWeight="semibold">
                        {startCase(params.field)}
                    </Typography>
                    <Tooltip title={tooltips.compute}>
                        <span>
                            <Info color="primary" size="xs" />
                        </span>
                    </Tooltip>
                </Stack>
            ),
            renderCell: (params) => (
                <ComputeView
                    id={params.row.id}
                    collectionName={params.row.collectionName}
                    compute={Boolean(params.row.compute)}
                    loading={Boolean(params.row.isLoading)}
                    setDataSource={setDataSource}
                    setDisabledByCompute={setDisabledByCompute}
                    updateDataset={updateDataset}
                />
            ),
        },
        {
            field: "remove",
            sortable: false,
            headerName: "",
            renderCell: (params) => {
                const id = params.row.id;

                const isGettingRemoved =
                    id === selectedRowId ? removeDatasetLoading : undefined;

                return (
                    <Stack flexDirection="row" gap={4}>
                        <WithLoading loading={isGettingRemoved} text="Removing">
                            <Button
                                variant="outlined"
                                color="error"
                                density="dense"
                                disabled={!params.row.isConnected}
                                onClick={() => {
                                    setSelectedRowId(params.row.id);
                                    removeDatasetMut({
                                        variables: {
                                            collectionName:
                                                params.row.collectionName,
                                        },
                                        onCompleted: ({
                                            removeDataSet: dataset,
                                        }) => {
                                            removeDataset(dataset);
                                        },
                                    });
                                }}
                            >
                                Remove
                            </Button>
                        </WithLoading>
                    </Stack>
                );
            },
        },
        {
            field: "view",
            sortable: false,
            headerName: "",
            renderCell: (params) => {
                const isViewDisabled = disabledByCompute.includes(
                    params.row.id
                );

                return (
                    <Stack flexDirection="row" gap={4}>
                        <Button
                            variant="filled"
                            color="primary"
                            density="dense"
                            endNode={<MapOutline size="xs" />}
                            disabled={isViewDisabled}
                            onClick={() =>
                                handleMapViewClick({ dataset: params.row })
                            }
                        >
                            View
                        </Button>
                    </Stack>
                );
            },
        },
    ];

    return dataSources.total > 0 ? (
        <DataGridContainer
            columnVisibilityModel={{
                compute: isRunningOnSF,
                remove: isRunningOnSF,
                link: !!toPage,
                tableName: isSNP,
            }}
            columns={columns}
            rows={dataSources.dataSources}
            rowCount={dataSources.total}
            title={isRunningOnSF ? "Snowflake" : "Big Geo Test Data"}
            filterSearchPaginateProps={filterSearchPaginateProps}
        />
    ) : (
        <EmptyDatasetView />
    );
};
