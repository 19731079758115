import {
    FilterItem,
    FilterObject,
    FilterObjectType,
    LogicOperator,
} from "@biggeo/bg-server-lib/datascape-ai";
import { useState } from "react";
import { useDatabaseMetaDataArray } from "../../database-meta-data/redux/hooks";

export type FilterControls = {
    filters: FilterObject;
    setFilters: (f: FilterObject) => FilterObject;
};

export const useFilters = (): {
    multiFilters: FilterObject[];
    addRemoveDataset: (v: string) => void;
    setMultiFilters: (mf: FilterObject[]) => void;
    addMultipleDatasets: (v: readonly FilterObjectType[]) => void;
} => {
    const datasets = useDatabaseMetaDataArray();

    const [multiFilters, setMultiFilters] = useState<FilterObject[]>([]);

    const addMultipleDatasets = (databaseIds: readonly FilterObjectType[]) => {
        const database = datasets.filter((c) =>
            databaseIds.some((d) => d.databaseId === c.id)
        );

        if (database.length === 0) return;

        setMultiFilters([
            ...multiFilters.filter(
                (c) => !databaseIds.some((d) => c.databaseId === d.databaseId)
            ),
            ...database.map((c) => {
                const find = databaseIds.find((d) => d.databaseId === c.id);
                const mf = multiFilters.find((m) => m.databaseId === c.id);
                if (!find)
                    return {
                        databaseId: c.id,
                        filters: [],
                        logicOperator: LogicOperator.and,
                        collection: c.collectionName,
                        databaseType: c.type,
                    };

                const updatedFilters = [
                    ...(find.filters?.map(
                        (d): FilterItem => ({
                            column: d.column,
                            data: {
                                dateData: d.data.dateData,
                                numberData: d.data.numberData,
                                stringData: d.data.stringData,
                                booleanData: d.data.booleanData,
                            },
                            operator: d.operator,
                            type: d.type,
                        })
                    ) || []),
                    ...(mf?.filters || []),
                ];
                return {
                    databaseId: c.id,
                    filters: updatedFilters.filter(
                        (filter, index) =>
                            index ===
                            updatedFilters.findIndex(
                                (other) => filter.column === other.column
                            )
                    ),
                    logicOperator: LogicOperator.and,
                    collection: c.collectionName,
                    databaseType: c.type,
                };
            }),
        ]);
    };

    const addRemoveDataset = (databaseId: string) => {
        const database = datasets.find((c) => c.id === databaseId);
        if (!database) {
            return;
        }
        const data = multiFilters.find((f) => f.databaseId === databaseId);
        if (data) {
            setMultiFilters(
                multiFilters.filter((f) => f.databaseId !== databaseId)
            );
        } else {
            setMultiFilters([
                ...multiFilters,
                {
                    databaseId: database.id,
                    filters: [],
                    logicOperator: LogicOperator.and,
                    collection: database.collectionName,
                    databaseType: database.type,
                },
            ]);
        }
    };

    return {
        multiFilters,
        addRemoveDataset,
        setMultiFilters,
        addMultipleDatasets,
    };
};
