import capitalize from "lodash/capitalize";
import { useEffect, useRef, useState } from "react";

import {
    // Don't change to lab component. This breaks color picker slider.
    Box,
} from "@biggeo/bg-ui";
import {
    Button,
    Grid,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Typography,
    textInputBaseClasses,
    useTheme,
} from "@biggeo/bg-ui/lab";
import { UnfoldMoreOutline } from "@biggeo/bg-ui/lab/icons";
import {
    Alpha,
    type HsvaColor,
    Hue,
    type PointerProps,
    Saturation,
} from "@uiw/react-color";
import type { ColorFormats } from "tinycolor2";
import {
    convertToTwoDecimalNumber,
    formatAmount,
    generateColorFormats,
    greaterThanCheck,
    removeHashAndLimitCharacters,
    removeNonDigits,
    startsWithZeroAndLengthIsGreaterThanOne,
} from "../../utils/color";

export interface ColorPickerProps {
    readonly initialColor?: string;
    readonly saveColor?: (color: string) => void;
    readonly addColor?: (color: string) => void;
    readonly getColor?: (color: string) => void;
    readonly applyButton?: boolean | string;
    readonly updateOnChange?: boolean;
    readonly isRunningOnSF?: boolean;
}

export type PossibleColorValues =
    | string
    | ColorFormats.HSVA
    | ColorFormats.HSLA
    | ColorFormats.RGBA;

type ColorFormatsLiteral = "hex" | "hsl" | "hsv" | "rgb";

interface Colors {
    hsl: ColorFormats.HSLA;
    hsv: ColorFormats.HSVA;
    rgb: ColorFormats.RGBA;
    hex: string;
}

const ColorPickerTextFields = ({
    selectedColorFormat,
    setColorFormat,
    color,
    colorFormatOptions,
    updateColor,
}: {
    readonly selectedColorFormat: ColorFormatsLiteral;
    readonly setColorFormat: (selectedColorFormat: ColorFormatsLiteral) => void;
    readonly color: Colors;
    readonly colorFormatOptions: ColorFormatsLiteral[];
    readonly updateColor: (inputColor: PossibleColorValues) => void;
}) => {
    const colorValue = color[selectedColorFormat];
    const isHex = typeof colorValue === "string";
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);

    const [alpha, setAlpha] = useState<number>(Number(color.rgb.a.toFixed(0)));
    const [hex, setHex] = useState<string>(color.hex);
    const [rgb, setRgb] = useState<ColorFormats.RGBA>({
        r: color.rgb.r,
        g: color.rgb.g,
        b: color.rgb.b,
        a: color.rgb.a,
    });
    const [hsv, setHsv] = useState<ColorFormats.HSVA>({
        h: convertToTwoDecimalNumber(color.hsv.h),
        s: convertToTwoDecimalNumber(color.hsv.s),
        v: convertToTwoDecimalNumber(color.hsv.v),
        a: convertToTwoDecimalNumber(color.hsv.a),
    });
    const [hsl, setHsl] = useState<ColorFormats.HSLA>({
        h: convertToTwoDecimalNumber(color.hsl.h),
        s: convertToTwoDecimalNumber(color.hsl.s),
        l: convertToTwoDecimalNumber(color.hsl.l),
        a: convertToTwoDecimalNumber(color.hsl.a),
    });

    const isRGB = typeof colorValue !== "string" && "r" in colorValue;

    const isHSV = typeof colorValue !== "string" && "v" in colorValue;

    const isHSL = typeof colorValue !== "string" && "l" in colorValue;

    useEffect(() => {
        setHex(color.hex);
        setRgb(color.rgb);
        setHsv({
            h: convertToTwoDecimalNumber(color.hsv.h),
            s: convertToTwoDecimalNumber(color.hsv.s),
            v: convertToTwoDecimalNumber(color.hsv.v),
            a: convertToTwoDecimalNumber(color.hsv.a),
        });
        setHsl({
            h: convertToTwoDecimalNumber(color.hsl.h),
            s: convertToTwoDecimalNumber(color.hsl.s),
            l: convertToTwoDecimalNumber(color.hsl.l),
            a: convertToTwoDecimalNumber(color.hsl.a),
        });
        setAlpha(Number(color.rgb.a));
    }, [color]);

    const updateContainerWidth = () => {
        if (containerRef.current) {
            setContainerWidth(
                containerRef.current?.getBoundingClientRect().width
            );
        }
    };

    const resizeObserver = new ResizeObserver(updateContainerWidth);

    // biome-ignore lint/correctness/useExhaustiveDependencies: allow
    useEffect(() => {
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
            updateContainerWidth();
        }
        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, []);

    return (
        <Grid
            container
            spacing={2}
            ref={containerRef}
            sx={{
                flexDirection: isHex
                    ? containerWidth > 267
                        ? "row"
                        : "column"
                    : "row",
            }}
        >
            <Grid item sx={{ maxWidth: (theme) => theme.spacing(22) }}>
                <Menu
                    content={colorFormatOptions.map((colorFormat) => (
                        <MenuItem
                            key={colorFormat}
                            value={capitalize(colorFormat)}
                            onClick={() => {
                                setColorFormat(colorFormat);
                                close();
                            }}
                            sx={{ cursor: "pointer" }}
                            label={capitalize(colorFormat)}
                        />
                    ))}
                >
                    <Box>
                        <TextField
                            size={0.2}
                            value={capitalize(selectedColorFormat)}
                            readonly
                            endNode={<UnfoldMoreOutline color="disabled" />}
                            fullWidth
                            slotProps={{
                                inputRoot: {
                                    sx: {
                                        paddingX: 1.5,
                                    },
                                },
                            }}
                            sx={{
                                cursor: "pointer",
                                [`& .${textInputBaseClasses.input}`]: {
                                    minHeight: (theme) => theme.spacing(8),
                                },
                            }}
                        />
                    </Box>
                </Menu>
            </Grid>
            {isHex ? (
                <Grid item xs sx={{ minWidth: (theme) => theme.spacing(27) }}>
                    <TextField
                        value={removeHashAndLimitCharacters({ color: hex })}
                        fullWidth
                        startNode={
                            <Typography variant="body3" color="disabled">
                                #
                            </Typography>
                        }
                        onChange={(e) => {
                            const input = e?.target.value || "";
                            setHex(input);
                            if (input.length >= 6) {
                                updateColor(input);
                            }
                        }}
                        slotProps={{
                            inputRoot: {
                                sx: {
                                    paddingLeft: 1.5,
                                },
                            },
                        }}
                        sx={{
                            [`& .${textInputBaseClasses.input}`]: {
                                minHeight: (theme) => theme.spacing(8),
                            },
                        }}
                    />
                </Grid>
            ) : (
                Object.keys(colorValue).map((val, index) =>
                    index < 3 ? (
                        <Grid key={val} item xs>
                            <TextField
                                fullWidth
                                slotProps={{
                                    inputRoot: {
                                        style: {
                                            paddingLeft: 6,
                                            paddingRight: 6,
                                        },
                                    },
                                }}
                                value={
                                    isRGB
                                        ? String(rgb[val as "r" | "g" | "b"])
                                        : isHSV
                                          ? formatAmount(
                                                false,
                                                String(
                                                    hsv[val as "h" | "s" | "v"]
                                                )
                                            )
                                          : formatAmount(
                                                false,
                                                String(
                                                    hsl[val as "h" | "s" | "l"]
                                                )
                                            )
                                }
                                startNode={
                                    <Typography
                                        variant="body3"
                                        color="disabled"
                                    >
                                        {capitalize(selectedColorFormat[index])}
                                    </Typography>
                                }
                                onChange={(e) => {
                                    if (!e) return undefined;
                                    const val = Number(e.target.value);

                                    if (isRGB) {
                                        const updatedRgb =
                                            index === 0
                                                ? {
                                                      ...rgb,
                                                      r: greaterThanCheck({
                                                          input: val,
                                                          check: 255,
                                                      }),
                                                  }
                                                : index === 1
                                                  ? {
                                                        ...rgb,
                                                        g: greaterThanCheck({
                                                            input: val,
                                                            check: 255,
                                                        }),
                                                    }
                                                  : {
                                                        ...rgb,
                                                        b: greaterThanCheck({
                                                            input: val,
                                                            check: 255,
                                                        }),
                                                    };
                                        setRgb(updatedRgb);
                                        updateColor(updatedRgb);
                                    }

                                    if (isHSV || isHSL) {
                                        const value = removeNonDigits(
                                            e?.target.value || ""
                                        );

                                        const integerPart =
                                            value &&
                                            startsWithZeroAndLengthIsGreaterThanOne(
                                                value.slice(0, -2)
                                            )
                                                ? value.slice(1, -2)
                                                : value.slice(0, -2);

                                        const decimalPart = value?.slice(-2);

                                        const input = `${
                                            integerPart ? integerPart : 0
                                        }.${decimalPart}`;

                                        const amount =
                                            input === "0."
                                                ? Number("0.00")
                                                : Number(input);

                                        if (isHSV) {
                                            const updatedHsv =
                                                index === 0
                                                    ? {
                                                          ...hsv,
                                                          h: greaterThanCheck({
                                                              input: amount,
                                                              check: 360,
                                                          }),
                                                      }
                                                    : index === 1
                                                      ? {
                                                            ...hsv,
                                                            s: greaterThanCheck(
                                                                {
                                                                    input: amount,
                                                                    check: 1,
                                                                }
                                                            ),
                                                        }
                                                      : {
                                                            ...hsv,
                                                            v: greaterThanCheck(
                                                                {
                                                                    input: amount,
                                                                    check: 1,
                                                                }
                                                            ),
                                                        };

                                            setHsv(updatedHsv);
                                            updateColor(updatedHsv);
                                        }

                                        if (isHSL) {
                                            const updatedHsl =
                                                index === 0
                                                    ? {
                                                          ...hsl,
                                                          h: greaterThanCheck({
                                                              input: amount,
                                                              check: 360,
                                                          }),
                                                      }
                                                    : index === 1
                                                      ? {
                                                            ...hsl,
                                                            s: greaterThanCheck(
                                                                {
                                                                    input: amount,
                                                                    check: 1,
                                                                }
                                                            ),
                                                        }
                                                      : {
                                                            ...hsl,
                                                            l: greaterThanCheck(
                                                                {
                                                                    input: amount,
                                                                    check: 1,
                                                                }
                                                            ),
                                                        };

                                            setHsl(updatedHsl);
                                            updateColor(updatedHsl);
                                        }
                                    }
                                }}
                            />
                        </Grid>
                    ) : undefined
                )
            )}
            <Grid item sx={{ maxWidth: (theme) => theme.spacing(18) }}>
                <TextField
                    readonly
                    fullWidth
                    value={`${(alpha * 100).toFixed(0)}%`}
                    onChange={(e) => {
                        const valueAsDecimal = Number(e?.target.value) / 100;

                        const validValue = greaterThanCheck({
                            input: valueAsDecimal,
                            check: 1,
                        });

                        setAlpha(validValue);
                        setRgb({ ...rgb, a: validValue });
                        setHsl({ ...hsl, a: validValue });
                        setHsv({ ...hsv, a: validValue });

                        updateColor({
                            r: rgb.r,
                            g: rgb.g,
                            b: rgb.b,
                            a: validValue,
                        });
                    }}
                    slotProps={{
                        inputRoot: {
                            sx: {
                                paddingX: 1.5,
                            },
                        },
                    }}
                    sx={{
                        [`& .${textInputBaseClasses.input}`]: {
                            minHeight: (theme) => theme.spacing(8),
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

const ColorPickerSlider = ({
    isPicker,
    ...props
}: PointerProps & { readonly isPicker?: boolean }) => {
    return (
        <Box
            {...props}
            sx={{
                width: (theme) => theme.spacing(1),
                height: (theme) => theme.spacing(1),
                borderRadius: "50%",
                border: 2,
                borderColor: (theme) => theme.palette.background.main,
                position: "absolute",
                transform: isPicker
                    ? "translate(-50%, -50%)"
                    : "translateX(-50%)",
                boxShadow: "0 0 2px rgba(0, 0, 0, 0.6)",
            }}
        />
    );
};

const _ColorPalette = ({
    color,
    selected,
    onClick,
}: {
    readonly color: string;
    readonly selected: boolean;
    readonly onClick: () => void;
}) => {
    return (
        <Box
            onClick={onClick}
            sx={{
                borderRadius: "50%",
                border: (theme) =>
                    `${theme.spacing(0.25)} solid ${
                        selected
                            ? theme.palette.disabled.main
                            : theme.palette.background.main
                    }`,
                borderWidth: (theme) => theme.spacing(0.25),
                cursor: "pointer",
            }}
        >
            <Box
                sx={{
                    borderRadius: "50%",
                    border: (theme) =>
                        `${theme.spacing(0.25)} solid ${
                            theme.palette.background.main
                        }`,
                }}
            >
                <Box
                    sx={{
                        width: (theme) => theme.spacing(3),
                        height: (theme) => theme.spacing(3),
                        backgroundColor: color,
                        borderRadius: "50%",
                    }}
                />
            </Box>
        </Box>
    );
};

const ColorPicker = ({
    initialColor = "#0D5D36",
    getColor,
    saveColor,
    isRunningOnSF,
    applyButton,
    updateOnChange,
}: ColorPickerProps) => {
    const theme = useTheme();
    const convertedColor = generateColorFormats(initialColor);
    const [color, setColor] = useState<Colors>(convertedColor);
    const [hsva, setHsva] = useState<HsvaColor>(convertedColor.hsv);
    const [selectedColorFormat, setColorFormat] =
        useState<ColorFormatsLiteral>("hex");

    // const [addedColors, setAddedColors] = useState<string[]>(savedColors || []);

    const isHsv = (o: PossibleColorValues): o is ColorFormats.HSVA =>
        (o as ColorFormats.HSVA).v !== undefined;

    const updateColor = (inputColor: PossibleColorValues) => {
        const colorFormats = generateColorFormats(inputColor);
        setColor(colorFormats);
        setHsva((prev) => ({
            ...prev,
            ...(!isHsv(inputColor) ? colorFormats.hsv : inputColor),
        }));
    };

    const getFormattedColor = (inputColor: PossibleColorValues) => {
        const colorFormats = generateColorFormats(inputColor);
        updateOnChange && getColor?.(colorFormats.hex);
    };

    useEffect(() => {
        setColor(generateColorFormats(initialColor));
    }, [initialColor]);

    return (
        <Stack
            gap={2}
            sx={{
                boxShadow: (theme) =>
                    applyButton ? "none" : theme.shadows.allAround,
                borderRadius: 2,
                padding: 2,
            }}
        >
            <Saturation
                hsva={hsva}
                onChange={(newColor) => {
                    updateColor(newColor);
                    getFormattedColor(newColor);
                }}
                style={{
                    height: theme.spacing(22),
                    width: "100%",
                    borderRadius: 4,
                }}
                pointer={(props) => <ColorPickerSlider {...props} isPicker />}
            />
            <Hue
                hue={hsva.h}
                onChange={(hue) => {
                    updateColor({ ...color.hsv, ...hue });
                    getFormattedColor({ ...color.hsv, ...hue });
                }}
                radius="100px"
                pointer={ColorPickerSlider}
                height={theme.spacing(2)}
            />
            <Alpha
                hsva={hsva}
                onChange={(alpha) => {
                    updateColor({ ...color.hsv, ...alpha });
                    getFormattedColor({ ...color.hsv, ...alpha });
                }}
                radius="100px"
                pointer={ColorPickerSlider}
                height={theme.spacing(2)}
            />
            <ColorPickerTextFields
                color={color}
                selectedColorFormat={selectedColorFormat}
                setColorFormat={setColorFormat}
                colorFormatOptions={["hex", "hsl", "hsv", "rgb"]}
                updateColor={(val) => {
                    updateColor(val);
                    getFormattedColor(val);
                }}
            />
            {applyButton && (
                <Button
                    fullWidth
                    onClick={() => getColor?.(color.hex)}
                    sx={{ alignSelf: "flex-end" }}
                    variant="outlined"
                    disabled={initialColor === color.hex}
                    density="dense"
                >
                    {typeof applyButton === "string" ? applyButton : "Apply"}
                </Button>
            )}
            {isRunningOnSF && (
                <Button
                    fullWidth
                    sx={{
                        alignSelf: "flex-end",
                    }}
                    variant="outlined"
                    disabled={initialColor === color.hex}
                    onClick={() => saveColor?.(color.hex)}
                >
                    Save As Default
                </Button>
            )}
            {/* <Box display="flex" justifyContent={"space-between"}>
                    <Typography variant="body3" fontWeight="bold">
                        Saved colors:
                    </Typography>
                    <Typography
                        variant="body3"
                        fontWeight="semibold"
                        color="primary"
                        onClick={() => {
                            setAddedColors([...addedColors, color.hex]);
                            addColor?.(color.hex);
                        }}
                        sx={{ cursor: "pointer" }}
                    >
                        +Add
                    </Typography>
                </Box>
                {addedColors && (
                    <Box display={"flex"} flexWrap={"wrap"} gap={0.5}>
                        {addedColors?.map((savedColor) => {
                            const savedColorWithAlpha =
                                savedColor.length === 7
                                    ? `${savedColor}ff`
                                    : savedColor;

                            return (
                                <ColorPalette
                                    color={savedColorWithAlpha}
                                    selected={savedColorWithAlpha === color.hex}
                                    onClick={() => {
                                        updateColor(savedColorWithAlpha);
                                    }}
                                />
                            );
                        })}
                    </Box>
                )} */}
        </Stack>
    );
};

export default ColorPicker;
