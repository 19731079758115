import { CellItem, Divider, Stack } from "@biggeo/bg-ui/lab";
import { ExpandLessOutline, ExpandMoreOutline } from "@biggeo/bg-ui/lab/icons";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import mapValues from "lodash/mapValues";
import startCase from "lodash/startCase";
import { useEffect, useState } from "react";
import { match } from "ts-pattern";
import { ColorSwatchOption } from "../../../common/components/ColorSwatchSelector";
import { HeatMapClusterColorMapper } from "../../../common/components/HeatMapClusterColorMapper";
import DatasetCustomMarker from "../../mapbox/views/DatasetCustomMarker";
import MapColorSetter from "../../views/MapColorSetter";
import {
    MapFilterCriteriaShapeStyle,
    MapFilterCriteriaStyle,
    MapFilterCriteriaStyleType,
} from "../utils/utils";
import FilterCriteriaStylesShape from "./FilterCriteriaStylesShape";

interface IFilterCriteriaStyles {
    readonly styles: Partial<MapFilterCriteriaStyle>;
    readonly onChange: (styles: Partial<MapFilterCriteriaStyle>) => void;
    readonly styleFilteredData: (
        styles: Partial<MapFilterCriteriaStyle>
    ) => void;
    readonly isOpen?: boolean;
}

const FilterCriteriaStyles = ({
    styles,
    onChange,
    styleFilteredData,
    isOpen = false,
}: IFilterCriteriaStyles) => {
    const [open, setOpen] = useState<
        Record<MapFilterCriteriaStyleType, boolean>
    >(mapValues(MapFilterCriteriaStyleType, () => isOpen));

    useEffect(() => {
        setOpen(mapValues(MapFilterCriteriaStyleType, () => isOpen));
    }, [isOpen]);
    return (
        <Stack>
            {pipe(
                Object.values(MapFilterCriteriaStyleType),
                A.map((category) => {
                    return (
                        <Stack key={category} gap={1} width={"100%"}>
                            <CellItem
                                title={startCase(category)}
                                density="dense"
                                disableHoverEffect
                                disableActiveEffect
                                endNode={
                                    open ? (
                                        <ExpandMoreOutline size="xs" />
                                    ) : (
                                        <ExpandLessOutline size="xs" />
                                    )
                                }
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpen((prev) => ({
                                        ...prev,
                                        [category]: !prev[category],
                                    }));
                                }}
                                slotProps={{
                                    title: {
                                        fontWeight: "regular",
                                        variant: "body3",
                                    },
                                }}
                            />
                            <Stack
                                flexDirection="row"
                                width={"100%"}
                                gap={4}
                                sx={{
                                    paddingLeft: (theme) => theme.spacing(4),
                                    paddingRight: (theme) => theme.spacing(2),
                                }}
                            >
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        margin: 0,
                                    }}
                                />
                                {open[category] &&
                                    match(category)
                                        .with(
                                            MapFilterCriteriaStyleType.shape,
                                            () => (
                                                <FilterCriteriaStylesShape
                                                    currentShape={
                                                        styles.shape ||
                                                        MapFilterCriteriaShapeStyle.oval
                                                    }
                                                    currentCustomMarker={
                                                        styles.customMarker
                                                    }
                                                    color={styles.fill}
                                                    stroke={styles.stroke}
                                                    onChange={(shape) => {
                                                        onChange({
                                                            ...styles,
                                                            shape,
                                                            customMarker:
                                                                undefined,
                                                        });
                                                        styleFilteredData({
                                                            shape,
                                                        });
                                                    }}
                                                />
                                            )
                                        )
                                        .with(
                                            MapFilterCriteriaStyleType.fill,
                                            () => (
                                                <MapColorSetter
                                                    color={styles.fill?.color}
                                                    opacity={
                                                        styles.fill?.opacity
                                                    }
                                                    onChange={({
                                                        color,
                                                        opacity,
                                                    }) => {
                                                        if (color && opacity) {
                                                            onChange({
                                                                ...styles,
                                                                [category]: {
                                                                    color,
                                                                    opacity,
                                                                },
                                                            });
                                                            styleFilteredData({
                                                                [category]: {
                                                                    color,
                                                                    opacity,
                                                                },
                                                            });
                                                        }
                                                    }}
                                                />
                                            )
                                        )
                                        .with(
                                            MapFilterCriteriaStyleType.stroke,
                                            () => (
                                                <MapColorSetter
                                                    color={styles.stroke?.color}
                                                    opacity={
                                                        styles.stroke?.opacity
                                                    }
                                                    onChange={({
                                                        color,
                                                        opacity,
                                                    }) => {
                                                        if (color && opacity) {
                                                            onChange({
                                                                ...styles,
                                                                [category]: {
                                                                    color,
                                                                    opacity,
                                                                },
                                                            });
                                                            styleFilteredData({
                                                                [category]: {
                                                                    color,
                                                                    opacity,
                                                                },
                                                            });
                                                        }
                                                    }}
                                                />
                                            )
                                        )
                                        .with(
                                            MapFilterCriteriaStyleType.customMarker,
                                            () => (
                                                <DatasetCustomMarker
                                                    value={styles.customMarker}
                                                    onChange={(v) => {
                                                        onChange({
                                                            ...styles,
                                                            [category]: v,
                                                        });
                                                        styleFilteredData({
                                                            [category]: v,
                                                        });
                                                    }}
                                                />
                                            )
                                        )
                                        .with(
                                            MapFilterCriteriaStyleType.dataAggregation,
                                            () => (
                                                <HeatMapClusterColorMapper
                                                    heatMapValue={
                                                        styles.dataAggregation
                                                            ?.heatmap
                                                    }
                                                    onClickHeatMapValue={(
                                                        value: ColorSwatchOption
                                                    ) => {
                                                        onChange({
                                                            ...styles,
                                                            [category]: {
                                                                heatmap: value,
                                                            },
                                                        });
                                                        styleFilteredData({
                                                            [category]: {
                                                                heatmap: value,
                                                            },
                                                        });
                                                    }}
                                                />
                                            )
                                        )
                                        .exhaustive()}
                            </Stack>
                        </Stack>
                    );
                })
            )}
        </Stack>
    );
};

export default FilterCriteriaStyles;
