import { AutoComplete } from "@biggeo/bg-ui/lab";
import { icons } from "../../utils/icons";

export type DatasetCustomMarkerProps = {
    value?: string;
    onChange: (v?: string) => void;
};

const DatasetCustomMarker = ({ value, onChange }: DatasetCustomMarkerProps) => {
    return (
        <AutoComplete
            fullWidth
            options={icons.map((item) => ({ label: item }))}
            value={value}
            placeholder="Search marker"
            onChange={(_, v) => {
                onChange(v);
            }}
        />
    );
};

export default DatasetCustomMarker;
