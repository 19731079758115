import {
    BasicDataWithSelect,
    CellItem,
    Checkbox,
    Divider,
    Grid,
    IconAvatar,
    OverflowingTypography,
    Stack,
    ThumbnailAvatar,
    Typography,
} from "@biggeo/bg-ui/lab";
import { ChevronRightOutline, KeyOutline } from "@biggeo/bg-ui/lab/icons";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import { Routes } from "../../navigation/redux/model";

const AddDatasetWithSubMenu = ({
    title,
    subTitle,
    filteredExistingDataset,
    handleOnCheck,
    toPage,
}: {
    readonly title: string;
    readonly subTitle: string;
    readonly filteredExistingDataset: readonly BasicDataWithSelect[];
    readonly handleOnCheck: (selectedData: BasicDataWithSelect) => void;
    readonly toPage: (url: string) => void;
}) => {
    return (
        <Stack>
            <CellItem
                density="dense"
                title={
                    <Typography
                        variant="body3"
                        fontWeight="semibold"
                        truncate
                        color="background"
                        colorSet="main"
                        invertColors
                    >
                        {title}
                    </Typography>
                }
                subtitle={
                    <Typography
                        variant="body4"
                        fontWeight="regular"
                        truncate
                        color="disabled"
                        colorSet="container"
                        invertColors
                    >
                        {subTitle}
                    </Typography>
                }
                startNode={<KeyOutline size="xs" />}
                endNode={<ChevronRightOutline size="xs" />}
                onClick={() =>
                    toPage(`${Routes.marketplace}/unlock/dataset-access-key`)
                }
            />

            <Stack gap={1} width={"100%"}>
                {pipe(
                    filteredExistingDataset,
                    toNonReadonlyArray,
                    A.map((data) => {
                        return (
                            <Stack
                                key={data.id}
                                sx={{ paddingX: 2, paddingTop: 2 }}
                            >
                                <Stack sx={{ paddingBottom: 2 }}>
                                    <Divider
                                        sx={{
                                            color: (theme) =>
                                                theme.palette.stroke[100],
                                        }}
                                    />
                                </Stack>
                                <Grid
                                    key={data.id}
                                    container
                                    sx={{
                                        alignItems: "center",
                                        gap: 1,
                                        height: (theme) => theme.spacing(10),
                                    }}
                                >
                                    <Checkbox
                                        checked={data.select}
                                        onChange={(_, select) => {
                                            handleOnCheck({
                                                ...data,
                                                select,
                                            });
                                        }}
                                    />

                                    <IconAvatar
                                        size="sm"
                                        sx={{
                                            borderRadius: (theme) =>
                                                theme.radius.default,
                                            backgroundColor: (theme) =>
                                                theme.palette.surface.container,
                                        }}
                                    >
                                        <ThumbnailAvatar
                                            size="xs"
                                            src={data.image}
                                            alt={data.title}
                                        />
                                    </IconAvatar>

                                    <Grid item xs minWidth={0}>
                                        <OverflowingTypography
                                            fontWeight="semibold"
                                            variant="body3"
                                            truncate
                                        >
                                            {data.title}
                                        </OverflowingTypography>
                                    </Grid>
                                </Grid>
                            </Stack>
                        );
                    })
                )}
            </Stack>
        </Stack>
    );
};

export default AddDatasetWithSubMenu;
