import { DescriptionTextField } from "@biggeo/bg-ui";
import { Stack, TextField } from "@biggeo/bg-ui/lab";
import { MapFilterCriteriaDetails } from "../utils/utils";

interface IFilterCriteriaDetails extends Partial<MapFilterCriteriaDetails> {
    readonly onChange: (i: Partial<MapFilterCriteriaDetails>) => void;
}

const FilterCriteriaDetails = ({
    name,
    description,
    onChange,
}: IFilterCriteriaDetails) => {
    return (
        <Stack gap={4}>
            <TextField
                required
                fullWidth
                name="name"
                label="Name"
                value={name || ""}
                placeholder="Enter name"
                onChange={(_, v) => onChange({ name: v, description })}
            />
            <DescriptionTextField
                value={description || ""}
                onChange={(_, v) => onChange({ description: v, name })}
            />
        </Stack>
    );
};

export default FilterCriteriaDetails;
