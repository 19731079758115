import { ApolloQueryResult } from "@apollo/client";
import { isNullish } from "@apollo/client/cache/inmemory/helpers";
import {
    DataSource,
    Exact,
    FetchAllAreasExtendedQuery,
    FetchSavedViewsQuery,
    FilterObject,
    InputCopyDataSource,
    InputFetchSavedView,
    InputViewBox,
    PolygonProperties,
    SavedView,
    useCreateSavedViewMutation,
    useUpdateSavedViewMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { WithLoading } from "@biggeo/bg-ui";
import {
    Box,
    Button,
    MultilineTextField,
    Severity,
    Stack,
    TextField,
    Typography,
} from "@biggeo/bg-ui/lab";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import { Formik, FormikHelpers } from "formik";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/function";
import html2canvas from "html2canvas";
import compact from "lodash/compact";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";
import { useDispatch } from "react-redux";
import Zod from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { toasterActions } from "../../../toaster/containers/redux/model.ts";
import { InputPolygonWithId } from "../../hooks/pure-data-string-hook.ts";
import { mapDataActions } from "../../redux/model.ts";
import { SavedPolygonType } from "../hooks/saved-polygon-hooks.ts";

const SaveViewForm = ({
    dataSources,
    bounds,
    selectedDataset,
    viewport,
    openSaveViewPopper,
    setOpenSaveViewPopper,
    refetchSavedAreas,
    refetchSavedViews,
    multiFilters,
    polygons,
    mapTemplateId,
    savedView,
    draw,
    savedPolygons,
    handleSavedView,
}: {
    readonly draw: React.MutableRefObject<MapboxDraw | null>;
    readonly dataSources: DataSource[];
    readonly mapTemplateId?: string;
    readonly multiFilters: FilterObject[];
    readonly bounds?: mapboxgl.LngLatBounds;
    readonly selectedDataset: readonly string[];
    readonly viewport: InputViewBox;
    readonly savedView?: Partial<SavedView> | null;
    readonly openSaveViewPopper?: boolean;
    readonly setOpenSaveViewPopper?: (value: boolean) => void;
    readonly polygons?: InputPolygonWithId[];
    readonly savedPolygons?: SavedPolygonType;
    readonly handleSavedView: ({
        savedArea,
        viewport,
    }: Pick<SavedView, "savedArea" | "viewport">) => void;
    readonly refetchSavedAreas: (
        variables?:
            | Partial<
                  Exact<{
                      [key: string]: never;
                  }>
              >
            | undefined
    ) => Promise<ApolloQueryResult<FetchAllAreasExtendedQuery>>;
    readonly refetchSavedViews: (
        variables?:
            | Partial<
                  Exact<{
                      input: InputFetchSavedView;
                  }>
              >
            | undefined
    ) => Promise<ApolloQueryResult<FetchSavedViewsQuery>>;
    onCloseDraw: () => void;
}) => {
    const dispatch = useDispatch();
    const {
        executeMutation: createSavedView,
        mutationReturn: [_, { loading }],
    } = useCreateSavedViewMutation();

    const { executeMutation: updateSavedView } = useUpdateSavedViewMutation();

    const getScreenshot = async (htmlid: string) => {
        const element = document.getElementById(htmlid);
        if (element) {
            const canvas = await html2canvas(element, {
                useCORS: true,
                scale: 0.25,
            });
            return canvas.toDataURL("image/png");
        }
    };

    const convert = (input: DataSource): InputCopyDataSource => {
        return {
            collectionName: input.collectionName,
            color: input.color,
            compute: input.compute,
            createdAt: input.createdAt,
            deletedAt: input.deletedAt,
            description: input.description,
            geographyColumn: input.geographyColumn,
            icon: input.icon,
            id: input.id,
            isConnected: input.isConnected,
            isMipmapped: input.isMipmapped,
            label: input.label,
            mapCost: input.mapCost,
            opacity: input.opacity,
            progress: input.progress,
            size: input.size,
            tableName: input.tableName,
            type: input.type,
        };
    };

    const filterDatasets = pipe(
        selectedDataset.map((id) => dataSources.find((data) => data.id === id)),
        compact,
        A.map(convert)
    );

    const savedViewport = bounds
        ? {
              latBounds: {
                  min: bounds._sw.lat,
                  max: bounds._ne.lat,
              },
              lngBounds: {
                  min: bounds._sw.lng,
                  max: bounds._ne.lng,
              },
          }
        : viewport;

    const handleSaveView = async (
        values: Partial<SavedView>,
        actions: FormikHelpers<Partial<SavedView>>
    ) => {
        const screenshot = await getScreenshot("map");
        const getPolygonProperties = (
            properties?: PolygonProperties | null
        ) => {
            return isNullish(properties)
                ? {}
                : {
                      ...omitBy({ ...omit(properties, "__typename") }, (x) =>
                          isNullish(x)
                      ),
                  };
        };
        const newPolygons = pipe(
            savedPolygons?.polygons,
            toNonReadonlyArray,
            A.map(({ id, geometries }) => ({
                id,
                geometries,
            })),
            A.flatMap(({ id, geometries }) =>
                pipe(
                    geometries,
                    A.map((g) => ({
                        id,
                        inners: [],
                        outer: {
                            points: pipe(
                                g.coordinates,
                                // biome-ignore lint/correctness/noFlatMapIdentity: <explanation>
                                A.flatMap((c) => c),
                                A.map((c) => ({
                                    latitude: c[1],
                                    longitude: c[0],
                                }))
                            ),
                        },
                        properties: getPolygonProperties(g.properties),
                    }))
                )
            )
        );

        if (values.id) {
            updateSavedView({
                variables: {
                    input: {
                        id: values.id,
                        name: values.name,
                        description: values.description,
                        viewport: savedViewport,
                        filters: multiFilters?.map((filter) => ({
                            ...omit(filter, "__typename"),
                        })),
                        image: screenshot,
                        datasets: filterDatasets,
                        savedAreaInput:
                            polygons && polygons.length > 0
                                ? {
                                      polygons: [
                                          ...polygons,
                                          ...newPolygons,
                                      ].map((p) => ({
                                          inners: p.inners,
                                          outer: p.outer,
                                          properties: p.properties || undefined,
                                      })),
                                      name: values.name,
                                      isEnabled: true,
                                      isSavedViewArea: true,
                                      fkMapTemplateId: Number(mapTemplateId),
                                      id: Number(savedView?.fkSavedAreaId),
                                  }
                                : undefined,
                    },
                },
                onCompleted: (_data) => {
                    dispatch(
                        toasterActions.openToast({
                            open: true,
                            title: "Saved view updated successfully",
                            autoHideDuration: 5000,
                        })
                    );
                    setOpenSaveViewPopper?.(false);
                },
                onError: (e) => {
                    dispatch(
                        toasterActions.openToast({
                            open: true,
                            severity: Severity.error,
                            title: e.message || "Error updating view",
                            autoHideDuration: 5000,
                        })
                    );
                },
            });
        } else {
            createSavedView({
                variables: {
                    input: {
                        name: values.name || "",
                        description: values.description,
                        viewport: savedViewport,
                        fkMapTemplateId: Number(mapTemplateId),
                        inputSaveArea:
                            polygons && polygons.length > 0
                                ? {
                                      polygons: polygons.map((p) => ({
                                          inners: p.inners,
                                          outer: p.outer,
                                          properties: p.properties || undefined,
                                      })),
                                      name: values.name,
                                      isEnabled: true,
                                      isSavedViewArea: true,
                                      fkMapTemplateId: Number(mapTemplateId),
                                      mapUse: true,
                                  }
                                : undefined,
                        datasets: filterDatasets,
                        filters: multiFilters.map((filter) => ({
                            collection: filter.collection,
                            databaseId: filter.databaseId,
                            databaseType: filter.databaseType,
                            filters: filter.filters,
                            logicOperator: filter.logicOperator,
                        })),
                        image: screenshot,
                    },
                },
                onError: (err) =>
                    dispatch(
                        toasterActions.openToast({
                            open: true,
                            title: err.message,
                            autoHideDuration: 5000,
                            severity: Severity.error,
                        })
                    ),
                onCompleted: (data) => {
                    actions.resetForm();
                    setOpenSaveViewPopper?.(false);
                    refetchSavedAreas();
                    refetchSavedViews();
                    dispatch(
                        mapDataActions.updateMapData(data.createSavedView)
                    );
                    dispatch(
                        toasterActions.openToast({
                            open: true,
                            title: "New view saved successfully.",
                            autoHideDuration: 5000,
                        })
                    );
                    // To display the saved area
                    const savedArea = data.createSavedView.savedArea;
                    const viewport = data.createSavedView.viewport;
                    handleSavedView({
                        savedArea,
                        viewport,
                    });
                    // To reset shape layers state.
                    draw.current?.deleteAll();
                },
            });
        }
    };

    const initialValues = {
        id: savedView?.id,
        name: savedView?.name || "",
        description: savedView?.description || "",
    };
    return (
        <Formik<Partial<SavedView>>
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                handleSaveView(values, actions);
            }}
            validationSchema={toFormikValidationSchema(
                Zod.object({
                    id: Zod.number().nullable().optional(),
                    name: Zod.string(),
                    description: Zod.string().optional(),
                })
            )}
            validateOnMount
        >
            {({
                values,
                setValues,
                resetForm,
                handleSubmit,
                isSubmitting,
                isValid,
            }) => {
                const onChange = (i: Partial<SavedView>) => {
                    setValues((p) => ({ ...p, ...i }));
                };
                return (
                    <Stack
                        gap={2}
                        sx={{
                            padding: 4,
                            backgroundColor: (theme) =>
                                theme.palette.background.main,
                            width: 80,
                            borderRadius: (theme) => theme.spacing(1),
                        }}
                    >
                        <Stack>
                            <Typography variant="body2" fontWeight="bold">
                                {`Save ${savedView?.id ? "Changes" : "View"}`}
                            </Typography>
                            <Typography
                                variant="body3"
                                sx={{
                                    color: (theme) =>
                                        theme.palette.disabled.onContainer,
                                }}
                            >
                                {`${savedView?.id ? "Update" : "Enter"} details
                            below`}
                            </Typography>
                        </Stack>
                        <TextField
                            label="Name"
                            fullWidth
                            required
                            value={values.name || ""}
                            onChange={(_, v) =>
                                onChange({
                                    name: v === "" ? undefined : v,
                                })
                            }
                        />
                        <MultilineTextField
                            label="Description"
                            subLabel="(Optional)"
                            fullWidth
                            value={values.description || ""}
                            onChange={(_, v) =>
                                onChange({
                                    description: v === "" ? undefined : v,
                                })
                            }
                        />
                        <Box
                            sx={{
                                display: "flex",
                                gap: 2,
                                alignSelf: "end",
                            }}
                        >
                            <Button
                                density="dense"
                                variant="outlined"
                                onClick={() => {
                                    setOpenSaveViewPopper?.(false);
                                    resetForm();
                                }}
                                disabled={loading}
                                sx={{
                                    display: loading ? "none" : "flex",
                                }}
                            >
                                Cancel
                            </Button>
                            <WithLoading
                                loading={isSubmitting}
                                text={`Saving ${savedView?.id ? "Changes" : "View"}`}
                            >
                                <Button
                                    disabled={!isValid}
                                    density="dense"
                                    type="submit"
                                    onClick={() => handleSubmit()}
                                >
                                    {`Save ${savedView?.id ? "Changes" : "View"}`}
                                </Button>
                            </WithLoading>
                        </Box>
                    </Stack>
                );
            }}
        </Formik>
    );
};

export default SaveViewForm;
