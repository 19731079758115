import { Grid, HorizontalScroller, Stack, Typography } from "@biggeo/bg-ui/lab";
import {
    Circle,
    VisibilityOffOutline,
    VisibilityOutline,
} from "@biggeo/bg-ui/lab/icons";
import { color } from "@uiw/react-color";
import { useState } from "react";
import { updateSelected } from "../../utils/utils.ts";
import { levelSetPercentiles } from "../../utils/variables.ts";

export type IconLegendProps = {
    time: number;
    color: string;
    label: string;
};

const MapInterfaceLegend = ({
    found,
    rendered,
    values,
    databaseId,
    map,
    allPointsColor,
}: {
    databaseId: string;
    allPointsColor?: string;
    found: number;
    rendered: number;
    values: IconLegendProps[];

    map: React.MutableRefObject<mapboxgl.Map | null>;
}) => {
    const totalMs = values.reduce((acc, data) => acc + data.time, 0);

    const [pointsVisible, setPointsVisible] = useState<boolean>(true);

    const togglePointsVisibility = ({
        databaseId,
        value,
    }: {
        databaseId: string;
        value: boolean;
    }) => {
        setPointsVisible(value);
        const current = map.current;
        if (current) {
            const visibility = value ? "visible" : "none";
            current.setLayoutProperty(
                `${databaseId}-points-layer`,
                "visibility",
                visibility
            );
            current.setLayoutProperty(
                `${databaseId}-points-heatmap-layer`,
                "visibility",
                visibility
            );

            current?.setLayoutProperty(
                `${databaseId}-filtered-points-layer`,
                "visibility",
                visibility
            );
            map.current?.setLayoutProperty(
                `${databaseId}-filtered-heatmap-layer`,
                "visibility",
                visibility
            );
            for (let i = 0; i < levelSetPercentiles.length; ++i) {
                map.current?.setLayoutProperty(
                    `${databaseId}-levelset-${i}`,
                    "visibility",
                    visibility
                );
            }
            map.current?.setLayoutProperty(
                `${databaseId}-polygons`,
                "visibility",
                visibility
            );
            map.current?.setLayoutProperty(
                `${databaseId}-polygons-outline`,
                "visibility",
                visibility
            );
        }
    };

    return (
        <Stack gap={2} width={54.5}>
            <Stack>
                <Typography
                    variant="body4"
                    fontWeight="semibold"
                    sx={{
                        color: (theme) => theme.palette.background.onMain,
                    }}
                >
                    Speed Legend
                </Typography>
                <Typography
                    variant="body4"
                    fontWeight="regular"
                    sx={{
                        color: (theme) => theme.palette.disabled.onContainer,
                    }}
                >
                    See below a breakdown of last query
                </Typography>
            </Stack>

            <Stack gap={2}>
                <Grid container flexWrap="nowrap">
                    {values.map((data) => {
                        const calculatedWidth = (data.time / totalMs) * 100;
                        return (
                            <Grid
                                key={`${data.time}-${data.color}`}
                                item
                                width={calculatedWidth}
                                height={1}
                                sx={{
                                    backgroundColor: data.color,
                                }}
                            />
                        );
                    })}
                </Grid>

                <HorizontalScroller slotProps={{ scrollContainer: { gap: 2 } }}>
                    {values.map((data) => {
                        return (
                            <Grid
                                container
                                key={`${data.time}-${data.color}`}
                                gap={1}
                                flexWrap="nowrap"
                            >
                                <Stack gap={1} flexGrow={1} maxWidth={13}>
                                    <Grid
                                        container
                                        gap={1}
                                        alignItems="center"
                                        sx={{ flexDirection: "row" }}
                                    >
                                        <Circle
                                            size="xxs"
                                            sx={{
                                                color: `${data.color}`,
                                            }}
                                        />

                                        <Typography
                                            variant="body4"
                                            fontWeight="regular"
                                        >
                                            {data.time} ms
                                        </Typography>
                                    </Grid>
                                    <Typography
                                        variant="body4"
                                        fontWeight="regular"
                                        sx={{
                                            color: (theme) =>
                                                theme.palette.disabled
                                                    .onContainer,
                                        }}
                                    >
                                        {data.label}
                                    </Typography>
                                </Stack>
                            </Grid>
                        );
                    })}
                </HorizontalScroller>
            </Stack>
            <Stack>
                <Grid
                    container
                    gap={1}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="body4" fontWeight="regular">
                        Found
                    </Typography>

                    <Typography variant="body3" fontWeight="bold">
                        {found}
                    </Typography>
                </Grid>
                <Grid
                    container
                    gap={1}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="body4" fontWeight="regular" truncate>
                        Rendered
                    </Typography>

                    <Typography variant="body3" fontWeight="bold">
                        {rendered}
                    </Typography>
                </Grid>
            </Stack>

            <Stack>
                <Typography variant="body4" fontWeight="semibold">
                    Dataset Legend
                </Typography>
                <Typography
                    variant="body4"
                    fontWeight="regular"
                    sx={{
                        color: (theme) => theme.palette.disabled.onContainer,
                    }}
                >
                    Dataset points breakdown
                </Typography>
            </Stack>

            <Grid container gap={2} alignItems="center">
                <Circle size="xxs" sx={{ color: allPointsColor }} />

                <Grid item xs minWidth={0}>
                    <Typography variant="body3" fontWeight="regular" truncate>
                        All points
                    </Typography>
                </Grid>

                {pointsVisible ? (
                    <VisibilityOutline
                        size="xs"
                        onClick={() =>
                            togglePointsVisibility({ databaseId, value: false })
                        }
                    />
                ) : (
                    <VisibilityOffOutline
                        size="xs"
                        onClick={() =>
                            togglePointsVisibility({ databaseId, value: true })
                        }
                    />
                )}
            </Grid>
        </Stack>
    );
};

export default MapInterfaceLegend;
