import { Box, Stack, Typography, TypographyProps } from "@biggeo/bg-ui/lab";
import { ComponentProps, cloneElement, memo } from "react";

type WithoutChildren = { title: string; subTitle?: string };
type WithChildren = { children: JSX.Element };
type InputType = WithChildren | WithoutChildren;

const isWithoutChildren = (input: InputType): input is WithoutChildren => {
    return "title" in input;
};

export const MemoizedField = memo(
    ({
        typographyProps,
        disable,
        sx,
        ...props
    }: InputType & {
        readonly typographyProps?: TypographyProps;
        readonly disable?: boolean;
        readonly sx?: ComponentProps<typeof Stack>["sx"];
    }) => {
        if (isWithoutChildren(props)) {
            return (
                <Stack sx={sx} width="100%">
                    <Typography
                        variant="body3"
                        fontWeight="semibold"
                        {...typographyProps}
                        sx={{
                            opacity: disable ? 0.5 : 1,
                            pointerEvents: disable ? "none" : undefined,
                            ...typographyProps?.sx,
                        }}
                    >
                        {props.title}
                    </Typography>
                    {props.subTitle && (
                        <Typography
                            variant="body3"
                            {...typographyProps}
                            sx={{
                                opacity: disable ? 0.5 : 1,
                                pointerEvents: disable ? "none" : undefined,
                                color: (theme) =>
                                    theme.palette.disabled.onContainer,
                                ...typographyProps?.sx,
                            }}
                        >
                            {props.subTitle}
                        </Typography>
                    )}
                </Stack>
            );
        }

        return (
            <Box
                width="100%"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: disable ? 0.5 : 1,
                    pointerEvents: disable ? "none" : undefined,
                    ...sx,
                }}
            >
                {disable
                    ? cloneElement(props.children, { disabled: true })
                    : props.children}
            </Box>
        );
    }
);
