import {
    DataSourcesExtended,
    MapTemplate,
    useFetchDataSourcesQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    BadgeDot,
    Button,
    FlexScrollArea,
    FlexScrollAreaContainer,
    Stack,
} from "@biggeo/bg-ui/lab";
import { ActionKeyOutline, KeyOutline } from "@biggeo/bg-ui/lab/icons";
import { DoubleColumnLayout } from "@biggeo/bg-ui/lab/layouts";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import camelCase from "lodash/camelCase";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import isUndefined from "lodash/isUndefined";
import replace from "lodash/replace";
import startCase from "lodash/startCase";
import { useNavigate, useParams } from "react-router";
import { match } from "ts-pattern";
import {
    isAppRunningOnSF,
    isSnp,
    useDataGridOptions,
} from "../../common/redux/hooks";
import { NavigationRail } from "../../components/NavigationRail";
import MapTemplateDataManagement from "../../data-sets/views/MapTemplateDataManagement";
import MapTemplateDatasets from "../../map/containers/MapTemplateDatasets";
import { Routes } from "../../navigation/redux/model";
import { AvailableDatasetsContainer } from "./AvailableDatasetsContainer";
import { UnAvailableDatasetsContainer } from "./UnAvailableDatasetsContainer";

export enum DataManagementTab {
    available = "available",
    processing = "processing",
    unIndexed = "unIndexed",
}

interface IDataManagementContainer {
    readonly mapTemplates: MapTemplate[];
}

const DataManagementContainer = ({
    mapTemplates,
}: IDataManagementContainer) => {
    const toPage = useNavigate();
    const isRunningOnSF = isAppRunningOnSF();
    const isSNP = isSnp();
    const { menuItem, mapTemplateId } = useParams();

    const { dataGridFetchInputProps, filterSearchPaginateProps } =
        useDataGridOptions();

    const {
        queryReturn: { data: dataSourcesExtended, loading },
    } = useFetchDataSourcesQuery({
        variables: {
            input: {
                dataGridFetchInput: dataGridFetchInputProps,
            },
        },
    });

    const { dataSources, snowflakeData } = pipe(
        dataSourcesExtended?.fetchDataSources,
        O.fromNullable,
        O.fold(
            () => ({
                dataSources: [],
                snowflakeData: [],
                total: 0,
            }),
            (fetchDataSources) => fetchDataSources
        )
    );

    const menuList = [
        DataManagementTab.available,
        DataManagementTab.processing,
        DataManagementTab.unIndexed,
    ];

    const filterDatasets = (tab: DataManagementTab): DataSourcesExtended => {
        return match(tab)
            .with(DataManagementTab.available, () => {
                return pipe(
                    dataSources,
                    A.filter((d) => d.isConnected),
                    (dataSources) => ({
                        dataSources,
                        snowflakeData,
                        total: pipe(dataSources, A.size),
                    })
                );
            })
            .with(DataManagementTab.processing, () => {
                return pipe(
                    dataSources,
                    A.filter(
                        (data) =>
                            !data.isConnected &&
                            !!data.progress &&
                            data.progress < 100
                    ),
                    (dataSources) => ({
                        dataSources,
                        snowflakeData: [],
                        total: pipe(dataSources, A.size),
                    })
                );
            })
            .with(DataManagementTab.unIndexed, () => {
                return pipe(
                    dataSources,
                    A.filter((data) => isNil(data.progress)),
                    (dataSources) => ({
                        dataSources,
                        snowflakeData: [],
                        total: pipe(dataSources, A.size),
                    })
                );
            })
            .exhaustive();
    };

    const getSelectedMenu = (param?: string): DataManagementTab => {
        return match(camelCase(param))
            .with(
                DataManagementTab.available,
                () => DataManagementTab.available
            )
            .with(
                DataManagementTab.processing,
                () => DataManagementTab.processing
            )
            .with(
                DataManagementTab.unIndexed,
                () => DataManagementTab.unIndexed
            )
            .otherwise(() => DataManagementTab.available);
    };

    const selectedMenu = getSelectedMenu(menuItem);

    const formatTitle = (title?: string) =>
        pipe(title, startCase, (title) => replace(title, " ", "-"));

    return (
        <DoubleColumnLayout
            slotProps={{
                left: {
                    sx: {
                        borderRight: 1,
                        borderColor: (theme) => theme.palette.stroke[100],
                        width: "100%",
                        breakpoints: {
                            cmd: {
                                width: 58.5,
                            },
                        },
                    },
                },
                main: {
                    sx: {
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        padding: 4,
                        gap: 4,
                    },
                },
            }}
            leftHeader={formatTitle(selectedMenu)}
            left={
                <FlexScrollAreaContainer width="100%">
                    <FlexScrollArea
                        gap={4}
                        flexDirection="column"
                        sx={{ padding: 4 }}
                    >
                        <Stack gap={1}>
                            {pipe(
                                menuList,
                                A.map((tab) => {
                                    const { total } = filterDatasets(tab);
                                    return (
                                        <NavigationRail
                                            key={tab}
                                            icon={undefined}
                                            selected={pipe(
                                                mapTemplateId,
                                                O.fromNullable,
                                                O.fold(
                                                    () =>
                                                        isEqual(
                                                            camelCase(
                                                                selectedMenu
                                                            ),
                                                            tab
                                                        ),
                                                    () => false
                                                )
                                            )}
                                            onClick={() => {
                                                toPage(`${Routes.data}/${tab}`);
                                                filterSearchPaginateProps.setSearchText(
                                                    ""
                                                );
                                            }}
                                            endNode={
                                                tab !==
                                                    DataManagementTab.available &&
                                                total !== 0 &&
                                                isUndefined(mapTemplateId) && (
                                                    <BadgeDot color="error">
                                                        {total}
                                                    </BadgeDot>
                                                )
                                            }
                                        >
                                            {formatTitle(tab)}
                                        </NavigationRail>
                                    );
                                })
                            )}
                        </Stack>
                        <MapTemplateDataManagement
                            currentMapTemplate={
                                mapTemplateId
                                    ? Number(mapTemplateId)
                                    : undefined
                            }
                            mapTemplates={mapTemplates}
                        />
                    </FlexScrollArea>
                    <Stack gap={2} sx={{ padding: 3 }}>
                        <Button
                            color="info"
                            startNode={<ActionKeyOutline size="xs" />}
                            density="dense"
                            fullWidth
                            onClick={() => {
                                toPage(`${Routes.marketplace}/datasets`);
                            }}
                        >
                            Data Marketplace
                        </Button>
                        <Button
                            variant="outlined"
                            startNode={<KeyOutline size="xs" />}
                            fullWidth
                            density="dense"
                            onClick={() => {
                                toPage(
                                    `${Routes.marketplace}/unlock/dataset-access-key`
                                );
                            }}
                        >
                            Dataset Access Key
                        </Button>
                    </Stack>
                </FlexScrollAreaContainer>
            }
        >
            {pipe(
                mapTemplateId,
                O.fromNullable,
                O.fold(
                    () =>
                        match(selectedMenu)
                            .with(DataManagementTab.available, (tab) => (
                                <AvailableDatasetsContainer
                                    dataSourcesExtended={filterDatasets(tab)}
                                    tab={tab}
                                    isRunningOnSF={isRunningOnSF}
                                    loading={loading}
                                    filterSearchPaginateProps={
                                        filterSearchPaginateProps
                                    }
                                    isSNP={isSNP}
                                    toPage={toPage}
                                />
                            ))
                            .with(DataManagementTab.processing, (tab) => (
                                <UnAvailableDatasetsContainer
                                    dataSourcesExtended={filterDatasets(tab)}
                                    tab={tab}
                                    isRunningOnSF={isRunningOnSF}
                                    loading={loading}
                                    filterSearchPaginateProps={
                                        filterSearchPaginateProps
                                    }
                                    isSNP={isSNP}
                                    toPage={toPage}
                                />
                            ))
                            .with(DataManagementTab.unIndexed, (tab) => (
                                <UnAvailableDatasetsContainer
                                    dataSourcesExtended={filterDatasets(tab)}
                                    tab={tab}
                                    isRunningOnSF={isRunningOnSF}
                                    loading={loading}
                                    filterSearchPaginateProps={
                                        filterSearchPaginateProps
                                    }
                                    isSNP={isSNP}
                                    toPage={toPage}
                                />
                            ))
                            .exhaustive(),
                    (mapTemplateId) => (
                        <MapTemplateDatasets
                            route={"data"}
                            mapTemplateId={Number(mapTemplateId)}
                            toPage={toPage}
                        />
                    )
                )
            )}
        </DoubleColumnLayout>
    );
};

export default DataManagementContainer;
