export const icons = [
    "airfield",
    "airport",
    "alcohol-shop",
    "american-football",
    "amusement-park",
    "aquarium",
    "attraction",
    "bank",
    "bar",
    "baseball",
    "basketball",
    "beach",
    "beer",
    "bicycle-share",
    "bicycle",
    "bowling-alley",
    "bridge",
    "bus",
    "cafe",
    "campsite",
    "car-rental",
    "car-repair",
    "car",
    "casino",
    "castle",
    "cemetery",
    "charging-station",
    "cinema",
    "circle-stroked",
    "circle",
    "clothing-store",
    "college",
    "communications-tower",
    "confectionery",
    "convenience",
    "dentist",
    "doctor",
    "dog-park",
    "drinking-water",
    "embassy",
    "entrance",
    "farm",
    "fast-food",
    "ferry",
    "fire-station",
    "fitness-centre",
    "fuel",
    "furniture",
    "garden",
    "gate",
    "globe",
    "golf",
    "grocery",
    "harbor",
    "hardware",
    "heliport",
    "highway-rest-area",
    "historic",
    "horse-riding",
    "hospital",
    "hot-spring",
    "ice-cream",
    "industry",
    "information",
    "jewelry-store",
    "landmark",
    "laundry",
    "library",
    "lift-gate",
    "lighthouse",
    "lodging",
    "marker-stroked",
    "marker",
    "mobile-phone",
    "monument",
    "mountain",
    "museum",
    "music",
    "observation-tower",
    "oil-well",
    "optician",
    "park",
    "parking-garage",
    "parking",
    "pharmacy",
    "picnic-site",
    "pitch",
    "place-of-worship",
    "playground",
    "police",
    "post",
    "prison",
    "racetrack-boat",
    "racetrack-cycling",
    "racetrack-horse",
    "racetrack",
    "rail-light",
    "rail-metro",
    "rail",
    "ranger-station",
    "religious-buddhist",
    "religious-christian",
    "religious-jewish",
    "religious-muslim",
    "religious-shinto",
    "residential-community",
    "restaurant-bbq",
    "restaurant-noodle",
    "restaurant-pizza",
    "restaurant-seafood",
    "restaurant-sushi",
    "restaurant",
    "rocket",
    "school",
    "shoe",
    "shop",
    "skateboard",
    "skiing",
    "slipway",
    "stadium",
    "star",
    "suitcase",
    "swimming",
    "table-tennis",
    "tennis",
    "theatre",
    "toilet",
    "toll",
    "town-hall",
    "town",
    "triangle-stroked",
    "triangle",
    "tunnel",
    "veterinary",
    "viewpoint",
    "volcano",
    "volleyball",
    "watch",
    "waterfall",
    "watermill",
    "windmill",
    "zoo",
];
