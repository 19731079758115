import { gql, useSubscription } from "@apollo/client";
import {
    AsyncError,
    useIsAppRunningOnSfQuery,
    useIsSnpQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    IFilterSearchPaginate,
    Severity,
    SlimFilterObject,
} from "@biggeo/bg-ui/lab";
import { pipe } from "fp-ts/lib/function";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducer";
import { toasterActions } from "../../toaster/containers/redux/model";
import {
    DatasetColor,
    MapEngine,
    ResponseParseTime,
    commonActions,
} from "./model";

export const isSnp = () =>
    useSelector((state: RootState) => state.common.isSnp);

export const useIsSnp = () => {
    const dispatch = useDispatch();
    const { queryReturn } = useIsSnpQuery();
    const isSnp = queryReturn.data?.isSnp || false;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (isSnp) {
            dispatch(
                commonActions.setIsSnp({
                    isSnp,
                })
            );
        }
    }, [isSnp]);
};

export const isAppRunningOnSF = (): boolean =>
    useSelector((state: RootState) => state.common.isRunningOnSF);

export const useMapEngines = (): readonly MapEngine[] =>
    useSelector((state: RootState) => state.common.mapEngines);

export const useAppRunningOnSF = () => {
    const dispatch = useDispatch();
    const { queryReturn } = useIsAppRunningOnSfQuery();
    const isRunningOnSF = queryReturn.data?.isAppRunningOnSF;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (isRunningOnSF) {
            dispatch(
                commonActions.setIsRunningOnSF({
                    isRunningOnSF,
                })
            );
        }
    }, [isRunningOnSF]);
};

export const useDebouncedSearch = (time?: number) => {
    return useCallback(
        debounce(
            (fn: (query?: string) => void, query?: string) => pipe(query, fn),
            time || 300
        ),
        []
    );
};

export type IFetchInput = {
    readonly searchText?: string;
    readonly pageIndex: number;
    readonly pageLimit: number;
    readonly filterObject?: SlimFilterObject;
};

export type DataGridOptions = {
    filterSearchPaginateProps: IFilterSearchPaginate;
    dataGridFetchInputProps: IFetchInput;
};

export const useDataGridOptions = (limit?: number): DataGridOptions => {
    const pageLimit = limit || 20;
    const [searchText, setSearchText] = useState<string | undefined>();
    const [filterObject, setFilterObject] = useState<
        SlimFilterObject | undefined
    >();
    const [pageIndex, setPageIndex] = useState<number>(1);

    return {
        dataGridFetchInputProps: {
            pageLimit,
            pageIndex,
            searchText,
            filterObject,
        },
        filterSearchPaginateProps: {
            pageLimit,
            pageIndex,
            setPageIndex,
            searchText,
            setSearchText,
            filterObject,
            setFilterObject,
        },
    };
};
export const useResponseParseTimes = (): readonly ResponseParseTime[] =>
    useSelector((state: RootState) => state.common.responseParseTimes);

export const useSearchTimestamp = (): number =>
    useSelector((state: RootState) => state.common.searchTimestamp);

const ASYNC_ERRORS = gql`
    subscription listenToAsyncErrors($channel: String!) {
        listenToAsyncErrors(channel: $channel) {
            type
            code
            message
        }
    }
`;

export const useAsyncErrors = (channel: string) => {
    const dispatch = useDispatch();

    const { data } = useSubscription<
        {
            listenToAsyncErrors: AsyncError;
        },
        { channel: string }
    >(ASYNC_ERRORS, {
        variables: {
            channel: channel,
        },
        context: { clientName: "node" },
        fetchPolicy: "no-cache",
    });
    useEffect(() => {
        if (data?.listenToAsyncErrors) {
            dispatch(
                toasterActions.openToast({
                    open: true,
                    severity: Severity.error,
                    title: data.listenToAsyncErrors.message,
                    message: data.listenToAsyncErrors.message,
                    autoHideDuration: 5000,
                })
            );
        }
    }, [data?.listenToAsyncErrors, dispatch]);

    return { data: data?.listenToAsyncErrors };
};
