import {
    type PointDataInput,
    SavedArea,
    useFetchAllAreasExtendedQuery,
    useFetchSavedViewsQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { EmptyState, SelectableTreeMenuItem } from "@biggeo/bg-ui";
import { Box } from "@biggeo/bg-ui/lab";
import { ActionKeyOutline } from "@biggeo/bg-ui/lab/icons";
import { MapLayout, MapLayoutTabs } from "@biggeo/bg-ui/lab/layouts";
import { WithRequiredProperty, toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import includes from "lodash/includes";
import isBoolean from "lodash/isBoolean";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import some from "lodash/some";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import uuid from "react-uuid";
import { ColorSwatchOption } from "../../../common/components/ColorSwatchSelector";
import OnMapLeave from "../../../common/components/OnMapLeave";
import { Consumers } from "../../../common/redux/model";
import { useDatabaseMetaDataArray } from "../../../database-meta-data/redux/hooks";
import { MapLayoutLeftContent } from "../../../layouts/MapLayoutLeftContent";
import { datasetInfo } from "../../../marketplace/dummyData";
import { ExploreDatasetsView } from "../../../marketplace/views/ExploreDatasetsView";
import {
    getSavedAreaIdFromParams,
    getSavedViewIdFromParams,
    snapToView,
} from "../../../utils/utils";
import { levelSetPercentiles } from "../../../utils/variables";
import DebugContainer from "../../DebugContainer";
import MapFilterCriteria from "../../filter-criteria/containers/MapFilterCriteria";
import {
    MapFilterCriteriaForm,
    clearMultifilters,
    mapToMultifilterType,
} from "../../filter-criteria/utils/utils";
import MapTopbar from "../../filter-criteria/views/MapTopbar";
import { PureDataStringHookReturnType } from "../../hooks/pure-data-string-hook";
import { RenderControlsHookReturnType } from "../../hooks/render-data-hooks";
import { MapTabs } from "../../map-wrappers/MapViewWrapper";
import {
    getLayerHeatmapStyle,
    hideFilteredDataLayers,
} from "../../utils/style-utils";
import {
    FunctionType,
    SavedPolygonSource,
    convertSavedAreas,
    decodeId,
    getConflictAreas,
    getMapFeatures,
    getPolygonFromSavedAreas,
    handleFilterReset,
} from "../../utils/utils";
import MapShapeLayers from "../../views/MapShapeLayers";
import MapStyles from "../../views/MapStyles";
import { SavedAreasSubmenu } from "../../views/SavedAreasSubmenu";
import { MapContextFilter, useMap } from "../context";
import { HoveredData } from "../hooks/view-data-hooks";
import { HeatMapColorSwatchOption } from "../utils/heatmap";
import MarketplaceDataView, { DatasetInfo } from "../views/MarketplaceDataView";
import { MapboxMapContainer } from "./MapContainer";
import { RightMapMainContainerSlot } from "./RightMapMainContainerSlot";

export interface IMapMainContainer
    extends RenderControlsHookReturnType,
        PureDataStringHookReturnType {
    readonly mapTemplateId: number;
    readonly activeConsumption: Consumers;
    readonly setConsumption: (consumption: Consumers) => void;
    readonly handleSideMenu?: (v: boolean) => void;
    readonly setSelectedPoint: React.Dispatch<
        React.SetStateAction<PointDataInput | undefined>
    >;
    readonly selectedDataset: readonly string[];
    readonly setSelectedDataset: React.Dispatch<
        React.SetStateAction<readonly string[]>
    >;
    readonly setOpenSaveViewPopper: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    readonly openSaveViewPopper: boolean;
    readonly selectedSavedView?: number;
    readonly hoveredData?: HoveredData;
    readonly setHoveredData: React.Dispatch<
        React.SetStateAction<HoveredData | undefined>
    >;
    readonly selectedPoint?: PointDataInput;
    readonly tab?: MapTabs;
}

const MapMainContainer = ({
    mapTemplateId,
    selectedDataset,
    setSelectedDataset,
    responses,
    multiFilters,
    addRemoveDataset,
    setMultiFilters,
    handleViewportChange,
    viewport,
    polygons,
    channelId,
    recentResponse,
    savedPolygons,
    setSavedPolygons,
    options,
    functionType,
    setFunctionType,
    clearShapes,
    handleMultiPolygons,
    addMultipleDatasets,
    hoveredData,
    setHoveredData,
    setSelectedPoint,
    selectedPoint,
    ...props
}: IMapMainContainer) => {
    const {
        map,
        isLoaded,
        previousMapState,
        selectedShapes,
        dispatch,
        draw,
        modes,
        filters,
        lastSelectedDataset,
    } = useMap();

    const drawnPolygons = getMapFeatures(draw, isLoaded);

    const handleSelectedShapes = (
        i:
            | GeoJSON.FeatureCollection<
                  GeoJSON.Geometry,
                  GeoJSON.GeoJsonProperties
              >
            | GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    ) => {
        dispatch?.({
            type: "SET_SELECTED_SHAPES",
            values: i,
        });
    };

    const hasPreviousState =
        !!previousMapState?.selectedSavedAreas.selectableItems &&
        !isEmpty(previousMapState?.selectedSavedAreas.selectableItems);

    const [reFetchSavedAreas, setReFetchSavedAreas] = useState<boolean>(false);

    const [selectedAreaId, setSelectedAreaId] = useState<number | undefined>();
    const [selectedSavedView, setSelectedSavedView] = useState<
        number | undefined
    >();

    const [filterItems, setFilterItems] = useState<
        readonly SelectableTreeMenuItem[]
    >([]);

    const [heatMapValue, setHeatMapValue] = useState<
        HeatMapColorSwatchOption[] | undefined
    >(undefined);

    const onClickHeatMapValue =
        (datasetId?: string) => (value: ColorSwatchOption) => {
            const { steps, heatMapColorArray, colorMap } =
                getLayerHeatmapStyle(value);

            map.current?.setPaintProperty(
                `${datasetId}-points-heatmap-layer`,
                "heatmap-color",
                [
                    "interpolate",
                    ["linear"],
                    ["heatmap-density"],
                    ...heatMapColorArray,
                ]
            );

            levelSetPercentiles.map((_, i) =>
                map.current?.setPaintProperty(
                    `${datasetId}-levelset-${i}`,
                    "fill-color",
                    [
                        "match",
                        ["get", "idCadence"],
                        ...colorMap,
                        value.swatch[steps.length / 2].color || "blue", // Default color
                    ]
                )
            );

            setHeatMapValue((prev) =>
                prev
                    ? [...prev, { datasetId: datasetId || "", swatch: value }]
                    : [{ datasetId: datasetId || "", swatch: value }]
            );

            if (datasetId) {
                dispatch?.({
                    type: "SET_LAST_SELECTED_DATASET_DATA_AGGREGATION",
                    values: {
                        id: datasetId,
                        heatmap: value,
                    },
                });
            }
        };

    const [openFilterCriteria, setOpenFilterCriteria] =
        useState<boolean>(false);

    const canAddFilterCriteria = !isEmpty(selectedDataset);

    const handleFilterCriteriaSidebar = (e?: boolean) => {
        if (isBoolean(e)) {
            setOpenRightSlot(false);
            setOpenFilterCriteria(e);
        } else {
            setOpenRightSlot(false);
            setOpenFilterCriteria(!openFilterCriteria);
        }
    };

    const isSavedAreaSelected = some(
        filterItems,
        (i) => !!i.selected || some(i.subItems, (s) => !!s.selected)
    );

    const [searchParams, setSearchParams] = useSearchParams();
    const savedAreaId = Number.parseInt(getSavedAreaIdFromParams(searchParams));
    const savedViewId = Number.parseInt(getSavedViewIdFromParams(searchParams));

    const resetAreasFilters = () =>
        setFilterItems(handleFilterReset(filterItems));

    const dataSources = useDatabaseMetaDataArray();
    const {
        queryReturn: { data: savedAreasData, refetch: refetchSavedAreas },
    } = useFetchAllAreasExtendedQuery({
        variables: {
            fkMapTemplateId: mapTemplateId,
        },
    });

    const {
        queryReturn: { refetch: refetchSavedViews },
    } = useFetchSavedViewsQuery({
        variables: { input: { limit: 100, pageIndex: 1 } },
        skip: true,
    });

    const handleSelectedSavedPolygons = (i: {
        savedAreas: SavedArea[];
        selectableItems: SelectableTreeMenuItem[];
        isConflict?: boolean;
    }) => {
        const { savedAreas, selectableItems, isConflict } = i;

        setSavedPolygons({
            source: SavedPolygonSource.savedArea,
            polygons: savedAreas,
            isConflict: isUndefined(isConflict)
                ? !isEmpty(savedAreas) && !isEmpty(drawnPolygons)
                : isConflict,
        });

        snapToView({
            polygons: getPolygonFromSavedAreas(savedAreas),
            map,
        });

        setFilterItems(selectableItems);
    };

    const selectSavedArea = (items: readonly SelectableTreeMenuItem[]) => {
        setFilterItems(items);

        setFunctionType(FunctionType.savedPolygon);

        const selectedIds = pipe(
            items,
            toNonReadonlyArray,
            A.flatMap((item) =>
                pipe(
                    item.subItems,
                    toNonReadonlyArray,
                    A.filter((sub) => sub.selected),
                    A.map((sub) => sub.id)
                )
            )
        );

        const savedAreaPolygons = pipe(
            savedAreasData?.fetchAllAreasExtended,
            toNonReadonlyArray,
            A.flatMap(({ savedAreas }) =>
                pipe(
                    savedAreas,
                    A.filter((sa) =>
                        includes(selectedIds, decodeId("savedArea", sa.id))
                    )
                )
            )
        );

        const conflicts =
            !isEmpty(drawnPolygons) && !isEmpty(savedAreaPolygons)
                ? pipe(
                      drawnPolygons,
                      A.map((shape) =>
                          getConflictAreas({
                              shape,
                              savedAreas: savedAreaPolygons,
                          })
                      ),
                      A.map(({ intersection }) => intersection),
                      compact
                  )
                : [];

        setSavedPolygons({
            source: SavedPolygonSource.savedArea,
            polygons: savedAreaPolygons,
            isConflict: !isEmpty(conflicts),
        });
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!hasPreviousState) {
            const data = convertSavedAreas(
                savedAreasData?.fetchAllAreasExtended || [],
                selectedAreaId
            );

            setFilterItems(!isEmpty(data) ? data : []);
        }
    }, [savedAreasData, selectedAreaId]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        if (reFetchSavedAreas) {
            refetchSavedAreas?.();
            setReFetchSavedAreas?.(false);
        }
    }, [reFetchSavedAreas]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        if (
            !isSavedAreaSelected &&
            !isEmpty(savedPolygons.polygons) &&
            !savedViewId
        ) {
            setFunctionType(FunctionType.viewport);
            clearShapes();
            setSavedPolygons({
                source: SavedPolygonSource.savedArea,
                polygons: [],
                isConflict: false,
            });
        }
    }, [isSavedAreaSelected, filterItems, savedAreasData]);

    const resizeMap = () => {
        window.setTimeout(
            () => map.current && isLoaded && map.current.resize(),
            200
        );
    };

    const exitMapModes = (tab: MapLayoutTabs) => {
        if (tab === "shapeLayers") {
            setFunctionType(FunctionType.viewport);
            draw.current?.changeMode("simple_select");

            if (modes && isEqual(modes.select.isSelectMode, true)) {
                modes.select.selectMode(false);
                draw.current?.add({
                    type: "FeatureCollection",
                    features: pipe(
                        getMapFeatures(draw, isLoaded),
                        A.map((f) => ({
                            ...f,
                            properties: {
                                ...f.properties,
                                selected: false,
                            },
                        }))
                    ),
                });
            }
        }
    };

    const [openRightSlot, setOpenRightSlot] = useState(false);
    const [openBottomSlot, setOpenBottomSlot] = useState(false);
    const [dataClicked, setDataClicked] = useState<DatasetInfo | undefined>(
        undefined
    );
    const exploreDataset = () => {
        setOpenBottomSlot(true);
    };
    const previewInfo = (data: DatasetInfo) => {
        setDataClicked(data);
        setOpenFilterCriteria(false);
        setOpenRightSlot(true);
    };

    const addFilter = (filter: MapFilterCriteriaForm) => {
        dispatch?.({
            type: "ADD_FILTER",
            values: {
                ...filter,
                id: uuid(),
                visible: true,
                selected: false,
                disabled: false,
            },
        });
    };

    const updateFilter = (
        filter: WithRequiredProperty<Partial<MapContextFilter>, "id">
    ) => {
        dispatch?.({
            type: "UPDATE_FILTER",
            values: { id: filter.id, ...omitBy(filter, isNil) },
        });
    };

    const removeFilter = (id: string) => {
        const filter = filters.find((f) => f.id === id);

        if (filter) {
            setMultiFilters(
                pipe(
                    multiFilters,
                    A.filter((f) => !isEqual(f, mapToMultifilterType(filter)))
                )
            );
        }

        hideFilteredDataLayers({
            map,
            isLoaded,
            suffix: id,
            dataset: lastSelectedDataset,
        });

        dispatch?.({
            type: "REMOVE_FILTER",
            values: id,
        });
    };

    const clearFilters = () => {
        dispatch?.({
            type: "CLEAR_FILTERS",
        });
        clearMultifilters(multiFilters, setMultiFilters);
    };

    const editFilter = (id: string) => {
        dispatch?.({
            type: "EDIT_FILTER",
            values: id,
        });
        handleFilterCriteriaSidebar(true);
    };

    const saveFilter = (id: string) => {
        dispatch?.({
            type: "SAVE_FILTER",
            values: id,
        });
        handleFilterCriteriaSidebar(false);
    };

    const onAddFilter = () => {
        dispatch?.({
            type: "RESET_FILTERS",
        });
        handleFilterCriteriaSidebar(true);
        clearMultifilters(multiFilters, setMultiFilters);
    };

    return (
        <OnMapLeave>
            <MapLayout
                sidebarOpen
                rightOpen={openFilterCriteria || openRightSlot}
                topOpen={canAddFilterCriteria}
                right={
                    openFilterCriteria ? (
                        <MapFilterCriteria
                            map={map}
                            isLoaded={isLoaded}
                            isOpen={openFilterCriteria}
                            mapTemplateId={mapTemplateId}
                            handleSidebar={handleFilterCriteriaSidebar}
                            addFilter={addFilter}
                            updateFilter={updateFilter}
                            saveFilter={saveFilter}
                            currentFilter={pipe(
                                filters,
                                A.findFirst((f) => isEqual(f.selected, true)),
                                O.fold(
                                    () => undefined,
                                    (f) => f
                                )
                            )}
                            lastSelectedDataset={lastSelectedDataset}
                            setMultiFilters={setMultiFilters}
                        />
                    ) : openRightSlot ? (
                        <RightMapMainContainerSlot
                            id={dataClicked?.id || 0}
                            name={dataClicked?.name || ""}
                            price={dataClicked?.price || 0}
                            image={dataClicked?.image || ""}
                            size={dataClicked?.size || 0}
                            preview={dataClicked?.preview || (() => {})}
                            openRightSlot={openRightSlot}
                            setOpenRightSlot={setOpenRightSlot}
                        />
                    ) : undefined
                }
                top={
                    <MapTopbar
                        onAddFilter={onAddFilter}
                        clearFilters={clearFilters}
                        removeFilter={removeFilter}
                        filters={filters}
                        updateFilter={updateFilter}
                        editFilter={editFilter}
                    />
                }
                leftSubPanelOpen={false}
                leftSubPanel={
                    datasetInfo ? (
                        <ExploreDatasetsView
                            datasetInfo={datasetInfo}
                            exploreDataset={exploreDataset}
                            previewInfo={previewInfo}
                        />
                    ) : (
                        <EmptyState
                            title="No Datasets To Preview"
                            subtitle="When datasets from the marketplace are ready to be previewed, they will appear here"
                            icon={<ActionKeyOutline size="md" />}
                            buttonText="Data Marketplace"
                            onClick={() => {
                                setOpenBottomSlot(true);
                            }}
                            buttonIcon={<ActionKeyOutline size="xs" />}
                            border={false}
                        />
                    )
                }
                bottomOpen={openBottomSlot}
                bottom={
                    <MarketplaceDataView
                        setOpenBottomSlot={setOpenBottomSlot}
                        setDataClicked={setDataClicked}
                        setOpenRightSlot={setOpenRightSlot}
                    />
                }
                datasets={
                    <MapLayoutLeftContent
                        map={map}
                        multiFilters={multiFilters}
                        addRemoveDataset={addRemoveDataset}
                        selectedDataset={selectedDataset}
                        setSelectedDataset={setSelectedDataset}
                        mapTemplateId={mapTemplateId}
                        heatMapValue={heatMapValue}
                        onClickHeatMapValue={onClickHeatMapValue}
                    />
                }
                shapeLayers={
                    <MapShapeLayers
                        deleteShape={props.deleteShape}
                        functionType={functionType}
                        exitMapModes={() => exitMapModes("shapeLayers")}
                    />
                }
                areas={
                    <SavedAreasSubmenu
                        filterItems={filterItems}
                        selectSavedArea={selectSavedArea}
                    />
                }
                maps={
                    <MapStyles
                        isLoaded={isLoaded}
                        map={map}
                        currentStyle={
                            map.current && isLoaded
                                ? map.current.getStyle()
                                : undefined
                        }
                    />
                }
                configuration={
                    <DebugContainer
                        options={options}
                        setOptions={props.setOptions}
                        showTriangles={props.showTriangles}
                        setShowTriangles={props.setShowTriangles}
                        showPoints={props.showPoints}
                        setShowPoints={props.setShowPoints}
                        showFiltered={props.showFiltered}
                        setShowFiltered={props.setShowFiltered}
                    />
                }
                onSidebarOpenChange={(_, tab) => {
                    resizeMap();
                    exitMapModes(tab);
                }}
                onSidebarIconClick={(_, tab) => {
                    exitMapModes(tab);
                }}
                onSidebarResizeEnd={() => resizeMap()}
                onTopOpenChange={() => resizeMap()}
                onTopResizeEnd={() => resizeMap()}
                onRightOpenChange={() => resizeMap()}
                onRightResizeEnd={() => resizeMap()}
            >
                <Box
                    height={"100%"}
                    width={"100%"}
                    sx={{
                        padding: 4,
                        overflow: "auto",
                        borderRadius: (theme) => theme.radius.xs3,
                    }}
                >
                    <MapboxMapContainer
                        key={Consumers.mapbox}
                        {...props}
                        isFilterCriteriaOpen={openFilterCriteria}
                        selectedPoint={selectedPoint}
                        setSelectedPoint={setSelectedPoint}
                        dataSources={dataSources}
                        selectedDataset={selectedDataset}
                        setSelectedDataset={setSelectedDataset}
                        addMultipleDatasets={addMultipleDatasets}
                        functionType={functionType}
                        multiFilters={multiFilters}
                        recentResponse={recentResponse}
                        responses={responses}
                        viewport={viewport}
                        handleViewportChange={handleViewportChange}
                        savedPolygons={savedPolygons}
                        hoveredData={hoveredData}
                        setHoveredData={setHoveredData}
                        options={options}
                        setMultiFilters={setMultiFilters}
                        channelId={channelId}
                        setFunctionType={setFunctionType}
                        setSavedPolygons={setSavedPolygons}
                        polygons={polygons}
                        handleMultiPolygons={handleMultiPolygons}
                        clearShapes={clearShapes}
                        addRemoveDataset={addRemoveDataset}
                        setSelectedAreaId={setSelectedAreaId}
                        selectedSavedView={selectedSavedView}
                        setSelectedSavedView={setSelectedSavedView}
                        refetchSavedAreas={refetchSavedAreas}
                        refetchSavedViews={refetchSavedViews}
                        setReFetchSavedAreas={setReFetchSavedAreas}
                        resetAreasFilters={resetAreasFilters}
                        handleSelectedSavedPolygons={
                            handleSelectedSavedPolygons
                        }
                        selectableItems={pipe(filterItems, toNonReadonlyArray)}
                        selectedShapes={selectedShapes}
                        handleSelectedShapes={handleSelectedShapes}
                        savedAreaId={savedAreaId}
                        savedViewId={savedViewId}
                        setSearchParams={setSearchParams}
                        searchParams={searchParams}
                        mapTemplateId={mapTemplateId}
                    />
                </Box>
            </MapLayout>
        </OnMapLeave>
    );
};

export default MapMainContainer;
