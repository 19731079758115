import { useFetchMapTemplatesQuery } from "@biggeo/bg-server-lib/datascape-ai";
import { LoadingBar } from "@biggeo/bg-ui/lab";
import { match } from "@vividtheory/remotedata";
import DataManagementContainer from "../containers/DataManagementContainer";

const DataManagementPage = () => {
    const { remote } = useFetchMapTemplatesQuery({ variables: { input: {} } });

    return match(remote, {
        _: () => <LoadingBar />,
        Success: (data) => {
            return (
                <DataManagementContainer
                    mapTemplates={data.fetchMapTemplates.mapTemplates}
                />
            );
        },
    });
};

export default DataManagementPage;
