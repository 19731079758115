import { ImageWithCheckbox } from "@biggeo/bg-ui";
import {
    Grid,
    InfoCell,
    Stack,
    Typography,
    UserAvatar,
} from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import capitalize from "lodash/capitalize";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import mapboxgl from "mapbox-gl";
import { match } from "ts-pattern";

export enum MapStyleType {
    light = "light",
    dark = "dark",
    streets = "streets",
    outdoors = "outdoors",
}

export const DEFAULT_MAP_STYLE: {
    key: MapStyleType;
    url: string;
    sprite?: string;
} = {
    key: MapStyleType.light,
    url: "mapbox://styles/mapbox/light-v11",
    sprite: "mapbox://sprites/mapbox/light-v11",
};

export const mapStylesImage = (style: MapStyleType) =>
    match(style)
        .with(
            MapStyleType.light,
            () =>
                "https://biggeo.blob.core.windows.net/test/4155b5e6-b287-43dd-a85b-88df630b063b.png.png"
        )
        .with(
            MapStyleType.dark,
            () =>
                "https://biggeo.blob.core.windows.net/test/43931eb1-cb05-c2ed-20f6-60af722042d6.png.png"
        )
        .with(
            MapStyleType.outdoors,
            () =>
                "https://biggeo.blob.core.windows.net/test/ce86263f-5d64-df87-c7b3-9cf73e786963.png.png"
        )
        .with(
            MapStyleType.streets,
            () =>
                "https://biggeo.blob.core.windows.net/test/046c21f3-e1cb-8f59-c239-a178ee572406.png.png"
        )
        .exhaustive();

export const mapStyles = (style: MapStyleType) =>
    match(style)
        .with(MapStyleType.light, () => "mapbox://styles/mapbox/light-v11")
        .with(MapStyleType.dark, () => "mapbox://styles/mapbox/dark-v11")
        .with(
            MapStyleType.outdoors,
            () => "mapbox://styles/mapbox/outdoors-v12"
        )
        .with(MapStyleType.streets, () => "mapbox://styles/mapbox/streets-v12")
        .exhaustive();

export const getMapStyleFromSprite = (sprite?: string): MapStyleType => {
    if (!sprite) {
        return DEFAULT_MAP_STYLE.key;
    }

    const styles = Object.values(MapStyleType);

    return (
        find(styles, (style) => sprite.includes(style)) || DEFAULT_MAP_STYLE.key
    );
};

export interface IMapStyles {
    readonly isLoaded: boolean;
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly onStyleChange?: (style?: MapStyleType) => void;
    readonly currentStyle?: mapboxgl.Style;
}

const MapStyles = ({
    isLoaded,
    map,
    onStyleChange,
    currentStyle,
}: IMapStyles) => {
    const style = currentStyle
        ? getMapStyleFromSprite(currentStyle.sprite)
        : DEFAULT_MAP_STYLE.key;

    const handleSelection = (s: MapStyleType) => {
        onStyleChange?.(s);

        if (isLoaded && map.current) {
            if (isEqual(style, s)) {
                map.current.setStyle(mapStyles(DEFAULT_MAP_STYLE.key));
            } else {
                map.current.setStyle(mapStyles(s));
            }
        }
    };

    return (
        <Stack>
            <InfoCell
                disableActiveEffect
                disableHoverEffect
                title={"MapBox"}
                endNode={
                    <UserAvatar
                        src="https://biggeo.blob.core.windows.net/test/4bc70c08-cdeb-d0f2-9db1-5e4972f95b94.png.png"
                        size="xs"
                    />
                }
            />
            <Grid
                container
                spacing={4}
                sx={{
                    padding: 4,
                }}
            >
                {pipe(
                    Object.values(MapStyleType),
                    A.map((_style) => (
                        <Grid item xs={6} key={_style}>
                            <Stack
                                gap={2}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <ImageWithCheckbox<MapStyleType>
                                    id={_style}
                                    selected={isEqual(style, _style)}
                                    image={mapStylesImage(_style)}
                                    onClick={handleSelection}
                                    invertColors={isEqual(
                                        _style,
                                        MapStyleType.dark
                                    )}
                                />
                                <Typography variant="title3">
                                    {capitalize(_style)}
                                </Typography>
                            </Stack>
                        </Grid>
                    ))
                )}
            </Grid>
        </Stack>
    );
};

export default MapStyles;
