import { IconAvatar, Stack, Typography } from "@biggeo/bg-ui/lab";
import { AddOutline } from "@biggeo/bg-ui/lab/icons";

interface ICreateMapTemplateCard {
    readonly onClick: () => void;
}

const CreateMapTemplateCard = ({ onClick }: ICreateMapTemplateCard) => {
    return (
        <Stack
            onClick={onClick}
            justifyContent="center"
            alignItems="center"
            gap={2}
            sx={{
                textAlign: "center",
                cursor: "pointer",
                padding: 4,
                backgroundColor: (theme) => theme.palette.background.main,
                borderRadius: (theme) => theme.radius.xs3,
                border: "1px dashed",
                borderColor: (theme) => theme.palette.stroke[200],
                minHeight: (theme) => theme.spacing(47.2),
            }}
        >
            <IconAvatar variant="tonal" color="primary">
                <AddOutline />
            </IconAvatar>
            <Typography variant="title2" fontWeight="bold">
                Create Map Template
            </Typography>
            <Typography
                variant="body3"
                sx={{
                    color: (theme) => theme.palette.disabled.onContainer,
                }}
            >
                Launch a new map and unlock your geospatial data's potential
            </Typography>
        </Stack>
    );
};

export default CreateMapTemplateCard;
