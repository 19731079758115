import { Checkbox, Stack, Typography } from "@biggeo/bg-ui/lab";
import { Circle, CropSquare } from "@biggeo/bg-ui/lab/icons";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import { match } from "ts-pattern";
import { convertToHex8String } from "../../../utils/color";
import { MapShapeColorType } from "../../views/MapShapeLayerStyles";
import { MapFilterCriteriaShapeStyle } from "../utils/utils";

interface IFilterCriteriaStylesShape {
    readonly currentShape: MapFilterCriteriaShapeStyle;
    readonly currentCustomMarker?: string;
    readonly color?: MapShapeColorType;
    readonly stroke?: MapShapeColorType;
    readonly onChange: (s: MapFilterCriteriaShapeStyle) => void;
}

const FilterCriteriaStylesShape = ({
    currentShape,
    currentCustomMarker,
    color,
    stroke,
    onChange,
}: IFilterCriteriaStylesShape) => {
    const isChecked = (shape: MapFilterCriteriaShapeStyle): boolean =>
        isEqual(currentShape, shape) && !currentCustomMarker;

    return (
        <Stack gap={4} width={"100%"}>
            {pipe(
                Object.values(MapFilterCriteriaShapeStyle),
                A.map((shape) => (
                    <Stack
                        key={shape}
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={2}
                    >
                        <Stack flexDirection="row" gap={2}>
                            <Checkbox
                                checked={isChecked(shape)}
                                onChange={() => {
                                    onChange(shape);
                                }}
                            />
                            <Typography variant="body3">
                                {startCase(shape)}
                            </Typography>
                        </Stack>
                        {match(shape)
                            .with(MapFilterCriteriaShapeStyle.oval, () => (
                                <Circle
                                    size="xxs"
                                    sx={{
                                        color: color
                                            ? convertToHex8String(
                                                  color.color,
                                                  color.opacity
                                              )
                                            : undefined,
                                        backgroundColor: color
                                            ? convertToHex8String(
                                                  color.color,
                                                  color.opacity
                                              )
                                            : undefined,
                                        borderRadius: (theme) =>
                                            theme.radius.full,
                                        border: 0.5,
                                        borderColor: stroke
                                            ? convertToHex8String(
                                                  stroke.color,
                                                  stroke.opacity
                                              )
                                            : undefined,
                                        height: (theme) => theme.spacing(2.375),
                                        width: (theme) => theme.spacing(2.375),
                                    }}
                                />
                            ))
                            .with(MapFilterCriteriaShapeStyle.square, () => (
                                <CropSquare
                                    size="xxs"
                                    sx={{
                                        color: color
                                            ? convertToHex8String(
                                                  color.color,
                                                  color.opacity
                                              )
                                            : undefined,
                                        backgroundColor: color
                                            ? convertToHex8String(
                                                  color.color,
                                                  color.opacity
                                              )
                                            : undefined,
                                        border: 0.5,
                                        borderColor: stroke
                                            ? convertToHex8String(
                                                  stroke.color,
                                                  stroke.opacity
                                              )
                                            : undefined,
                                        height: (theme) => theme.spacing(2.375),
                                        width: (theme) => theme.spacing(2.375),
                                    }}
                                />
                            ))
                            .exhaustive()}
                    </Stack>
                ))
            )}
        </Stack>
    );
};

export default FilterCriteriaStylesShape;
