import {
    DataSource,
    useFetchDataSourcesQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Button, IconAvatar, LoadingBar } from "@biggeo/bg-ui/lab";
import { AddOutline, BigGeoLogo, SnowflakeLogo } from "@biggeo/bg-ui/lab/icons";
import * as Remote from "@vividtheory/remotedata";
import { match } from "@vividtheory/remotedata";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import startCase from "lodash/startCase";
import { useEffect, useMemo, useState } from "react";
import {
    isAppRunningOnSF,
    useDataGridOptions,
} from "../../common/redux/hooks.ts";
import { NavigationRail } from "../../components/NavigationRail.tsx";
import { BigGeoDataSetsContainer } from "../../data-sets/containers/BigGeoDataSetsContainer.tsx";
import { SnowflakesAds } from "../../data-sets/views/SnowflakesAds.tsx";
import { DatasetLayout } from "../../layouts/DatasetLayout.tsx";
type DataSourceTab = {
    readonly id: number;
    readonly name: "Big Geo Test Data" | "Snowflake";
    readonly icon: JSX.Element;
};

const dataSourceTabs: DataSourceTab[] = [
    {
        id: 1,
        name: "Big Geo Test Data",
        icon: (
            <IconAvatar
                size="xs"
                color="primary"
                sx={{
                    borderRadius: (theme) => theme.radius.xs3,
                }}
            >
                <BigGeoLogo />
            </IconAvatar>
        ),
    },
    {
        id: 2,
        name: "Snowflake",
        icon: (
            <IconAvatar
                size="xs"
                color="background"
                sx={{
                    borderRadius: (theme) => theme.radius.xs3,
                }}
            >
                <SnowflakeLogo />
            </IconAvatar>
        ),
    },
];
export type SelectedDataSource = (typeof dataSourceTabs)[number];

const DataSetsContainer = ({
    selectedDataset,
    setSelectedDataset,
    toPage,
    hideViewButton,
}: {
    readonly selectedDataset?: readonly string[];
    readonly setSelectedDataset?: (datasets: readonly string[]) => void;
    readonly toPage?: (path: string) => void;
    readonly hideViewButton?: boolean;
}) => {
    const isRunningOnSF = isAppRunningOnSF();
    const tabs = useMemo(
        () =>
            isRunningOnSF
                ? pipe(
                      dataSourceTabs,
                      A.filter((tab) => tab.name !== "Big Geo Test Data")
                  )
                : dataSourceTabs,
        [isRunningOnSF]
    );

    const { dataGridFetchInputProps, filterSearchPaginateProps } =
        useDataGridOptions();
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setSelected(tabs[0]);
    }, [isRunningOnSF]);

    const [selected, setSelected] = useState<DataSourceTab>(tabs[0]);

    const handleSelectDataSource = (dataset: DataSourceTab) => {
        setSelected(dataset);
    };

    const { remote: dataSourcesRd, setRemote } = useFetchDataSourcesQuery({
        variables: {
            input: {
                isConnected: true,
                dataGridFetchInput: dataGridFetchInputProps,
            },
        },
    });

    return (
        <DatasetLayout
            leftSidePanelContent={pipe(
                tabs,
                A.map((source: DataSourceTab) => {
                    return (
                        <NavigationRail
                            key={source.id}
                            selected={selected === source}
                            onClick={() => handleSelectDataSource(source)}
                            icon={source.icon}
                        >
                            {startCase(source.name)}
                        </NavigationRail>
                    );
                })
            )}
            leftSidePanelHeader={
                <a href="/datasources">
                    <Button
                        color="info"
                        density="dense"
                        startNode={<AddOutline />}
                    >
                        Add Data Source
                    </Button>
                </a>
            }
        >
            {match(dataSourcesRd, {
                _: () => <LoadingBar />,
                Success: (data) => {
                    const condition = isRunningOnSF
                        ? selected.name === "Snowflake"
                        : selected.name === "Big Geo Test Data";

                    const setDataSources = (d: DataSource[]) => {
                        setRemote(
                            new Remote.Success({
                                fetchDataSources: {
                                    ...data.fetchDataSources,
                                    dataSources: d,
                                },
                            })
                        );
                    };

                    return condition ? (
                        <BigGeoDataSetsContainer
                            isRunningOnSF={isRunningOnSF}
                            dataSources={data.fetchDataSources}
                            selectedDataset={selectedDataset}
                            setSelectedDataset={setSelectedDataset}
                            setDataSources={setDataSources}
                            hideViewButton={hideViewButton}
                            toPage={toPage}
                            filterSearchPaginateProps={
                                filterSearchPaginateProps
                            }
                        />
                    ) : (
                        <SnowflakesAds />
                    );
                },
            })}
        </DatasetLayout>
    );
};

export default DataSetsContainer;
