import {
    DataSource,
    InputConnectDataSource,
    UserSnowflakeTableData,
    useConnectDataSourceMutation,
    useFetchDataSourcesQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Box,
    BreadcrumbsButton,
    BreadcrumbsGroup,
    Severity,
    Stack,
} from "@biggeo/bg-ui/lab";
import { SplitLayoutWithHeader } from "@biggeo/bg-ui/lab/layouts";
import { Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import tinycolor2 from "tinycolor2";
import Zod from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useDataGridOptions } from "../../common/redux/hooks";
import { MappingColumnForm } from "../../components/MappingColumnForm";
import { NewMappingModal } from "../../components/NewMappingModal";
import { databaseMetaDataActions } from "../../database-meta-data/redux/model";
import { Routes } from "../../navigation/redux/model";
import { toasterActions } from "../../toaster/containers/redux/model";
export type FormValuesType = Pick<
    InputConnectDataSource,
    "id" | "collectionName" | "label" | "color"
> & {
    readonly lat: string;
    readonly long: string;
    readonly geoSpatialColumn: string;
    readonly tableId: string;
    readonly description: string;
};
export const UnIndexedManagementContainer = () => {
    const toPage = useNavigate();
    const dispatch = useDispatch();
    const [allDataSources, setAllDatasources] = useState<DataSource[]>([]);
    const [snowflakeData, setSnowflakeData] = useState<
        UserSnowflakeTableData[]
    >([]);
    const [renderedDataSources, setRenderedDataSources] = useState<
        DataSource[]
    >([]);
    const { dataGridFetchInputProps } = useDataGridOptions();

    const {
        // biome-ignore lint/correctness/noUnusedVariables: <explanation>
        queryReturn: { loading: isLoading },
    } = useFetchDataSourcesQuery({
        variables: {
            input: { dataGridFetchInput: dataGridFetchInputProps },
        },
        onCompleted: (d) => {
            setAllDatasources(d.fetchDataSources.dataSources);
            setRenderedDataSources(d.fetchDataSources.dataSources);
            setSnowflakeData(d.fetchDataSources.snowflakeData);
        },
    });

    const dataFromSnowflake = snowflakeData.find((data) =>
        renderedDataSources.some(
            (dataSource) => dataSource.tableName === data.tableName
        )
    );

    const dataSourceEntry = renderedDataSources.find((dataSource) =>
        allDataSources.find((data) => data.tableName === dataSource.tableName)
    );

    const addOrUpdateDataset = (dataSource: DataSource) => {
        dispatch(databaseMetaDataActions.addOrUpdateDataset(dataSource));
    };

    const {
        executeMutation: connectDataSourceMutation,
        // biome-ignore lint/correctness/noUnusedVariables: <explanation>
        mutationReturn: [_, { loading }],
    } = useConnectDataSourceMutation();

    const connectDataSource = (input: InputConnectDataSource) => {
        connectDataSourceMutation({
            variables: { input },
            onCompleted: (data) => {
                addOrUpdateDataset(data.connectDataSource);

                setRenderedDataSources((prev) =>
                    prev.map((dataSource) =>
                        dataSource.id === data.connectDataSource.id
                            ? {
                                  ...dataSource,
                                  ...data.connectDataSource,
                              }
                            : dataSource
                    )
                );
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        title: "Data indexing started successfully",
                        autoHideDuration: 5000,
                    })
                );
                toPage("/data/processing");
            },
            onError: (e) => {
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        severity: Severity.error,
                        title: e.message || "Error connecting datasource",
                        autoHideDuration: 5000,
                    })
                );
            },
        });
    };

    const defaultValues: FormValuesType = {
        id: dataSourceEntry?.id || "",
        label: "",
        lat: "",
        long: "",
        tableId: "",
        color: "#000000ff",
        geoSpatialColumn: "",
        description: "",
        collectionName: dataSourceEntry?.collectionName || "",
    };

    const [oneGeospatialColumn, setOneGeospatialColumn] =
        useState<boolean>(false);
    const [twoGeospatialColumn, setTwoGeospatialColumn] =
        useState<boolean>(false);

    const onSubmit = async (values: FormValuesType) => {
        const tinyColor = tinycolor2(values.color);

        connectDataSource({
            id: dataSourceEntry?.id || values.id,
            geographyColumn: twoGeospatialColumn
                ? `${values.long},${values.lat}`
                : `${values.geoSpatialColumn}`,
            collectionName:
                dataSourceEntry?.collectionName || values.collectionName,
            label: values.label,
            color: tinyColor.toHexString(),
            tableId: values.tableId,
            description: values.description,
            tableIdType: values.tableId,
        });
    };

    const navigate = (to?: string) =>
        to ? toPage(`${to}`) : toPage(Routes.data);
    return (
        <Formik
            initialValues={defaultValues}
            onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                await onSubmit(values);
                setSubmitting(false);
            }}
            validationSchema={toFormikValidationSchema(
                Zod.object({
                    label: Zod.string().optional(),
                    description: Zod.string().optional(),
                    color: Zod.string(),
                    lat: Zod.string().optional(),
                    long: Zod.string().optional(),
                    geoSpatialColumn: Zod.string().optional(),
                    tableId: Zod.string(),
                }).refine(
                    (data) => {
                        if (oneGeospatialColumn) {
                            return (
                                !!data.geoSpatialColumn &&
                                (!data.lat || !data.long)
                            );
                        }
                        return !data.geoSpatialColumn && data.lat && data.long;
                    },
                    {
                        message: "Invalid column configuration",
                        path: ["geoSpatialColumn"], // You can specify the path for the error message
                    }
                )
            )}
        >
            {(props) => {
                return (
                    <Stack width="100%" height="100%">
                        <SplitLayoutWithHeader
                            header={
                                <BreadcrumbsGroup
                                    value={"index-data-source"}
                                    onClick={() => navigate()}
                                >
                                    <BreadcrumbsButton value={"data"}>
                                        Data
                                    </BreadcrumbsButton>
                                    <BreadcrumbsButton
                                        value={"index-data-source"}
                                        hideSeparator
                                    >
                                        Index data source
                                    </BreadcrumbsButton>
                                </BreadcrumbsGroup>
                            }
                            left={
                                dataFromSnowflake &&
                                dataSourceEntry && (
                                    <Box
                                        height="100%"
                                        width="100%"
                                        sx={{
                                            breakpoints: {
                                                cmd: { padding: 4 },
                                            },
                                        }}
                                    >
                                        <MappingColumnForm
                                            props={props}
                                            dataSource={dataSourceEntry}
                                        />
                                    </Box>
                                )
                            }
                            right={
                                dataFromSnowflake &&
                                dataSourceEntry && (
                                    <NewMappingModal
                                        snowflakeData={dataFromSnowflake}
                                        props={props}
                                        oneGeospatialColumn={
                                            oneGeospatialColumn
                                        }
                                        setOneGeospatialColumn={
                                            setOneGeospatialColumn
                                        }
                                        twoGeospatialColumn={
                                            twoGeospatialColumn
                                        }
                                        setTwoGeospatialColumn={
                                            setTwoGeospatialColumn
                                        }
                                    />
                                )
                            }
                        />
                    </Stack>
                );
            }}
        </Formik>
    );
};
