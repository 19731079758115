import { ScrollContainer } from "@biggeo/bg-ui";
import { Stack } from "@biggeo/bg-ui/lab";
import { LayoutTab } from "./DatasourcesLayout.tsx";
import { DatasourcesLeftContent } from "./DatasourcesLeftContentItem.tsx";

export const DatasourcesLeftContentLayout = ({
    tabs,
    onClickItem,
    selected,
}: {
    readonly tabs: readonly LayoutTab[];
    readonly onClickItem: (label: string) => void;
    readonly selected: string;
}) => {
    return (
        <ScrollContainer>
            <Stack gap={1}>
                {tabs.map((tab, index) => (
                    <DatasourcesLeftContent
                        selected={selected}
                        key={`allData-${
                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                            index
                        }`}
                        tab={tab}
                        onClick={onClickItem}
                    />
                ))}
            </Stack>
        </ScrollContainer>
    );
};
