import {
    FileUploadState,
    FileWithUploadState,
    SingleFileUploadProvider,
    appCookies,
} from "@biggeo/bg-common";
import { IFileUpload } from "@biggeo/bg-common/lib/files/utils/type";
import { UploadFile } from "@biggeo/bg-server-lib/studio";
import { Button, ButtonProps, CircularLoading } from "@biggeo/bg-ui/lab";
import { UploadOutline } from "@biggeo/bg-ui/lab/icons";
import isEqual from "lodash/isEqual";
import { CallBacksType } from "../../utils/types.ts";

interface BaseUploadOptions {
    readonly callbacks: CallBacksType<UploadFile>;
}

type WithButton = BaseUploadOptions & {
    readonly withButton: true;

    readonly label?: string;
    readonly startIcon?: JSX.Element;
    readonly endIcon?: JSX.Element;
    readonly buttonProps?: ButtonProps;
    readonly disabled?: boolean;
};

type WithComponent = BaseUploadOptions & {
    readonly withButton?: false;

    readonly children: React.FC<{
        readonly file?: FileWithUploadState;
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        readonly onClick: (e: any) => void;
    }>;
};

type UploadFileComponentType = WithButton | WithComponent;

export const UploadSingleImage = (props: UploadFileComponentType) => {
    const uploadOptions: IFileUpload = {
        fileTypes: ["image/*"],
        cookies: appCookies,
        onError: (err) => {
            props.callbacks.onError?.(err.message);
        },
        onSuccess: (file) => {
            props.callbacks.onSuccess?.(file);
        },
        onLoad: () => {
            props.callbacks.onFetch?.();
        },
    };
    return (
        <SingleFileUploadProvider uploadOptions={uploadOptions}>
            {({ input, onClick, file }) => (
                <>
                    {props.withButton ? (
                        <Button
                            variant="outlined"
                            density="dense"
                            onClick={onClick}
                            startNode={
                                isEqual(file.state, FileUploadState.loading) ? (
                                    <CircularLoading size={"xs"} />
                                ) : (
                                    props.startIcon || (
                                        <UploadOutline size={"xs"} />
                                    )
                                )
                            }
                            endNode={props.endIcon}
                            disabled={
                                isEqual(file.state, FileUploadState.loading) ||
                                props.disabled
                            }
                            {...props.buttonProps}
                        >
                            {props.label || "Upload Image"}
                        </Button>
                    ) : (
                        props.children({
                            onClick,
                            file,
                        })
                    )}

                    {input}
                </>
            )}
        </SingleFileUploadProvider>
    );
};
