import { useLazyQuery } from "@apollo/client";
import {
    FetchMapUpdatesDocument,
    type FilterObject,
    type FilterObjectType,
    type InputPolygon,
    type InputRadius,
    type InputViewBox,
    type MultiPolygon,
    type NewFilterObjectConnection,
    type ReqOptions,
    type SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import uuid from "react-uuid";
import { v4 as uuidV4 } from "uuid";
import { isAppRunningOnSF } from "../../common/redux/hooks";
import { commonActions } from "../../common/redux/model.ts";
import type { DatasetPointShape } from "../../components/DatapointShape/types";
import { levelSetPercentiles } from "../../utils/variables";
import { SavedPolygonType } from "../mapbox/hooks/saved-polygon-hooks";
import {
    useLatestMapChangesV2,
    useServerWebsocket,
} from "../utils/subscription";
import {
    FunctionType,
    SavedPolygonSource,
    matchFunctionType,
    updateArray,
} from "../utils/utils";
import { useFilters } from "./filters";

const channelId = uuid();

export type InputPolygonWithId = InputPolygon & {
    readonly id: string;
};

export type InputRadiusWithId = InputRadius & {
    readonly id: string;
};

export type PureDataStringHookProps = {
    readonly functionType: FunctionType;
    readonly setFunctionType: React.Dispatch<
        React.SetStateAction<FunctionType>
    >;
    readonly polygons: InputPolygonWithId[];
    readonly setPolygons: React.Dispatch<
        React.SetStateAction<InputPolygonWithId[]>
    >;
};

export type PureDataStringHookReturnType = {
    polygons: InputPolygonWithId[];
    handleMultiPolygons: ({
        polygon,
    }: {
        polygon: InputPolygonWithId | InputPolygonWithId[];
    }) => void;
    clearData: () => void;
    responses: Partial<Record<string, SubscriptionResponse | null>>;
    channelId: string;
    multiFilters: FilterObject[];
    addRemoveDataset: (v: string) => void;
    handlePolygonsOnZoom: (p: InputPolygon[]) => void;
    addMultipleDatasets: (v: readonly FilterObjectType[]) => void;
    setMultiFilters: (mf: FilterObject[]) => void;
    handleViewportChange: ({
        viewport,
    }: {
        viewport: InputViewBox;
    }) => void;
    viewport: InputViewBox;
    recentResponse?: SubscriptionResponse;
    savedPolygons: SavedPolygonType;
    setSavedPolygons: (i: Partial<SavedPolygonType>) => void;
    options: ReqOptions;
    setOptions: React.Dispatch<React.SetStateAction<ReqOptions>>;
    functionType: FunctionType;
    setFunctionType: React.Dispatch<React.SetStateAction<FunctionType>>;
    deleteShape: (id: string) => void;
    clearShapes: () => void;
    handleSavedPolygons: (p: InputPolygonWithId[]) => void;
    datasetPointsShapes: readonly DatasetPointShape[];
    setDatasetPointsShapes: (datasetShape: DatasetPointShape) => void;
};

export const usePureDataString = (): PureDataStringHookReturnType => {
    const socket = useServerWebsocket();
    const isRunningOnSF = isAppRunningOnSF();
    const [options, setOptions] = useState<ReqOptions>({
        polygonMaxGeomsPerCell: isRunningOnSF ? 125 : 75,
        pointMaxGeomsPerCell: isRunningOnSF ? 200 : 101,
        polygonResolutionOffset: isRunningOnSF ? 3 : 3,
        pointResolutionOffset: 3,
        levelSets: levelSetPercentiles,
    });
    const dispatch = useDispatch();

    const [savedPolygons, setSavedPolygons] = useState<SavedPolygonType>({
        source: SavedPolygonSource.savedArea,
        polygons: [],
        isConflict: false,
    });

    const [responses, setResponses] = useState<
        Partial<Record<string, SubscriptionResponse | null>>
    >({});

    const [recentResponse, setRecentResponse] =
        useState<SubscriptionResponse>();
    const {
        multiFilters,
        addRemoveDataset,
        setMultiFilters,
        addMultipleDatasets,
    } = useFilters();

    const [viewport, setViewport] = useState<InputViewBox>({
        latBounds: {
            min: 44.41032116923924,
            max: 56.77085035366497,
        },
        lngBounds: {
            min: -125.46972656249946,
            max: -102.53027343749929,
        },
    });
    const [polygons, setPolygons] = useState<InputPolygonWithId[]>([]);

    const [_loading, setLoading] = useState(false);

    const [functionType, setFunctionType] = useState<FunctionType>(
        FunctionType.viewport
    );

    const [datasetPointsShapes, setDatasetPointsShapes] = useState<
        readonly DatasetPointShape[]
    >([]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        matchFunctionType(functionType, {
            radius: () => {
                setPolygons([]);
            },
            viewport: () => {},
            polygon: () => {
                setPolygons([]);
            },
            savedPolygon: () => {
                setSavedPolygons(savedPolygons);
            },
            square: () => {
                setPolygons([]);
            },
        });
    }, [options]);

    const clearShapes = () => {
        setPolygons([]);
    };

    const clearData = () => {
        setResponses({});
    };

    useLatestMapChangesV2(channelId, (response) => {
        setResponses({ ...responses, [response.databaseId]: response });
        setRecentResponse(response);
    });

    const [execute] = useLazyQuery<
        { fetchMapUpdates: boolean },
        { input: NewFilterObjectConnection }
    >(FetchMapUpdatesDocument);

    const sendMultiPolygons = (
        polygons: (InputPolygonWithId | InputPolygon)[]
    ) => {
        const multipolygon: MultiPolygon = {
            polygons: polygons.map((p) => ({
                inners: p.inners,
                outer: p.outer,
            })),
        };

        setLoading(true);
        socket.emit("updateViewPortV2", {
            dateTime: Date.now().toString(),
            channel: channelId,
            multiFilters,
            options,
            requestId: uuidV4(),
            isStreaming: false,
            multipolygon,
        });

        if (multiFilters.some((d) => d.filters.length > 0))
            execute({
                variables: {
                    input: {
                        multipolygon: multipolygon.polygons,
                        dateTime: Date.now().toString(),
                        channelId: channelId,
                        filters: multiFilters,
                        options,
                    },
                },
            });
    };

    socket.on("message-back", () => {
        dispatch(
            commonActions.setSearchTimestamp({
                timestamp: Date.now(),
            })
        );
        setLoading(false);
    });
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        isEmpty(polygons)
            ? handleViewportChange({ viewport })
            : sendMultiPolygons(polygons);
    }, [polygons]);

    const handleMultiPolygons = ({
        polygon,
    }: {
        polygon: InputPolygonWithId | InputPolygonWithId[];
    }) => {
        setPolygons((prev) => updateArray(polygon, prev));
    };

    const deleteShape = (id: string) => {
        setPolygons((prev) => prev.filter((p) => p.id !== id));
    };

    const handleSavedPolygons = (p: InputPolygonWithId[]) => {
        setPolygons(p);
    };

    const handlePolygonsOnZoom = (p: InputPolygon[]) => {
        /* Shouldn't set the polygons state because it relies on it to calculate the
         * union polygon and intersections.
         */
        sendMultiPolygons(p);
    };

    const handleViewportChange = ({ viewport }: { viewport: InputViewBox }) => {
        if (!isEmpty(polygons)) return;
        setViewport(viewport);
        setLoading(true);
        socket.emit("updateViewPortV2", {
            viewport,
            dateTime: Date.now().toString(),
            channel: channelId,
            multiFilters,
            options,
            requestId: uuidV4(),
            isStreaming: false,
        });
        if (multiFilters.some((d) => d.filters.length > 0))
            execute({
                variables: {
                    input: {
                        viewport,
                        dateTime: Date.now().toString(),
                        channelId: channelId,
                        filters: multiFilters,
                        options,
                    },
                },
            });
    };

    const handleSetDatasetPointsShapes = (datasetShape: DatasetPointShape) => {
        setDatasetPointsShapes((prev) =>
            pipe(
                prev,
                toNonReadonlyArray,
                A.filter((dataset) => !isEqual(dataset.id, datasetShape.id)),
                (datasets) => [
                    ...datasets,
                    {
                        id: datasetShape.id,
                        shape: datasetShape.shape,
                    },
                ]
            )
        );
    };

    return {
        polygons,
        handleMultiPolygons,
        clearData,
        responses,
        channelId,
        multiFilters,
        addRemoveDataset,
        addMultipleDatasets,
        setMultiFilters,
        handleViewportChange,
        viewport,
        recentResponse,
        savedPolygons,
        setSavedPolygons: (value) => {
            setSavedPolygons((p) => ({ ...p, ...value }));
        },
        options,
        setOptions,
        setFunctionType,
        functionType,
        deleteShape,
        clearShapes,
        handleSavedPolygons,
        datasetPointsShapes,
        setDatasetPointsShapes: handleSetDatasetPointsShapes,
        handlePolygonsOnZoom,
    };
};
